const saidas = [
  // {
  //   text: "Cupom Fiscal",
  //   icon: "mdi-cash",
  //   route: "/TaxCupom",
  //   class: "white--text",
  //   active: true,
  // },

  {
    text: "Cupom Fiscal",
    icon: "mdi-cash",
    route: "/TaxCupomActive",
    class: "white--text",
    active: true,
  },
  {
    text: "Controle de Trocas",
    icon: "mdi-arrow-left-right-bold",
    route: "/exchange-control",
    class: "white--text",
    active: true,
  },
  {
    text: "Vendas Futuras",
    icon: "mdi-arrow-left-right-bold",
    route: "/future-sales",
    class: "white--text",
    active: true,
  },
];
export { saidas };
