import axios from "axios";
const {
  GET_ALL_ALIQUOT,
  ADD_ALIQUOT,
  UPDATE_ALIQUOT,
  DESTROY_ALIQUOT,
  GET_FILTER_ALIQUOT,
  AUX_TO_UPDATE_ALIQUOT
} = require("./actions/aliquot");
const {
  SPLICE_ERRO
} = require("./actions/alert_api");

const moduleAliquot = {
  state: () => ({
    aliquots: Object(),
    auxToUpdate: Object(),
    aliquotToAdd: Object(),
  }),

  mutations: {
    GET_ALL_ALIQUOT: (state, payload) => {
      state.aliquots = payload;
    },

    GET_FILTER_ALIQUOT: (state, payload) => {

      if (payload !== undefined) {
        state.aliquots = payload
      }

    },

    ADD_ALIQUOT: (state, payload) => {
      state.aliquots.data.push(payload);
      //state.aliquotToAdd = payload
    },

    UPDATE_ALIQUOT(state, payload) {
      state.aliquots.data.Update(payload);
    },

    DESTROY_ALIQUOT: (state, id) => {
      let auxState = [...state.aliquots.data];

      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.aliquots = [...auxState];
    },

    AUX_TO_UPDATE_ALIQUOT: function (state, payload) {
      state.auxToUpdate = payload
    }
  },
  actions: {
    GET_ALL_ALIQUOT({
      commit
    }, payload) {
      axios.post("aliquot/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
        commit(GET_ALL_ALIQUOT, response.data[0]);
      });
    },

    GET_FILTER_ALIQUOT({
      commit
    }, payload) {
      axios.post("aliquot/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
        
      }).then((response) => {
        commit(GET_FILTER_ALIQUOT, response.data[0]);
      })
    },
    ADD_ALIQUOT: async function (context, payload) {
      try {
        await axios.post('aliquot/store', payload).then((response) => {
          
          context.commit(ADD_ALIQUOT, response.data[0]);
          context.commit(SPLICE_ERRO, {
            alert: "Alíquota adicionada com sucesso!",
          });
        })
      } catch (error) {
        console.log(error)
      }

    },

    UPDATE_ALIQUOT({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("aliquot/update", payload).then((response) => {
          resolve()

          commit(UPDATE_ALIQUOT, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Alíquota alterado com sucesso!",
          });
        }).catch((error) => {
          reject(error.response.data)
        })
      })

    },

    async DESTROY_ALIQUOT({
      commit
    }, payload) {

      await axios
        .post("aliquot/delete", {
          ...payload,
        }).then(() => {
          commit(DESTROY_ALIQUOT, payload.id);
          commit(SPLICE_ERRO, {
            alert: 'Alíquota deletada com sucesso!',
          })
        })

    },
    AUX_TO_UPDATE_ALIQUOT: function (context, payload) {
      
      context.commit(AUX_TO_UPDATE_ALIQUOT, payload)
    }
  },

  getters: {
    getAliquotToAdd: (state) => ({
      ...state.aliquotToAdd
    }),
    getAliquot: (state) => ({
      ...state.aliquots
    }),
    getAuxAliquotToUpdate: (state) => ({
      ...state.auxToUpdate
    }),
  },
};

export default moduleAliquot;