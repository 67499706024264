const estoque = [
  {
    text: "Saldo Estoque",
    icon: "mdi-chart-box",
    route: "/Stock_balance",
    class: "white--text",
    permission_col: [],
    active: true,
  },
  {
    text: "Movimentação estoque",
    icon: "mdi-truck-outline",
    route: "/move_stock",
    class: "white--text",
    permission_col: [],
    active: true,
  },
];

export { estoque };
