<template>
  <v-row>
    <v-dialog v-model="this.component.addDialog" persistent max-width="500">
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-card-title>Registrar Profissão</v-card-title>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="description"
                  outlined
                  label="*Descrição:"
                  :rules="[() => !!description || 'Campo Obrigatório']"
                ></v-text-field>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    desactive_dialog();
                  }
                "
              >
                <v-icon>mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                :disabled="!valid"
                @click="
                  () => {
                    confirm_add();
                  }
                "
              >
                <v-icon>mdi-content-save</v-icon>
                Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_PROFESSION,
  UPDATE_PROFESSION,
} from "../../../store/actions/profession";

export default {
  props: { component: Object },

  data: () => ({
    valid: true,
    id: "",
    description: "",
  }),

  methods: {
    desactive_dialog() {
      this.component.addDialog = !this.component.addDialog;
    },

    confirm_add() {
      this.id == undefined
        ? this.create_profession()
        : this.update_profession();
    },

    async create_profession() {
      const profession = {
        description: this.description,
      };
      await this.$store.dispatch(ADD_PROFESSION, profession).then(() => {
        this.desactive_dialog();
      });
    },

    async update_profession() {
      const profession = {
        id: this.id,
        description: this.description,
      };
      await this.$store.dispatch(UPDATE_PROFESSION, profession).then(() => {
        this.desactive_dialog();
      });
    },

    getComponent() {
      return this.component;
    },

    validate() {
      this.$refs.form.validate();
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.description = this.object.description;
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
