var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(this._props.component.addDialog),callback:function ($$v) {_vm.$set(this._props.component, "addDialog", $$v)},expression:"this._props.component.addDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Modelo de nota fiscal")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Numero da Sequência","readonly":_vm.justVisible,"type":"number","outlined":"","rules":_vm.rulesSequence},model:{value:(_vm.sequence),callback:function ($$v) {_vm.sequence=$$v},expression:"sequence"}}),_c('v-text-field',{attrs:{"label":"*Descrição","readonly":_vm.justVisible,"outlined":"","rules":[
                () => !!_vm.description || 'Campo Obrigatório',
                () =>
                  (!!_vm.description && _vm.description.length >= 2) ||
                  'Campo tem que ser maior que 2',
              ]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{attrs:{"items":_vm.taxDocumentType,"item-text":"text","item-value":"taxDocumentType","readonly":_vm.justVisible,"label":"*Tipo de Documento Fiscal","outlined":""},model:{value:(_vm.tax_document_type),callback:function ($$v) {_vm.tax_document_type=$$v},expression:"tax_document_type"}}),_c('v-select',{attrs:{"items":_vm.typeModel,"item-text":"text","item-value":"value","readonly":_vm.justVisible,"label":"*Modelo da nota","outlined":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-text-field',{attrs:{"label":"*Série","readonly":_vm.justVisible,"outlined":"","rules":[
                () => !!_vm.series || 'Campo Obrigatório',
                () =>
                  (!!_vm.series && _vm.series.length >= 1) ||
                  'Campo tem que ser maior do que 1',
              ]},model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}}),_c('v-select',{attrs:{"outlined":"","label":"Empresa","item-value":"id","item-text":"fantasyName","readonly":_vm.justVisible,"loader-height":"","items":_vm.enterprises},model:{value:(_vm.enterprise_id),callback:function ($$v) {_vm.enterprise_id=$$v},expression:"enterprise_id"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.close();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }