<template>
  <div>
    <v-responsive>
      <v-data-table 
      :hide-default-footer="true" 
      :headers="headers" 
      :items="results">
        <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="blue"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  component.visibleItem(item)
                }
              "
            >mdi-information-outline</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
      </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-pagination v-model="current" color="success" :length="total" :total-visible="10"></v-pagination>
    </v-responsive>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {

  props: {
    headers: Array(),
    lengthPaginator: Number(),
    component: Object()
  },

  name: "Table",
  data: function () {
    return {
      page: 1,
      results: Array(),
      current: Number()
    };
  },
  computed: {
    ...mapGetters(["getAllSequenceInvoice"]),
  },
  watch: {
    getAllSequenceInvoice: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.search) {
        this.component.cancal_sequence_filter && this.component.cancal_sequence_filter(val);
      } else {
        this.component.getAllCancelSequence && this.component.getAllCancelSequence(val);
      }
    }
  }

};
</script>

<style></style>