import apifiscal from "../apifiscal"
const {
    GET_NFCE_WITH_KEY
} = require("./actions/nfce")
const moduleNfce = {
    state: () => ({
        infoNfce: Object(),
    }),
    mutations: {
        GET_NFCE_WITH_KEY: (state, payload) => {
            state.infoNfce = payload
          },
    },
    actions: {
        GET_NFCE_WITH_KEY ({commit}, payload){
            let token = localStorage.getItem("token_fiscal");
            apifiscal.post("/noteissuenfce/getNfceConsultKey", payload, {
                headers: {
                  'Authorization': 'Bearer ' + token,
        
                }
              }).then((response) => {
                commit(GET_NFCE_WITH_KEY, response.data.nfes[0]);
              })
        }  
    },
    getters: {
        getStatusNFce: (state) => ({
            ...state.infoNfce,
          }),
    }
}
export default moduleNfce