<template>
<div>
   <Dashboard/>
</div>
</template>

<script>
import Dashboard from "../components/Dashboard/Dashboard"

export default {
    components:{
        Dashboard,
    }
    
}
</script>

<style scoped>

</style>