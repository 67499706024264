
module.exports = {

    state: () => ({
        error: Array(),
    }),

    mutations: {
        ADD_MESSAGE: (state, payload) => {
            state.error = payload

        },
        RESET_MESSAGE: (state) => {
            state.error = Array()
        }
    },

    getters: {
        getError: function (state) {
            return state.error
        }
    }
}