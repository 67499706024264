<template>
  <v-container>
     <v-divider></v-divider>
  <v-form ref="form" v-model="component.validPersonal">
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.physical_people.motherName"
          :readonly="component.justVisible"
          label="Nome da Mãe"
          
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.physical_people.fatherName"
          :readonly="component.justVisible"
          label="Nome do Pai"
          
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-select
          v-model="component.physical_people.civilStatus"
          :readonly="component.justVisible"
          label="* Estado Civil"
          :rules="[() => !!component.physical_people.civilStatus || 'Campo Obrigatorio']"
          outlined
          :items="items_civilStatus"
          required
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.physical_people.spouse"
          :readonly="component.justVisible"
          label="Cônjugue"
          
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="component.physical_people.icomeSpouse"
          :readonly="component.justVisible"
          label="Renda Cônjugue"
          
          prefix="R$"
          :rules="incomeNegative"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>

  </v-container>
</template>

<script>
import { incomeNegative } from "../TabGeral/script";
export default {
  props: {
    component: Object(),
    justVisible: Boolean()
  },

  data: () => ({
    items_civilStatus: [
      "Casado(a)",
      "Divorciado(a)",
      "Solteiro(a)",
      "Viúvo(a)",
    ],
    incomeNegative,
  }),

  methods: {
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>