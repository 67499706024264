import { GET_ALL_CST } from "../../../store/actions/cst";
import { AUX_TO_UPDATE_CST } from "../../../store/actions/cst";


function visibleItem(item){
    this.$store.dispatch(AUX_TO_UPDATE_CST, {item: item, justVisible: true})
    this.$store.dispatch("setShowDialogRegisterCST", true);
  }

function open_Dialog(item) {
    this.$store.dispatch("setShowDialogRegisterCST", true);
    this.$store.dispatch(AUX_TO_UPDATE_CST, {});
    this.$store.dispatch(AUX_TO_UPDATE_CST, item);
}
function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}

async function getAllCST() {
    const payload = {
        page: 1,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_CST, payload);
}



export default {
    open_Dialog,
    deleteItem,
    handleClick,
    getAllCST,
    visibleItem,
}