<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <v-switch
          v-model="isFormalPeople"
          label="Pessoa Jurídica"
          color="primary"
        ></v-switch>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          @keyup.enter="setAutocomplete()"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/transport/CardTitle/";
import Table from "../components/transport/Table/";
import loadScreen from "../components/load/";
import AlertAPI from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
export default {
  components: {
    Container,
    CardTitle,
    loadScreen,
    Table,
    AlertAPI,
  },
  data: () => ({
    searchInput: "",
    search: "",
    isFormalPeople: false,
    enterpriseId: Number(),
    permission: Object(),
  }),
  computed: {
    ...mapGetters(["getCadastrosLinks"]),
  },
  methods: {
    setAutocomplete() {
      if(this.searchInput.length >= 2) {
        this.search = this.searchInput
      }
    },
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

