import { LegalPeople, PhysicalPeople, address, banks, contact, enterprise_id, enterprises, new_city, provider, register, valid, validLegal, validPhysical, validTable } from "./WipeData"

function itemToUpdate(item) {
    if (item) {
        if (item.type_people === "PhysicalPeople") {
            this.register = false;
            this.PhysicalPeople = { ...item.physicalpeople }
            this.provider.relatedRepresentative = item.relatedRepresentative;
            this.provider.type = item.type;
            this.provider.id = item.id
            this.provider.people_id = item.people_id
            this.provider.type_people = item.type_people;
            this.address = { ...item.address[0] }
            this.contact = { ...item.contacts[0] }
            this.banks = { ...item.banks[0] }
            this.enterprise_id = item.enterprise_id;
        } else if (item.type_people === "LegalPeople") {
            this.register = true;
            this.LegalPeople = { ...item.legalpeople }
            this.provider.relatedRepresentative = item.relatedRepresentative;
            this.provider.type = item.type;
            this.provider.people_id = item.people_id
            this.provider.id = item.id
            this.provider.type_people = item.type_people;
            this.enterprise_id = item.enterprise_id;
            this.address = { ...item.address[0] }
            this.contact = { ...item.contacts[0] }
            this.banks = { ...item.banks[0] }
        }
    }
}

function wipeData() {
    this.register = register
    this.valid = valid
    this.validPhysical = validPhysical
    this.validLegal = validLegal
    this.validTable = validTable
    this.enterprises = enterprises
    this.enterprise_id = enterprise_id
    this.provider = provider
    this.PhysicalPeople = PhysicalPeople
    this.LegalPeople = LegalPeople
    this.address = address
    this.banks = banks
    this.contact = contact
    this.new_city = new_city
}


export default {
    itemToUpdate,
    wipeData
}





        // this.PhysicalPeople.people_id = item.people_id;
        // this.PhysicalPeople.id = item.id
        // this.PhysicalPeople.type_people = item.type_people;
        // this.PhysicalPeople.enterprise_id = item.enterprise_id;
        // this.PhysicalPeople.name = item.name;
        // this.PhysicalPeople.lastName = item.lastName;
        // this.PhysicalPeople.cpf = item.cpf;
        // this.PhysicalPeople.gender = item.gender;
        // this.PhysicalPeople.emitter = item.emitter;
        // this.PhysicalPeople.emitterState = item.emitterState;
        // this.PhysicalPeople.idCard = item.idCard;
        // this.PhysicalPeople.dateOfBirth = item.dateOfBirth;
        // this.PhysicalPeople.fatherName = item.fatherName;
        // this.PhysicalPeople.motherName = item.motherName;
        // this.PhysicalPeople.nacionality = item.nacionality;
        // this.PhysicalPeople.naturality = item.naturality;
        // this.PhysicalPeople.monthlyIncome = item.monthlyIncome;
        // this.PhysicalPeople.civilStatus = item.civilStatus;
        // this.PhysicalPeople.spouse = item.spouse;
        // this.PhysicalPeople.icomeSpouse = item.icomeSpouse;



        // this.LegalPeople.people_id = item.people_id;
        // this.LegalPeople.id = item.id
        // this.LegalPeople.enterprise_id = item.enterprise_id;
        // this.LegalPeople.cnpj = item.cnpj;
        // this.LegalPeople.fantasyName = item.fantasyName;
        // this.LegalPeople.socialName = item.socialName;
        // this.LegalPeople.stateRegistration = item.stateRegistration;
        // this.LegalPeople.municipalRegistration = item.municipalRegistration;
        // this.LegalPeople.homePage = item.homePage;