<template>
  <div>
    <v-data-table :headers="headers" :items="results" no-data-text="Sem registro!"
      no-results-text="Nenhum resultado encontrado" hide-default-footer :loading="component.loading"
      loading-text="Carregando os dados!" @click:row="handleClick">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>

          <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" class="mr-1" v-bind="attrs" v-on="on" @click="viewInfo(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" @click="changeReverse(item)" v-on="on">mdi-cancel</v-icon>
          </template>
          <span>Cancelar NFe</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" v-bind="attrs" @click="changePrintNFe(item)" v-on="on">mdi-printer</v-icon>
          </template>
          <span>Imprimir</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" v-bind="attrs" @click="changeReprocessNFe(item)"
              v-on="on">mdi-note-edit</v-icon>
          </template>
          <span>Reprocessamento NFe</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination class="mb-6" v-model="current" :length="total" color="success" :total-visible="10"></v-pagination>
    <ViewDialog v-if="viewDialog" :dialog="viewDialog" :object="ObjectToView" :changeDialog="alterDialogView"
      title="Informações do" :component="this" :disabled="true" />
    <CancelRevenues :component="this" />
  </div>
</template>

<script>
import ViewDialog from "../AddDialog/";
import ReverseNote from "../AddDialog/ConfirmAction/";
import CancelRevenues from "../AddDialog/View_cancel/"
import { GET_NFE_WITH_KEY } from "../../../store/actions/nfe";
import { REFRESH_REVENUES_ITENS } from "../../../store/actions/revenuesitens";
import { REVERSE_REVENUE, REVENUES_UPDATE_NFE, GET_ALL_NOT_EMITTER_NFE } from "../../../store/actions/revenues";
import { PRINT_NFE, REPROCESS_NFE_BEFORE_ERROR_DATA, REPROCESS_NFE_WITH_KEY } from "../../../store/actions/nfe"
import { mapGetters } from "vuex";


export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    headers: Array,
    change_addDialog: Function,
    isLoad: Boolean,
  },
  components: {
    ViewDialog,
    ReverseNote,
    CancelRevenues
  },

  data: () => ({
    destroyDialog: false,
    viewDialog: Boolean(),
    dialogReverse: Boolean(),
    ObjectToView: Object(),
    enterpriseId: Number(),
    dialog_cancel: Boolean(),
    results: Array(),
    total: Number(),
    current: Number(),
    nfePrint: Object(),
    dialog: Boolean(),
    objectNfeCancel: Object(),
    confirmDialog: Boolean(),
    id: Number(),
    itemSelectedOnClick: Object(),
    idReverse: Number(),
  }),
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  methods: {
    changePrintNFe(item) {
      let payload = {
        cnpjEmitter: this.component.enterpriseObject.cnpj,
        keyNfe: item.access_key,
        modelNf: "55"
      }
      if (item.status === "Autorizada") {
        this.$store.dispatch(PRINT_NFE, payload).then((response) => {
          this.nfePrint = response.response.data.nfe;
          window.open(this.nfePrint);
        }).catch((error) => {
        })
      } else {
        alert("Essa nota não pode ser impressa!");
      }



    },

    alterDialogView() {
      this.viewDialog = !this.viewDialog;
    },

    dialogDelete(item) {
      this.dialog = !this.dialog;
      this.id = item.id;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },

    changeReverse(item) {
      if (item.status == "Autorizada") {
        let checkTimeRevenesActiveCancel = this.checkTimeReverseRevenues(item.created_at)
        if (checkTimeRevenesActiveCancel == true) {
          this.objectNfeCancel = { ...item }
          this.dialog_cancel = !this.dialog_cancel;
        } else if (checkTimeRevenesActiveCancel == false) {
          alert("Não e possivel cancelar o faturamento tempo excedido")
        }
      } else {
        alert("Não e possivel cancelar o faturamento - status não permitido")
      }

    },
    checkTimeReverseRevenues(timeCreateRevenues) {
      let dataHoraRevenuesCreate = new Date(timeCreateRevenues);
      let dataNowCheck = Date.now();
      let timeTotalCreateTenant = dataNowCheck - dataHoraRevenuesCreate
      let timeCalculeCheck = timeTotalCreateTenant / 60000
      if (timeCalculeCheck >= 30) {
        return false

      }
      return true
    },
    async changeReprocessNFe(item) {
      if (item.status === "Rejeitada") {
        await this.$store.dispatch(REPROCESS_NFE_WITH_KEY,item).then((response)=>{
          item.access_key = response.data.nfe.key;
          item.status = response.data.nfe.status;
          item.number_grade = response.data.nfe.numberNfe;
          item.authorization_protocol = response.data.nfe.numberProtocol;
          item.series = response.data.nfe.seriesDocumentSupervisior;
          this.$store.dispatch(REVENUES_UPDATE_NFE, item);
          this.nfePrint = response.data.nfepdf;
          window.open(this.nfePrint)
        }).catch((error) => {
          alert("erro ao processar nfe pre pedido")
        })


      } else if (item.status === "Pre-pedido") {
        item.status = "Em Digitacao"
        await this.$store.dispatch(REPROCESS_NFE_BEFORE_ERROR_DATA, item).then((response) => {
          item.access_key = response.data.nfe.key;
          item.status = response.data.nfe.status;
          item.number_grade = response.data.nfe.numberNfe;
          item.authorization_protocol = response.data.nfe.numberProtocol;
          item.series = response.data.nfe.seriesDocumentSupervisior;
          this.$store.dispatch(REVENUES_UPDATE_NFE, item);
          this.nfePrint = response.data.nfepdf;
          window.open(this.nfePrint)
        }).catch((error) => {
          alert("erro ao processar nfe pre pedido")
        })
      } else {
        alert("Essa nota não pode ser reprocessada");
      }

    },
    async confirmReverse() {
      try {
        await this.$store.dispatch(REVERSE_REVENUE, this.idReverse);
      } finally {
        this.dialogReverse = !this.dialogReverse;
      }
    },
    refreshRevenusItem(item) {
      let listItens = [...item.revenus_item];

      listItens.forEach((e) => {
        e.description_item = e.produto.description_item;
        e.value_products = e.value_subtotal_product;
        e.venda_sale = e.value_sale;
        e.description_unit = e.produto.unit.sigla;
        e.base_icms_product = e.value_base_icms_product;

        if (e.cst_id === null) {
          e.cst_code = e.produto.csons.code;
        } else {
          e.cst_code = e.produto.cst.code;
        }
      });
      this.$store.dispatch(REFRESH_REVENUES_ITENS, listItens);
    },
    async viewInfo(item) {
      if(item.status === "Rejeitada"){
      let payload = {
        cnpjEmitter : item.enterprise.cnpj,
        keyNfe: item.access_key,
        modelNf: "55",
      }
      await this.$store.dispatch(GET_NFE_WITH_KEY, payload)
      }
      this.ObjectToView.id = item.id;
      this.ObjectToView.status = item.status;
      this.ObjectToView.type_grade_id = item.type_grade_id;
      this.ObjectToView.finality_nf = item.finality_nf;
      this.ObjectToView.date_of_issue = item.date_of_issue;
      this.ObjectToView.access_key = item.access_key;
      this.ObjectToView.authorization_protocol = item.authorization_protocol;
      this.ObjectToView.series = item.series;
      this.ObjectToView.value_total_products = item.value_total_products;
      this.ObjectToView.value_book = item.value_book;
      this.ObjectToView.value_total_note = item.value_total_note;
      this.ObjectToView.value_discount = item.value_discount;
      this.ObjectToView.specie_note = item.specie_note;
      this.ObjectToView.value_icms_substituicao_total =
        item.value_icms_substituicao_total;
      this.ObjectToView.value_freigth = item.value_freigth;
      this.ObjectToView.base_calc_icms_total = item.base_calc_icms_total;
      this.ObjectToView.base_calc_susbt_total = item.base_calc_susbt_total;
      this.ObjectToView.value_icms_free_total = item.value_icms_free_total;
      this.ObjectToView.value_account_total = item.value_account_total;
      this.ObjectToView.value_outher_tax_total = item.value_outher_tax_total;
      this.ObjectToView.value_icms_total = item.value_icms_total;
      this.ObjectToView.note = item.note;
      this.ObjectToView.value_security = item.value_security;
      this.ObjectToView.value_ipi_total = item.value_ipi_total;
      this.ObjectToView.value_ipi_outher_total = item.value_ipi_outher_total;
      this.ObjectToView.value_ipi_free_total = item.value_ipi_free_total;
      this.ObjectToView.base_calc_ipi_total = item.base_calc_ipi_total;
      this.ObjectToView.vehicle_plate = item.vehicle_plate;
      this.ObjectToView.volume_transported = item.volume_transported;
      this.ObjectToView.gross_weight = item.gross_weight;
      this.ObjectToView.net_weight = item.net_weight;
      this.ObjectToView.volume_type = item.volume_type;
      this.ObjectToView.transporter_id = item.transporter_id;
      this.ObjectToView.client_id = item.client_id;
      this.ObjectToView.payment_terms_id = item.payment_terms_id;
      this.ObjectToView.tax_operation_types_id = item.tax_operation_types_id;
      this.ObjectToView.account_plans_id = item.account_plans_id;
      this.ObjectToView.enterprise_id = item.enterprise_id;
      this.ObjectToView.cliente = item.cliente;
      this.ObjectToView.value_other_incidental_expenses =
        item.value_other_incidental_expenses;
      this.ObjectToView.value_icms_outher_product =
        item.value_icms_outher_product;
      this.refreshRevenusItem(item);
      this.viewDialog = !this.viewDialog;
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.statusNFe == "Todos" && this.component.search == "") {
        this.component.GetAll && this.component.GetAll(val);
      } else if(this.component.search.length >= 2) {
        this.component.revenues_filter && this.component.revenues_filter(val);
      } else {
        this.component.getAllStatusNotEmitter(this.component.statusNFe, val)
      }
    },
  },
};

//<ReverseNote v-if="dialogReverse" :dialog="dialogReverse" title="Deseja estornar esta Nota?"
//subtitle="A nota será estornada." :alterDialog="changeReverse" :confirm="confirmReverse" />
</script>
