import axios from "axios";
import {
  SPLICE_ERRO
} from "./actions/alert_api";

const {
  GET_ALL_NCM,
  ADD_NCM,
  UPDATE_NCM,
  DESTROY_NCM,
  GET_FILTER_NCM,
  AUX_TO_UPDATE_NCM,
} = require("./actions/ncm");

const moduleNcm = {
  state: () => ({
    ncms: {},
    auxToUpdateNcm: Object(),
  }),

  mutations: {
    GET_ALL_NCM: (state, ncms) => {
      state.ncms = ncms;
    },
    GET_FILTER_NCM: (state, payload) => {
      if (payload !== undefined) {
        state.ncms = payload

      }
    },
    ADD_NCM: (state, newNcms) => {
      if (state.ncms.data) {
        state.ncms.data.push(newNcms.data[0])
      } else {
        state.ncms.push(newNcms.data[0])
      }
    },
    UPDATE_NCM(state, editNCM) {
      state.ncms.data.Update(editNCM)
    },
    DESTROY_NCM: (state, id) => {
      let auxState = [...state.ncms.data]
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.ncms = [...auxState];
    },

    AUX_TO_UPDATE_NCM: function (state, payload) {
      state.auxToUpdateNcm = payload
    }
  },

  actions: {
    async GET_ALL_NCM({
      commit
    },
      payload
    ) {

      try {
        const response = await axios.post("ncm/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
        
        commit(GET_ALL_NCM, response.data[0]);

        return response.data[0]
      } catch (error) {
        
      }

    },

    async GET_FILTER_NCM({
      commit
    }, payload) {
      try {

        const response = await axios.post("ncm/autocomplete", {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            paginate: payload.paginate
        })
          commit(GET_FILTER_NCM, response.data[0]);
          return response.data[0]
      } catch (error) {
        
      }
    },

    ADD_NCM({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("ncm/store", payload).then((response) => {
          resolve()
          commit(ADD_NCM, response);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_NCM({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("ncm/update", payload).then((response) => {
          resolve()
          commit(UPDATE_NCM, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_NCM({
      commit
    }, payload) {
      axios
        .post("ncm/delete", payload.id,
      )
        .then((response) => {
          commit(DESTROY_NCM, response.data);
          commit(SPLICE_ERRO, {
            alert: 'Deletado com Sucesso',
            type: 'S'
          })
        })
    },
    AUX_TO_UPDATE_NCM: function (context, payload) {
      context.commit(AUX_TO_UPDATE_NCM, payload)
    }
  },

  getters: {
    getNcm: (state) => ({
      ...state.ncms
    }),

    getAuxNcmToUpdate: (state) => state.auxToUpdateNcm
  },
};

export default moduleNcm;