import { SET_REPORT_COMISSION_SELLERS } from "./actions/report_comission_sellers";
import { SPLICE_ERRO } from "./actions/alert_api";
import axios from "axios";

export default {
  state: {
    dataReportComissionSellers: Object(),
  },
  getters: {
    getDataReportComissionSellers: function (state) {
      return state.dataReportComissionSellers;
    },
  },
  mutations: {
    SET_REPORT_COMISSION_SELLERS: function (state, payload) {
      state.dataReportComissionSellers = payload;
    },
  },
  actions: {
    SET_REPORT_COMISSION_SELLERS: async function (context, payload) {
      try {
        const response = await axios.post("sales/salescommission", payload)

        context.commit(SET_REPORT_COMISSION_SELLERS, response);
        context.commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
          type: 'S'
        })

        if(response.data[0].length < 1){
          // alert('Nenhum item Encontrado')
          context.commit(SPLICE_ERRO, {
            alert: 'Nenhum item Encontrado',
            type: 'S'
          })
        }

        return response.data[0]

      } catch (error) {
        return 'error:' + error
      }

    },
  },
};