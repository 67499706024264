const nameRules = [(v) => !!v || "Nome é obrigatório"];

const LastNameRules = [(v) => !!v || "Sobrenome é obrigatório"];

const cpfRules = [
  v => !!v || 'CPF é obrigatório',
  v => v.length == 14 || 'O CPF deve possuir 11 caracteres'

]

const incomeNegative = [
  v => {
    if (v < 0) {
      return 'Valores negativos não são permitidos!'
    } else {
      return true
    }
  }
]

export { nameRules, LastNameRules, cpfRules, incomeNegative };
