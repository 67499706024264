<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Condição Pagamento</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Codigo"
                v-model="code"
                :readonly="justVisible"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              ></v-text-field>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="*Acrescimo"
                type="number"
                v-model="addition"
                :readonly="justVisible"
                :rules="[() => addition >= 0 || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-select
                label="*Forma de Pagamento"
                type="number"
                :items="formPaymentType"
                item-text="description"
                item-value="id"
                v-model="formPayment"
                :rules="[(v) => !!v || 'Campo Obrigatorio']"
                outlined
              ></v-select>
              <div style="display: flex; justify-content: center">
                <v-text-field
                  label="*Prazo"
                  v-model="deadline"
                  :rules="[() => !!deadline || 'Campo Obrigatorio']"
                  readonly
                  outlined
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openAddDeadline"
                      class="mt-3"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Prazos</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    this.close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <Deadline :component="this" v-if="dialogDeadline" />
  </v-row>
</template>

<script>
import Deadline from "./Deadline/index";
import {GET_ALL_FORM_OF_PAYMENT } from "../../../store/actions/formofpayment"
import {
  ADD_PAYMENT_TERM,
  AUX_TO_UPDATE_PAYMENTTERM,
  UPDATE_PAYMENT_TERM,
} from "../../../store/actions/paymentterm";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
  },

  components: {
    Deadline,
  },

  data() {
    return {
      disabled: false,
      dialogDeadline: false,
      attrs: false,
      on: false,
      valid: true,
      id: String(),
      code: String(),
      description: String(),
      addition: String(),
      amount: Number(),
      formPaymentType: Array(),
      // [
      //   { description: "À vista", value: "01" },
      //   { description: "Cartão à vista", value: "03" },
      //   { description: "Cartão à prazo", value: "09" },
      // ],
      formPayment: String(),
      search: String(),
      justVisible: Boolean(),
    };
  },

  computed: {
    ...mapGetters(["getAuxPaymenttermToUpdate"]),
  },
  methods: {
    getComponent() {
      return this._props.component;
    },
    close() {
      this.component.addDialog = false;
      this.justVisible = false;
      this.$store.dispatch(AUX_TO_UPDATE_PAYMENTTERM, Object());
    },
    async create_paymentterm() {
      //À vista
      //Cartão à vista
      //Cartão à prazo

      const paymentterm = {
        form_of_payment_id: parseInt( this.formPayment),
        description: this.description,
        code: this.code,
        addition: this.addition,
        amount: this.amount,
        deadline: this.deadline,
      };
      await this.$store.dispatch(ADD_PAYMENT_TERM, paymentterm).then(() => {
        this.close();
      });
    },
    submit() {
      this.id == undefined
        ? this.create_paymentterm()
        : this.update_paymentterm();
    },
    async update_paymentterm() {
      const paymentterm = {
        form_of_payment_id: parseInt( this.formPayment),
        description: this.description,
        code: this.code,
        addition: this.addition,
        amount: this.amount,
        deadline: this.deadline,
        id: this.id,
      };
      await this.$store.dispatch(UPDATE_PAYMENT_TERM, paymentterm).then(() => {
        this.close();
      });
    },

    openAddDeadline() {
      this.dialogDeadline = !this.dialogDeadline;
    },

    // adjust_object() {
    //   this.object = { ...this.getComponent().objectToUpdate };

    //   this.id = this.object.id;
    //   this.description = this.object.description;
    //   this.addition = this.object.addition;
    //   this.amount = this.object.amount;
    //   this.deadline = this.object.deadline;
    //   if (this.id == undefined) {
    //     this.code = this.object.code;
    //   } else {
    //     this.code = this.object.code.toString();
    //   }
    // },

    autoload(payload) {
      if (payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.code = payload.item.code;
        this.description = payload.item.description;
        this.addition = payload.item.addition;
        this.deadline = payload.item.deadline;
      } else {
        this.code = payload.code;
        this.description = payload.description;
        this.addition = payload.addition;
        this.deadline = payload.deadline;
        this.id = payload.id;
      }
    },

    getAllFormPayment(page = 1){
      const payload = {
        page,
        limit: 10,
        paginate: true
      }
      this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, payload).then((response ) => {
        this.formPaymentType = response.data
      })
    }
  },

  async created() {
    this.autoload(this.getAuxPaymenttermToUpdate);
    this.getAllFormPayment()
  },

  watch: {
    amount: function (val) {
      if (!val) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
