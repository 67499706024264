import {GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise"

async function GetAllNoPageEnterprise() {
    let value = {
      page: null,
      limit: null,
      paginate: false,
    };
    await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
  }
  function getComponent() {
    return this._props.component;
  }
  function getTitle() {
    return this.getComponent().title;
  }
  function open_Dialog() {
    this.alterAddDialog(this.getComponent());
  }
  function alterAddDialog(component) {
    component.addDialogNcm = !component.addDialogNcm;
  }



export default {
GetAllNoPageEnterprise,
getComponent,
getTitle,
open_Dialog,
alterAddDialog,
}