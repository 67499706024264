


import { ADD_UNIT, UPDATE_UNIT, ITEM_UNIT_TO_UPDATE } from "../../../store/actions/unit";

function getComponent() {
    return this.component;
}
function autoLoad(value) {

    if (value.justVisible) {
        this.justVisible = value.justVisible;
        this.sigla = value.item.sigla;
        this.description = value.item.description;
    } else {
        this.id = value.id
        this.sigla = value.sigla
        this.description = value.description
    }
}
function close() {
    this.$refs.form.reset()
    this.$store.dispatch(ITEM_UNIT_TO_UPDATE, Object())
    this.$store.dispatch("setShowDialogRegisterUnit", false)
    this.justVisible = false
}
async function create_unit() {
    const unit = {
        sigla: this.sigla,
        description: this.description,
    };

    await this.$store.dispatch(ADD_UNIT, unit).then(() => {
        this.component.UnitMeasurementObject = unit;
        this.close();
    });
}
function submit() {
    this.id == undefined ? this.create_unit() : this.update_unit();
}
function setItemToUpdate(value) {
    this.id = value.id
    this.sigla = value.sigla
    this.description = value.description
}
async function update_unit() {
    const unit = {
        id: this.id,
        description: this.description,
        sigla: this.sigla,
    };
    await this.$store.dispatch(UPDATE_UNIT, unit).then(() => {
        this.component.GetAll()
        this.close();
    });
}


export default {
    getComponent,
    autoLoad,
    close,
    create_unit,
    submit,
    setItemToUpdate,
    update_unit,
}