




var Data = {
    search: "",
    searchInput: "",
    code: "",
    getter: "getCashier",
    addDialog: false,
    objectToUpdate: {},
    filter: "",
    title: "Caixa",
    headers: [
        { text: "Descrição", value: "description" },
        { text: "Código", value: "code" },
        { text: "Saldo inicial", value: "initial_balance" },
        { text: "Data inicial", value: "initial_date" },
        { text: "Saldo atual", value: "balance_current" },
        { text: "Ações", value: "actions" }
    ],
    permission: Object()
}


export { Data }