/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_CASHIER_OPERATION_TYPE,
  UPDATE_CASHIER_OPERATION_TYPE,
  GET_ALL_CASHIER_OPERATION_TYPE,
  DESTROY_CASHIER_OPERATION_TYPE,
  GET_FILTER_CASHIER_OPERATION_TYPE,
  AUX_TO_UPDATE_CASHIER_OPERATION_TYPE
} = require("./actions/cashier_operation_type");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCashierOperationType = {
  state: () => ({
    cashier_operation_types: Object(),
    auxToUpdateCashierOperationType: Object(),
  }),

  mutations: {
    GET_ALL_CASHIER_OPERATION_TYPE: (state, cashier_operation_types) => {
      state.cashier_operation_types = cashier_operation_types;
    },
    GET_FILTER_CASHIER_OPERATION_TYPE: (state, payload) => {
      state.cashier_operation_types = payload
    },
    ADD_CASHIER_OPERATION_TYPE: (state, newCashier_operation_types) => {
      state.cashier_operation_types = newCashier_operation_types;
    },
    UPDATE_CASHIER_OPERATION_TYPE(state, editCashier_operation_types) {
      state.cashier_operation_types.data.Update(editCashier_operation_types);
    },
    DESTROY_CASHIER_OPERATION_TYPE: (state, id) => {
      let auxState = [...state.cashier_operation_types.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id)
      );
      if (index >= 0) {
        auxState.splice(index, 1);
        state.cashier_operation_types = [...auxState]
      }
    },

    AUX_TO_UPDATE_CASHIER_OPERATION_TYPE: function (state, payload) {
      state.auxToUpdateCashierOperationType = payload
    }
  },

  actions: {
    async GET_ALL_CASHIER_OPERATION_TYPE({
      commit
    }, payload) {
      axios.post("cashierOperationType/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
        commit(GET_ALL_CASHIER_OPERATION_TYPE, response.data[0]);
      });
    },

    GET_FILTER_CASHIER_OPERATION_TYPE({
      commit
    }, payload) {
      axios.post("cashierOperationType/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_CASHIER_OPERATION_TYPE, response.data[0]);
      })
    },

    ADD_CASHIER_OPERATION_TYPE({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("cashierOperationType/store", payload).then((response) => {
          resolve()
          commit(ADD_CASHIER_OPERATION_TYPE, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
          });
        }).catch(() => {
          reject()
        })

      })
    },

    async UPDATE_CASHIER_OPERATION_TYPE({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("cashierOperationType/update", payload).then((response) => {
          resolve()
          commit(UPDATE_CASHIER_OPERATION_TYPE, response.data[0])
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!"
          })
        }).catch(() => {
          reject()
        })
      })

    },

    DESTROY_CASHIER_OPERATION_TYPE({
      commit
    }, payload) {
      axios
        .post("cashierOperationType/delete", payload)
        .then((response) => {
          commit(DESTROY_CASHIER_OPERATION_TYPE, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
          });
        })
    },

    AUX_TO_UPDATE_CASHIER_OPERATION_TYPE:function (context, payload) {
      context.commit(AUX_TO_UPDATE_CASHIER_OPERATION_TYPE, payload)
    }
  },

  getters: {
    getCashierOperationType: (state) => ({
      ...state.cashier_operation_types
    }),

    getAuxCashierOperationTypeToUpdate: (state) => state.auxToUpdateCashierOperationType
  },
};

export default moduleCashierOperationType;