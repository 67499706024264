import { GET_ALL_CASHIER, DESTROY_CASHIER, AUX_TO_UPDATE_CASHIER } from "../../../store/actions/cashier";
import { GET_FILTER_CASHIER } from "../../../store/actions/cashier";

async function destroy_cashier(item) {
    const car_adm = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_CASHIER, car_adm);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {
    item.initial_date = item.initial_date.split("/").reverse().join("-");
    this.addDialog = !this.addDialog;
    this.$store.dispatch(AUX_TO_UPDATE_CASHIER, item)
    // this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };

    await this.$store.dispatch(GET_ALL_CASHIER, value);
}

async function cashier_filter(page = 1) {
        let value = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_CASHIER, value);
    
}

function autocompleteCashier() {
    this.search = this.searchInput
}


export default {
    destroy_cashier,
    open_Dialog,
    GetAll,
    cashier_filter,
    autocompleteCashier,
}