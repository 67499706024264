<template>
    <v-dialog max-width="500px" v-model="component.selectTableShow">
        <v-card>
            <div class="container-header-select-table">
                <p class="title-select-table">{{ title }}</p>
                <div class="type-person-select-table">
                    <v-switch v-model="component.togleTypePeople" />
                    <p class="title-type-person-switch"> Pessoa Juridica </p>
                </div>
                <v-text-field hint="Pesquisar" append-icon="mdi-magnify" outlined />
            </div>
            <v-data-table @click:row="component.rowClickSelectTalbe" hide-default-footer :items="component.listPeople" :headers="component.headersSelectTable">
            </v-data-table>
            <v-pagination v-model="component.paginateInf.currentPage" :length="component.paginateInf.lastPage" />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        component: Object(),
        title: String(),
    },
    data: function () {
        return {
        }
    },
}
</script>

<style lang="css" scoped>
.title-select-table {
    text-align: start;
}

.container-header-select-table {
    padding: 5%;
}

.type-person-select-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.title-type-person-switch {
    font-size: 15px;
    margin-top: 10px;
}</style> >

.title-type-person-switch {
    font-size: 15px;
    margin-top: 10px;
}
</style> >