async function calculeTributeCofins(
  productRevenues,
  codeCstCofins,
  aliquot_cofins
) {
  let productTributeCofins = classificationCstCofins(
    codeCstCofins,
    productRevenues,
    aliquot_cofins
  );
  return productTributeCofins;
}

async function classificationCstCofins(
  codeCstCofins,
  productRevenues,
  aliquot_cofins
) {
  try {
    let cst_cofins_code_found = parseInt(codeCstCofins);

    if (cst_cofins_code_found >= 1 || cst_cofins_code_found <= 97) {
      return _normal_Cofins_Calculation(productRevenues, aliquot_cofins);
    } else if (cst_cofins_code_found >= 98 || cst_cofins_code_found <= 99) {
      return _others_Calculation_Cofins(productRevenues, aliquot_cofins);
    }
  } catch (error) {
    return error;
  }
}

async function _normal_Cofins_Calculation(productRevenues, aliquot_cofins) {
  try {
    productRevenues.aliquot_cofins = aliquot_cofins;
    productRevenues.value_base_cofins_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_cofins_product =
      productRevenues.value_base_cofins_product * (aliquot_cofins / 100);
    productRevenues.value_base_cofins_st_product = 0;
    productRevenues.value_cofins_st_product = 0;
    productRevenues.value_other_cofins_product = 0;
    productRevenues.value_free_cofins_product = 0;

    return productRevenues;
  } catch (error) {
    return error;
  }
}

async function _others_Calculation_Cofins(productRevenues, aliquot_cofins) {
  try {
    productRevenues.aliquot_cofins = aliquot_cofins;
    productRevenues.value_base_cofins_product = 0;
    productRevenues.value_cofins_product = 0;
    productRevenues.value_base_cofins_st_product = 0;
    productRevenues.value_cofins_st_product = 0;
    productRevenues.value_other_cofins_product =
      productRevenues.amount_add * productRevenues.value_sale;
    productRevenues.value_free_cofins_product = 0;

    return productRevenues;
  } catch (error) {
    return error;
  }
}

module.exports = {
  calculeTributeCofins,
};
