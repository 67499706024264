<template>
  <div>
    <v-form v-model="valid">
      <v-col>
        <v-text-field
          label="*Valor do Titulo"
          :readonly="component.justVisible"
          v-model="component.object.value_title"
          type="number"
          prefix="R$"
          outlined
          :rules="[() => !!component.object.value_title || 'Campo Obrigatorio']"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Abatimento"
          :readonly="component.justVisible"
          v-model="component.object.dejection"
          type="number"
          outlined
          :rules="[
            () => !!component.object.dejection || 'Campo Obrigatorio',
            () =>
              (!!component.object.dejection &&
                component.object.dejection.length >= 0) ||
              'Campo tem que ser maior do que 0',
          ]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Juros"
          :readonly="component.justVisible"
          v-model="component.object.interest"
          type="number"
          outlined
          :rules="[() => !!component.object.interest || 'Campo Obrigatorio']"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Valor Liquido"
          :readonly="component.justVisible"
          v-model="component.object.value_net"
          prefix="R$"
          readonly
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Saldo Titulo"
          :readonly="component.justVisible"
          v-model="component.object.title_balance"
          prefix="R$"
          readonly
          outlined
        ></v-text-field>
      </v-col>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    component: Object,
  },
  data() {
    return {
      valid: true,
    };
  },
  watch: {
    valid: function () {
      if (this.valid == true) {
        this.component.Validation_form.tab_Values = this.valid;
      }
    },
  },
};
</script>

<style>
</style>