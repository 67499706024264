var copyGeneralData = {
    additional_information: String(),
    tax_operation_types_id: Number(),
    access_key: String(),
    authorization_protocol: String(),
    provider_id: String(),
    number_grade: Number(),
    series: String(),
    date_of_issue: String(),
    entry_date: String(),
    enterprise_id: Number(),
}


module.exports = {
    copyGeneralData
}