import { CHANGE_LOAD } from "../../../store/actions/load";
import { GET_ALL_BANK, DESTROY_BANK, UPDATE_BANK, AUX_TO_UPDATE_BANK } from "../../../store/actions/bank";
import { GET_FILTER_BANK } from "../../../store/actions/bank";


async function destroy_bank(item) {
    const id = item.id;
    try {
        await this.$store.dispatch(DESTROY_BANK, id);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {

    this.addDialog = !this.addDialog;
    // this.objectToUpdate = item;
    this.$store.dispatch(AUX_TO_UPDATE_BANK, item)
}

async function update_object(item) {
    const bank = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(UPDATE_BANK, bank);
    } catch (error) {
        alert(error);
    }
}
async function GetAll(page = 1) {
    this.$store.commit(CHANGE_LOAD, true);
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };

    await this.$store.dispatch(GET_ALL_BANK, value);
}
async function bank_filter(page = 1) {
    page;
    if (this.filter == "") {
        this.GetAll();
    } else {
        let value = {
            search: this.filter,
            page: null,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_BANK, value);
    }
}


export default {
    destroy_bank,
    open_Dialog,
    update_object,
    GetAll,
    bank_filter,
}