import { AUX_TO_UPDATE_PIS_COFINS } from "../../store/actions/pis_cofins";

function visibleItem(item){
    this.$store.dispatch(AUX_TO_UPDATE_PIS_COFINS, {item: item, justVisible: true})
    this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
  }

function open_Dialog(item) {
    this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
    this.$store.dispatch(AUX_TO_UPDATE_PIS_COFINS, {});
    this.$store.dispatch(AUX_TO_UPDATE_PIS_COFINS, item);
}
function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}



export default {
    open_Dialog,
    deleteItem,
    handleClick,
    visibleItem,
}