




var Data = {
    selectValue: Object(),
    enterprises: []
}


export { Data }