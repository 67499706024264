const ADD_CST = "ADD_CST";
const UPDATE_CST = "UPDATE_CST";
const GET_ALL_CST = "GET_ALL_CST";
const DESTROY_CST = "DESTROY_CST";
const GET_FILTER_CST = "GET_FILTER_CST";
const AUX_TO_UPDATE_CST = "AUX_TO_UPDATE_CST"

module.exports = {
    AUX_TO_UPDATE_CST,
    ADD_CST,
    UPDATE_CST,
    GET_ALL_CST,
    DESTROY_CST,
    GET_FILTER_CST
}
