<template>
  <div>
    <!-- pay -->
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2 orange--text"
              v-bind="attrs"
              v-on="on"
              v-if="component.permission.Editar"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>
        
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs"
              v-on="on" color="green darken-4" class="mr-2" v-if="component.permission.Excluir" @click="
                  () => {
                    visibleItem(item);
                  }
                ">mdi-eye</v-icon>
          </template>
          <span>Visualizar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2 error--text"
              v-if="component.permission.Excluir"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green darken-4"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  change_downloadDialog(item);
                }
              "
              >mdi-download</v-icon
            >
          </template>
          <span>Baixar esse Titulo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    
    <v-pagination
      v-model="current"
      color="success"
      :length="total"
      :total-visible="10"
    ></v-pagination>
    <DestroyDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import DestroyDialog from "../DestroyDialog/";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    headers: Array,
    change_addDialog: Function,
    visibleItem: Function,
    change_downloadDialog: Function,
  },
  components: {
    DestroyDialog,
  },
  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    itemToUpade: Object(),
  }),
  methods: {
    executeAlterUpdateDialog(item) {
      const component = this.component;
      this.setObjectToUpdate(component, item);
      this.alterAddDialog(component);
      this.component.show_object();
    },

   

    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    downloadItem(item) {
      this.itemToDownload = item;
      this.component.downloadialog = true;
    },
    setIdToDestroy(component, id) {
      component.idToDestroy = id;
    },
    alterDestroyDialog(component) {
      component.destroyDialog = !component.destroyDialog;
    },
    setObjectToUpdate(component, object) {
      component.objectToUpdate = object;
    },
    alterUpdateDialog(component) {
      component.updateDialog = !component.updateDialog;
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    adjust_customer(item) {
      item.forEach((e) => {
        e.issuance_date = e.issuance_date.split("-").reverse().join("/");
        e.issuance_due = e.issuance_due.split("-").reverse().join("/");
        if (e.client.type_people === "LegalPeople") {
          e.client.name = e.client.people.legalpeople.fantasyName;
        } else {
          e.client.name = e.client.people.physicalpeople.name;
        }
      });
    },
    adjust_supplier(item) {
      item.forEach((e) => {
        e.issuance_date = e.issuance_date.split("-").reverse().join("/");
        e.issuance_due = e.issuance_due.split("-").reverse().join("/");
        if (e.provider.type_people === "LegalPeople") {
          e.provider.name = e.provider.people.legalpeople.fantasyName;
        } else {
          e.provider.name = e.provider.people.physicalpeople.name;
        }
      });
    },
    callAdjuster(val) {
      if (val.data.length !== 0) {
        if (val.data[0].client_id) {
          this.adjust_customer(val.data);
        } else if (val.data[0].provider_id) {
          this.adjust_supplier(val.data);
        }
      }
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.callAdjuster(val);
    },
    current: function (val) {
      if(this.component.search.length >= 2 ) {
        this.component.filterBillsToPay && this.component.filterBillsToPay(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
