




var Data = {
    justVisible: Boolean(),
    attrs: false,
    on: false,
    dialog: false,
    valid: true,
    formHasErrors: false,
    updated_at: "",
    created_at: "",
    id: "",
    code: "",
    description: "",
    initial_balance: Number(),
    balance_current: "0",
    initial_date: "",
    cashierType: "",
    itensSelect: ["Caixa Loja", "Caixa Tesouraria"]
}


export { Data }