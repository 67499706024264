import { AUX_TO_UPDATE_NCM } from "../../../store/actions/ncm";

function visibleItem(item) {
  this.$store.dispatch(AUX_TO_UPDATE_NCM, {item: item, justVisible: true})
  this.component.addDialogNcm = true;
}




function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
  }

  function handleClick(i) {
    this.itemSelectedOnClick = i;
  }


  export default {
      deleteItem,
      handleClick,
      visibleItem
  }