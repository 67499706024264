<template>
  <v-form ref="form" v-model="component.validPhysical">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="component.PhysicalPeople.name"
          :readonly="component.justVisible"
          label="*Nome"
          :rules="nameRules"
          outlined
          required

        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.PhysicalPeople.lastName"
          :readonly="component.justVisible"
          label="*Sobrenome"
          :rules="LastNameRules"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="component.PhysicalPeople.cpf"
          :readonly="component.justVisible"
          label="*CPF"
          v-mask="'###.###.###-##'"
          :rules="[cpfRule]"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="component.PhysicalPeople.idCard"
          :readonly="component.justVisible"
          label="Identidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="component.PhysicalPeople.emitter"
          :readonly="component.justVisible"
          label="Orgão Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="component.PhysicalPeople.emitterState"
          :readonly="component.justVisible"
          label="UF Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.PhysicalPeople.dateOfBirth"
          :readonly="component.justVisible"
          label="Data de Nascimento"
          placeholder="null"
          outlined
          type="date"
          required
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="component.PhysicalPeople.nacionality"
          :readonly="component.justVisible"
          label="Nacionalidade"
          :items="nacionality"
          outlined
          required
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.PhysicalPeople.naturality"
          :readonly="component.justVisible"
          label="Naturalidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="Representante"
          v-model="component.provider.relatedRepresentative"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          outlined
          item-text="text"
          item-value="value"
          v-model="component.provider.type"
          :readonly="component.justVisible"
          :items="type_provider"
          label="*Tipo de Fornecedor"
          :rules="[() => !!component.provider.type || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { nameRules, LastNameRules, cpfRules } from "./script";

export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: false,
    type_provider: [
      { text: "Mercadoria", value: "M" },
      { text: "Serviço", value: "S" },
    ],
    nacionality: ["Brasileiro", "Estrangeiro"],
    nameRules,
    LastNameRules,
    cpfRules,
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
    checkCpf(cpf) {
      // Remove caracteres não numéricos do CPF
      cpf = cpf.replace(/[^\d]/g, '');

      // Verifica se o CPF tem 11 dígitos
      if (cpf.length !== 11) {
        return false;
      }

      // Verifica se todos os dígitos são iguais (CPF inválido se for verdadeiro)
      if (/^(\d)\1+$/.test(cpf)) {
        return false;
      }

      // Calcula o primeiro dígito verificador
      let soma = 0;
      for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let primeiroDigito = 11 - (soma % 11);
      if (primeiroDigito === 10 || primeiroDigito === 11) {
        primeiroDigito = 0;
      }

      // Verifica se o primeiro dígito verificador está correto
      if (primeiroDigito !== parseInt(cpf.charAt(9))) {
        return false;
      }

      // Calcula o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
      }
      let segundoDigito = 11 - (soma % 11);
      if (segundoDigito === 10 || segundoDigito === 11) {
        segundoDigito = 0;
      }

      // Verifica se o segundo dígito verificador está correto
      if (segundoDigito !== parseInt(cpf.charAt(10))) {
        return false;
      }

      // Se todas as verificações passaram, o CPF é válido

      return true;
    },

    cpfRule(v) {
      if (!this.checkCpf(v)) {
        return 'CPF Inválido';
      }
      return true;
    },
  },
};
</script>

<style>
</style>