<template>
  <v-row>
    <v-dialog
      v-model="this.component.dialog_edit"
      transition="fab-transition"
      persistent
      max-width="500"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-card-title>Editar Tipo de Comissão</v-card-title>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form>
                <v-select
                  v-model="component.edit_item.commissionT"
                  :items="item"
                  item-text="text"
                  item-value="value"
                  outlined
                  label="Tipo de Comissão:"
                ></v-select>
                <v-text-field
                  v-model="component.edit_item.description"
                  outlined
                  label="Descrição:"
                ></v-text-field>
              </v-form>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="error"
              @click="
                () => {
                  desactive_dialog();
                }
              "
            >
              Cancelar</v-btn
            >
            <v-btn color="success" @click="confirm"> Confirmar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { UPDATE_COMISSION_TYPE } from "../../../../store/actions/commission_type";
export default {
  props: { component: Object() },
  data: () => ({
    item: [
      { text: "Items", value: 1 },
      { text: "Vendas", value: 2 },
      { text: "Produto", value: 3 },
    ],
  }),
  methods: {
    desactive_dialog() {
      this.component.dialog_edit = !this.component.dialog_edit;
    },
    confirm() {
      this.component.edit_item.commissionType = this.component.edit_item.commissionT;
      this.$store
        .dispatch(UPDATE_COMISSION_TYPE, this.component.edit_item)
        .then(() => {
          this.desactive_dialog();
        });
    },
  },
};
</script>
