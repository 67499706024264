import { GET_ALL_UNIT, DESTROY_UNIT } from "../../../store/actions/unit";
import { GET_FILTER_UNIT } from "../../../store/actions/unit";



async function destroy_unit(item) {
    const unit = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_UNIT, unit);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {
    item
    this.addDialog = !this.addDialog;
    // this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_UNIT, value);
}

async function unit_filter(page = 1) {
        let value = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_UNIT, value);
}

function autocompleteUnit() {
    this.search = this.searchInput
  }


export default {
    destroy_unit,
    open_Dialog,
    GetAll,
    unit_filter,
    autocompleteUnit,
}