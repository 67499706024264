const GET_ALL_STOCK_BALANCE = "GET_ALL_STOCK_BALANCE"
const UPDATE_STOCK_BALANCE = "UPDATE_STOCK_BALANCE"
const DESTROY_STOCK_BALANCE = "DESTROY_STOCK_BALANCE"
const GET_FILTER_STOCK_BALANCE = "GET_FILTER_STOCK_BALANCE"
const GET_ALL_STOCK_BALANCE_BY_ENTERPRISE = "GET_ALL_STOCK_BALANCE_BY_ENTERPRISE"
const GET_FILTER_STOCK_BALANCE_BY_ENTERPRISE = "GET_FILTER_STOCK_BALANCE_BY_ENTERPRISE"


module.exports = {
    GET_ALL_STOCK_BALANCE,
    UPDATE_STOCK_BALANCE,
    DESTROY_STOCK_BALANCE,
    GET_FILTER_STOCK_BALANCE,
    GET_ALL_STOCK_BALANCE_BY_ENTERPRISE,
    GET_FILTER_STOCK_BALANCE_BY_ENTERPRISE,
}