const movimentacao = [


{
  "text": "Vendas",
  "icon": "mdi-cash",
  "route": "/Vendas",
  "class": "white--text",
  "active": true

},
];
export {
  movimentacao
};
