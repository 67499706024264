m<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getBank"
        :destroy_bank="destroy_bank"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalBank v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>


<script>
import Container from "../components/Utils/Container/Container";
import ModalBank from "../components/bank/AddDialog/index";
import CardTitle from "../components/bank/CardTitle/index";
import Table from "../components/bank/Table/index";
import loadScreen from "../components/load/";
import AlertApi from "./../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import UiBusinessRules from "../UIBusinessRule/Bank/methods/View";
import { Data } from "../UIBusinessRule/Bank/Data/View";

export default {
  data: () => ({
    ...Data
  }),

  computed: {
    ...mapGetters(["getBank", "getCadastrosLinks"])
  },

  methods: {
    ...UiBusinessRules
  },

  async created() {
    this.GetAll();
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  watch: {
    filter: function() {
      this.bank_filter();
    }
  },

  components: {
    Container,
    CardTitle,
    Table,
    ModalBank,
    AlertApi,
    loadScreen
  }
};
</script>


<style scoped>
</style>