import { AUX_TO_UPDATE_PRODUCT_GROUP, GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup"

function visibleItem(item) {
    this.$store.dispatch(AUX_TO_UPDATE_PRODUCT_GROUP, { item: item, justVisible: true })
    this.$store.dispatch("setShowDialogRegisterProductGroup", true);
}


function open_editDialog(item) {
    this.$store.dispatch("setShowDialogRegisterProductGroup", true)
    this.$store.dispatch(AUX_TO_UPDATE_PRODUCT_GROUP, {})
    this.$store.dispatch(AUX_TO_UPDATE_PRODUCT_GROUP, item)
}
function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function getAllProductGroup(page = 1) {
    const payload = {
        page,
        paginate: true,
        limit: 10
    }
    this.$store.dispatch(GET_ALL_PRODUCT_GROUP, payload).then((response) => {
        this.results = response.data;
        this.total = response.last_page;
        this.current = response.current_page;
    })
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}



export default {
    open_editDialog,
    deleteItem,
    handleClick,
    visibleItem,
    getAllProductGroup
}