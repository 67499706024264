<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        flat
        height="10px"
        label="Empresa"
        item-value="id"
        item-text="fantasyName"
        loader-height
        v-model="component.enterprise_id"
        :items="getEnterprises.data"
        >Adicionar</v-select
      >
    </v-col>
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
import { mapGetters } from "vuex";
//import { dispatchGetAll } from "../../Methods/dispatchGetAll";
export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
  }),

  created() {
    this.GetAll();
  },

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true
      }
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
   
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.component.enterprise_id= val.data[0].id;
    },
  },
};
</script>

<style></style>
