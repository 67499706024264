import { cadastros } from "../components/Navbar/Navlist/Cadastros/script";
import { compras } from "../components/Navbar/Navlist/Compras/script";
import { movimentacao } from "../components/Navbar/movimentacao";
import { entradas } from "../components/Navbar/Navlist/Entradas/script";
import { faturamento } from "../components/Navbar/Navlist/Faturamento/script";
import { saidas } from "../components/Navbar/Navlist/Saidas/script";
import { estoque } from "../components/Navbar/Navlist/Estoque/script";
import { financeiro } from "../components/Navbar/Navlist/Financeiro/script";
import { fiscal } from "../components/Navbar/Navlist/Fiscal/script";
import { relatorio } from "../components/Navbar/relatorio";
import { seguranca } from "../components/Navbar/Navlist/Seguranca/script";

const module_links = {
  state: () => ({
    cadastros,
    cadastrosSize: Number(),

    entradas,
    entradasSize: Number(),

    compras,
    comprasSize: Number(),

    faturamento,
    faturamentoSize: Number(),

    estoque,
    estoqueSize: Number(),

    saidas,
    saidasSize: Number(),

    movimentacao,
    movimentacaoSize: Number(),

    financeiro,
    financeiroSize: Number(),

    fiscal,
    fiscalSize: Number(),

    relatorio,
    relatorioSize: Number(),

    seguranca,
    segurancaSize: Number(),
  }),

  mutations: {
    SET_CADASTROS(state, payload) {
      let id = payload.index;
      state.cadastros[id].active = true;
      state.cadastros[id].permission_col = payload.perm.split(",");
    },
    CHECK_CADASTROS(state) {
      state.cadastrosSize = state.cadastros.filter(
        (val) => val.active == true
      ).length;
    },

    SET_COMPRAS(state, payload) {
      let id = payload.index;
      state.compras[id].active = true;
      state.compras[id].permission_col = payload.perm.split(",");
    },
    CHECK_COMPRAS(state) {
      state.comprasSize= state.compras.filter(
        (val) => val.active == true
      ).length;
    },


    SET_MOVIMENTACAO(state, payload) {
      let id = payload.index;
      state.movimentacao[id].active = true;
      state.movimentacao[id].permission_col = payload.perm.split(",");
    },

    CHECK_MOVIMENTACA(state) {
      state.movimentacaoSize = state.movimentacao.filter(
        (val) => val.active == true
      ).length;
    },

    SET_ENTRADAS(state, payload) {
      let id = payload.index;
      state.entradas[id].active = true;
      state.entradas[id].permission_col = payload.perm.split(",");
    },

    CHECK_ENTRADAS(state) {
      state.entradasSize = state.entradas.filter(
        (val) => val.active == true
      ).length;
    },

    SET_SAIDAS(state, payload) {
      let id = payload.index;
      state.saidas[id].active = true;
      state.saidas[id].permission_col = payload.perm.split(",");
    },

    CHECK_SAIDAS(state) {
      state.saidasSize = state.saidas.filter(
        (val) => val.active == true
      ).length;
    },

    SET_FATURAMENTO(state, payload) {
      let id = payload.index;
      state.faturamento[id].active = true;
      state.faturamento[id].permission_col = payload.perm.split(",");
    },

    CHECK_FATURAMENTO(state) {
      state.faturamentoSize = state.entradas.filter(
        (val) => val.active == true
      ).length;
    },

    SET_ESTOQUE(state, payload) {
      let id = payload.index;
      state.faturamento[id].active = true;
      state.faturamento[id].permission_col = payload.perm.split(",");
    },

    CHECK_ESTOQUE(state) {
      state.faturamentoSize = state.entradas.filter(
        (val) => val.active == true
      ).length;
    },

    SET_FINANCEIRO(state, payload) {
      let id = payload.index;
      state.financeiro[id].active = true;
      state.financeiro[id].permission_col = payload.perm.split(",");
    },

    CHECK_FINANCEIRO(state) {
      state.movimentacaoSize = state.financeiro.filter(
        (val) => val.active == true
      ).length;
    },

    SET_FISCAL(state, payload) {
      let id = payload.index;
      state.fiscal[id].active = true;
      state.fiscal[id].permission_col = payload.perm.split(",");
    },

    CHECK_FISCAL(state) {
      state.fiscalSize = state.fiscal.filter(
        (val) => val.active == true
      ).length;
    },

    SET_RELATORIOL(state, payload) {
      let id = payload.index;
      state.relatorio[id].active = true;
      state.relatorio[id].permission_col = payload.perm.split(",");
    },

    CHECK_RELATORIOL(state) {
      state.relatoriolSize = state.relatorio.filter(
        (val) => val.active == true
      ).length;
    },

    SET_SEGURANCA(state, payload) {
      let id = payload.index;
      state.seguranca[id].active = true;
      state.seguranca[id].permission_col = payload.perm.split(",");
    },

    CHECK_SEGURANCAL(state) {
      state.segurancaSize = state.seguranca.filter(
        (val) => val.active == true
      ).length;
    },
  },

  actions: {},

  getters: {
    getCadastrosLinks: (state) => [...state.cadastros],
    getCadastrosLinksSize: (state) => state.cadastrosSize,
    getComprasLinks: (state) => [...state.compras],
    getComprasLinksSize: (state) => state.comprasSize,
    getEntradaLinks: (state) => [...state.entradas],
    getEntradaLinksSize: (state) => state.entradasSize,
    getEstoqueLinks: (state) => [...state.estoque],
    getEstoqueLinksSize: (state) => state.estoqueSize,
    getFaturamentoLinks: (state) => [...state.faturamento],
    getFaturamentoLinksSize: (state) => state.faturamentoSize,
    getFinanceiroLinks: (state) => [...state.financeiro],
    getFinanceiroLinksSize: (state) => state.financeiroSize,
    getFiscalLinks: (state) => [...state.fiscal],
    getFiscalLinksSize: (state) => state.fiscalSize,
    getMovimentacaoLinks: (state) => [...state.movimentacao],
    getMovimentacaoLinksSize: (state) => state.movimentacaoSize,
    getRelatorioLinks: (state) => [...state.relatorio],
    getRetorioLinksSize: (state) => state.relatorioSize,
    getSaidasLinks: (state) => [...state.saidas],
    getSaidasLinksSize: (state) => state.saidasSize,
    getSegurancaLinks: (state) => [...state.seguranca],
    getSegurancaLinksSize: (state) => state.segurancaSize,
  },
};

export default module_links;
