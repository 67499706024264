const GET_ALL_NOTEENTRY = "GET_ALL_NOTEENTRY";
const REVERSAL_NOTEENTRY = "REVERSAL_NOTEENTRY";
const ADD_NOTE_ENTRY = "ADD_NOTE_ENTRY";
const GET_FILTER = "GET_FILTER";
const GET_ALL_NOTEENTRY_BY_ENTERPRISE = "GET_ALL_NOTEENTRY_BY_ENTERPRISE";
const GET_FILTER_BY_ENTERPRISE = "GET_FILTER_BY_ENTERPRISE";

/** Entrada com XML */

const VALIDATION_PRODUCT = "VALIDATION_PRODUCT";

module.exports = {
  GET_FILTER,
  GET_ALL_NOTEENTRY,
  REVERSAL_NOTEENTRY,
  ADD_NOTE_ENTRY,
  VALIDATION_PRODUCT,
  GET_ALL_NOTEENTRY_BY_ENTERPRISE,
  GET_FILTER_BY_ENTERPRISE,
};
