<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="this._props.component.addDialog"
      max-width="600"
    >
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Modelo de nota fiscal</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Numero da Sequência"
                v-model="sequence"
                :readonly="justVisible"
                type="number"
                outlined
                :rules="rulesSequence"
              >
              </v-text-field>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                outlined
                :rules="[
                  () => !!description || 'Campo Obrigatório',
                  () =>
                    (!!description && description.length >= 2) ||
                    'Campo tem que ser maior que 2',
                ]"
              ></v-text-field>
              <v-select
                :items="taxDocumentType"
                item-text="text"
                item-value="taxDocumentType"
                :readonly="justVisible"
                label="*Tipo de Documento Fiscal"
                v-model="tax_document_type"
                outlined
              ></v-select>
              <v-select
                :items="typeModel"
                item-text="text"
                item-value="value"
                :readonly="justVisible"
                label="*Modelo da nota"
                v-model="model"
                outlined
              ></v-select>
              <v-text-field
                v-model="series"
                label="*Série"
                :readonly="justVisible"
                outlined
                :rules="[
                  () => !!series || 'Campo Obrigatório',
                  () =>
                    (!!series && series.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
              >
              </v-text-field>

              <v-select
                outlined
                label="Empresa"
                item-value="id"
                item-text="fantasyName"
                :readonly="justVisible"
                loader-height
                v-model="enterprise_id"
                :items="enterprises"
              ></v-select>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_NOTE_MODEL,
  UPDATE_NOTE_MODEL,
  AUX_TO_UPDATE_NOTE_MODEL,
} from "../../../store/actions/note_model";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";

export default {
  props: {
    component: Object,
  },

  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: "",
      sequence: "",
      description: "",
      taxDocumentType: [
        { text: "NF-e", value: 1 },
        { text: "NFC-e", value: 2 },
        { text: "MDF-e", value: 3 },
        { text: "MEI", value: 4 },
        { text: "NFS-e", value: 5 },
        { text: "ORC", value: 6 },
      ],

      typeModel: [
        { text: "55", value: "55" },
        { text: "65", value: "65" },
        { text: "Outros", value: "Outros" },
      ],
      tax_document_type: "",
      model: "",
      series: "",
      search: "",
      enterprise_id: "",
    selectValue: Object(),
    enterprises: Array(),
      rulesSequence: [
        (sequence) => !!sequence || "Campo Obrigatório",
        (sequence) =>
          (!!sequence && sequence.length >= 1) ||
          "Campo tem que ser maior do que 1",
        (sequence) => sequence > 0 || "O valor não pode ser menor que 1",
      ],
    };
  },

  methods: {
    autoload(payload) {
      if(payload.justVisible) {
        this.GetAll();
        this.justVisible = payload.justVisible;
        this.sequence = payload.item.sequence;
        this.description = payload.item.description;
        this.tax_document_type = payload.item.tax_document_type;
        this.model = payload.item.model;
        this.series = payload.item.series;
        this.enterprise_id = payload.item.enterprise_id;
      } else {
          this.GetAll();
          this.object = { ...this.getComponent().objectToUpdate };
          this.id = this.object.id;
          if (this.id) {
            this.sequence = this.object.sequence.toString();
          }
          this.description = this.object.description;
          this.tax_document_type = this.object.tax_document_type;
          this.model = this.object.model;
          this.series = this.object.series;
          this.enterprise_id = this.object.enterprise_id;
      }
    },
    getComponent() {
      return this.component;
    },
    close() {
      this.component.addDialog = false;
      this.justVisible = false;
      this.$store.dispatch(AUX_TO_UPDATE_NOTE_MODEL, Object())
    },
    async create_note_model() {
      const note_model = {
        sequence: this.sequence,
        description: this.description,
        tax_document_type: this.tax_document_type,
        model: this.model,
        series: this.series,
        enterprise_id: this.enterprise_id,
      };
      await this.$store.dispatch(ADD_NOTE_MODEL, note_model).then(() => {
        this.close();
      });
    },
    submit() {
      this.id == undefined
        ? this.create_note_model()
        : this.update_note_model();
    },
    async update_note_model() {
      const note_model = {
        sequence: this.sequence,
        description: this.description,
        tax_document_type: this.tax_document_type,
        model: this.model,
        series: this.series,
        id: this.id,
        enterprise_id: this.enterprise_id,
      };
      await this.$store.dispatch(UPDATE_NOTE_MODEL, note_model).then(() => {
        this.close();
      });
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },

  async created() {
    this.autoload(this.getAuxNoteModelToUpdate)

  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
    ...mapGetters(["getAuxNoteModelToUpdate"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val.data);
      this.selectValue = this.enterprises;
    },
  },
};
</script>
