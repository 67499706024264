<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterSeller"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" dark flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                executeAddDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{headerTypeProcess}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-divider></v-divider>
          <v-card flat>
            <v-tabs>
              <v-tab href="#tab1">
                <v-icon class="mr-1">mdi-account-details</v-icon>Gerais
              </v-tab>
              <v-tab href="#tab2">
                <v-icon class="mr-1">mdi-family-tree</v-icon>Pessoais
              </v-tab>
              <v-tab href="#tab3">
                <v-icon class="mr-1">mdi-home-account</v-icon>Endereço
              </v-tab>
              <v-tab href="#tab4">
                <v-icon class="mr-1">mdi-card-account-mail</v-icon>Contato
              </v-tab>
              <v-tab-item value="tab1">
                <v-card flat>
                  <GeneralDate :out_of_register="out_of_register" :component="this" ref="date" :justVisible="justVisible"/>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab2">
                <v-card flat>
                  <FamillyDate :component="this" :justVisible="justVisible"/>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab3">
                <v-card flat>
                  <TitleAddress :component="this" :justVisible="justVisible"/>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab4">
                <Contacts :component="this" :justVisible="justVisible"/>
              </v-tab-item>
            </v-tabs>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="!justVisible" @click="clear()" class="error">
                <v-icon>mdi-cancel</v-icon>Limpar Formulário
              </v-btn>
              <v-btn v-if="!justVisible" class="success" :disabled="!valid" v-on:click="submit()">
                <v-icon>mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GeneralDate from "../Tabs/TabGeral/";
import TitleAddress from "../Tabs/TabAddress/";
import FamillyDate from "../Tabs/TabFamily/";
import Contacts from "../Tabs/TabContact/";
import { ADD_SELLER } from "../../../store/actions/seller";
import { mapGetters } from "vuex";
import {ITEM_TO_UPDATE_SELLER,UPDATE_SELLER } from  "../../../store/actions/seller"
import Methods from "./helpers/Methods"
import {cleanCPF, cleanString} from "../../Utils/global_functions/clean_cnpj_cpf"

export default {
  computed: {
    ...mapGetters(["getShowDialogRegisterSeller", "getSelectedEnterprise","getItemToUpdateSeller"])
  },
  components: {
    GeneralDate,
    TitleAddress,
    FamillyDate,
    Contacts
  },

  props: {
    out_of_register: Boolean(),
    component: Object(),
    enterpriseId: Number()
  },

  data: () => ({
    justVisible: Boolean(),
    headerTypeProcess:String(),
    type_people: String(),
    id:undefined,
    client_id: undefined,
    enterprise_id: Number(),
    physical_people: {
      name: String(),
      lastName: String(),
      cpf: String(),
      gender: String(),
      emitter: String(),
      emitterState: String(),
      idCard: String(),
      dateOfBirth: String(),
      fatherName: String(),
      motherName: String(),
      nacionality: String(),
      naturality: String(),
      monthlyIncome: String(),
      civilStatus: String(),
      spouse: String(),
      icomeSpouse: Number(),
      people_id: undefined
    },
    address: {
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id: Number()
    },
    contact: {
      primaryEmail: "",
      secundaryEmail: "",
      phone: String(),
      cellPhone: String(),
      phoneReference: String(),
      people_id: Number()
    },
    new_city: Object(),
    ListContact: Array(),
    ListAddress: Array(),
    valid: Boolean(),
    validGeneral: Boolean(),
    validPersonal: Boolean(),
    validAddress: Boolean(),
    validContact: Boolean(),
    sellerComission: Number(),
  }),
  methods: {
    ...Methods,
    executeAddDialog() {
      this.wipeData()
      this.$store.dispatch(ITEM_TO_UPDATE_SELLER, Object())
      this.$store.dispatch("setShowDialogRegisterSeller", false);
      // this.component.addDialog = !this.component.addDialog;
    },

    async create() {
      // if (this.valid === false) {
      //   alert("Digite o Nome, Sobrenome e CPF!");
      // } else if (this.address < 1) {
      //   alert("Digite um Endereço!");
      // } else if (this.contact < 1) {
      //   alert("Digite um Contato!");
      // } else {
     //alert('teste')
      let newSeller = {};
      newSeller.physical_people = { ...this.physical_people };
      newSeller.physical_people.cpf = cleanCPF(newSeller.physical_people.cpf)
      newSeller.type_people = "PhysicalPeople";
      newSeller.address = { ...this.address };
      newSeller.contacts = { ...this.contact };
      newSeller.enterprise_id = this.out_of_register
        ? this.enterpriseId
        : this.enterpriseId;
      // newSeller.enterprise_id = this.enterpriseId;
      newSeller.address.city_id = this.new_city.id;
      newSeller.address.city = this.new_city.nome;


      newSeller.address.cep = cleanString(this.address.cep)
        newSeller.contacts.phone = cleanString(this.contact.phone)
        newSeller.contacts.cellPhone = cleanString(this.contact.cellPhone)
        newSeller.contacts.phoneReference = cleanString(this.contact.phoneReference)
        newSeller.sellers_comission = this.sellerComission
      await this.$store.dispatch(ADD_SELLER, newSeller).then(() => {
        this.$store.dispatch(ITEM_TO_UPDATE_SELLER, Object())
        this.$store.dispatch("setShowDialogRegisterSeller", false);
      });
      // }
    },

    submit(){
      this.id != undefined ? this.update() : this.create()
    },
    update() {
      let newSeller = Object();
      newSeller.id = this.id
      newSeller.physical_people = this.physical_people;
      newSeller.physical_people.cpf = cleanCPF(newSeller.physical_people.cpf)
      newSeller.address = this.address;
      newSeller.contacts = this.contact;
      newSeller.enterprise_id = this.enterpriseId;
      newSeller.type_people = "PhysicalPeople"
      newSeller.sellers_comission = this.sellerComission
      this.$store.dispatch(UPDATE_SELLER, newSeller).then(() => {
        this.wipeData()
        this.$store.dispatch(ITEM_TO_UPDATE_SELLER, Object())
        this.$store.dispatch("setShowDialogRegisterSeller", false);
      })
    },
    itemToUpdate(item) {
      if (item.justVisible) {
        this.justVisible = item.justVisible,
        this.type_people = item.item.type_people
        this.enterprise_id = item.item.enterprise_id
        this.physical_people = item.item.people.physicalpeople
        this.address = { ...item.item.people.address[0] }
        this.address.city = item.item.people.address[0].city.name
        this.contact = { ...item.item.people.contact[0] }
        this.sellerComission = item.item.sellers_comission
      }
      else if (item.isUpdate) {
        this.id = item.id
        this.headerTypeProcess = `${item.header} de Vendedor`
        this.type_people = item.type_people
        this.enterprise_id = item.enterprise_id
        this.physical_people = item.people.physicalpeople
        this.address = { ...item.people.address[0] }
        this.address.city = item.people.address[0].city.name
        this.contact = { ...item.people.contact[0] }
        this.sellerComission = item.sellers_comission

      } else {
        this.headerTypeProcess = `Cadastro de Vendedor`
      }
    },
   
    clear() {
      this.physical_people.name = "";
      this.physical_people.lastName = "";
      this.physical_people.cpf = "";
      this.physical_people.gender = "";
      this.physical_people.emitter = "";
      this.physical_people.emitterState = "";
      this.physical_people.idCard = "";
      this.physical_people.dateOfBirth = "";
      this.physical_people.fatherName = "";
      this.physical_people.motherName = "";
      this.physical_people.nacionality = "";
      this.physical_people.naturality = "";
      this.physical_people.icomeSpouse = 0;
      this.physical_people.monthlyIncome = 0;
      this.physical_people.civilStatus = "";
      this.physical_people.spouse = "";
      this.ListContact = Array;
      this.ListAddress = Array();
    }
  },
  created(){
    this.itemToUpdate(this.getItemToUpdateSeller)
  },

  watch: {
    validGeneral(val) {
      if(val && this.validPersonal && this.validAddress && this.validContact) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validPersonal(val) {
      if(val && this.validGeneral && this.validAddress && this.validContact) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validAddress(val) {
      if(val && this.validGeneral && this.validPersonal && this.validContact) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validContact(val) {
      if(val && this.validGeneral && this.validPersonal && this.validAddress) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
  },
};
</script>

<style>
</style>