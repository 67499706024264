<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
              @click="viewInfo(item)"
              >mdi-eye</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              v-bind="attrs"
              @click="changeReverse(item)"
              v-on="on"
              >mdi-chevron-up-box</v-icon
            >
          </template>
          <span>Estornar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>

    <ReverseNote
      :dialog="dialogReverse"
      title="Deseja estornar esta Nota?"
      subtitle="A nota será estornada."
      :alterDialog="changeReverse"
      :confirm="confirmReverse"
    />
    <InfoNote
      v-if="infoDialog"
      ref="info"
      :dialog="infoDialog"
      :changeDialog="changeInfo"
      :objectToView="info_itens"
      :component="this"
      title="Informações da"
      :disabled="true"
    />
  </div>
</template>

<script>
import { REVERSAL_NOTEENTRY } from "../../../store/actions/note_entry";
import { REFRESH_NOTE_ENTRY_ITENS } from "../../../store/actions/note_entry_itens";
import InfoNote from "../AddDialog/index";
import ReverseNote from "../AddDialog/ConfirmAction/";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    headers: Array,
    change_addDialog: Function,
  },
  components: { InfoNote, ReverseNote },
  data: () => ({
    destroyDialog: false,
    info_itens: Object(),
    addDialog: false,
    confirmDialog: Boolean(),
    editDialog: Boolean(),
    infoDialog: Boolean(),
    dialogReverse: Boolean(),
    idReverse: Number(),
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    totalNF: Number(),
    checkinfo: Number,

    title: String(),
    subtitle: String(),
    dialog: Boolean(),
  }),
  methods: {
    changeInfo() {
      this.infoDialog = !this.infoDialog;
    },

    adjust_view_item(item) {
      let aux = Object.values(item);

      aux.forEach((e) => {
        e.description = e.product.description_item;
        e.description_unit = e.product.unit.sigla;
        e.description_cfop = e.product.cfop.code;
        e.value_products = e.value_sub_total_product;
        if (e.csosn_id === null) {
          e.description_cst = e.product.cst.code;
        } else {
          e.description_cst = e.product.csons.code;
        }
      });
    },

    viewInfo(item) {
      this.info_itens = { ...item };
      this.adjust_view_item(item.grade_itens);
      this.checkinfo = 2;

      this.$store.dispatch(REFRESH_NOTE_ENTRY_ITENS, item.grade_itens);
      this.changeInfo();
    },
    change_edit() {
      this.editDialog = !this.editDialog;
    },

    alterDialog() {
      this.dialog = !this.dialog;
    },
    changeConfirm(item) {
      this.totalNF = item.value_nf;
      this.$refs.amount.remaining = item.value_nf;
      this.confirmDialog = !this.confirmDialog;
    },

    changeReverse(item) {
      this.idReverse = { ...item };
      this.dialogReverse = !this.dialogReverse;
    },
    async confirmReverse() {
      let reverse = {
        id: this.idReverse.id,
        enterprise_id: this.idReverse.enterprise_id,
      };
      try {
        await this.$store.dispatch(REVERSAL_NOTEENTRY, reverse);
      } finally {
        this.dialogReverse = !this.dialogReverse;
      }
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      if (val.data) {
        this.results.forEach((e) => {
          e.date_of_issue = e.date_of_issue.split("-").reverse().join("/");
          e.entry_date = e.entry_date.split("-").reverse().join("/");
          e.value_total_products = `R$ ${e.value_total_products
            .toFixed(2)
            .replace(".", ",")}`;
          e.value_total_note = `R$ ${e.value_total_note
            .toFixed(2)
            .replace(".", ",")}`;
          e.base_calc_icms_total = `R$ ${e.base_calc_icms_total
            .toFixed(2)
            .replace(".", ",")}`;
          e.value_icms_total = `R$ ${e.value_icms_total
            .toFixed(2)
            .replace(".", ",")}`;
          e.value_untaxed_tax_total = e.value_untaxed_tax_total ? `R$ ${e.value_untaxed_tax_total
            .toFixed(2)
            .replace(".", ",")}` : 0;
          e.base_calc_susbt_total = e.base_calc_susbt_total ? `R$ ${e.base_calc_susbt_total
            .toFixed(2)
            .replace(".", ",")}` : 0;
          e.value_icms_substituicao_total = `R$ ${e.value_icms_substituicao_total
            .toFixed(2)
            .replace(".", ",")}`;
        });

        for(let i = 0; i < this.results.length; i++) {
          this.results[i].providerToShow = String()
          if(this.results[i].provider.people.physicalpeople == null) {
            this.results[i].providerToShow = this.results[i].provider.people.legalpeople.fantasyName
          } else {
            this.results[i].providerToShow = this.results[i].provider.people.physicalpeople.name
          }
        }
      }
    },
    current: function (val) {
      if(this.component.search.length < 2) {
        this.component.GetAll && this.component.GetAll(val);
      } else {
        this.component.note_entry_filter && this.component.note_entry_filter(val);
      }
    },
  },
};
</script>
