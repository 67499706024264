<template>
  <v-form ref="form" v-model="component.validLegal">
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="*Razão Social"
          v-model="component.LegalPeople.socialName"
          :readonly="component.justVisible"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="*Nome Fantasia"
          v-model="component.LegalPeople.fantasyName"
          :readonly="component.justVisible"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="*CNPJ"
          v-model="component.LegalPeople.cnpj"
          :readonly="component.justVisible"
          v-mask="'##.###.###/####-##'"
          :rules="[cnpjRule]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Inscrição Estadual"
          v-model="component.LegalPeople.stateRegistration"
          :readonly="component.justVisible"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Inscrição Municipal"
          v-model="component.LegalPeople.municipalRegistration"
          :readonly="component.justVisible"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Site"
          outlined
          v-model="component.LegalPeople.homePage"
          :readonly="component.justVisible"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Representante Relacionado"
          v-model="component.provider.relatedRepresentative"
          :readonly="component.justVisible"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          :items="type_provider"
          item-text="text"
          item-value="value"
          label="*Tipo de Fornecedor"
          v-model="component.provider.type"
          :readonly="component.justVisible"
          outlined
          :rules="[() => !!component.provider.type || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: false,
    type_provider: [
      { text: "Mercadoria", value: "M" },
      { text: "Serviço", value: "S" },
    ],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },

    checkCnpj(cnpj) {
      // Remove caracteres não numéricos
      cnpj = cnpj.replace(/\D/g, '');

      // Verifica se o CNPJ tem 14 dígitos
      if (cnpj.length !== 14) {
        return false;
      }

      // Verifica se todos os dígitos são iguais (CNPJ inválido se for verdadeiro)
      if (/^(\d)\1+$/.test(cnpj)) {
        return false;
      }

      // Calcula o primeiro dígito verificador
      let soma = 0;
      let peso = 2;
      for (let i = 11; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso = peso === 9 ? 2 : peso + 1;
      }
      const digito1 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);

      // Verifica se o primeiro dígito verificador está correto
      if (digito1 !== parseInt(cnpj.charAt(12))) {
        return false;
      }

      // Calcula o segundo dígito verificador
      soma = 0;
      peso = 2;
      for (let i = 12; i >= 0; i--) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso = peso === 9 ? 2 : peso + 1;
      }
      const digito2 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);

      // Verifica se o segundo dígito verificador está correto
      if (digito2 !== parseInt(cnpj.charAt(13))) {
        return false;
      }

      // Se todas as verificações passaram, o CNPJ é válido
      return true;
    },

    cnpjRule(v) {
      if (!this.checkCnpj(v)) {
        return 'CNPJ Inválido';
      }
      return true;
    },
  },
};
</script>

<style>
</style>