const ADD_SUB_PRODUCT_GROUP = "ADD_SUB_PRODUCT_GROUP";
const UPDATE_SUB_PRODUCT_GROUP = "UPDATE_SUB_PRODUCT_GROUP";
const GET_ALL_SUB_PRODUCT_GROUP = "GET_ALL_SUB_PRODUCT_GROUP";
const DESTROY_SUB_PRODUCT_GROUP = "DESTROY_SUB_PRODUCT_GROUP";
const GET_FILTER_SUB_PRODUCT_GROUP = "GET_FILTER_SUB_PRODUCT_GROUP";
const ITEM_TO_UPDATE_SUB_PRODUCT_GROUP = "ITEM_TO_UPDATE_SUB_PRODUCT_GROUP"

module.exports = {
  ADD_SUB_PRODUCT_GROUP,
  UPDATE_SUB_PRODUCT_GROUP,
  GET_ALL_SUB_PRODUCT_GROUP,
  DESTROY_SUB_PRODUCT_GROUP,
  GET_FILTER_SUB_PRODUCT_GROUP,
  ITEM_TO_UPDATE_SUB_PRODUCT_GROUP
};
