var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"no-data-text":"Sem registros","hide-default-footer":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
                _vm.visibleItem(item)
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("Visualizar Este Item")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.component.permission.Editar)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
                _vm.change_addDialog(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar esse Item")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.component.permission.Excluir)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
                _vm.deleteItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Deletar esse Item")])])]}}],null,true)}),_c('div',[_c('v-divider'),_c('v-pagination',{staticClass:"mb-6",attrs:{"length":_vm.total,"color":"success"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1),_c('destroyDialog',{attrs:{"component":this,"destroy_object":_vm.destroy_object}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }