import { AUX_TO_UPDATE_ALIQUOT } from "../../store/actions/aliquot";

function visibleItem(item){
  this.$store.dispatch(AUX_TO_UPDATE_ALIQUOT, {item: item, justVisible: true})
  this.$store.dispatch("setShowDialogRegisterAliquot", true);
}

function change_addDialog(item) {
  this.$store.dispatch("setShowDialogRegisterAliquot", true);
  this.$store.dispatch(AUX_TO_UPDATE_ALIQUOT, {});
  this.$store.dispatch(AUX_TO_UPDATE_ALIQUOT, item);
}
function deleteItem(item) {
  this.destroyDialog = true;
  this.currentItem = item;
}

function handleClick(i) {
  this.itemSelectedOnClick = i;
}

export default {
  change_addDialog,
  deleteItem,
  handleClick,
  visibleItem
};
