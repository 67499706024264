<template>
  <v-row>
    <v-card-title>Administradora de Cartão</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        class="mt-3"
        label="Empresa"
        outlined
        item-value="id"
        item-text="fantasyName"
        dense
        flat
        v-model="selectId"
        :items="enterprises"
        height="10px"
      ></v-select>
    </v-col>
    <v-btn
      v-if="component.permission.Cadastrar"
      class="mr-2 mt-6"
      color="success"
      @click="component.dialog = !component.dialog"
    >
      Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
import { mapGetters } from "vuex";
//import { dispatchGetAll } from "../../Methods/dispatchGetAll";
export default {
  props: {
    component: Object,
  },

  data: () => ({
    enterprises: [],
    selectId: Object,
  }),
  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },

  created() {
    this.GetAllNoPage();
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectId = this.enterprises[0];
    },

    selectId(val) {
      this.component.enterpriseId = val.id;
    },
  },
};
</script>
