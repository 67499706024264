<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <AddDialog v-if="dialog" :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :search="search" :component="this" />
    </v-card>
    <AlertApi />
    <loadscreen/>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/card_administrators/CardTitle/";
import Table from "../components/card_administrators/Table/";
import AddDialog from "../components/card_administrators/AddDialog/";
import loadscreen from "../components/load/"
import AlertApi from "./../components/alert_api/alert_api";

import { mapGetters } from "vuex";
import {
  GET_FILTER_CARD_ADM,
  GET_ALL_CARD_ADM,
} from "../store/actions/card_adm";

export default {
  data: () => ({
    search: String(),
    filter: String(),
    dialog: false,
    enterpriseId: Number(),
    title: "Administradoras de Cartão",
    permission: Object(),
  }),
  methods: {
    active_add() {
      this.dialog = !this.dialog;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_CARD_ADM, value);
    },

    async cardAdm_filter(page = 1) {
      if (!this.filter) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        switch (value.search) {
          case "TEF":
            value.search = "T";
            break;
          case "POS":
            value.search = "P";
            break;
        }
        await this.$store.dispatch(GET_FILTER_CARD_ADM, value);
      }
    },
  },
  watch: {
    filter: function () {
      this.cardAdm_filter();
    },
  },

  components: {
    Container,
    CardTitle,
    AlertApi,
    AddDialog,
    loadscreen,
    Table,
  },
  computed: {
    ...mapGetters(["getCadastrosLinks"]),
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

<style scoped>
</style>