import axios from "axios";

const {
  ADD_CSOSN,
  UPDATE_CSOSN,
  GET_ALL_CSOSN,
  DESTROY_CSOSN,
  GET_FILTER_CSOSN,
  AUX_TO_UPDATE_CSOSN
} = require("./actions/csosn");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCsosn = {
  state: () => ({
    csosnToAdd: Object(),
    csosns: Object(),
    itemToUpdate: Object(),
  }),

  mutations: {
    GET_ALL_CSOSN: (state, csosns) => {
      state.csosns = csosns;
    },
    GET_FILTER_CSOSN: (state, payload) => {
      state.csosns = payload
    },
    ADD_CSOSN: (state, newCSOSN) => {
      state.csosnToAdd = newCSOSN
      if (state.csosns.data) {
        state.csosns.data.push(newCSOSN)
      } else {
        state.csosns.push(newCSOSN)
      }
    },
    UPDATE_CSOSN(state, editCSOSN) {
      state.csosns.data.Update(editCSOSN)
    },
    DESTROY_CSOSN: (state, id) => {
      let auxState = [...state.csosns.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.csosns = [...auxState];
    },
    AUX_TO_UPDATE_CSOSN: function (state, payload) {
      state.itemToUpdate = payload;
    }
  },

  actions: {
    async GET_ALL_CSOSN({
      commit
    },
      payload
    ) {
      try {
      const response =  await axios.post("csosn/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate
        })
        
        commit(GET_ALL_CSOSN, response.data[0]);
        return response.data[0]
        
      } catch (error) {
        
      }
    },

    async GET_FILTER_CSOSN({
      commit
    }, payload) {
      try {
        const response = await axios.post("csosn/autocomplete", {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            paginate: payload.paginate

      })
        commit(GET_FILTER_CSOSN, response.data[0]);
        return response.data[0]
      } catch (error) {
        
      }
    },

    ADD_CSOSN({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("csosn/store", payload).then((response) => {
          resolve()
          commit(ADD_CSOSN, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastro realizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_CSOSN({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("csosn/update", payload).then((response) => {
          resolve()
          commit(UPDATE_CSOSN, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualização realizada com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_CSOSN({
      commit
    }, payload) {
      axios
        .post("csosn/delete", {
          ...payload
        })
        .then(() => {
          commit(DESTROY_CSOSN, payload);
          commit(SPLICE_ERRO, {
            alert: "Item deletado com sucesso!",
            type: 'S'
          })
        })
    },
    AUX_TO_UPDATE_CSOSN: function (context, payload) {
      context.commit(AUX_TO_UPDATE_CSOSN, payload)
    }
  },

  getters: {
    getCsosn: (state) => ({ ...state.csosns }),
    getItemToUpdate: (state) => ({ ...state.itemToUpdate }),
    getCsosnToAdd: (state) => state.csosnToAdd
  },
};

export default moduleCsosn;