import axios from "axios"

import { DATA_SALES } from "./actions/dashboard_charts"

const dashBoardChart = {
    state: {
        reportChartSales: Object()
    },
    getters: {
        getReportChartSales: (state) => state.reportChartSales
    },
    mutations: {
        DATA_SALES: function (state, payload) {
            state.reportChartSales = payload
        }
    },
    actions: {
        DATA_SALES: async function (context, payload) {
            try {
                const response = await axios.post('sales/salesdashboard', payload)
                if(payload.filter){
                    context.commit(DATA_SALES, response.data[0])
                }

                return response.data[0]
            } catch (error) {
                throw new Error(error)
            }
        }
    },
}



export default dashBoardChart