


const SEARCH_CUPOM = "SEARCH_CUPOM"
const VALUE_CUPOM = "VALUE_CUPOM"
const TOTAL_CREDIT = "TOTAL_CREDIT"
const EXCHANGE_HEADER = "EXCHANGE_HEADER"
const REGISTER_CREDIT = "REGISTER_CREDIT"
const GET_ALL_EXCHANGE = "GET_ALL_EXCHANGE"
const VIEW_EXCHANGE = "VIEW_EXCHANGE"
const CANCEL_EXCHANGE = "CANCEL_EXCHANGE"
const GET_ALL_TAX_OPERATION_TYPE = "GET_ALL_TAX_OPERATION_TYPE"
const GET_AUTOCOMPLETE_EXCHANGE_BY_ENTERPRISE = "GET_AUTOCOMPLETE_EXCHANGE_BY_ENTERPRISE"

export {
    GET_ALL_EXCHANGE,
    SEARCH_CUPOM,
    VALUE_CUPOM,
    TOTAL_CREDIT,
    EXCHANGE_HEADER,
    REGISTER_CREDIT,
    VIEW_EXCHANGE,
    CANCEL_EXCHANGE,
    GET_ALL_TAX_OPERATION_TYPE,
    GET_AUTOCOMPLETE_EXCHANGE_BY_ENTERPRISE
}