



var getters = ["getEnterprises"]


module.exports = {
    getters
}

