<template>
  <v-row>
    <v-card-title primary-title>Vendas</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="selectEnterprise"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { SET_CNPJ } from "../../../store/actions/taxCupom";
export default {
  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  props: {
    component: Object(),
  },

  data: () => ({
    selectEnterprise: Object(),
    enterprises: Array(),
    cnpj: '',
  }),

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },

    async setCnpj() {
      await this.$store.dispatch(SET_CNPJ, this.cnpj)
    }

  },
  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectEnterprise = this.enterprises[0];
      this.cnpj = this.selectValue.cnpj.replace(/\D/g, '')
      this.setCnpj()
    },

    selectEnterprise: function(val) {
      this.component.enterpriseId = val.id
    }
  },

  created() {
    this.GetAllNoPage();
  },
  
};



</script>

<style>
</style>