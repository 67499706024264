
import axios from 'axios'
import apifiscal from '../apifiscal';

const {
    GET_ALL_XML_NFCE,
} = require("./actions/write_off_xml")
// import { mockData } from "../components/write_off_xml/CardTitle/helpers/mockdata"
const moduleWrite_off_xml = {
    state: {
        listXML: Object(),
    },
    getters: {
        getListXML: function (state) {
            return state.listXML
        }
    },
    mutations: {
        GET_ALL_XML_NFCE: function (state, payload) {
            state.listXML = payload
        }
    },
    actions: {
        async GET_ALL_XML_NFCE({ commit }, payload) {
            console.log('payload', payload);
            try {
              const response = await apifiscal
                .post("/noteissuenfce/downloadXmlNfce", payload)
                commit(GET_ALL_XML_NFCE, response);
      
                return response.data[0];
      
            } catch (error) {
              throw new Error(error)
            }
      
              
            // .then((response) => {
      
            //   commit(GET_ALL_TAX_OPERATION_TYPE, response.data[0]);
            // });
        },
    },
};

export default moduleWrite_off_xml