let EmitterNFe = require("../../../Domain/Service/IssueNfe/MounterEmitterNFeDomainService");
let IdentifyNFe = require("../../../Domain/Service/IssueNfe/MounterIdentifyNfeDomainService");
let mounterIdentifyReferenceNFe = require("../../../Domain/Service/IssueNfe/MounterIdentifyNFeReferenceNfeDomainService")
let DestinationNFe = require("../../../Domain/Service/IssueNfe/MounterDestinationNfeDomainService");
let mounterProductNfe = require("../../../Domain/Service/IssueNfe/MounterProductNFeDomainService")
let TransporteInfoNfe = require("../../../Domain/Service/IssueNfe/MounterTransportInfoDomainService");

async function execute(revenues) {
  try {
    let emmiterNfe = await EmitterNFe.execute(revenues.enterprise_nf.cnpj);
    let identifyNfe = await IdentifyNFe.execute(revenues);
    let identifyReferenceNFe = await mounterIdentifyReferenceNFe.execute(revenues)
    let destinationNfe = await DestinationNFe.execute(revenues);
    let productNfe = await mounterProductNfe.execute(revenues, revenues.enterprise_nf);
    let transportInfoNfe = await TransporteInfoNfe.execute(revenues);

    let nfe = {}
    if (revenues.nfereferences.keyAcess != "" && revenues.nfereferences.taxDocumentNumber != "") {
      nfe = mounterNfeIdentifyReference(
        emmiterNfe,
        identifyReferenceNFe,
        identifyNfe,
        destinationNfe,
        transportInfoNfe,
        productNfe
      );
    } else {
      nfe = mounterNfe(
        emmiterNfe,
        identifyNfe,
        destinationNfe,
        transportInfoNfe,
        productNfe

      );

    }
    return nfe;
  }
  catch (error) {
    return error
  }
}


async function mounterNfe(emmiterNfe, identifyNfe, destinationNfe, transportInfoNfe, productNfe) {
  let mounterNfe = {}
  mounterNfe.emitterNFe = { ...emmiterNfe }
  mounterNfe.identifyNFe = { ...identifyNfe }
  mounterNfe.destinationNFe = { ...destinationNfe }
  mounterNfe.productsNFe = { ...productNfe }
  mounterNfe.transportInfo = { ...transportInfoNfe }

  return mounterNfe;
}

async function mounterNfeIdentifyReference(emmiterNfe, identifyReference, identifyNfe, destinationNfe, transportInfoNfe, productNfe) {
  let mounterNfe = {}
  mounterNfe.emitterNFe = { ...emmiterNfe }
  mounterNfe.identifyNFe = { ...identifyNfe }
  mounterNfe.identifyReference = { ...identifyReference }
  mounterNfe.destinationNFe = { ...destinationNfe }
  mounterNfe.productsNFe = { ...productNfe }
  mounterNfe.transportInfo = { ...transportInfoNfe }


  return mounterNfe;
}



module.exports = {
  execute,
};
