<template>
  <div>
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          color="orange darken-3"
          v-if="component.permission.Editar"
          class="mr-2"
          @click="
            () => {
              //   edit_item(item);
              editItem(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-3"
          v-if="component.permission.Excluir"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        class="mb-3"
        v-model="current"
        :length="total"
        color="success"
        :total-visible="10"
      ></v-pagination>
    </div>
    <Edit :component="this" ref="edit" />
    <Delete :component="this" />
  </div>
</template>

<script>
import { header } from "./script";
import Edit from "./Edit_item/";
import Delete from "./Delete_Item/index";
import {
  ITEM_TO_UPDATE_SELLER,
  GET_AUTOCOMPLETE_SELLER,
} from "../../../store/actions/seller";
import {
  fixingPeopleCPF,
  setMaskCPF,
} from "../../Utils/global_functions/clean_cnpj_cpf";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    enterpriseId: Number,
  },

  components: { Edit, Delete },

  data: () => ({
    header,
    dialog_cancel: false,
    ObjectToDestroy: Object(),
    element: Object(),
    dialog: Boolean(),
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    destroyDialog: false,
  }),
  computed: {
    ...mapGetters([
      "getItemToAddSeller",
      "getClientPhysicalAuto",
      "getClientLegalAuto",
    ]),
  },
  methods: {
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE_SELLER, {
        item: item,
        justVisible: true,
      });
      this.$store.dispatch("setShowDialogRegisterSeller", true);
    },
    editItem(item) {
      this.$store.dispatch("setShowDialogRegisterSeller", true);
      this.$store.dispatch(ITEM_TO_UPDATE_SELLER, {
        ...item,
        isUpdate: true,
        header: "Update",
      });
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
      this.ObjectToDestroy.enterprise_id = this.component.selectValue
    },
    close() {
      this.dialog = !this.dialog;
    },

    //método desativado
    edit_item(item) {
      this.close();
      (this.$refs.edit.physical_people.type_people = item.type_people),
        (this.$refs.edit.physical_people.client_id = String()),
        (this.$refs.edit.physical_people.id = item.id),
        (this.$refs.edit.physical_people.name =
          item.people.physicalpeople.name),
        (this.$refs.edit.physical_people.lastName =
          item.people.physicalpeople.lastName),
        (this.$refs.edit.physical_people.cpf = item.people.physicalpeople.cpf),
        (this.$refs.edit.physical_people.gender =
          item.people.physicalpeople.gender),
        (this.$refs.edit.physical_people.emitter =
          item.people.physicalpeople.emitter),
        (this.$refs.edit.physical_people.emitterState =
          item.people.physicalpeople.emitterState),
        (this.$refs.edit.physical_people.idCard =
          item.people.physicalpeople.idCard),
        (this.$refs.edit.physical_people.dateOfBirth =
          item.people.physicalpeople.dateOfBirth),
        (this.$refs.edit.physical_people.fatherName =
          item.people.physicalpeople.fatherName),
        (this.$refs.edit.physical_people.motherName =
          item.people.physicalpeople.motherName),
        (this.$refs.edit.physical_people.nacionality =
          item.people.physicalpeople.nacionality),
        (this.$refs.edit.physical_people.naturality =
          item.people.physicalpeople.naturality),
        (this.$refs.edit.physical_people.icomeSpouse =
          item.people.physicalpeople.icomeSpouse),
        (this.$refs.edit.physical_people.monthlyIncome =
          item.people.physicalpeople.monthlyIncome),
        (this.$refs.edit.physical_people.civilStatus =
          item.people.physicalpeople.civilStatus),
        (this.$refs.edit.physical_people.spouse =
          item.people.physicalpeople.spouse),
        (this.$refs.edit.physical_people.enterprise_id = item.enterprise_id),
        this.contact_edit(item),
        this.address_edit(item);
    },

    contact_edit(item) {
      let contact = Object();
      this.$refs.edit.ListContact = [];
      item.people.contact.forEach((e) => {
        contact.primaryEmail = e.primaryEmail;
        contact.secundaryEmail = e.secundaryEmail;
        contact.phone = e.phone;
        contact.cellPhone = e.cellPhone;
        contact.phoneReference = e.phoneReference;
        contact.id = e.id;
        this.$refs.edit.ListContact.push(contact);
      });
    },

    address_edit(item) {
      let address = Object();
      this.$refs.edit.ListAddress = [];
      item.people.address.forEach((e) => {
        address.street = e.street;
        address.number = e.number;
        address.district = e.district;
        address.complement = e.complement;
        address.city = e.city.name;
        address.state = e.state;
        address.cep = e.cep;
        address.city_id = e.city_id;
        address.id = e.id;
        this.$refs.edit.ListAddress.push(address);
      });
    },

    async confirmDelete() {
      this.destroy_object(this.ObjectToDestroy);
      this.dialog_cancel = !this.dialog_cancel;
    },
    async AutocompleteSellerPhysical(page = 1) {
      if (this.component.search == "") {
        this.component.GetAll();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_SELLER, {
          type_people: "PhysicalPeople",
          enterprise_id: this.component.selectValue,
          search: this.component.searchInput,
          page: page,
          limit: 10,
          paginate: true,
        });
      }
    },
  },


  watch: {
    getItemToAddSeller: function (val) {
      val.people.physicalpeople.cpf = setMaskCPF(val.people.physicalpeople.cpf);
      this.results.push(val);
    },
    getters: function (val) {
      this.results = fixingPeopleCPF(val.data);
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if(this.component.search.length >= 2) {
        this.AutocompleteSellerPhysical(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
    "component.search": function () {
      this.AutocompleteSellerPhysical();
    },
    "component.searchInput": function (val) {
      if(val == "") {
        this.component.search = ""
      }
    },
  },
};
</script>

<style>
</style>