function filterResponse(response, payload) {
  var finalObject = Object();
  if (response == false) {
    finalObject = { ...payload.generalData };
    finalObject.provider.isRegistered = false;
    //  
    return finalObject;
  } else {
    finalObject = { ...payload.generalData };
    finalObject.provider = response.people.legalpeople;
    finalObject.provider.id = response.id
    finalObject.provider.isRegistered = true;
    //  
    return finalObject;
  }
}

export default filterResponse;
