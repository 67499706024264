const ADD_PRODUCT = "ADD_PRODUCT";
const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
const UPDATE_PRODUCT = "UPDATE_PRODUCT";
const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
const DESTROY_PRODUCT = "DESTROY_PRODUCT";
const GET_FILTER_PRODUCT = "GET_FILTER_PRODUCT";
const ITEM_UPDATE_PRODUCT = "ITEM_UPDATE_PRODUCT"
const AUTO_LOAD_PRODUCT = "AUTO_LOAD_PRODUCT"
const SAVE_IMAGE_PRODUCT = "SAVE_IMAGE_PRODUCT"
const GET_ALL_PRODUCT_BY_ENTERPRISE = "GET_ALL_PRODUCT_BY_ENTERPRISE"
const GET_FILTER_PRODUCT_BY_ENTERPRISE = "GET_FILTER_PRODUCT_BY_ENTERPRISE"

module.exports = {
  ITEM_UPDATE_PRODUCT,
  ADD_PRODUCT,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT,
  DESTROY_PRODUCT,
  GET_FILTER_PRODUCT,
  AUTO_LOAD_PRODUCT,
  SAVE_IMAGE_PRODUCT,
  GET_ALL_PRODUCT_BY_ENTERPRISE,
  GET_FILTER_PRODUCT_BY_ENTERPRISE
};
