var headerTypeProcess = String()
var type_people = String()
var id = undefined
var client_id = undefined
var enterprise_id = Number()

var physical_people = {
    name: String(),
    lastName: String(),
    cpf: String(),
    gender: String(),
    emitter: String(),
    emitterState: String(),
    idCard: String(),
    dateOfBirth: String(),
    fatherName: String(),
    motherName: String(),
    nacionality: String(),
    naturality: String(),
    monthlyIncome: String(),
    civilStatus: String(),
    spouse: String(),
    icomeSpouse: Number(),
    people_id: undefined
}

var address = {
    street: String(),
    number: String(),
    district: String(),
    complement: String(),
    city: String(),
    state: String(),
    cep: String(),
    city_id: Number(),
    people_id: Number()
}
var contact = {
    primaryEmail: "",
    secundaryEmail: "",
    phone: String(),
    cellPhone: String(),
    phoneReference: String(),
    people_id: Number()
}
var new_city = Object()
var ListContact = Array()
var ListAddress = Array()
var valid = false





module.exports = {
    headerTypeProcess,
    type_people,
    id,
    client_id,
    enterprise_id,
    physical_people,
    address,
    contact,
    new_city,
    ListContact,
    ListAddress,
    valid,
}