var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(this._props.addDialogNcm),callback:function ($$v) {_vm.$set(this._props, "addDialogNcm", $$v)},expression:"this._props.addDialogNcm"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Ncm")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Codigo","onKeyPress":"if(this.value.length==8) return false;","type":"number","readonly":_vm.justVisible,"rules":[
                () => !!_vm.code || 'Campo Obrigatorio',
                () =>
                  (!!_vm.code && _vm.code.length == 8) ||
                  'Campo tem que ser igual a 8',
              ],"outlined":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('v-textarea',{attrs:{"label":"*Descrição","readonly":_vm.justVisible,"rules":[() => !!_vm.description || 'Campo Obrigatorio'],"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.closeNcmDialog();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v("Salvar")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }