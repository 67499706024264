<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteComissionType()"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" :search="search" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/CommissionType/CardTitle/";
import Table from "../components/CommissionType/Table/";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";
import { mapGetters } from "vuex";
import { GET_ALL_COMISSION_TYPE, GET_FILTER_COMISSION_TYPE } from "../store/actions/commission_type";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    filter: "",
    title: "Tipo de comissão",
    permission: Object(),
  }),
  computed: {
    ...mapGetters(["getCadastrosLinks"]),
  },
  components: {
    AlertApi,
    Container,
    loadScreen,
    CardTitle,
    Table,
  },

  methods: {

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_COMISSION_TYPE, value);
    },

    async comissionType_filter(page = 1) {
      let value = {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_COMISSION_TYPE, value);
    },

    autocompleteComissionType() {
      this.search = this.searchInput
    }
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.comissionType_filter()
      }
    }
  },
};
</script>

