<template>
  <div>
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Nenhum subgrupo de produtos Cadastrado"
      no-results-text="Nenhum subgrupo de produtos Encontrado"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="yellow darken-4"
          class="mr-2"
          v-if="component.permission.Editar"
          @click="
            () => {
              change_addDialog(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              delete_item(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div>
      <v-divider></v-divider>
      <v-pagination
      class="mb-6"
        v-model="current"
        :length="total"
        :total-visible="10"
        color="success"
      ></v-pagination>
      <DestroyDialog :component="this" :destroy_object="destroy_object" />
    </div>
  </div>
</template>

<script>
import { header } from "./script";

import DestroyDialog from "../destroyDialog/destroyDialog";
import { ITEM_TO_UPDATE_SUB_PRODUCT_GROUP} from "../../../store/actions/sub_product_group"
import { mapGetters } from "vuex";

export default {
  components: {
    DestroyDialog,
  },

  props: {
    component: Object,
    getters: Object,
    search: String,
    // change_addDialog: Function,
    destroy_object: Function,
  },
  computed:{
    ...mapGetters(['getSubProductGroupToAdd'])
  },
  data: () => ({
    page: Number(),
    header,
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    destroyDialog: false,
    last_page: Number(),
  }),

  methods: {
    change_addDialog(item){
      this.$store.dispatch(ITEM_TO_UPDATE_SUB_PRODUCT_GROUP, item)
      this.$store.dispatch("setShowDialogRegisterSubProductGroup",true)
    },
    delete_item(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
  },

  created() {
    this.component.GetAll();
  },
  computed:{
    ...mapGetters(['getSubProductGroupToAdd'])
  },
  watch: {
    getSubProductGroupToAdd: function(val){
      this.results.push(val)
    },
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.subProductGroup_filter &&
          this.component.subProductGroup_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
