<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <!-- exemplo de como deve aplicar as permissoes na view -->
    <v-btn
    :block="$vuetify.breakpoint.xsOnly"
      v-if="component.permission.Cadastrar"
      color="success mr-2 mt-3"
      @click="
        () => {
          executeAlterAddDialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    selectValue: Object(),
    enterprises: [],
  }),

  created() {
    this.GetAllNoPage();
  },

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    executeAlterAddDialog() {
      this.component.enable_disable_save_addDialog = false;
      this.alterAddDialog(this.getComponent());
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    selectValue: function () {
      this.component.enterprise_id = this.selectValue.id
      this.$store.dispatch(SELECTED_ENTERPRISE, this.selectValue);
    },
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectValue = this.enterprises[0];
    },
  },
};
</script>

<style></style>
