import axios from "axios";

const {
  ADD_CST_IPI,
  UPDATE_CST_IPI,
  GET_ALL_CST_IPI,
  DESTROY_CST_IPI,
  GET_FILTER_CST_IPI
} = require("./actions/cstipi");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCstIPI = {
  state: () => ({
    csts_ipi: {},
  }),

  mutations: {
    GET_ALL_CST_IPI: (state, csts_ipi) => {
      state.csts_ipi = csts_ipi;
    },
    GET_FILTER_CST: (state, payload) => {
      if (payload !== undefined) {
        state.csts_ipi = payload
      }

    },
    ADD_CST_IPI: (state, newCST_IPI) => {
      if (state.csts_ipi.data) {
        state.csts_ipi.data.push(newCST_IPI[0])
      } else {
        state.csts_ipi.push(newCST_IPI[0])
      }
    },
    UPDATE_CST(state, updateCST_IPI) {
      state.csts_ipi.data.Update(updateCST_IPI)
    },
    DESTROY_CST: (state, id) => {
      let auxState = state.csts_ipi;
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.csts_ipi = auxState;
    },
  },

  actions: {
    async GET_ALL_CST_IPI({
      commit
    },
      payload
    ) {

      try {
       const response =  await axios.post("cstipi/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate
        })
        commit(GET_ALL_CST_IPI, response.data[0]);
        
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }

    },

    async GET_FILTER_CST_IPI({
      commit
    }, payload) {

      try {
        const response = await axios.post("cst/autocomplete", {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            paginate: payload.paginate
        })
        commit(GET_FILTER_CST_IPI, response.data[0]);
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
    },


    ADD_CST_IPI({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("cst/store", payload).then((response) => {
          resolve()
          commit(ADD_CST_IPI, response.data);
          commit(SPLICE_ERRO, {
            alert: 'Cadastrado com sucesso!',
            type: 'S',

          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_CST({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("cst/update", payload).then((response) => {
          resolve()
          commit(UPDATE_CST_IPI, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_CST_IPI({
      commit
    }, payload) {
      axios
        .post("cst/delete", payload)
        .then((response) => {
          commit(DESTROY_CST_IPI, response.id);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message[0],
            type: 'S'
          })
        })
    },
  },

  getters: {
    getCstIpi: (state) => ({ ...state.csts_ipi }),
  },
};

export default moduleCstIPI;