import axios from "axios";
const {
    ADD_ACCOUNT_PLAN,
    UPDATE_ACCOUNT_PLAN,
    GET_ALL_ACCOUNT_PLAN,
    DESTROY_ACCOUNT_PLAN,
    GET_FILTER_ACCOUNT_PLAN,
    AUX_TO_UPDATE_ACCOUNT_PLAN,
    GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE,
    GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE,

} = require("./actions/account_plan");
const {
    SPLICE_ERRO
} = require('./actions/alert_api')

const moduleAccount_plan = {
    state: () => ({
        new_account_plan: Object(),
        account_plans: Object(),
        itemToUpdateAccountPlans: Object(),
    }),

    mutations: {
        GET_ALL_ACCOUNT_PLAN: (state, account_plans) => {
            // if (account_plans.current_page) {
            //     account_plans.data.forEach((e) => {

            //         if (e.status === 1) {
            //             e.status = "Habilitado"
            //         } else {
            //             e.status = "Desabilitado"
            //         }
            //     })
            // } else {
            //     account_plans.forEach((e) => {

            //         if (e.status === 1) {
            //             e.status = "Habilitado"
            //         } else {
            //             e.status = "Desabilitado"
            //         }
            //     })
            // }
            state.account_plans = account_plans;
        },

        GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE: (state, account_plans) => {
            state.account_plans = account_plans;
        },

        GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE: (state, account_plans) => {
            state.account_plans = account_plans;
        },

        GET_FILTER_ACCOUNT_PLAN: (state, payload) => {
            // if (payload != undefined) {
            //     payload.data.forEach((e) => {
            //         if (e.status === 1) {
            //             e.status = "Habilitado"
            //         } else {
            //             e.status = "Desabilitado"
            //         }
            //     })
            // }

            state.account_plans = payload
        },
        ADD_ACCOUNT_PLAN: (state, newAccount_plans) => {

            //state.account_plans = newAccount_plans;
            state.new_account_plan = newAccount_plans
        },
        UPDATE_ACCOUNT_PLAN(state, editAccount_plan) {

            // if (editAccount_plan.status === 1) {
            //     editAccount_plan.status = "Habilitado"
            // } else {
            //     editAccount_plan.status = "Desabilitado"
            // }
            state.account_plans.data.Update(editAccount_plan)
        },


        DESTROY_ACCOUNT_PLAN: (state, id) => {
            let auxState = [...state.account_plans.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.account_plans = [...auxState];
        },
        AUX_TO_UPDATE_ACCOUNT_PLAN: function (state, payload) {
            state.itemToUpdateAccountPlans = payload
        }
    },

    actions: {

        GET_ALL_ACCOUNT_PLAN({
            commit
        }, payload) {
            axios.post("accountplan/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                commit(GET_ALL_ACCOUNT_PLAN, response.data[0]);

            });
        },

        GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE({
            commit
        }, payload) {
            axios.post("accountplan/getAllByEnterprise", { page: payload.page, limit: payload.limit, paginate: payload.paginate, enterprise_id: payload.enterprise_id }).then((response) => {
                commit(GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE, response.data);

            });
        },

        GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE({
            commit
        }, payload) {
            axios.post("accountplan/autocompleteByEnterprise", { page: payload.page, limit: payload.limit, paginate: payload.paginate, enterprise_id: payload.enterprise_id, search: payload.search }).then((response) => {
                commit(GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE, response.data);

            });
        },

        GET_FILTER_ACCOUNT_PLAN({
            commit
        }, payload) {
            axios.post("accountplan/autocomplete", {
                    search: payload.search,
                    limit: payload.limit,
                    page: payload.page,
                    paginate: payload.paginate
            }).then((response) => {
                commit(GET_FILTER_ACCOUNT_PLAN, response.data[0]);
            })
        },

        ADD_ACCOUNT_PLAN({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {

                axios.post("accountplan/store", payload).then((response) => {
                    resolve()
                    commit(ADD_ACCOUNT_PLAN, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: "Cadastrado com sucesso!",
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },

        UPDATE_ACCOUNT_PLAN({
            commit
        }, payload) {

            return new Promise((resolve, reject) => {

                axios.post("accountplan/update", payload).then((response) => {
                    resolve()
                    commit(SPLICE_ERRO, {
                        alert: "Atualizado com sucesso!",
                        type: 'S'
                    })
                    commit(UPDATE_ACCOUNT_PLAN, response.data[0]);
                }).catch(() => {
                    reject()
                })

            })
        },

        DESTROY_ACCOUNT_PLAN({
            commit
        }, payload) {
            axios.post("accountplan/delete", payload).then((response) => {
                commit(DESTROY_ACCOUNT_PLAN, payload);
                commit(SPLICE_ERRO, {
                    alert: response.data.menssage[0],
                    type: 'S'
                })
            })
        },
        AUX_TO_UPDATE_ACCOUNT_PLAN: function (context, payload) {
            context.commit(AUX_TO_UPDATE_ACCOUNT_PLAN, payload)
        }
    },

    getters: {
        getNewAccountPlan: (state) => ({ ...state.new_account_plan }),
        getAccount_Plan: (state) => ({ ...state.account_plans }),
        getItemToUpdateAccountPlan: (state) => ({ ...state.itemToUpdateAccountPlans }),

    },
};

export default moduleAccount_plan;