<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTite" />
        <v-row>
          <v-col cols="2">
            <v-text-field label="Data Inicial" v-model="initialDate" outlined type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Data Final" v-model="finalDate" outlined type="date">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select label="Status da Nota" v-model="statusNFe"
              :items="['Todos', 'Autorizada', 'Pre-pedido', 'Cancelada', 'Rejeitada']" outlined>
            </v-select>

          </v-col>

        </v-row>

      </v-card-text>

      <v-card-text>
        <v-text-field v-model="searchInput" @keyup.enter="setSearchRevenue()" label="Pesquisar" outlined append-icon="mdi-magnify"></v-text-field>
      </v-card-text>

      <AddDialog v-if="addDialog" :dialog="addDialog" :changeDialog="alterDialog" title="Registrar"
        :enterprise="enterprise" :enterpriseType="enterpriseType" :component="this" />
      <Table :component="this" :getters="getRevenues" :headers="headers" :isLoad="loading" />
    </v-card>
    <AlertApi />
    <InfoError/>
    <LoadScreen />
  </Container>
</template>

<script>
// v-if="permission.Visualizar"
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/revenues/CardTitle";
import Table from "../components/revenues/Table/";
import InfoError from "../components/infoError/"
import { ENTERPRISE_OBJECT } from "../store/actions/enterprise";
import { GET_ALL_REVENUES_BY_ENTERPRISE, GET_FILTER_REVENUE_BY_ENTERPRISE, GET_ALL_NOT_EMITTER_NFE } from "../store/actions/revenues";
import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"

export default {
  data: () => ({
    enterpriseId: Number(),
    enterpriseObject: Object(),
    initialDate: Date(),
    finalDate: Date(),
    search: "",
    searchInput: "",
    code: "",
    getter: "getRevenues",
    statusNFe: 'Todos',
    addDialog: Boolean(),
    destroyDialog: false,
    isNote: Boolean(),
    loading: Boolean(),
    idToDestroy: 0,
    objectToUpdate: {},
    enterprise: Object(),
    title: "Faturamento",
    permission: Object(),
    enterpriseType: String(),
    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Faturamento", value: "date_of_issue" },
      { text: "Chave da nota", value: "access_key" },
      { text: "Cliente", value: "cliente.people.name" },
      { text: "Número Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos", value: "value_total_products" },
      { text: "Valor da Nota", value: "value_total_note" },
      { text: "Base ICMS", value: "base_calc_icms_total" },
      { text: "Valor ICMS", value: "value_total_note" },
      { text: "Base IPI", value: "value_total_note" },
      { text: "Valor IPI", value: "value_total_note" },
      { text: "Valor Isento", value: "value_total_note" },
      { text: "Valor Outros", value: "value_total_note" },
      { text: "Ações", value: "actions" }
    ]
  }),
  computed: { ...mapGetters(["getRevenues", "getMovimentacaoLinks"]) },

  methods: {
    loadFilterDate() {
      var currentDate = new Date();
      currentDate.setDate(1);
      var initialDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);
      var lastDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format

      this.initialDate = initialDate
      this.finalDate = lastDate
    },
    async revenues_filter(page = 1) {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
          enterprise_id: this.enterpriseId
        };
        await this.$store.dispatch(GET_FILTER_REVENUE_BY_ENTERPRISE, value);
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_REVENUES_BY_ENTERPRISE, {
        status: null,
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId
      });
    },
    async getAllStatusNotEmitter(status, page = 1) {
      await this.$store.dispatch(GET_ALL_NOT_EMITTER_NFE, {
        status: status,
        data_initial: this.initialDate,
        date_final: this.finalDate,
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId
      });
    },

    async alterDialog() {
      this.addDialog = !this.addDialog;
    },

    setSearchRevenue() {
      this.search = this.searchInput
    }
  },

  async created() {
    this.loading = true;
    this.loading = false;
    await this.loadFilterDate()
  },
  watch: {
    search: function () {
      if(this.search.length >=2) {
        this.revenues_filter();
      }
    },
    searchInput: function (val) {
      if(val.length == 0) {
        this.search = ""
        this.GetAll()
      }
    },
    statusNFe: function (val) {
      if (this.statusNFe == "Todos") {
       this.GetAll()
      } else {
        this.getAllStatusNotEmitter(this.statusNFe)
      }
    },
    enterpriseId: function(val) {
      this.GetAll()
    }
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getMovimentacaoLinks.getPermissions(name);

    // this.$watch(
    //   () => {
    //     return this.$refs.cardTite.selectValue;
    //   },
    //   val => {
    //     this.enterpriseType = val.tax_regime_id;
    //     this.enterpriseId = val.id;
    //   }
    // );
  },

  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    LoadScreen,
    InfoError,
    AddDialog: () => import("../components/revenues/AddDialog/")
  }

};
</script>

<style scoped></style>
