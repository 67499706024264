/* eslint-disable no-unused-vars */
import VueRouter from "vue-router";
import store from "../store";

import {
  SET_CADASTROS,
  SET_MOVIMENTACAO,
  SET_FINANCEIRO,
  SET_ENTRADAS,
  SET_COMPRAS,
  SET_FATURAMENTO,
  SET_FISCAL,
  SET_RELATORIOL,
  SET_SEGURANCA,
  SET_SAIDAS,
} from "../store/actions/links";

export default (router = new VueRouter()) => {
  router.beforeEach((_, __, next) => {
    let user = store.getters["auth/user"];
    let cadastrosLinks = store.getters.getCadastrosLinks;
    let comprasLinks = store.getters.getComprasLinks;
    let entradasLinks = store.getters.getEntradaLinks;
    let faturamentoLinks = store.getters.getFaturamentoLinks;
    let saidasLinks = store.getters.getSaidasLinks;
    let movimentacaoLinks = store.getters.getMovimentacaoLinks;
    let financeiroLinks = store.getters.getFinanceiroLinks;
    let fiscalLinks = store.getters.getFiscalLinks;
    let relatorioLinks = store.getters.getRelatorioLinks;
    let segurancaLinks = store.getters.getSegurancaLinks;

    user &&
      user.roles.length > 0 &&
      user.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          cadastrosLinks.forEach((cadastros, id) => {
            if (cadastros.text === permission.display_name) {
              let index = {
                index: id,
              };
              if (
                permission.pivot.permission_col &&
                permission.pivot.permission_col.includes("Visualizar")
              ) {
                store.commit(SET_CADASTROS, {
                  index: index.index,
                  perm: permission.pivot.permission_col,
                });
              }
            }
          }),
            comprasLinks.forEach((compras, id) => {
              if (compras.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_COMPRAS, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            entradasLinks.forEach((entradas, id) => {
              if (entradas.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_ENTRADAS, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            faturamentoLinks.forEach((faturamento, id) => {
              if (faturamento.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_FATURAMENTO, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            saidasLinks.forEach((saidas, id) => {
              if (saidas.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_SAIDAS, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            relatorioLinks.forEach((relatorio, id) => {
              if (relatorio.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_RELATORIOL, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            segurancaLinks.forEach((seguranca, id) => {
              if (seguranca.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_SEGURANCA, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            fiscalLinks.forEach((fiscal, id) => {
              if (fiscal.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_FISCAL, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            movimentacaoLinks.forEach((movimentacao, id) => {
              if (movimentacao.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_MOVIMENTACAO, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            }),
            financeiroLinks.forEach((financeiro, id) => {
              if (financeiro.text === permission.display_name) {
                let index = {
                  index: id,
                };
                if (
                  permission.pivot.permission_col &&
                  permission.pivot.permission_col.includes("Visualizar")
                ) {
                  store.commit(SET_FINANCEIRO, {
                    index: index.index,
                    perm: permission.pivot.permission_col,
                  });
                }
              }
            });
        });
      });
    next();
  });
  // })
};
