const ADD_CARD_ADM = "ADD_CARD_ADM";
const UPDATE_CARD_ADM = "UPDATE_CARD_ADM";
const AUX_TO_UPDATE_CARD_ADM = "AUX_TO_UPDATE_CARD_ADM"
const GET_ALL_CARD_ADM = "GET_ALL_CARD_ADM";
const DESTROY_CARD_ADM = "DESTROY_CARD_ADM";
const GET_FILTER_CARD_ADM = "GET_FILTER_CARD_ADM";

module.exports = {
    ADD_CARD_ADM,
    UPDATE_CARD_ADM,
    AUX_TO_UPDATE_CARD_ADM,
    GET_ALL_CARD_ADM,
    DESTROY_CARD_ADM,
    GET_FILTER_CARD_ADM
}
