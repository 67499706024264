import { AUX_TO_UPDATE_CSOSN } from "../../../store/actions/csosn";


function visibleItem(item){
    this.$store.dispatch(AUX_TO_UPDATE_CSOSN, {item: item, justVisible: true})
    this.$store.dispatch("setShowDialogRegisterCSOSN", true);
}
function open_Dialog(item) {
    this.$store.dispatch("setShowDialogRegisterCSOSN", true);
    this.$store.dispatch(AUX_TO_UPDATE_CSOSN, {});
    this.$store.dispatch(AUX_TO_UPDATE_CSOSN, item);
}
function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}




export default {
    open_Dialog,
    deleteItem,
    handleClick,
    visibleItem
}