<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteNoteModel()"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getNoteModel"
        :destroy_noteModels="destroy_noteModels"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalNoteModel v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/note_models/CardTitle/index";
import Table from "../components/note_models/Table/index";
import ModalNoteModel from "../components/note_models/AddDialog/index";
import AlertApi from "./../components/alert_api/alert_api";
import loadScreen from "../components/load/";

import { mapGetters } from "vuex";
import {
  GET_ALL_NOTE_MODEL_BY_ENTERPRISE,
  DESTROY_NOTE_MODEL,
  GET_FILTER_NOTE_MODEL_BY_ENTERPRISE,
} from "../store/actions/note_model";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    getter: "getNoteModel",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    enterprise_id: Number(),
    objectToUpdate: {},
    permission: Object(),
    title: "Modelo de Nota fiscal ",
    headers: [
      { text: "Numero da Sequência", value: "sequence" },
      { text: "Descrição", value: "description" },
      { text: "Tipo Documento Fiscal", value: "tax_document_type" },
      { text: "Modelo", value: "model" },
      { text: "Séries", value: "series" },
      { text: "Ações", value: "actions" },
    ],
  }),

  methods: {
    async destroy_noteModels(item) {
      const notel_model = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_NOTE_MODEL, notel_model);
      } catch (error) {
        alert(error);
      }
    },
    open_Dialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_NOTE_MODEL_BY_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterprise_id,
      });
    },
    async noteModel_filter(page = 1) {
      let value = {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterprise_id
      };
      await this.$store.dispatch(GET_FILTER_NOTE_MODEL_BY_ENTERPRISE, value);
    },
    autocompleteNoteModel() {
      this.search = this.searchInput
    }
  },

  computed: {
    ...mapGetters(["getNoteModel", "getCadastrosLinks"]),
  },


  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  watch: {
    enterprise_id: function(val) {
      this.GetAll()
    },

    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length >= 2){
        this.noteModel_filter()
      }
    }
  },

  components: {
    Container,
    AlertApi,
    loadScreen,
    CardTitle,
    Table,
    ModalNoteModel,
  },
};
</script>

<style scoped></style>
