
import Axios from "axios"
import interceptors from "../../interceptors-v2"
import { GENERATE_SPED_ICMS } from "../actions/sped"



export default {
    state: {
        spedICMS: Object()
    },
    getters: {
        getSpedICMS: (state) => state.spedICMS
    },
    mutations: {
        GENERATE_SPED_ICMS(state, payload) {
            state.spedICMS = payload
        }
    },
    actions: {
        GENERATE_SPED_ICMS(context, payload) {
            try {
                Axios.post("spedfiscal/create", payload).then((response) => {
                    context.commit(GENERATE_SPED_ICMS, response)
                })
            } catch (error) {
                interceptors(error.data)
            }
        }
    },
}