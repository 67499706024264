<template>
  <v-row justify="center">
    <v-dialog v-model="component.dialog" persistent max-width="500">
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-card-title>Registrar Tipo de Comissão</v-card-title>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid">
                <v-select
                  v-model="commission_type.commissionType"
                  outlined
                  item-value="value"
                  item-text="text"
                  :items="item"
                  label="Tipo de Comissão:"
                  :rules="[
                    () =>
                      !!commission_type.commissionType || 'Campo Obrigatório',
                  ]"
                ></v-select>
                <v-text-field
                  v-model="commission_type.description"
                  outlined
                  label="Descrição:"
                  :rules="[
                    () =>
                      !!commission_type.description.trim() ||
                      'Campo Obrigatório',
                  ]"
                ></v-text-field>
              </v-form>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="error"
              @click="
                () => {
                  desactive_dialog();
                }
              "
            >
              <v-icon>mdi-cancel</v-icon>
              Cancelar</v-btn
            >
            <v-btn
              color="success"
              @click="
                () => {
                  submit();
                }
              "
            >
              <v-icon>mdi-content-save</v-icon>
              Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_COMISSION_TYPE } from "../../../store/actions/commission_type";
export default {
  props: { component: Object() },
  data: () => ({
    valid: false,
    id: "",
    commission_type: {
      description: String(),
      commissionType: Number(),
    },
    item: [
      { text: "Items", value: 1 },
      { text: "Vendas", value: 2 },
      { text: "Produto", value: 3 },
    ],
  }),
  methods: {
    desactive_dialog() {
      this.component.dialog = !this.component.dialog;
      this.commission_type.commissionType = "";
      this.commission_type.description = "";
      this.$refs.form.resetValidation();
    },

    submit() {
      this.create_commission_type();
    },
    async create_commission_type() {
      const commission_type = {
        commissionType: this.commission_type.commissionType,
        description: this.commission_type.description,
      };
      await this.$store
        .dispatch(ADD_COMISSION_TYPE, commission_type)
        .then(() => {
          this.desactive_dialog();
        });
    },
  },
};
</script>
