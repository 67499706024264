import { SET_REPORT_SALES } from "./actions/report_sales";
import axios from "axios";

export default {
  state: {
    dataReportSales: Object(),
  },
  getters: {
    getDataReportSales: function (state) {
      return state.dataReportSales;
    },
  },
  mutations: {
    SET_REPORT_SALES: function (state, payload) {
      state.dataReportSales = payload;
    },
  },
  actions: {
    SET_REPORT_SALES: function (context, payload) {
      axios.post("catalog/sales", payload).then((response) => {
        context.commit(SET_REPORT_SALES, response.data[0]);
      });
      // var x = 1;
      // if (x == 0) {
      //   context.commit(SET_REPORT_SALES,  mock);
      // } else {
  
      // }
    },
  },
};

// 'sales.data_sale            as data_sale ',
//                 'sales.number_sale          as number_sale ',
//                 'sales.client_id                as client_id',
//                 'sales.series               as series',
//                 'sales.value_total_products as value_total_products',
//                 'sales.value_descount_total as value_descount_total',
//                 'sales.value_total_note     as value_total_note',
var mock = []

// var mock = [
//   {
//     data_sale: '1',
//     number_sale: "1",
//     client_id: "1",
//     series: "1",
//     value_total_products: "1",
//     value_descount_total: "1",
//     value_total_note: "1",
//   },
//   {
//     data_sale: '2',
//     number_sale: "2",
//     client_id: "1",
//     series: "2",
//     value_total_products: "2",
//     value_descount_total: "3",
//     value_total_note: "4",
//   },  
// ]