const globalDialogs = {
    state: {
        showDialogRegisterGeneralDataSales : false,
        showDialogRegisterCloseCashier : false,
        showDialogRegisterSuply : false,
        showDialogRegisterBleed : false,
        showDialogRegisterOpenCashier : false,
        showDialogRegisterParameterItens: false,
        showDialogRegisterParameter: false,
        showDialogRegisterFutureSeles: false,
        showDialogRegisterUnit: false,
        showDialogRegisterEmitionTag: false,
        showDialogRegisterSeller: false,
        showDialogRegisterImportXml: true,
        showDialogRegisterProvider: false,
        showDialogRegisterGroupEnterprise: false,
        showDialogRegisterEnterprise: false,
        showDialogRegisterCST: false,
        showDialogRegisterAccountPlan: false,
        showDialogRegisterProduct: false,
        showDialogRegisterProductGroup: false,
        showDialogRegisterSubProductGroup: false,
        showDialogRegisterCFOP: false,
        showDialogRegisterClient: false,
        showDialogRegisterTaxOperationType: false,
        showDialogRegisterAliquot: false,
        showDialogRegisterCSOSN: false,
        showDialogRegisterIPI: false,
        showDialogRegisterPISCOFINS: false,
        showDialogRegisterTransporter: false,
        showDialogRegisterCorrectionLetter: false,
        showDialogRegisterReprintNFE: false,
        showDialogRegisterCancelSequenceInvoice: false,
        showDialogRegisterConfirmNote: false,
        showDialogRegisterNoteNFCEViewCancel: false,
        showDialogLoading: false,
        showDialogRegisterCancelNote: false,
        showDialogRegisterExchangeControl: false,
        fiscalType: undefined,
        showDialogRegisterSelectionTable: false,

    },
    getters: {
        getShowDialogRegisterGeneralDataSales: function (state) {
            return state.showDialogRegisterGeneralDataSales;
        },
        getShowDialogRegisterCloseCashier: function (state) {
            return state.showDialogRegisterCloseCashier;
        },
        getShowDialogRegisterSuply: function (state) {
            return state.showDialogRegisterSuply;
        },        
        getShowDialogRegisterBleed: function (state) {
            return state.showDialogRegisterBleed;
        },
        getShowDialogRegisterOpenCashier: function (state) {
            return state.showDialogRegisterOpenCashier;
        },        
        getShowDialogRegisterParameterItens: function (state) {
            return state.showDialogRegisterParameterItens;
        },
        getShowDialogRegisterParameter: function (state) {
            return state.showDialogRegisterParameter;
        },
        getShowDialogRegisterUnit: function (state) {
            return state.showDialogRegisterUnit;
        },
        getShowDialogRegisterEmitionTag: function (state) {
            return state.showDialogRegisterEmitionTag;
        },
        // getShowDialogRegisterSeller: function (state, payload) {
        //    return  state.showDialogRegisterSeller = payload;
        // },
        getShowDialogRegisterSeller: function (state) {
            return state.showDialogRegisterSeller;
        },
        getShowDialogRegisterSelectionTable: function (state) {
            return state.showDialogRegisterSelectionTable;
        },
        getShowDialogRegisterFutureSeles: function (state) {
            return state.showDialogRegisterFutureSeles;
        },
        getShowDialogRegisterImportXml: function (state) {
            return state.showDialogRegisterImportXml;
        },
        getShowDialogRegisterCancelNote: function (state) {
            return state.showDialogRegisterCancelNote;
        },
        getShowDialogRegisterProvider: function (state) {
            return state.showDialogRegisterProvider;
        },
        getShowDialogRegisterGroupEnterprise: function (state) {
            return state.showDialogRegisterGroupEnterprise;
        },
        getShowDialogRegisterEnterprise: function (state) {
            return state.showDialogRegisterEnterprise;
        },
        getShowDialogRegisterCST: function (state) {
            return state.showDialogRegisterCST;
        },
        getShowDialogRegisterAccountPlan: function (state) {
            return state.showDialogRegisterAccountPlan;
        },
        getShowDialogRegisterProductGroup: function (state) {
            return state.showDialogRegisterProductGroup;
        },
        getShowDialogRegisterSubProductGroup: function (state) {
            return state.showDialogRegisterSubProductGroup;
        },
        getShowDialogRegisterCFOP: function (state) {
            return state.showDialogRegisterCFOP;
        },
        getShowDialogRegisterClient: function (state) {
            return state.showDialogRegisterClient;
        },
        getShowDialogRegisterTaxOperationType: function (state) {
            return state.showDialogRegisterTaxOperationType;
        },
        getShowDialogRegisterProduct: function (state) {
            return state.showDialogRegisterProduct;
        },
        getShowDialogRegisterAliquot: function (state) {
            return state.showDialogRegisterAliquot;
        },
        getShowDialogRegisterCSOSN: function (state) {
            return state.showDialogRegisterCSOSN;
        },
        getShowDialogRegisterIPI: function (state) {
            return state.showDialogRegisterIPI;
        },
        getShowDialogRegisterPISCOFINS: function (state) {
            return state.showDialogRegisterPISCOFINS;
        },
        getShowDialogRegisterTransporter: function (state) {
            return state.showDialogRegisterTransporter;
        },
        getShowDialogRegisterConfirmNote: function (state) {
            return state.showDialogRegisterConfirmNote
        },
        getShowDialogRegisterCorrectionLetter: function (state) {
            return state.showDialogRegisterCorrectionLetter;
        },
        getShowDialogRegisterCancelSequenceInvoice: function (state) {
            return state.showDialogRegisterCancelSequenceInvoice;
        },
        getShowDialogLoading: function (state) {
            return state.showDialogLoading;
        },
        getShowDialogRegisterNoteNFCEViewCancel: function (state) {
            return state.showDialogRegisterNoteNFCEViewCancel;
        },
        getShowDialogRegisterReprintNFE: function (state) {
            return state.showDialogRegisterReprintNFE;
        },
        getFiscalType: function (state) {
            return state.fiscalType
        },
        getShowDialogRegisterExchangeControl: function (state) {
            return state.showDialogRegisterExchangeControl
        }

    },



    mutations: {
        setShowDialogRegisterGeneralDataSales: function (state, payload) {
            state.showDialogRegisterGeneralDataSales = payload;
        },
        setShowDialogRegisterCloseCashier: function (state, payload) {
            state.showDialogRegisterCloseCashier = payload;
        },
        
        setShowDialogRegisterSuply: function (state, payload) {
            state.showDialogRegisterSuply = payload;
        },
        setShowDialogRegisterBleed: function (state, payload) {
            state.showDialogRegisterBleed = payload;
        },
        setShowDialogRegisterOpenCashier: function (state, payload) {
            state.showDialogRegisterOpenCashier = payload;
        },
        setShowDialogRegisterParameterItens: function (state, payload) {
            state.showDialogRegisterParameterItens = payload;
        },
        setShowDialogRegisterParameter: function (state, payload) {
            state.showDialogRegisterParameter = payload;
        },
        setShowDialogRegisterFutureSeles: function (state, payload) {
            state.showDialogRegisterFutureSeles = payload;
        },
        setShowDialogRegisterUnit: function (state, payload) {
            state.showDialogRegisterUnit = payload;
        },
        setShowDialogRegisterEmitionTag: function (state, payload) {
            state.showDialogRegisterEmitionTag = payload;
        },
        setShowDialogRegisterSeller: function (state, payload) {
            state.showDialogRegisterSeller = payload;
        },
        setShowDialogRegisterSelectionTable: function (state, payload) {
            state.showDialogRegisterSelectionTable = payload;
        },
        setShowDialogRegisterImportXml: function (state, payload) {
            state.showDialogRegisterImportXml = payload;
        },
        setShowDialogRegisterCancelNote: function (state, payload) {
            state.showDialogRegisterCancelNote = payload;
        },
        setShowDialogRegisterProvider: function (state, payload) {
            state.showDialogRegisterProvider = payload;
        },
        setShowDialogRegisterReprintNFE: function (state, payload) {
            state.showDialogRegisterReprintNFE = payload;
        },
        setShowDialogRegisterGroupEnterprise: function (state, payload) {
            state.showDialogRegisterGroupEnterprise = payload;
        },
        setShowDialogRegisterEnterprise: function (state, payload) {
            state.showDialogRegisterEnterprise = payload;
        },
        setShowDialogRegisterCST: function (state, payload) {
            state.showDialogRegisterCST = payload;
        },
        setShowDialogRegisterAccountPlan: function (state, payload) {
            state.showDialogRegisterAccountPlan = payload;
        },
        setShowDialogRegisterProductGroup: function (state, payload) {
            state.showDialogRegisterProductGroup = payload;
        },
        setShowDialogRegisterSubProductGroup: function (state, payload) {
            state.showDialogRegisterSubProductGroup = payload;
        },
        setShowDialogRegisterCFOP: function (state, payload) {
            state.showDialogRegisterCFOP = payload;
        },
        setShowDialogRegisterClient: function (state, payload) {
            state.showDialogRegisterClient = payload;
        },
        setShowDialogRegisterTaxOperationType: function (state, payload) {
            state.showDialogRegisterTaxOperationType = payload;
        },
        setShowDialogRegisterConfirmNote: function (state, payload) {
            state.showDialogRegisterConfirmNote = payload
        },
        setShowDialogRegisterProduct: function (state, payload) {
            state.showDialogRegisterProduct = payload;
        },
        setShowDialogRegisterAliquot: function (state, payload) {
            state.showDialogRegisterAliquot = payload;
        },
        setShowDialogRegisterCSOSN: function (state, payload) {
            state.showDialogRegisterCSOSN = payload;
        },
        setShowDialogRegisterIPI: function (state, payload) {
            state.showDialogRegisterIPI = payload;
        },
        setShowDialogRegisterPISCOFINS: function (state, payload) {
            state.showDialogRegisterPISCOFINS = payload;
        },
        setShowDialogRegisterTransporter: function (state, payload) {
            state.showDialogRegisterTransporter = payload;
        },
        setShowDialogRegisterCorrectionLetter: function (state, payload) {
            state.showDialogRegisterCorrectionLetter = payload;
        },
        setShowDialogRegisterCancelSequenceInvoice: function (state, payload) {
            state.showDialogRegisterCancelSequenceInvoice = payload;
        },
        setShowDialogLoading: function (state, payload) {
            state.showDialogLoading = payload;
        },
        setShowDialogRegisterNoteNFCEViewCancel: function (state, payload) {
            state.showDialogRegisterNoteNFCEViewCancel = payload;
        },
        setFiscalType: function (state, payload) {
            state.fiscalType = payload;
        },
        setShowDialogRegisterExchangeControl: function (state, payload) {
            state.showDialogRegisterExchangeControl = payload;
        }
    },//showDialogRegisterExchangeControl
    actions: {
        setShowDialogRegisterGeneralDataSales: function (context, payload) {
            context.commit('setShowDialogRegisterGeneralDataSales', payload)
        },
        setShowDialogRegisterCloseCashier: function (context, payload) {
            context.commit('setShowDialogRegisterCloseCashier', payload)
        },
        setShowDialogRegisterSuply: function (context, payload) {
            context.commit('setShowDialogRegisterSuply', payload)
        },
        setShowDialogRegisterBleed: function (context, payload) {
            context.commit('setShowDialogRegisterBleed', payload)
        },
        setShowDialogRegisterOpenCashier: function (context, payload) {
            context.commit('setShowDialogRegisterOpenCashier', payload)
        },
        setShowDialogRegisterParameterItens: function (context, payload) {
            context.commit('setShowDialogRegisterParameterItens', payload)
        },
        setShowDialogRegisterParameter: function (context, payload) {
            context.commit('setShowDialogRegisterParameter', payload)
        },
        setShowDialogRegisterFutureSeles: function (context, payload) {
            context.commit('setShowDialogRegisterFutureSeles', payload)
        },
        setShowDialogRegisterUnit: function (context, payload) {
            context.commit('setShowDialogRegisterUnit', payload)
        },
        setShowDialogRegisterEmitionTag: function (context, payload) {
            context.commit('setShowDialogRegisterEmitionTag', payload)
        },
        setShowDialogRegisterSeller: function (context, payload) {
            context.commit('setShowDialogRegisterSeller', payload)
        },
        setShowDialogRegisterSelectionTable: function (context, payload) {
            context.commit('setShowDialogRegisterSelectionTable', payload)
        },
        setShowDialogRegisterImportXml: function (context, payload) {
            context.commit('setShowDialogRegisterImportXml', payload)
        },
        setShowDialogRegisterCancelNote: function (context, payload) {
            context.commit('setShowDialogRegisterCancelNote', payload)
        },
        setShowDialogRegisterProvider: function (context, payload) {
            context.commit("setShowDialogRegisterProvider", payload)
        },
        setShowDialogRegisterGroupEnterprise: function (context, payload) {
            context.commit("setShowDialogRegisterGroupEnterprise", payload)
        },
        setShowDialogRegisterEnterprise: function (context, payload) {
            context.commit("setShowDialogRegisterEnterprise", payload)
        },
        setShowDialogRegisterCST: function (context, payload) {
            context.commit("setShowDialogRegisterCST", payload)
        },
        setShowDialogRegisterAccountPlan: function (context, payload) {
            context.commit("setShowDialogRegisterAccountPlan", payload)
        },
        setShowDialogRegisterProductGroup: function (context, payload) {
            context.commit("setShowDialogRegisterProductGroup", payload)
        },
        setShowDialogRegisterReprintNFE: function (context, payload) {
            context.commit('setShowDialogRegisterReprintNFE', payload)
        },
        setShowDialogRegisterConfirmNote: function (context, payload) {
            context.commit("setShowDialogRegisterConfirmNote", payload)
        },
        setShowDialogRegisterSubProductGroup: function (context, payload) {
            context.commit("setShowDialogRegisterSubProductGroup", payload)
        },
        setShowDialogRegisterCFOP: function (context, payload) {
            context.commit("setShowDialogRegisterCFOP", payload)
        },
        setShowDialogRegisterClient: function (context, payload) {
            context.commit("setShowDialogRegisterClient", payload)
        },
        setShowDialogRegisterTaxOperationType: function (context, payload) {
            context.commit("setShowDialogRegisterTaxOperationType", payload)
        },
        setShowDialogRegisterProduct: function (context, payload) {
            context.commit("setShowDialogRegisterProduct", payload)
        },
        setShowDialogRegisterAliquot: function (context, payload) {
            context.commit("setShowDialogRegisterAliquot", payload)
        },
        setShowDialogRegisterCSOSN: function (context, payload) {
            context.commit("setShowDialogRegisterCSOSN", payload)
        },
        setShowDialogRegisterIPI: function (context, payload) {
            context.commit("setShowDialogRegisterIPI", payload)
        },
        setShowDialogRegisterPISCOFINS: function (context, payload) {
            context.commit("setShowDialogRegisterPISCOFINS", payload)
        },
        setShowDialogRegisterTransporter: function (context, payload) {
            context.commit("setShowDialogRegisterTransporter", payload)
        },
        setShowDialogRegisterCorrectionLetter: function (context, payload) {
            context.commit("setShowDialogRegisterCorrectionLetter", payload)
        },
        setShowDialogRegisterCancelSequenceInvoice: function (context, payload) {
            context.commit("setShowDialogRegisterCancelSequenceInvoice", payload)
        },
        setShowDialogLoading: function (context, payload) {
            context.commit("setShowDialogLoading", payload)
        },
        setShowDialogRegisterNoteNFCEViewCancel: function (context, payload) {
            context.commit("setShowDialogRegisterNoteNFCEViewCancel", payload)
        },
        setFiscalType: function (context, payload) {
            context.commit("setFiscalType", payload)
        },
        setShowDialogRegisterExchangeControl: function (context, payload) {
            context.commit("setShowDialogRegisterExchangeControl", payload)
        }
    },


}
export default globalDialogs