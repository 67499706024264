const headers = [
    { text: "Codigo", value: "id" },
    { text: "Descrição", value: "description_item" },
    { text: "Referencia", value: "reference" },
    { text: "Unidade", value: "unit.description" },
    { text: "Ncm", value: "ncm.code" },
    { text: "Preço de Venda", value: "sale_price" },
    { text: "Ações", value: "actions" },
]

export { headers }