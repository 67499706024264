<template>
  <Container>
    <v-card>
      <v-card-text>
        <!-- Botão Teste -->
        <CardTitle :component="this"/>
      </v-card-text>

      <v-text-field
        class="mx-4"
        label="Pesquisar"
        outlined
        append-icon="mdi-magnify"
        v-model="searchInput"
        @keyup.enter="setAutocomplete()"
      >
      </v-text-field>
      <Table :component="this" />
      <AddDialog :component="this" :items="items" />
      
      <AlertAPI/>
      <LoadScreen/>
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container.vue";
import Table from "../components/TaxCupomActive/Table";
import AddDialog from "../components/TaxCupomActive/AddDialog/index.vue";
import CardTitle from "../components/TaxCupomActive/CardTitle";
import { GET_ALL_TAX_CUPOM } from "../store/actions/taxCupom";
import { GET_NFCE_WITH_KEY } from "../store/actions/nfce";
import { CHECK_EMITER_NFE_REFERENCES } from "../store/actions/nfe"
import AlertAPI from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    Container,
    Table,
    AddDialog,
    CardTitle,
    LoadScreen,
    AlertAPI,
  },

  data() {
    return {
      searchInput: String(),
      search: String(),
      enterpriseId: Number(),
      Content: undefined,
      items: undefined,
      addDialogVendas: Boolean(),
      headers: [
        { text: "Id", value: "id" },
        { text: "Data da Venda", value: "data_sale" },
        { text: "Numero da nota", value: "note" },
        { text: "Cliente", value: "cliente.people.physicalpeople.name" },
        { text: "Vendedor", value: "seller.people.physicalpeople.name" },
        { text: "PDV", value: "pdv.code" },
        { text: "Valor da nota(R$)", value: "value_total_note" },
        { text: "Ações", value: "actions" },
      ],
      nfceSelectApiFiscalReferences: Object(),
      nfceSelectApiFiscalStatus: Object(),
      statusEmitterNfceOut: false,
      statusNfceRejeitada:false
    };
  },
  methods: {
    call_dialog(items) {
      this.items = items;
      this.addDialogVendas = !this.addDialogVendas;
      if (items.status === "Rejeitada") {
        this.statusNfceRejeitada = true 
        this.getStatusNfce(items)
      } else if (items.status === "Autorizada") {
        this.checkNfceReferencesCancelAfterDeadline(items)
      }
    },
    closeDialogSales() {
      this.addDialogVendas = !this.addDialogVendas;
      this.statusNfceRejeitada = false 
      this.statusEmitterNfceOut = false 
      this.nfceSelectApiFiscalReferences = {}
    },
    async getStatusNfce(nfce) {
      let payload = {
        cnpjEmitter: nfce.enterprise.cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65"
      }
      await this.$store.dispatch(GET_NFCE_WITH_KEY, payload)
    },
    async checkNfceReferencesCancelAfterDeadline(nfce) {
      let payload = {
        cnpjEmitter: nfce.enterprise.cnpj,
        keyNfe: nfce.access_key,
      }
      await this.$store.dispatch(CHECK_EMITER_NFE_REFERENCES, payload)
    },


    setAutocomplete() {
      this.search = this.searchInput
    }
    
  },
  computed: {
    ...mapGetters(["getterVendas", "getStatusNFce", "getNfeReferences"]),
  },

  created() {
    // this.getAllTaxCupon();
  },
  watch: {
    getStatusNFce: function (val) {
      this.nfceSelectApiFiscalStatus = val;
  
    },
    getNfeReferences: function (val) {
      if (val.nfe.length > 0){
        this.statusEmitterNfceOut = true 
        this.nfceSelectApiFiscalReferences = {...val.nfe[0].nfes};
      }else {   
        this.nfceSelectApiFiscalReferences = {}
      }
   
    },
  },
};
</script>

<style>
</style>