<template>
  <Container>
    <v-card>
      <v-card-text>
        <CardTilte :component="this" />
      </v-card-text>

      <v-card-text>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          v-model="searchInput"
          label="Pesquisar"
          @keyup.enter="SetAutocomplete()"
        ></v-text-field>
      </v-card-text>
      <Table 
        :getters="getBookSupervisionIn"
        :component="this" 
      />
    </v-card>
    <LoadScreen/>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTilte from "../components/BookSupervisionIn/CardTitle/index";
import Table from "../components/BookSupervisionIn/Table/index";
import { GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE, GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE } from "../store/actions/book_supervision_in";
import { mapGetters } from "vuex";
import AlertApi from "../components/alert_api/alert_api";
import LoadScreen from "../components/load/index.vue"
export default {
  components: {
    LoadScreen,
    Container,
    Table,
    CardTilte,
    AlertApi
  },
  data: () => ({
    search: String(),
    searchInput: String(),
    enterpriseId: Number(),
  }),

  methods:{
    async GetAll(page = 1){
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId
      }
      await this.$store.dispatch(GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE, value)
    },

    async GetAutocomplete(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        search: this.search,
        enterprise_id: this.enterpriseId
      }
      await this.$store.dispatch(GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE, value)
    },

    SetAutocomplete() {
      this.search = this.searchInput
    }
  },

  watch: {
    enterpriseId: function() {
      this.GetAll()
    },
    search: function(val) {
      if(val.length >= 2) {
        this.GetAutocomplete()
      }
    },
    searchInput: function(val) {
      if(val.length == 0) {
        this.GetAll()
      }
    }
  },

  computed: {
    ...mapGetters(["getBookSupervisionIn"]),
  }
};
</script>

<style>
</style>