<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="2">
        <v-select
          label="Habilitar?"
          :readonly="justVisible"
          :items="status"
          item-text="text"
          item-value="value"
          v-model="component.enterprise.status"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="*Razão Social"
          :readonly="justVisible"
          v-model="component.enterprise.name"
          :rules="[
            () => !!component.enterprise.name || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.name &&
                component.enterprise.name.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="*Nome Fantasia"
          :readonly="justVisible"
          v-model="component.enterprise.fantasyName"
          :rules="[
            () => !!component.enterprise.fantasyName || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.fantasyName &&
                component.enterprise.fantasyName.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="*CNPJ"
          :readonly="justVisible"
          v-model="component.enterprise.cnpj"
          v-mask="'##.###.###/####-##'"
          outlined
          :rules="cnpjRules"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Empresa--"
          :readonly="justVisible"
          v-model="component.enterprise.enterprise_type"
          item-text="text"
          item-value="value"
          :items="enterprise_type"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Tributário--"
          :readonly="justVisible"
          v-model="component.enterprise.tax_regime"
          :items="tax_regime"
          item-text="text"
          item-value="value"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="*Inscrição Estadual"
          :readonly="justVisible"
          v-model="component.enterprise.registrationState"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Inscrição Municipal"
          :readonly="justVisible"
          v-model="component.enterprise.registrationMunicipal"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="companyGroup"
          :readonly="justVisible"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogAction"
          item-text="name"
          item-value="id"
          v-model="component.enterprise.company_group_id"
          :rules="campoRules"
          outlined
          label="*Grupo de Empresas"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row></v-row>

    <RegisterGroupEnterPriseDialog :showDialog="showDialog" />
  </v-form>
</template>

<script>
import { campoRules, cnpjRules } from "../script";
import { mapGetters } from "vuex";
import { GET_ALL_COMPANY_GROUP } from "../../../../store/actions/companygroup";
import RegisterGroupEnterPriseDialog from "../../../companygroup/AddDialog";
import { mdiPlusCircle } from "@mdi/js";

export default {
  props: {
    component: Object(),
    justVisible: Boolean(),
  },
  components: {
    RegisterGroupEnterPriseDialog
  },
  computed: {
    ...mapGetters(["getCompanygroup", "getShowDialogRegisterGroupEnterprise"])
  },

  data: () => ({
    showDialog: Boolean(),
    icons: [mdiPlusCircle],
    campoRules,
    cnpjRules,
    companyGroup: Array(),
    enterprise_type: [
      { text: "Atacadista", value: "A" },
      { text: "Indústria", value: "I" },
      { text: "Serviço", value: "S" },
      { text: "Varejo", value: "V" }
    ],
    tax_regime: [
      { text: "Lucro Presumido", value: "1" },
      { text: "Lucro Real", value: "2" },
      { text: "Simples Nacional", value: "3" },
      { text: "Micro Empreendedor", value: "4" }
    ],
    status: [
      { text: "Ativo", value: "Ativo" },
      { text: "Inativo", value: "Inativo" },
      { text: "Bloqueado", value: "Bloqueado" }
    ]
  }),
  methods: {
    getInformations() {
      var payload = {
        page: 1,
        limit: 10,
        paginate: true
      };
      this.$store.dispatch(GET_ALL_COMPANY_GROUP, payload);
    },
    showDialogAction() {
      this.$store.dispatch("setShowDialogRegisterGroupEnterprise", true);
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  created() {
    this.getInformations();
  },
  watch: {
    getShowDialogRegisterGroupEnterprise: function() {
      this.showDialog = this.getShowDialogRegisterGroupEnterprise;
    },
    getCompanygroup: function(val) {
      this.companyGroup = val.data
    }
  }
};
</script>
