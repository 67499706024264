




import axios from "axios";
const {
  ADD_SALES,
  UPDATE_SALES,
  GET_ALL_SALES,
  DESTROY_SALES,
  SET_CART,
  NEW_PRODUCT_CALCULED,
  UPDATE_CART,
  RECALC_CART,
} = require("./actions/future_sales");

const { SPLICE_ERRO } = require("./actions/alert_api");
const { SET_CLOSE_SALE } = require('./actions/future_sales')
// const { CLEAN_CART } = require("./actions/cart")


const moduleSales = {
  state: () => ({
    sales: Object(),
    close: Object(),
    client: Object(),
    seller: Object(),
    newProductCalculed: Object(),
    cart: Array(),
  }),

  mutations: {
    SET_CART: function (state, payload) {
      state.cart.push(payload)// = payload;
    },
    UPDATE_CART: function(state, payload){
      state.cart = [...payload]
    },
    NEW_PRODUCT_CALCULED: function (state, payload) {
      state.newProductCalculed = payload;
    },
    SET_CLOSE_SALE: (state, payload) => {
      state.close = payload
    },
    GET_ALL_SALES: (state, payload) => {
      state.sales = payload.data;
    },
    ADD_SALES: (state, newSales) => {
      state.sales = newSales;
    },
    UPDATE_SALES(state, updateSales) {
      state.sales.data.Update(updateSales);
    },
    RECALC_CART(state, payload){
      state.cart = [...payload]
    },
    DESTROY_SALES: (state, id) => {
      let auxState = [...state.productgroups.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.productgroups = [...auxState];
    },

    SET_CLIENT_IN_MEMORY(state, data) {
      state.client = data
    },
    SET_SELLER_IN_MEMORY(state, data) {
      state.seller = data
    },
    RESET_MEMORY(state) {

      state.seller = Object()
      state.client = Object()
    }
  },

  actions: {
    RECALC_CART: function(context, payload){
      context.commit(RECALC_CART, payload)
    },
    UPDATE_CART: function(context, payload){
      context.commit(UPDATE_CART, payload)
    },
    SET_CART: function (context, payload) {
      context.commit(SET_CART, payload)
    },
    NEW_PRODUCT_CALCULED: function (context, payload) {
      context.commit(NEW_PRODUCT_CALCULED, payload)
    },
    GET_ALL_SALES({ commit }, payload) {
      axios
        .post("sale/index", {
          page: payload.page,
          limit: payload.limit, 
          paginate:true
        })
        .then((response) => {
          commit(GET_ALL_SALES, response.data);
        });
    },

    GET_CALC_TRIBUTE({ commit }, payload) {
       
      commit
      return new Promise((resolve) => {
        axios.post('sale/calctribute', payload).then(({ data }) => {
          resolve({ data })
        })

      })
    },

    ADD_SALES({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/sales/createsale", payload)
          .then(({ data }) => {
            
            resolve({
              data
            })
            commit(CLEAN_CART)
            commit(ADD_SALES, data);
            commit(SPLICE_ERRO, {
              alert: data.msg,
              type: "S",
            });
          })
          .catch((error) => {
            reject(
              error.response.data
            )

          });
      })

    },

    UPDATE_SALES({ commit }, payload) {
      axios
        .post("productGroup/update", payload)
        .then((response) => {
          commit(UPDATE_SALES, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors || r.response.data[0].errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: erro + " : " + err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_SALES({ commit }, payload) {
      axios
        .post("productGroup/delete", payload)
        .then((response) => {
          commit(DESTROY_SALES, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
    async OPEN_CASHIER({ commit }, payload) {
      alert('openchashier')
      try {
        const response = await axios.post("/sales/opencashier", payload)
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        })
        return response
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: error.response.data.errors,
          type: 'E'
        })
      }
    },

  async  BLEED({ commit }, payload) {
      try {
        const response = await axios.post("/sales/bleed", payload)
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        })
        return response
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: error.response.data.errors,
          type: 'E'
        })
      }
    },
    async SUPPLY({ commit }, payload) {
      try {
        const response = await axios.post("/sales/supply", payload)
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        })
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: error.response.data.errors,
          type: 'E'
        })
      }
    },
    async SET_CLOSE_SALE({ commit }, payload) {
      try {
        let response = await axios.post("sale/closeCashier", payload)
        commit(SET_CLOSE_SALE, response.data)
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: error.response.data.msg,
          type: 'E'
        })

        throw new Error('')
      }
    },
    async CONFIRM_CLOSE_CASHIER(_, payload) {
      let response = await axios.post("sale/confirmCloseCashier", payload)
       
    }
    


  },

  getters: {
    getCart:(state) => state.cart,
    getNewProductCalculed:(state) => state.newProductCalculed,
    getSales: (state) => ({ ...state.sales }),
    getSellerMemory: (state) => ({ ...state.seller }),
    getClientMemory: (state) => ({ ...state.client }),
    getCloseCashier: (state) => ({ ...state.close }),
  },
};

export default moduleSales;
