let TributeIcmsInPutDomainService = require("../../../Domain/Service/TributeIcms/TributeIcmsInDomainService");
let TributeIpiInDomainService = require("../../../Domain/Service/TributeIpi/TributeIpiInDomainService");
let TributePisInDomainService = require("../../../Domain/Service/TributePis/TributePisInDomainService");
let TributeCofinsInDomainService = require("../../../Domain/Service/TributeCofins/TributeCofinsInDomainService");

async function calculeTributeIn(productInvoiceEntry) {
  try {
    let cfop_code = productInvoiceEntry.code_cfop;
    let cst_code_ipi = productInvoiceEntry.cst_code_ipi;
    let aliquot_ipi_product = productInvoiceEntry.aliquot_ipi_product;
    let cst_code_pis = productInvoiceEntry.cst_code_pis;
    let cst_code_confis = productInvoiceEntry.cst_code_confis;
    let aliquot_pis = productInvoiceEntry.aliquot_pis;
    let aliquot_confis = productInvoiceEntry.aliquot_confis;

    let productInvoiceTribute = null;
    productInvoiceTribute = await TributeIcmsInPutDomainService.calculeIcmsIn(
      productInvoiceEntry,
      cfop_code
    );
    productInvoiceTribute = await TributeIpiInDomainService.calculeTributeIpiIn(
      productInvoiceEntry,
      cst_code_ipi,
      aliquot_ipi_product
    );
    productInvoiceTribute = await TributePisInDomainService.calculeTributePisIn(
      productInvoiceEntry,
      cst_code_pis,
      aliquot_pis
    );
    productInvoiceTribute =
      await TributeCofinsInDomainService.calculeTributeCofins(
        productInvoiceEntry,
        cst_code_confis,
        aliquot_confis
      );
    return productInvoiceTribute;
  } catch (error) {
    return error;
  }
}

module.exports = {
  calculeTributeIn,
};
