var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(this.component.addDialog),callback:function ($$v) {_vm.$set(this.component, "addDialog", $$v)},expression:"this.component.addDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Condição Pagamento")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Codigo","readonly":_vm.justVisible,"rules":[
                () => !!_vm.code || 'Campo Obrigatorio',
                () =>
                  (!!_vm.code && _vm.code.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ],"outlined":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('v-text-field',{attrs:{"label":"*Descrição","readonly":_vm.justVisible,"rules":[() => !!_vm.description || 'Campo Obrigatorio'],"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-text-field',{attrs:{"label":"*Acrescimo","type":"number","readonly":_vm.justVisible,"rules":[() => _vm.addition >= 0 || 'Campo Obrigatorio'],"outlined":""},model:{value:(_vm.addition),callback:function ($$v) {_vm.addition=$$v},expression:"addition"}}),_c('v-select',{attrs:{"label":"*Forma de Pagamento","type":"number","items":_vm.formPaymentType,"item-text":"description","item-value":"id","rules":[(v) => !!v || 'Campo Obrigatorio'],"outlined":""},model:{value:(_vm.formPayment),callback:function ($$v) {_vm.formPayment=$$v},expression:"formPayment"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-text-field',{attrs:{"label":"*Prazo","rules":[() => !!_vm.deadline || 'Campo Obrigatorio'],"readonly":"","outlined":""},model:{value:(_vm.deadline),callback:function ($$v) {_vm.deadline=$$v},expression:"deadline"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":""},on:{"click":_vm.openAddDeadline}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('span',[_vm._v("Adicionar Prazos")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  this.close();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1):_vm._e()],1)],1)],1)],1)],1),(_vm.dialogDeadline)?_c('Deadline',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }