const entradas = [
  {
    "text": "Pré Entrada de Nota",
    "icon": "mdi-file-move-outline",
    "route": "/Pre_Note_Entry",
    "class": "white--text",
    "permission_col": [],
    "active": true
  },
  {
    "text": "Entrada de Nota",
    "icon": "mdi-note-plus-outline",
    "route": "/note_entry",
    "class": "white--text",
    "permission_col": [],
    "active": true
  },
  {
    "text": "Entrada de XML",
    "icon": "mdi-file-xml-box",
    "route": "/importxml",
    "class": "white--text",
    "permission_col": [],
    "active": true
  },
];

export { entradas };