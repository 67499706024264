const ADD_NOTE_MODEL = "ADD_NOTE_MODEL";
const UPDATE_NOTE_MODEL = "UPDATE_NOTE_MODEL";
const GET_ALL_NOTE_MODEL = "GET_ALL_NOTE_MODEL";
const GET_SEQUENCE_NFE = "GET_SEQUENCE_NFE";
const DESTROY_NOTE_MODEL = "DESTROY_NOTE_MODEL";
const GET_FILTER_NOTE_MODEL = "GET_FILTER_NOTE_MODEL";
const AUX_TO_UPDATE_NOTE_MODEL = "AUX_TO_UPDATE_NOTE_MODEL";
const GET_ALL_NOTE_MODEL_BY_ENTERPRISE = "GET_ALL_NOTE_MODEL_BY_ENTERPRISE";
const GET_FILTER_NOTE_MODEL_BY_ENTERPRISE = "GET_FILTER_NOTE_MODEL_BY_ENTERPRISE";


module.exports = {
    ADD_NOTE_MODEL,
    UPDATE_NOTE_MODEL,
    GET_ALL_NOTE_MODEL,
    DESTROY_NOTE_MODEL,
    GET_FILTER_NOTE_MODEL,
    GET_SEQUENCE_NFE,
    AUX_TO_UPDATE_NOTE_MODEL,
    GET_ALL_NOTE_MODEL_BY_ENTERPRISE,
    GET_FILTER_NOTE_MODEL_BY_ENTERPRISE,
}
