import { AUX_TO_UPDATE_BARCODE } from "../../../store/actions/barcode";

function visibleItem(item){
    this.$store.dispatch(AUX_TO_UPDATE_BARCODE, {item: item, justVisible: true})
    this.component.addDialog = true;
}



function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}
function adjust_results(results) {
    if (results) {
        let aux = Object.values(this.component.getProduct);
        results.forEach((e) => {
            aux.forEach((r) => {
                if (e.product_id == r.id) {
                    e.products = r;
                }
            });
        });
    }
}


export default {
    deleteItem,
    handleClick,
    adjust_results,
    visibleItem 
}