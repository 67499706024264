<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <v-btn
      :block="$vuetify.breakpoint.xsOnly"
      color="success mr-2 mt-3"
      @click="
        () => {
          executeAlterAddDialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
//v-if="component.permission.Cadastrar"
import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
import { ENTERPRISE_OBJECT } from "../../../store/actions/revenuesitens";
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    enterprises: Array(),
    selectValue: Object()
  }),

  async created() {
    let page = 1;
    await this.$store.dispatch(GET_ALL_ENTERPRISE, {
      page: page,
      limit: 10,
      paginate: false,
    });
  },

  methods: {
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    executeAlterAddDialog() {
      this.alterAddDialog(this.getComponent());
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    selectValue: function (val) {
      this.component.enterpriseId = val.id;
      this.component.enterpriseType = val.tax_regime_id;
      this.component.enterpriseObject = val
      this.$store.dispatch(ENTERPRISE_OBJECT, this.selectValue);
    },
    getEnterprises(val) {
      this.enterprises = Object.values(val.data);
      this.selectValue = this.enterprises[0];
    },
  },
};
</script>

<style></style>
