let transportInfo = {
    shippingModalityFreight: "",

};
async function execute() {

    transportInfo.shippingModalityFreight = "9"
    return transportInfo

}

module.exports = {
    execute,
};