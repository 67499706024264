



const Header = [
    { text: "Id", value: "id" },
    { text: "Chave", value: "key" },
    { text: "Protocolo", value: "numberProtocol" },
    { text: "Status", value: "status" },
    { text: "Operação", value: "descriptionNatureOperation" },
    { text: "serie", value: "seriesDocumentSupervisior" },
    { text: "Número da Nota", value: "numberNfe" },
    { text: "Data", value: "issuanceDateNfe" },
    { text: "CNPJ", value: "cnpjorcpfDestination" },
    { text: "Nome do Destinatário", value: "nameDestination" },
    { text: "Valor Base de ICMS", value: "valueBaseTotalCalculeIcms" },
    { text: "Valor Total ICMS", value: "valueTotalCalculeIcms" },
    { text: "Valor Total do Produto ", value: "valueTotalProduct" },
    { text: "Valor Total da Nota", value: "valueTotalNF" },
    { text: "Ações", value: "actions" }
]
module.exports = {
    Header
}