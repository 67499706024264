// import axios from "axios"
import Axios from "axios";
import { MockData } from "../components/notenfe/Table/helpers/mockData"
import apifiscal from "../apifiscal"
import IssueNfeApplication from "../Application/Service/IssueNfe/IssueNfeApplication"
import ReprocesBeforeErrorNfeApplication from "../Application/Service/IssueNfe/ReprocessBeforeErrorDataApplication"
import ReprocessNfeWithKey from "../Application/Service/IssueNfe/ReprocessNfeApplication"


import { INFO_ERROR } from "./actions/infoError";

const {
    ITEM_TO_REPRINT,
    PRINT_NFE,
    GET_ALL_ISSUE_OF_INVOICE,
    GET_SEQUENCE_NFE_EMITER_NFE,
    REPROCESS_NFE_BEFORE_ERROR_DATA,
    REPROCESS_NFE_WITH_KEY,
    CANCEL_NFE,
    GET_NFE_WITH_KEY,
    CHECK_EMITER_NFE_REFERENCES
} = require("./actions/nfe")

const {
    SPLICE_ERRO
} = require('./actions/alert_api')


const moduleNfe = {

    state: () => ({
        nfe: Object(),
        itemToReprint: Object(),
        printNFE: Object(),
        sequenceNfe: Object(),
        reprocessNfeDataError: Object(),
        cancelNfe: Object(),
        reprocessNfeData: Object(),
        infoNfe:Object(),
        getNfeReferences: Object()
    }),
    mutations: {
        GET_ALL_ISSUE_OF_INVOICE: (state, nfe) => {
            state.nfe = nfe;
        },
        GET_SEQUENCE_NFE_EMITER_NFE: (state, payload) => {
            state.sequenceNfe = payload
        },
        ISSUE_OF_INVOICE_NFE: (state, nfe) => {
            state.nfe = nfe;
        },
        ITEM_TO_REPRINT: function (state, payload) {
            state.itemToReprint = payload
        },
        PRINT_NFE: function (state, payload) {
            state.printNFE = payload
        },
        REPROCESS_NFE_BEFORE_ERROR_DATA: function (state, payload) {
            state.reprocessNfeDataError = payload
        },
        REPROCESS_NFE_WITH_KEY: function (state, payload) {
            state.reprocessNfeData = payload
        },
        CANCEL_NFE: function (state, payload) {
            state.cancelNfe = payload
        },
        GET_NFE_WITH_KEY: function (state, payload){
            state.infoNfe = payload
        },
        CHECK_EMITER_NFE_REFERENCES: function (state, payload){
            state.getNfeReferences = payload
        }
    },
    actions: {
        GET_ALL_ISSUE_OF_INVOICE: function (context, payload) {
            try {
                var x = 1;
                if (x == 1) {
                    context.commit(GET_ALL_ISSUE_OF_INVOICE, MockData)
                } else {
                    Axios.post("/noteissue/index", payload).then((response) => {
                        context.commit(GET_ALL_ISSUE_OF_INVOICE, response)
                    })
                }
            } catch (error) {
                return error
            }
        },
        ITEM_TO_REPRINT: function (context, payload) {
            context.commit(ITEM_TO_REPRINT, payload)
        },
        async CHECK_EMITER_NFE_REFERENCES ({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
            return new Promise((resolve)=> {
               apifiscal.post("/noteissue/getNfeReferences", payload, {
                   headers: {
                       'Authorization': 'Bearer ' + token,
                   }
               }).then((response) => {
                   resolve({ response })
                   commit(CHECK_EMITER_NFE_REFERENCES, response.data)
               }).catch((r) => {
                   reject(r)
                   commit(SPLICE_ERRO, {
                       alert: "Erro ao obter a validação da nfce emitida!",
                       type: 'E'
                   })
               })
            })
           } ,
        PRINT_NFE: function (context, payload) {
            let token = localStorage.getItem("token_fiscal");
            return new Promise((resolve) => {
                apifiscal.post("/noteissue/nfereprintdanfe", payload, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then((response) => {
                    resolve({ response })
                    commit(PRINT_NFE, response.data)
                }).catch((r) => {
                    reject(r)
                    commit(SPLICE_ERRO, {
                        alert: "Erro ao imprimir NFe!",
                        type: 'E'
                    })
                })

            })
        },
        async ISSUE_OF_INVOICE_NFE({ commit }, payload) {
            commit
            let token = localStorage.getItem("token_fiscal");
            let nfe = await IssueNfeApplication.execute(payload);
            return new Promise((resolve, reject) => {
                apifiscal.post('/noteissue/nfe', {
                    ...nfe
                }, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(({ data }) => {
                    resolve({ data })
                    if (data.nfe.status == "Rejeitada") {
                        commit(INFO_ERROR, {
                          dialog: true,
                          title: "Erro ao emitir NFe - NFe Rejeitada",
                          menssage: data.nfe.errorReason
                        })
          
                      }
                }).catch((r) => {
                    commit(SPLICE_ERRO, {
                        alert: r.response.data[0],
                        type: 'E'
                    })
                    reject(r)

                })

            })
        },
        async REPROCESS_NFE_WITH_KEY({ commit }, payload) {

            let token = localStorage.getItem("token_fiscal");
            let nfeReprossNfeKey = await ReprocessNfeWithKey.execute(payload)
            return new Promise((resolve, reject) => {
                apifiscal.post('/noteissue/reprocesssingNfe', {
                    ...nfeReprossNfeKey
                }, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(({ data }) => {
                    resolve({ data })
                }).catch((r) => {
                    reject(r)
                    commit(SPLICE_ERRO, {
                        alert: "Erro ao reprocessar nfe!",
                        type: 'E'
                    })
                })

            })

        },

        async REPROCESS_NFE_BEFORE_ERROR_DATA({ commit }, payload) {
            commit
            let token = localStorage.getItem("token_fiscal");
            let nfeRepross = await ReprocesBeforeErrorNfeApplication.execute(payload);
            return new Promise((resolve, reject) => {
                apifiscal.post('/noteissue/nfe', {
                    ...nfeRepross
                }, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(({ data }) => {
                    resolve({ data })
                }).catch((r) => {
                    reject(r)
                    commit(SPLICE_ERRO, {
                        alert: "Erro ao processar a nota!",
                        type: 'E'
                    })
                })

            })
        },
        async GET_SEQUENCE_NFE_EMITER_NFE({ commit }, payload) {
            commit
            return new Promise((resolve, reject) => {
                Axios.post("noteModel/getSequenceTypeNfe", {
                    enterprise_id: payload.enterprise_id,
                    typeNoteModel: payload.typeNoteModel,
                })
                    .then(({ data }) => {
                        resolve({ data });
                        commit(GET_SEQUENCE_NFE_EMITER_NFE
                            , data)
                    }).catch((r) => {
                        reject(r)
                    })
            });
        },
        async CANCEL_NFE({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
            return new Promise((resolve, reject) => {
                apifiscal.post('/noteissue/cancelnfe', payload, {
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(({ data }) => {
                    resolve({ data })
                    commit(CANCEL_NFE
                        , data)
                }).catch((r) => {
                    commit(SPLICE_ERRO, {
                        alert: r.response.data[0],
                        type: 'E'
                    })
                    reject(r)

                })

            })
        },
        GET_NFE_WITH_KEY({commit}, payload){
            let token = localStorage.getItem("token_fiscal");
            apifiscal.post("/noteissuenfce/getNfceConsultKey", payload, {
                headers: {
                    'Authorization': 'Bearer ' + token,
          
                  }
            }).then((response)=> {
                commit(GET_NFE_WITH_KEY, response.data.nfes[0]);
            })
        }
    },
    getters: {
        getNfe: (state) => ({
            ...state.nfe
        }),
        getItemToReprint: (state) => ({
            ...state.itemToReprint
        }),
        getStatusNFe: (state) => ({
            ...state.infoNfe
        }),
        getNfeReferences : (state)=> ({
            ...state.getNfeReferences
        })
    },

}

export default moduleNfe;



