import {
    ADD_PRODUCT_GROUP,
    UPDATE_PRODUCT_GROUP,

} from "../../../store/actions/productgroup";


function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.code = payload.item.code;
        this.name = payload.item.name;
    } else {
        this.code = payload.code;
        this.name = payload.name;
        this.id = payload.id;
    }
}
function getComponent() {
    return this._props.component;
}
function cancel() {
    this.$refs.form.resetValidation();
    this.$store.dispatch("setShowDialogRegisterProductGroup", false);
    this.justVisible = false;
    this.id = undefined;
    this.code = Number();
    this.name = String();
}
async function create_productgroup() {
    const product_group = {
        code: parseInt(this.code),
        name: this.name,
        enterpriseId: this.EnterPriseID
    };
    await this.$store.dispatch(ADD_PRODUCT_GROUP, product_group).then(() => {
        this.cancel();
    });
}
function submit() {
    this.id == undefined
        ? this.create_productgroup()
        : this.update_productgroup();
}
async function update_productgroup() {
    const product_group = {
        id: this.id,
        code: this.code,
        name: this.name
    };
    await this.$store
        .dispatch(UPDATE_PRODUCT_GROUP, product_group)
        .then(() => {
            this.cancel();
        });
}



export default {
    getComponent,
    cancel,
    create_productgroup,
    submit,
    update_productgroup,
    autoload,
}