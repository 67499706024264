<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-value="id"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <!-- exemplo de como deve aplicar as permissoes na view -->
    <v-btn
      v-if="component.permission.Cadastrar"
      color="success mr-2 mt-3"
      @click="
        () => {
          open_Dialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import UIBusinessRule from "../../../UIBusinessRule/Unit/CardTitle/CardTitle";

export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    selectValue: Object(),
    enterprises: []
  }),

  created() {
    this.GetAllNoPageEnterprise();
  },

  methods: {
...UIBusinessRule    
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val)
      this.selectValue = this.enterprises[0];
    },
  },
};
</script>

<style></style>
