import axios from "axios";
const {
  GET_ALL_REVENUES,
  ADD_REVENUES,
  DESTROY_REVENUES,
  UPDATE_REVENUES,
  CONFIRM_REVENUES,
  ADD_PRENOTE,
  REVERSE_REVENUE,
  GET_FILTER_REVENUE,
  REVENUES_UPDATE_NFE,
  GET_ALL_NOT_EMITTER_NFE,
  ADD_REVENUES_WITHOUT_BILLS_TO_RECEIVE,
  GET_ALL_REVENUES_BY_ENTERPRISE,
  GET_FILTER_REVENUE_BY_ENTERPRISE,
} = require("./actions/revenues");
const { SPLICE_ERRO } = require("./actions/alert_api");
const moduleRevenues = {
  state: () => ({
    revenues: Object(),
    revenuesNfe:  Object(),
    revenuesWithoutBillsToReceive: Object(),
  }),

  mutations: {
    GET_FILTER_REVENUE: (state, payload) => {
      if (payload !== undefined) {
        state.revenues = payload;
      }
    },
    GET_FILTER_REVENUE_BY_ENTERPRISE: (state, payload) => {
      if(payload.data) {
        payload.data.forEach((e) => {
          if (e.cliente.people.legalpeople === null) {
            e.cliente.people.name = e.cliente.people.physicalpeople.name;
          } else {
            e.cliente.people.name = e.cliente.people.legalpeople.fantasyName;
          }
        });
        state.revenues = payload;
      } else {
        state.revenues = []
      }
    },
    GET_ALL_NOT_EMITTER_NFE: (state, revenues) => {
     if (revenues.data == null) {
      state.revenues = {}
     }else {
      revenues.data.forEach((e) => {
        if (e.cliente.people.legalpeople === null) {
          e.cliente.people.name = e.cliente.people.physicalpeople.name;
        } else {
          e.cliente.people.name = e.cliente.people.legalpeople.fantasyName;
        }
      });
      state.revenues = revenues;
     }

    },
    GET_ALL_REVENUES: (state, revenues) => {
      revenues.data.forEach((e) => {
        if (e.cliente.people.legalpeople === null) {
          e.cliente.people.name = e.cliente.people.physicalpeople.name;
        } else {
          e.cliente.people.name = e.cliente.people.legalpeople.fantasyName;
        }
      });
      state.revenues = revenues;
    },

    GET_ALL_REVENUES_BY_ENTERPRISE: (state, revenues) => {
      if(revenues.data) {
        revenues.data.forEach((e) => {
          if (e.cliente.people.legalpeople === null) {
            e.cliente.people.name = e.cliente.people.physicalpeople.name;
          } else {
            e.cliente.people.name = e.cliente.people.legalpeople.fantasyName;
          }
        });
        state.revenues = revenues;
      } else {
        state.revenues = []
      }
    },
    
    ADD_REVENUES: (state, payload) => {
      state.revenues = payload;
    },
    ADD_REVENUES_WITHOUT_BILLS_TO_RECEIVE: (state, payload) => {
      state.revenuesWithoutBillsToReceive = payload;
    },
    REVENUES_UPDATE_NFE: (state, payload)=> {
      state.revenuesNfe = payload;
    },
    ADD_PRENOTE: (state, payload) => {
      state.revenues = payload;
    },
    UPDATE_REVENUES(state, payload) {
      let copyState = state.revenues;
      copyState.data.Update(payload);
      copyState.data.forEach((e) => {
        if (e.cliente.people.legalpeople === null) {
          e.cliente.people.name = e.cliente.people.physicalpeople.name;
        } else {
          e.cliente.people.name = e.cliente.people.legalpeople.fantasyName;
        }
      });
      state.revenues = copyState;
    },

    DESTROY_REVENUES: (state, id) => {
      let auxState = [...state.revenues.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.revenues = [...auxState];
    },
    CONFIRM_REVENUES: (state, id) => {
      let auxState = [...state.revenues.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.revenues = [...auxState];
    },
    REVERSE_REVENUE: (state, id) => {
      let auxState = [...state.revenues.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.revenues = [...auxState];
    },
  },

  actions: {
    GET_FILTER_REVENUE({ commit }, payload) {
      axios
        .post("revenues/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_FILTER_REVENUE, response.data);
        });
    },
    GET_FILTER_REVENUE_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("revenues/autocompleteByEnterprise", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
          enterprise_id: payload.enterprise_id
        })
        .then((response) => {
          commit(GET_FILTER_REVENUE_BY_ENTERPRISE, response.data);
        });
    },
    GET_ALL_NOT_EMITTER_NFE ({commit},payload){
      axios.post("revenues/getallnotemitternfe", payload).then((response)=> {
        commit(GET_ALL_NOT_EMITTER_NFE, response.data);
      }).catch((r) => {
        r.response.data.errors.message.forEach((e) => {
          commit(SPLICE_ERRO, {
            alert: e,
            type: "E",
          });
        });
      });


    },
    GET_ALL_REVENUES({ commit }, payload) {
      axios
        .get("revenues/getrevenuesbystatus", {
          params: {
            page: payload.page,
            limit: payload.limit,
            paginate: payload.paginate,
            status: payload.status,
          },
        })
        .then((response) => {
          commit(GET_ALL_REVENUES, response.data);
        })
        .catch((r) => {
          r.response.data.errors.message.forEach((e) => {
            commit(SPLICE_ERRO, {
              alert: e,
              type: "E",
            });
          });
        });
    },

    GET_ALL_REVENUES_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("revenues/getAllByEnterprise", {
            page: payload.page,
            limit: payload.limit,
            paginate: payload.paginate,
            status: payload.status,
            enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          commit(GET_ALL_REVENUES_BY_ENTERPRISE, response.data);
        })
    },
    

    async ADD_REVENUES({ commit }, payload) {
      return new Promise((resolve,reject)=> {
         axios
        .post("revenues/store", payload)
        .then((response) => {
          resolve({response})
          commit(ADD_REVENUES, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.message,
            type: "S",
          });
        })
        .catch((r) => {
          reject({r})
          commit(SPLICE_ERRO, {
            alert: "Não foi possivel salvar faturamento",
            type: "E",
          });
        });

      })
     
    },
    async ADD_REVENUES_WITHOUT_BILLS_TO_RECEIVE({ commit }, payload) {
      return new Promise((resolve,reject)=> {
         axios
        .post("revenues/outDeadline", payload)
        .then((response) => {
          resolve({response})
          commit(ADD_REVENUES_WITHOUT_BILLS_TO_RECEIVE, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.message,
            type: "S",
          });
        })
        .catch((r) => {
          reject({r})
          commit(SPLICE_ERRO, {
            alert: "Não foi possivel salvar faturamento",
            type: "E",
          });
        });
      })
     
    },


    UPDATE_REVENUES({ commit }, payload) {
      axios
        .post("revenues/update", payload)
        .then((response) => {
          commit(UPDATE_REVENUES, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.message,
            type: "S",
          });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r.response.data.errors.messsage[0],
            type: "E",
          });
        });
    },
    REVENUES_UPDATE_NFE({ commit }, payload) {
      axios
        .post("revenues/updaterevenuesnfe", payload)
        .then((response) => {
          commit(REVENUES_UPDATE_NFE, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.message,
            type: "S",
          });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r.response.data.errors.messsage[0],
            type: "E",
          });
        });
    },
    DESTROY_REVENUES({ commit }, payload) {
      axios
        .post("revenues/delete", { id: payload })
        .then((response) => {
          commit(DESTROY_REVENUES, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r.response.data.errors.message[0],
            type: "E",
          });
        });
    },
    CONFIRM_REVENUES({ commit }, payload) {
      axios
        .post("revenues/confirmationRequest", payload)
        .then((response) => {
          commit(CONFIRM_REVENUES, payload);
          commit(SPLICE_ERRO, { alert: response.data.msg, type: "S" });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r.response.data.errors.message[0],
            type: "E",
          });
        });
    },
    ADD_PRENOTE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("revenues/prenote", payload)
          .then((response) => {
            resolve(response.data.message);
            commit(ADD_PRENOTE, payload);
            commit(SPLICE_ERRO, {
              alert: response.data.message,
              type: "S",
            });
          })
          .catch((e) => {
            reject(e.response.data.errors.message[0]);
            commit(SPLICE_ERRO, {
              alert: e.response.data.errors.message[0],
              type: "E",
            });
          });
      });
    },
    REVERSE_REVENUE({ commit }, payload) {
      axios
        .post("revenues/reverse", payload  )
        .then((response) => {
          commit(REVERSE_REVENUE, payload);
          commit(SPLICE_ERRO, {
            alert: "Cancelamento realizado com Sucesso",
            type: "S",
          });
        })
        .catch((e) => {
          commit(SPLICE_ERRO, {
            alert: e.response.data.errors[0],
            type: "E",
          });
        });
    },
  },
  getters: {
    getRevenues: (state) => ({
      ...state.revenues,
    }),
  },
};

export default moduleRevenues;
