<template>
  <v-row justify="center">
    <v-dialog persistent v-model="showDialog" max-width="600">
      <v-card>
        <v-card-title primary-title>Grupo de Empresas</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Nome"
                v-model="name"
                :readonly="justVisible"
                :rules="[() => !!name || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_COMPANY_GROUP,
  UPDATE_COMPANY_GROUP,
} from "../../../store/actions/companygroup";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    showDialog: Boolean(),
  },

  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      name: "",
      search: "",
    };
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getComponent() {
      return this.component;
    },
    close() {
      this.$store.dispatch("setShowDialogRegisterGroupEnterprise", false);
      this.justVisible = false
      this.reset();
      this.resetValidation();
    },
    async create_companygroup() {
      const companygroup = {
        name: this.name,
      };
      await this.$store.dispatch(ADD_COMPANY_GROUP, companygroup).then(() => {
        this.close();
      });
    },
    submit() {
      this.id == undefined
        ? this.create_companygroup()
        : this.update_companygroup();
    },
    async update_companygroup() {
      const companygroup = {
        id: this.id,
        name: this.name,
      };
      await this.$store
        .dispatch(UPDATE_COMPANY_GROUP, companygroup)
        .then(() => {
          this.close();
        });
    },
  },

  computed: {
    ...mapGetters([
      "getShowDialogRegisterGroupEnterprise",
      "getItemCompanyToUpdate",
    ]),
  },
  watch: {
    getItemCompanyToUpdate: function (val) {

      if (val.justVisible) {
        this.justVisible = val.justVisible;
        this.name = val.item.name;
      } else {
        this.created_at = this.getItemCompanyToUpdate.created_at;
        this.name = this.getItemCompanyToUpdate.name;
        this.updated_at = this.getItemCompanyToUpdate.updated_at;
        this.id = this.getItemCompanyToUpdate.id;
      }
    },
  },
  async created() {
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.name = this.object.name;
  },

  beforeDestroy() {
    // this.getComponent().objectToUpdate = {};
  },
};
</script>
