

var itemNote = {
    product: Object(),
    productAmount: Number(),
    aliquot: Object(),
    cost: Number(),
    CFOP: Object(),
    cstCSOSN: Object(),
    cstPIS: Object(),
    cstCOFINS: Object(),
    IPI: Number(),
  }
  var description = Object()



  module.exports = {
    itemNote,
    description,
    
  }