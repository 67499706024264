const financeiro = [
    {
      "text": "Cts a Pagar",
      "icon": "mdi-cash-minus",
      "route": "/bills_pay",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
    {
      "text": "Cts a Receber",
      "icon": "mdi-cash-plus",
      "route": "/bills_receive",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
    {
      "text": "Movimentação Financeira",
      "icon": "mdi-cash-refund",
      "route": "/financial_movement",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
    {
      "text": "Baixa de Titulos a Pagar",
      "icon": "mdi-arrow-right-box",
      "route": "/TitleDown_pay",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
    {
      "text": "Baixa de Títulos a Receber",
      "icon": "mdi-arrow-left-box",
      "route": "/titleDown_receive",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
    {
      "text": "Estorno de Títulos",
      "icon": "mdi-restore",
      "route": "/billsToPayReversal",
      "class": "white--text",
      "permission_col": [],
      "active": true,
    },
  ];
  export { financeiro };
  