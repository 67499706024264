<template>
  <div>
    <v-data-table
      :headers="header"
      :search="search"
      :items="results"
      no-results-text="Não encontrado!"
      no-data-text="Sem registro!"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item)
                }
              "
            >mdi-eye</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              v-if="component.permission.Editar"
              @click="
                () => {
                  edit_item(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar este Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              v-bind="attrs"
              v-on="on"
              v-if="component.permission.Excluir"
              class="mr-2"
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar este Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider class="mb-6"></v-divider>
    <Edit v-if="dialog" :component="this" :objectToUpdate="objectToUpdate" />
    <Delete :component="this" />
    <v-pagination
      
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
import { DESTROY_CARD_ADM, AUX_TO_UPDATE_CARD_ADM } from "../../../store/actions/card_adm";
import Edit from "../AddDialog/";
import Delete from "../Delete/";
import { mapGetters } from "vuex";
export default {
  components: { Edit, Delete },
  props: { search: String, component: Object },
  data: () => ({
    results: Array(),
    total: Number(),
    current: Number(),
    dialog: Boolean(),
    dialog_cancel: Boolean(),
    objectToDestroy: Object(),
    objectToUpdate: Object(),

    header: [
      { text: "Descrição", value: "description" },
      { text: "Código", value: "code" },
      { text: "Status", value: "status" },
      { text: "Administradores", value: "managers" },
      { text: "Dias de crédito", value: "credit_days" },
      { text: "Taxa administrativa", value: "administrate_rate" },
      { text: "Taxa de antecipação", value: "anteciption_rate" },
      { text: "Tipo de uso", value: "use_type" },
      { text: "Ações", value: "actions" },
    ],
  }),
  computed: {
    ...mapGetters(["getCard_adm"]),
  },
  methods: {
    
    visibleItem(item) {
      this.$store.dispatch(AUX_TO_UPDATE_CARD_ADM, {item: item, justVisible: true})
      this.dialog = true;
    },

    active_add() {
      this.dialog = !this.dialog;
    },

    edit_item(item) {
      // this.objectToUpdate = { ...item };
      // this.objectToUpdate.id = item.id;
      this.$store.dispatch(AUX_TO_UPDATE_CARD_ADM, item)
      this.active_add();
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.objectToDestroy = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_CARD_ADM, this.objectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_cancel = !this.dialog_cancel;
      }
    },
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getCard_adm: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if (this.component.filter) {
        this.component.cardAdm_filter && this.component.cardAdm_filter(val);
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>

<style>
</style>