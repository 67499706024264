import axios from "axios";

const {
  GET_ALL_BANK,
  ADD_BANK,
  UPDATE_BANK,
  DESTROY_BANK,
  GET_FILTER_BANK,
  AUX_TO_UPDATE_BANK
} = require("./actions/bank");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleBank = {
  state: () => ({
    banks: {},
    auxToUpdateBank: Object(),
  }),

  mutations: {
    GET_ALL_BANK: (state, banks) => {
      state.banks = banks;
    },
    GET_FILTER_BANK: (state, payload) => {
      state.banks = payload
    },
    ADD_BANK: (state, newBanks) => {
      state.banks = newBanks;
    },
    UPDATE_BANK(state, editBank) {
      state.banks.data.Update(editBank);
    },
    DESTROY_BANK: (state, id) => {

      let auxState = [...state.banks.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.banks = [...auxState];
    },

    AUX_TO_UPDATE_BANK: function (state, payload) {
      state.auxToUpdateBank = payload
      
    }
  },


  actions: {
    GET_ALL_BANK({ commit }, payload) {
      axios.post("bank/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: payload.paginate
      }).then((response) => {
        commit(GET_ALL_BANK, response.data[0]);
      });
    },

    GET_FILTER_BANK({
      commit
    }, payload) {
      axios.post("bank/autocomplete", {
        search: payload.search,
        limit: payload.limit,
        page: payload.page,
        paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_BANK, response.data[0]);
      })
    },

    async ADD_BANK({ commit }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("bank/store", payload).then((response) => {
          resolve()
          commit(ADD_BANK, response.data)
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com Sucesso!",
          })
        }).catch(() => {
          reject()
        })
      })
    },

    UPDATE_BANK({ commit }, payload) {

      return new Promise((resolve, reject) => {
        axios
          .post("bank/update", payload)
          .then((response) => {
            resolve()
            commit(UPDATE_BANK, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: 'S'
            });
          }).catch(() => {
            reject()
          })

      })

    },

    DESTROY_BANK({ commit }, payload) {
      axios
        .post("bank/delete", { id: payload })
        .then(() => {
          commit(DESTROY_BANK, payload);
          commit(SPLICE_ERRO, {
            alert: 'Deletado com sucesso!',
          });
        })

    },
    AUX_TO_UPDATE_BANK: function (context, payload) {
      context.commit(AUX_TO_UPDATE_BANK, payload)
    }
  },

  getters: {
    getBank: (state) => ({ ...state.banks }),

    getAuxBankToUpdate: (state) => state.auxToUpdateBank
  },
};

export default moduleBank;
