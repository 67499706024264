<template>
  <v-container>
  <v-divider></v-divider>
    <v-form ref="form" v-model="component.validAddress">
      <v-divider></v-divider>
      <v-row class="mt-6">
        <v-col cols="2">
          <v-text-field
            v-model="component.address.cep"
            :readonly="component.justVisible"
            outlined
            label="*CEP"
            append-icon="mdi-magnify"
            v-mask="'#####-###'"
            @click:append="
            () => {
              loadAddress()
            }
              "
            :rules="cep_rules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="component.address.street"
            :readonly="component.justVisible"
            outlined
            label="*Rua"
            
            :rules="[() => !!component.address.street || 'Campo Obrigatório']"
          >
          </v-text-field>
        </v-col>
        <v-col
          ><v-text-field
            v-model="component.address.number"
            :readonly="component.justVisible"
            outlined
            
            label="*Número"
            :rules="[() => !!component.address.number || 'Campo Obrigatório']"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.address.district"
            :readonly="component.justVisible"
            outlined
            
            label="*Bairro"
            :rules="[() => !!component.address.district || 'Campo Obrigatório']"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.address.complement"
            :readonly="component.justVisible"
            outlined
            required
            label="*Complemento"
            :rules="[() => !!component.address.complement || 'Campo Obrigatorio']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="oldState"
            :readonly="component.justVisible"
            :items="initials"
            item-text="text"
            return-object
            outlined
            
            label="*Estado"
            :rules="[() => !!oldState || 'Campo Obrigatório']"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="component.new_city"
            :readonly="component.justVisible"
            :items="cities"
            item-text="nome"
            outlined
            return-object
            label="*Cidade"
            :rules="[() => !!component.new_city || 'Campo Obrigatório']"
          ></v-autocomplete>
        </v-col>

      </v-row>
      <v-divider></v-divider>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    cep_rules: [
      (v) => !!v || "CEP é obrigatório",
      (v) => v.length == 9 || "O CEP deve possuir 8 caracteres",
    ],
    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
    cities: Array(),
    oldState: String(),
    valid: false,
    auxCity: String(),
  }),

  props: {
    component: Object(),
    justVisible: Boolean()
  },

  methods: {
    loadAddress() {
      axios.get(
        `https://viacep.com.br/ws/${this.component.address.cep}/json`
      )
      .then((response) => {
        this.component.address.street = response.data.logradouro
        this.component.address.district = response.data.bairro
        this.auxCity = response.data.localidade
        this.oldState = response.data.uf.toLowerCase()
      });
    },
    loadCity() {
      if(this.cities.length != 0) {
        for(let i = 0; i <= this.cities.length; i++) {
          if (this.cities[i].nome == this.auxCity) {
            this.component.new_city = this.cities[i]
          }
        }
      }
    }
  },

  created() {
    if(this.component.justVisible || this.component.id) {
      this.loadAddress()
    }
  },

  watch: {
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
          this.component.address.state = val;
          this.loadCity()
        });
    },
  },

  created() {
    if(this.component.id || this.component.justVisible) {
      this.loadAddress()
    }
  },
};
</script>


          