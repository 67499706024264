<template>
  <v-row justify="center">
    <v-dialog :retain-focus="false" persistent v-model="getShowDialogRegisterCST" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Cst</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
            lazy-validation
          >
            <v-col>
              <v-text-field
                label="*Codigo"
                type="number"
                v-model="code"
                :readonly="justVisible"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="2"
                :rules="[() => !!code || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-textarea
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closeCst();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapGetters } from "vuex";
import UIBusinessRule from "../../../UIBusinessRule/Cst/AddDialog/AddDialog";
import {AUX_TO_UPDATE_CST} from "../../../store/actions/cst"
export default {
  props: {
    component: Object,
  },
  name:"cst-dialog",
  data() {
    return {
      justVisible: Boolean(),
      showDialog: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      code: String(),
      description: String(),
      search: "",
      formHasErrors: false,
    };
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterCST", "getAuxCst"]),
  },
  watch: {
    getAuxCst: function (val) {
      this.autoLoad(val)
    },
  },

  methods: {
      ...UIBusinessRule    
  },
  async created() {
    this.autoLoad(this.getAuxCst)
  },
  
  beforeDestroy() {
    this.$store.dispatch(AUX_TO_UPDATE_CST, {});
    // this.reset();
  },
};
</script>
