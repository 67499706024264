<template>
  <div>
    <apexchart
      type="bar"
      width="500"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    series: [
      {
        name: "Gastos",
        data: [44.5, 55.78, 57.0, 56.41, 61.69, 58.54, 63.12, 60.1, 66.12],
      },
      {
        name: "Vendas",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Bruto",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
        ],
      },
      yaxis: {
        title: {
          text: "Últimas Vendas (R$)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "R$ " + val;
          },
        },
      },
    },
  }),
};
</script>
