


const GENERATE_SPED_ICMS = "GENERATE_SPED_ICMS"




module.exports = {
    GENERATE_SPED_ICMS
}