import axios from "axios";

const {
  ADD_FORM_OF_PAYMENT,
  UPDATE_FORM_OF_PAYMENT,
  GET_ALL_FORM_OF_PAYMENT,
  DESTROY_FORM_OF_PAYMENT,
  GET_FILTER_FORM_OF_PAYMENT,
  AUX_TO_UPDATE_FORM,
} = require("./actions/formofpayment");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleFormOfPayments = {
  state: () => ({
    formofpayments: {},
    auxToUpdateForm: Object(),
  }),

  mutations: {
    GET_ALL_FORM_OF_PAYMENT: (state, formofpayments) => {
      state.formofpayments = formofpayments;
    },
    GET_FILTER_FORM_OF_PAYMENT: (state, payload) => {
      state.formofpayments = payload
    },
    ADD_FORM_OF_PAYMENT: (state, newFormofpayments) => {
      state.formofpayments = newFormofpayments;
    },
    UPDATE_FORM_OF_PAYMENT(state, updateFormofpayment) {
      state.formofpayments.data.Update(updateFormofpayment)
    },
    DESTROY_FORM_OF_PAYMENT: (state, id) => {
      let auxState = [...state.formofpayments.data]
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.formofpayments = [...auxState];
    },

    AUX_TO_UPDATE_FORM: function (state, payload) {
      state.auxToUpdateForm = payload
    }
  },

  actions: {
    async GET_ALL_FORM_OF_PAYMENT({
      commit
    },
      payload
    ) {

      try {
        const response = await axios.post("formOfPayment/index", {
         page: payload.page,
         limit: payload.limit,
         paginate: payload.paginate
       })
       
       commit(GET_ALL_FORM_OF_PAYMENT, response.data[0]);
       return response.data[0];
        
      } catch (error) {
        throw new Error(error)
      }
      
    },

    GET_FILTER_FORM_OF_PAYMENT({
      commit
    }, payload) {
      axios.post("formOfPayment/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_FORM_OF_PAYMENT, response.data[0]);
      })
    },

    ADD_FORM_OF_PAYMENT({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("formOfPayment/store", payload).then((response) => {
          resolve()
          commit(ADD_FORM_OF_PAYMENT, response.data);
          commit(SPLICE_ERRO, {
            alert: "Cadastro realizado com sucesso!"
          })
        }).catch(() => {
          reject()
        })

      })
    },
    UPDATE_FORM_OF_PAYMENT({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("formOfPayment/update", payload).then((response) => {
          resolve()
          commit(UPDATE_FORM_OF_PAYMENT, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!"
          })
        }).catch(() => {
          reject()
        })

      })
    },
    DESTROY_FORM_OF_PAYMENT({
      commit
    }, payload) {
      axios
        .post("formOfPayment/delete", payload)
        .then((response) => {
          commit(DESTROY_FORM_OF_PAYMENT, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0]
          })
        })
    },
    AUX_TO_UPDATE_FORM: function (context, payload) {
      context.commit(AUX_TO_UPDATE_FORM, payload)
    }
  },

  getters: {
    getFormOfPayment: (state) => ({ ...state.formofpayments }),

    getAuxFormToUpdate: (state) => state.auxToUpdateForm
  },
};
export default moduleFormOfPayments;