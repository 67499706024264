const reducer = (accumulator, currentValue) => accumulator + currentValue;

const return_value_calculate = function (val, key) {
    let result = val.map((e) => parseFloat(e[key]))
    return result.reduce(reducer).toFixed(2).replace(".", ",")

}

const calculate_all = function () {
    // Cálculos da Entrada de Notas

    let basecalc_icmstotal;
    let value_icmstotal;
    let value = parseFloat(this.invoice.value_freigth);

    this.baseCalcIcms(this.getNoteEntryItens);
    this.value_products(this.getNoteEntryItens);
    this.calculate_InfoNote(this.getNoteEntryItens);

    if (this.getNoteEntryItens.length === 0) {
        basecalc_icmstotal = 0;
        value_icmstotal = 0;
    } else {
        basecalc_icmstotal = parseFloat(this.invoice.base_calc_icms_total);
        value_icmstotal = parseFloat(this.invoice.value_icms_total);
    }

    if (isNaN(basecalc_icmstotal) === true) {
        basecalc_icmstotal = 0;
    }
    if (isNaN(value_icmstotal) === true) {
        value_icmstotal = 0;
    }
    if (isNaN(this.invoice.value_total_note)) {
        this.invoice.value_total_note = 0;
    }

    if (this.invoice.type_freigth === "CIF") {
        if (isNaN(value) === true) {
            this.baseCalcIcms(this.getNoteEntryItens);
            this.calculate_InfoNote(this.getNoteEntryItens);
        } else {
            this.invoice.value_total_note += value;
            this.invoice.base_calc_icms_total = basecalc_icmstotal + value;
            this.invoice.value_icms_total = (
                value_icmstotal *
                (this.aliquot / 100)
            )
                .toFixed(2)
                .replace(".", ",");
            this.invoice.base_calc_icms_total = this.invoice.base_calc_icms_total
                .toFixed(2)
                .replace(".", ",");
        }
    }

    this.invoice.value_total_note = this.invoice.value_total_note + parseFloat(this.invoice.value_ipi_total.replace(",", "."));
    this.subtraction_discount();
    this.some_outher_expenses_acess();
    this.security_transporter();
    this.invoice.value_total_note = parseFloat(this.invoice.value_total_note)
        .toFixed(2)
        .replace(".", ",");
};

const value_products = function (val) {

    if (val.length !== 0) {
        this.invoice.value_total_products = return_value_calculate(val, 'value_sub_total_product');
        this.invoice.value_total_note = parseFloat(this.invoice.value_total_products);
        this.invoice.value_icms_outher_product = return_value_calculate(val, 'value_icms_outher_product');
    } else {
        this.invoice.value_total_products = 0;
        this.invoice.value_total_note = 0;
    }
};

const baseCalcIcms = function (val) {

    if (val.length !== 0) {
        this.invoice.base_calc_icms_total = return_value_calculate(val, 'value_base_icms_product')
    } else {
        this.invoice.base_calc_icms_total = 0;
    }
};

const subtraction_discount = function () {
    let value = parseFloat(this.invoice.value_discont);
    if (isNaN(value) === false) {
        this.invoice.value_total_note -= value;
    }
};

const some_outher_expenses_acess = function () {
    let value = parseFloat(this.invoice.outher_expenses_acess);
    if (isNaN(value) === false) {
        this.invoice.value_total_note += value;
    }
};

const security_transporter = function () {
    let value = parseFloat(this.invoice.value_security);
    if (isNaN(value) === false) {
        this.invoice.value_total_note += value;
    }
};

const calculate_InfoNote = function (val) {

    if (val.length !== 0) {

        this.invoice.value_icms_total = return_value_calculate(val, 'value_icms_product');
        this.invoice.value_base_icms_st_product = return_value_calculate(val, 'value_base_icms_st_product');
        this.invoice.value_account_total = return_value_calculate(val, 'value_accounting_product');
        this.invoice.base_calc_ipi_total = return_value_calculate(val, 'value_base_ipi_product');
        this.invoice.value_ipi_total = return_value_calculate(val, 'value_ipi_product');
        this.invoice.value_ipi_outher_total = return_value_calculate(val, 'value_ipi_outher_product');
        this.invoice.value_ipi_free_total = return_value_calculate(val, 'value_ipi_free_product');
        this.invoice.value_icms_free_total = return_value_calculate(val, 'value_free_icms_product')


    } else {
        this.invoice.value_icms_total = 0;
        this.invoice.value_base_icms_st_product = 0;
        this.invoice.value_account_total = 0;
        this.invoice.base_calc_ipi_total = 0;
        this.invoice.value_ipi_total = 0;
        this.invoice.value_ipi_outher_total = 0;
        this.invoice.value_ipi_free_total = 0;
        this.invoice.value_icms_free_total = 0;
    }
}

module.exports = {
    calculate_all,
    baseCalcIcms,
    value_products,
    calculate_InfoNote,
    subtraction_discount,
    some_outher_expenses_acess,
    security_transporter
}