<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      hide-default-footer
      no-data-text="Sem empresas registradas"
      no-results-text="Nenhum resultado encontrado"
    >
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="component.permission.Editar"
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  editItem(item, index);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="component.permission.Excluir"
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-6"
      v-model="current"
      :length="total"
      :total-visible="10"
      color="success"
    >
    </v-pagination>

    <DestroyDialog :component="this" :destroy_object="destroy_object" />
    <EditDialog :component="this" ref="edit" v-if="DialogEdit" />
  </div>
</template>

<script>
import { headers } from "./script";
import DestroyDialog from "../DestroyDialog/destroyDialog";
import EditDialog from "../Table/Edit/";
import {
  DESTROY_ENTERPRISE,
  VIEW_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { fixingCNPJ } from "../../Utils/global_functions/clean_cnpj_cpf";
import { mapGetters } from "vuex";
export default {
  components: { DestroyDialog, EditDialog },

  props: {
    getters: Object,
    component: Object,
    destroy_object: Function,
    search: String,
  },
  computed: {
    ...mapGetters(["getEnterprises", "getCadastrosLinks"]),
  },
  data: () => ({
    headers,
    destroyDialog: false,
    DialogEdit: Boolean(),
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    page: 1,
    itemToUpdade: Object(),
    indexItemUpdate: undefined,
  }),

  methods: {
    visibleItem(item, i) {
      this.indexItemUpdate = i;
      this.itemToUpdade = item;
      this.DialogEdit = !this.DialogEdit;
      this.$store.dispatch(VIEW_ENTERPRISE, true);
    },
    editItem(item, i) {
      this.indexItemUpdate = i;
      this.itemToUpdade = item;
      this.DialogEdit = !this.DialogEdit;
    },
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_ENTERPRISE, this.currentItem);
      } catch (e) {
        alert(e);
      } finally {
        this.destroyDialog = !this.destroyDialog;
      }
    },
  },
  created() {
    this.component.GetAll();
  },

  watch: {
    getEnterprises: function (val) {
      this.results = val.data ? fixingCNPJ(Object.values(val.data)) : [];
      this.total = val.last_page;
      this.current = val.current_page;
    },

    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
