const GET_ALL_PRE_NOTEENTRY = 'GET_ALL_PRE_NOTEENTRY';
const UPDATE_PRE_NOTEENTRY = 'UPDATE_PRE_NOTEENTRY';
const DESTROY_PRE_NOTEENTRY = 'DESTROY_PRE_NOTEENTRY';
const ADD_PRE_NOTEENTRY = 'ADD_PRE_NOTEENTRY';
const CONFIRM_PRE_NOTEENTRY = 'CONFIRM_PRE_NOTEENTRY';
const UPDATED_LIST_ITEMS = 'UPDATED_LIST_ITEMS';
const GET_ALL_PRE_NOTEENTRY_BY_ENTERPRISE = 'GET_ALL_PRE_NOTEENTRY_BY_ENTERPRISE';
const GET_AUTOCOMPLETE_PRE_NOTEENTRY_BY_ENTERPRISE = 'GET_AUTOCOMPLETE_PRE_NOTEENTRY_BY_ENTERPRISE';


module.exports = {
    GET_ALL_PRE_NOTEENTRY,
    UPDATE_PRE_NOTEENTRY,
    DESTROY_PRE_NOTEENTRY,
    ADD_PRE_NOTEENTRY,
    CONFIRM_PRE_NOTEENTRY,
    UPDATED_LIST_ITEMS,
    GET_ALL_PRE_NOTEENTRY_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_PRE_NOTEENTRY_BY_ENTERPRISE

}