const campoRules = [
    v => !!v || "Campo Obrigatório!"
]

const cnpjRules = [
    v => !!v || 'CNPJ é obrigatório',
    //v => v.length == 18 || 'O CNPJ deve possuir 14 caracteres'

]


export { campoRules, cnpjRules }
