<template>
  <v-row>
    <v-card-title>Livro Fiscal de Saída</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        class="mt-3"
        label="Empresa"
        outlined
        item-value="id"
        v-model="component.enterprise_id"
        item-text="fantasyName"
        dense
        flat
        :items="enterprises"
        height="10px"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },

  data: () => ({
    enterprises: Array(),
  }),

  methods: {
    active_add() {
      this.component.dialog = !this.component.dialog;
    },
    async GetAllEnterprise(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },
  async created() {
    await this.GetAllEnterprise();
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = [...val.data];
      this.component.enterprise_id = this.enterprises[0].id
    },
  },
};
</script>
