import axios from "axios";
let TributeApplication = require("../Application/Service/Tribute/TributeOutPutApplication");

import {
  DESTROY_REVENUES_ITENS,
  ADD_REVENUES_ITENS,
  REFRESH_REVENUES_ITENS,
  UPDATE_REVENUES_ITENS,
  UPDATE_DISCOUNT,
  ENTERPRISE_OBJECT
} from "./actions/revenuesitens";
// const {
//   SPLICE_ERRO
// } = require('./actions/alert_api')

const moduleRevenuesItens = {
  state: () => ({
    revenusitens: Array(),
    revenusitensUpdate: Array(),
    enterpriseObject: Object(),
  }),

  mutations: {
    ENTERPRISE_OBJECT: function (state, payload) {
      state.enterpriseObject = payload;
    },
    ADD_REVENUES_ITENS: (state, payload) => {
      payload.value_products = parseFloat(
        parseFloat(payload.value_products).toFixed(2)
      );
      payload.venda_sale = parseFloat(
        parseFloat(payload.venda_sale).toFixed(2)
      );
      payload.value_base_icms_product = parseFloat(
        payload.value_base_icms_product.toFixed(2)
      );
      payload.value_icms_product = parseFloat(
        payload.value_icms_product.toFixed(2)
      );
      state.revenusitens.push(payload);

      let tableId = state.revenusitens.indexOf(payload);

      const current = state.revenusitens[tableId];
      current.tableId = tableId;

      if (state.revenusitensUpdate.length !== 0) {
        let product = { ...state.revenusitens[tableId] };
        state.revenusitensUpdate.push(product);
      }
    },
    REFRESH_REVENUES_ITENS: (state, payload) => {
      payload.forEach((element, index) => {
        element.tableId = index;
      });

      state.revenusitens = [...payload];
      state.revenusitensUpdate = [...payload];
    },

    DESTROY_REVENUES_ITENS: (state, payload) => {
      if (payload === "all") {
        state.revenusitens = [];
        state.revenusitensUpdate = [];
      } else {
        let auxState = [...state.revenusitens];

        let index = auxState.indexOf(
          auxState.find((value) => value.tableId === payload)
        );

        if (index >= 0) {
          auxState.splice(index, 1);
          if (state.revenusitensUpdate.length !== 0) {
            state.revenusitensUpdate[index].onDelete = true;
          }
        }
        state.revenusitens = [...auxState];
      }
    },
    UPDATE_REVENUES_ITENS: (state, payload) => {
      const index = state.revenusitens.findIndex((e) => e.product_id == payload.product_id)
      state.revenusitens.splice(index, 1, payload)
    },
    UPDATE_DISCOUNT: (state, payload) => {
      state.revenusitens.forEach((e) => {
        if (e.tableId === payload.tableId) {
          e.value_descont_product = payload.discount;
        }
      });
    },
  },
  // produtRevenues,
  // taxRegimeEnterprise,
  // codeCstPis,
  // codeCstCofins,
  // aliquot_pis,
  // aliquot_cofins,
  // $typeEnterprise

  actions: {
    ENTERPRISE_OBJECT: function (context, payload) {
      context.commit(ENTERPRISE_OBJECT, payload)
    },
    async ADD_REVENUES_ITENS({ commit }, payload) {
      //  
      let productIcms = await TributeApplication.calculeTribute(
        payload,
      );
      commit(ADD_REVENUES_ITENS, productIcms);
      return productIcms
    },
    async UPDATE_REVENUES_ITENS({ commit }, payload) {

      let productIcms = await TributeApplication.calculeTribute(
        payload,
      );

      commit(UPDATE_REVENUES_ITENS, productIcms);

      return productIcms

      // return new Promise((resolve, reject) => {
      //   axios
      //     .post("revenues/addProduct", payload)
      //     .then((response) => {
      //       resolve();
      //       response.data.id = payload.id;
      //       response.data.tableId = payload.tableId;
      //       commit(UPDATE_REVENUES_ITENS, response.data);
      //     })
      //     .catch(() => {
      //       reject();
      //       alert("Verifique as Informações!");
      //     });
      // });
    },
    UPDATE_DISCOUNT({ commit }, payload) {
      commit(UPDATE_DISCOUNT, payload);
    },

    DESTROY_REVENUES_ITENS({ commit }, payload) {
      commit(DESTROY_REVENUES_ITENS, payload);
    },
    REFRESH_REVENUES_ITENS({ commit }, payload) {
      commit(REFRESH_REVENUES_ITENS, payload);
    },
  },
  getters: {
    getEnterpriseObject: (state) => state.enterpriseObject,
    getRevenusItens: (state) => state.revenusitens,
    getRevenusitensUpdate: (state) => state.revenusitensUpdate,
  },
};

export default moduleRevenuesItens;
