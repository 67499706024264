<template>
  <v-row>
    <v-card-title> Profissões </v-card-title>
    <v-spacer></v-spacer>
    <v-btn
      class="success mr-2 mt-6"
      v-if="component.permission.Cadastrar"
      @click="
        () => {
          active_dialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },

  data: () => ({}),
  methods: {
    active_dialog() {
      this.component.addDialog = !this.component.addDialog;
    },
  },
};
</script>
