<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="this.component.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" dark flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                component.close();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Vendedor</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-divider></v-divider>
          <v-card flat>
            <v-tabs>
              <v-tab href="#tab1">
                <v-icon class="mr-1">mdi-account-details</v-icon>
                Gerais
              </v-tab>
              <v-tab href="#tab2">
                <v-icon class="mr-1">mdi-family-tree</v-icon>
                Pessoais
              </v-tab>
              <v-tab href="#tab3">
                <v-icon class="mr-1">mdi-home-account</v-icon>
                Endereço
              </v-tab>
              <v-tab href="#tab4">
                <v-icon class="mr-1">mdi-card-account-mail</v-icon> Contato
              </v-tab>
              <v-tab-item value="tab1">
                <v-card flat>
                  <GeneralDate :component="this" ref="date" />
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab2">
                <v-card flat>
                  <FamillyDate :component="this" />
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab3">
                <v-card flat>
                  <TitleAddress :component="this" />
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab4">
                <Contact :component="this" />
              </v-tab-item>
            </v-tabs>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error">
                <v-icon>mdi-cancel</v-icon> Limpar Formulário</v-btn
              >
              <v-btn class="success" :disabled="!valid" v-on:click="submit()">
                <v-icon>mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GeneralDate from "../../Tabs/TabGeral/index";
import TitleAddress from "../../Tabs/TabAddress/index";
import FamillyDate from "../../Tabs/TabFamily/index";
import Contact from "../../Tabs/TabContact/";
import { UPDATE_SELLER } from "../../../../store/actions/seller";

export default {
  components: {
    GeneralDate,
    TitleAddress,
    FamillyDate,
    Contact,
  },

  props: {
    component: Object(),
  },

  data: () => ({
    physical_people: {
      type_people: String(),
      client_id: String(),
      id: "",
      name: "",
      lastName: "",
      cpf: "",
      gender: "",
      emitter: "",
      emitterState: "",
      idCard: "",
      dateOfBirth: "",
      fatherName: "",
      motherName: "",
      nacionality: "",
      naturality: "",
      icomeSpouse: 0,
      monthlyIncome: 0,
      civilStatus: "",
      spouse: "",
      incomeSpouse: "",
      enterprise_id: "",
      address: Array(),
      contacts: Array(),
    },
    ListContact: Array(),
    ListAddress: Array(),
    valid: false,
  }),
  methods: {
    executeAddDialog() {
      this.component.addDialog = !this.component.addDialog;
    },
    async submit() {
      let newSeller;
      newSeller = { ...this.physical_people };
      newSeller.type_people = "PhysicalPeople";
      newSeller.address = this.ListAddress;
      newSeller.contacts = this.ListContact;
      newSeller.enterprise_id = this.physical_people.enterprise_id;

      await this.$store.dispatch(UPDATE_SELLER, newSeller).then(() => {
        this.component.close();
      });
    },
  },
};
</script>

<style>
</style>