const ADD_COMPANY_GROUP = "ADD_COMPANY_GROUP";
const UPDATE_COMPANY_GROUP = "UPDATE_COMPANY_GROUP";
const GET_ALL_COMPANY_GROUP = "GET_ALL_COMPANY_GROUP";
const DESTROY_COMPANY_GROUP = "DESTROY_COMPANY_GROUP";
const GET_FILTER_COMPANY_GROUP = "GET_FILTER_COMPANY_GROUP";
const AUX_TO_UPDATE_COMPANY_GROUP = "AUX_TO_UPDATE_COMPANY_GROUP";
const GET_ALL_COMPANY_GROUP_BY_USER = "GET_ALL_COMPANY_GROUP_BY_USER";

module.exports = {
    AUX_TO_UPDATE_COMPANY_GROUP,
    ADD_COMPANY_GROUP,
    UPDATE_COMPANY_GROUP,
    GET_ALL_COMPANY_GROUP,
    DESTROY_COMPANY_GROUP,
    GET_FILTER_COMPANY_GROUP,
    GET_ALL_COMPANY_GROUP_BY_USER
}
