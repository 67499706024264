const INDUSTRY = "I";
let TributeIcmsOutPutDomainService = require("../../../Domain/Service/TributeIcms/TributeIcmsOutPutDomainService");
let TributePisDomainService = require("../../../Domain/Service/TributePis/TributePisOutDomainService");
let TributeCofinsDomainService = require("../../../Domain/Service/TributeCofins/TributeCofinsOutDomainService");
let TributeIpiDomainService = require("../../../Domain/Service/TributeIpi/TributeIpiOutDomainService");

async function calculeTribute(produtRevenues) {
  try {
    let taxRegimeEnterprise = parseInt(produtRevenues.enterprises[0].tax_regime)
    let codeCstPis = produtRevenues.cst_code_pis
    let codeCstCofins = produtRevenues.cst_code_confis
    let aliquot_pis = produtRevenues.enterprises[0].aliquot_pis
    let aliquot_cofins = produtRevenues.enterprises[0].aliquot_cofins
    let typeEnterprise = produtRevenues.enterprises[0].enterprise_type
    let cfop_code = parseInt(produtRevenues.code_cfop)
    let cst_ipi_code = 99
    let aliquot_ipi = produtRevenues.aliquot_ipi_product
    let code_csosn =  produtRevenues.code_csosn
     
    
    let productRevenuesTribute = null
    productRevenuesTribute = await TributeIcmsOutPutDomainService.calculeTributeIcms(
      taxRegimeEnterprise,
      produtRevenues,
      cfop_code,
      code_csosn
    );
     
    productRevenuesTribute = await TributePisDomainService.calculeTributePis(
      produtRevenues,
      aliquot_pis,
      codeCstPis
    );
     
    productRevenuesTribute = await TributeCofinsDomainService.calculeTributeCofins(
      produtRevenues,
      codeCstCofins,
      aliquot_cofins,
    );

     
    if (INDUSTRY === typeEnterprise) {
      productRevenuesTribute = await TributeIpiDomainService.calculeTributeIpi(
        produtRevenues, cst_ipi_code, aliquot_ipi
      );
    }
     
    return productRevenuesTribute;
  } catch (error) {

    return error;
  }
}

module.exports = {
  calculeTribute,
};
