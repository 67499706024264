<template>
  <div>
    <v-list link>
      <v-list-item class="px-2">
        <v-list-item-avatar align-center>
          <v-img
            src="https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title white--text">{{
            user.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="white--text">{{
            user.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list-item to="/Home" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-home-plus</v-icon
          >Home</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <!-- <v-list-item to="/Dashboard" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-chart-pie</v-icon
          >Dashboard</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item> -->
    <Cadastro />
    <Compras />
    <Entradas />
    <Faturamento />
    <Saidas />
    <Estoque />
    <Financeiro />
    <Fiscal />
    <Relatorios />
    <Seguranca />
  </div>
</template>

<script>
import Cadastro from "./Cadastros/index.vue";
import Compras from "./Compras/index.vue";
import Entradas from "./Entradas/index.vue";
import Faturamento from "./Faturamento/index.vue";
import Saidas from "./Saidas/index.vue";
import Estoque from "./Estoque/index.vue";
import Financeiro from "./Financeiro/index.vue";
import Fiscal from "./Fiscal/index.vue";
import Relatorios from "./Relatorios/index.vue";
import Seguranca from "./Seguranca/index.vue";

export default {
  components: {
    Cadastro,
    Compras,
    Entradas,
    Faturamento,
    Saidas,
    Estoque,
    Financeiro,
    Fiscal,
    Relatorios,
    Seguranca,
  },
  data: () => ({
    user: Object,
  }),
  mounted() {
    let user = this.$store.getters["auth/userLogin"].user;
    this.user = user || {};
  },
};
</script>

<style>
.navDrop {
  padding-left: 25px !important;
}
</style>
