const GET_ALL_NOTE_ENTRY_ITENS = 'GET_ALL_NOTE_ENTRY_ITENS'
const UPDATE_NOTE_ENTRY_ITENS = 'UPDATE_NOTE_ENTRY_ITENS'
const DESTROY_NOTE_ENTRY_ITENS = 'DESTROY_NOTE_ENTRY_ITENS'
const ADD_NOTE_ENTRY_ITENS = 'ADD_NOTE_ENTRY_ITENS'
const REFRESH_NOTE_ENTRY_ITENS = 'REFRESH_NOTE_ENTRY_ITENS'
const UPDATE_ITENS_NOTE = 'UPDATE_ITENS_NOTE'
const CLEAN_NOTE_ENTRY_ITENS = 'CLEAN_NOTE_ENTRY_ITENS'



module.exports = {
    CLEAN_NOTE_ENTRY_ITENS,
    GET_ALL_NOTE_ENTRY_ITENS,
    UPDATE_NOTE_ENTRY_ITENS,
    DESTROY_NOTE_ENTRY_ITENS,
    ADD_NOTE_ENTRY_ITENS,
    REFRESH_NOTE_ENTRY_ITENS,
    UPDATE_ITENS_NOTE
}