import { AUX_TO_UPDATE_CASHIER } from "../../../store/actions/cashier";

function visibleItem(item){
    item.initial_date = item.initial_date.split("/").reverse().join("-");
    this.$store.dispatch(AUX_TO_UPDATE_CASHIER, {item: item, justVisible: true})
    this.component.addDialog = true;
  }



function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}

function adjust_date(item) {
    if (item != undefined) {
        item.forEach((e) => {
            e.initial_date = e.initial_date.split("-").reverse().join("/");
        });
    }
}


export default {
    deleteItem,
    handleClick,
    adjust_date,
    visibleItem
}