<template>
  <v-layout row justify-center>
    <v-dialog v-model="component.destroyDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Deseja Excluir este item do {{ getTitle() || "" }} ?</v-card-title>
        <v-card-text>Essa ação irá excluir o item do {{ getTitle() || "" }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="
              () => {
                close_DestroyDialog(this);
              }
            "
          >Cancelar</v-btn>
          <v-btn
            color="success ml-4"
            @click="
              () => {
                submit(this);
              }
            "
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import UiBusinessRules from "../../../UIBusinessRule/Bank/methods/DestroyDialog";
export default {
  props: {
    component: Object,
    destroy_bank: Function
  },
  methods: {
    ...UiBusinessRules
  }
};
</script>

<style>
</style>