import axios from "axios";

const {
    ADD_CARD_ADM,
    UPDATE_CARD_ADM,
    GET_ALL_CARD_ADM,
    DESTROY_CARD_ADM,
    GET_FILTER_CARD_ADM,
    AUX_TO_UPDATE_CARD_ADM
} = require("./actions/card_adm");

const {
    SPLICE_ERRO,
} = require('./actions/alert_api')

const moduleCard_adm = {
    state: () => ({
        card_adms: Object(),
        auxToUpdateCardAdm: Object(),
    }),

    mutations: {
        GET_ALL_CARD_ADM: (state, card_adms) => {
            state.card_adms = card_adms;
        },

        GET_FILTER_CARD_ADM: (state, payload) => {
            if (payload != undefined) {
                payload.data.forEach((e) => {
                    if (e.use_type === "P") {
                        e.use_type = "POS"
                    } else {
                        e.use_type = "TEF"
                    }
                    if (e.status === 1) {
                        e.status = "Habilitado"
                    } else {
                        e.status = "Desabilitado"
                    }
                })
            }
            state.card_adms = payload
        },

        ADD_CARD_ADM: (state, newCard_adms) => {
            state.card_adms = newCard_adms;
        },

        UPDATE_CARD_ADM(state) {
            state.card_adms = {}
        },
        DESTROY_CARD_ADM: (state, id) => {
            let auxState = [...state.card_adms.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.card_adms = [...auxState];
        },

        AUX_TO_UPDATE_CARD_ADM: function (state, payload) {
            state.auxToUpdateCardAdm = payload
        }
    },

    actions: {
        GET_ALL_CARD_ADM({
            commit
        }, payload) {
            axios.post("cardadministrator/index",
                { page: payload.page, limit: payload.limit, paginate: payload.paginate }
            ).then((response) => {
                commit(GET_ALL_CARD_ADM, response.data[0]);
            });
        },

        GET_FILTER_CARD_ADM({
            commit
        }, payload) {
            axios.get("cardadministrator/autocomplete", {
                params: {
                    search: payload.search,
                    limit: payload.limit,
                    page: payload.page,
                    paginate: payload.paginate
                }
            }).then((response) => {
                commit(GET_FILTER_CARD_ADM, response.data[0]);
            })
        },

        ADD_CARD_ADM({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("cardadministrator/store", payload).then((response) => {
                    resolve()
                    commit(ADD_CARD_ADM, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Cadastrado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },

        UPDATE_CARD_ADM({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("cardadministrator/update", payload).then((response) => {
                    resolve()
                    commit(UPDATE_CARD_ADM, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Atualizado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },

        DESTROY_CARD_ADM({
            commit
        }, payload) {
            commit
            axios.post("cardadministrator/delete", { id: payload.id }).then(() => {
                commit(DESTROY_CARD_ADM, payload.id);
                commit(SPLICE_ERRO, {
                    alert: 'Item deletado com sucesso!',
                    type: 'S'
                })
            })
        },

        AUX_TO_UPDATE_CARD_ADM: function (context, payload) {
            context.commit(AUX_TO_UPDATE_CARD_ADM, payload)
        }
    },

    getters: {
        getCard_adm: (state) => ({
            ...state.card_adms
        }),

        getAuxCardAdmToUpdate: (state) => state.auxToUpdateCardAdm
    },
};

export default moduleCard_adm;