import { ITEM_UNIT_TO_UPDATE } from "../../../store/actions/unit"



function visibleItem(item){
    this.$store.dispatch(ITEM_UNIT_TO_UPDATE, {item: item, justVisible: true})
    this.$store.dispatch("setShowDialogRegisterUnit", true);
}

function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}
function editItem(item) {
    this.$store.dispatch(ITEM_UNIT_TO_UPDATE, item)
    this.$store.dispatch("setShowDialogRegisterUnit", true)
}
function handleClick(i) {
    this.itemSelectedOnClick = i;
}




export default {
    deleteItem,
    editItem,
    handleClick,
    visibleItem
}