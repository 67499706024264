import { AUX_CFOP_TO_UPDATE } from "../../../store/actions/cfop";

function visibleItem(item){
    this.$store.dispatch(AUX_CFOP_TO_UPDATE, {item: item, justVisible: true})
    this.$store.dispatch("setShowDialogRegisterCFOP", true);
  }

function open_Dialog(item) {
    this.$store.dispatch(AUX_CFOP_TO_UPDATE, {});
    this.$store.dispatch(AUX_CFOP_TO_UPDATE, item);
    this.$store.dispatch("setShowDialogRegisterCFOP", true);
}
function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
}

function handleClick(i) {
    this.itemSelectedOnClick = i;
}




export default {
    open_Dialog,
    deleteItem,
    handleClick,
    visibleItem,
}