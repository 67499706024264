



var text_field_rules = [
    (v) => !!v || "Campo Obrigatório"
]
var select_rules = [
    (v) => !!v || "Campo Obrigatório"
]

module.exports = {
    text_field_rules,
    select_rules
}