import axios from 'axios'

const {
    ADD_PDV,
    UPDATE_PDV,
    GET_ALL_PDV,
    DESTROY_PDV,
    GET_FILTER_PDV
} = require('./actions/pdv')

const { SPLICE_ERRO } = require('./actions/alert_api')

const modulePDV = {
    state: () => ({
        pdv: {},
    }),

    /* ################### Mutations ########### */
    mutations: {
        ADD_PDV: (state, payload) => {
            state.pdv.data.push(payload)
        },

        GET_ALL_PDV: (state, payload) => {
            state.pdv = payload
        },
        UPDATE_PDV: (state, payload) => {

            state.pdv.data.Update(payload)
        },
        DESTROY_PDV: (state, id) => {
            let auxState = [...state.pdv.data];
            let index = auxState.indexOf(auxState.find(value => value.id === id))
           
            if (index >= 0) {
                auxState.splice(index, 1)
            }
            state.pdv.data = [...auxState]
        },
        GET_FILTER_PDV(state, payload) {
            if (payload !== undefined) {
                state.pdv = payload
            }
        }
    },
    /* ################### Actions ########### */

    actions: {
        GET_ALL_PDV(context, payload) {
            axios.post('pdv/index', { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                context.commit(GET_ALL_PDV, response.data[0])
            })
        },

        ADD_PDV({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post('pdv/store', payload).then((response) => {
                    resolve()
                    commit(ADD_PDV, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: "Cadastrado com sucesso!",
                        Type: "S"
                    })
                }).catch(() => { reject() })
            })
        },

        UPDATE_PDV({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("pdv/update", payload).then((response) => {
                    resolve()
                    commit(UPDATE_PDV, response.data[0])
                    commit(SPLICE_ERRO, { alert: "DPV alterado com sucesso" })
                }).catch((error) => {
                    reject(error.response.data)
                })
            })
        },

        async DESTROY_PDV(context, payload) {
            await axios.post("pdv/delete", { ...payload }).then(() => {
                context.commit(DESTROY_PDV, payload.id);
                context.commit(SPLICE_ERRO, {
                    alert: "Ponto de venda Deletado com sucesso"
                })
            })
        },

        GET_FILTER_PDV(context, payload) {
            
            axios.get("pdv/autocomplete", {
                params: {
                    search: payload.search,
                    limit: payload.limit,
                    page: payload.page,
                    paginate: payload.paginate
                }
            }).then((response) => { context.commit(GET_FILTER_PDV, response.data[0]); })
        }
    },

    /* ################### Getters ########### */
    getters: {
        getPdv: (state) => ({
            ...state.pdv
        })
    }
}

export default modulePDV