<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTitle" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          append-icon="mdi-magnify"
          outlined
          @keyup.enter="setAutocomplete()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :getters="getPreNoteEntry"
        :destroy_object="destroy_object"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
      <ModalNoteEntry
        v-if="addDialog"
        :dialog="addDialog"
        :changeDialog="change_addDialog"
        :component="this"
        :title="registerNote"
        :enterpriseId="enterpriseId"
      />
    </v-card>
    <AlertApi />
    <LoadScreen/>
  </Container>
</template>

<script>
//v-if="permission.Visualizar"
import AlertApi from "../components/alert_api/alert_api";
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/pre_note_entry/CardTitle/Index";
import Table from "../components/pre_note_entry/Table/";
import ModalNoteEntry from "../components/pre_note_entry/AddDialog/index";
import LoadScreen from "../components/load/index.vue"
import {
  GET_ALL_PRE_NOTEENTRY_BY_ENTERPRISE,
  GET_AUTOCOMPLETE_PRE_NOTEENTRY_BY_ENTERPRISE,
  DESTROY_PRE_NOTEENTRY,
} from "../store/actions/preNote_entry";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    checkinfo: 0,
    getter: "getNoteEntry",
    registerNote: "Registrar",
    isNote: Boolean(),
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    clients: Array(),
    enterpriseId: Number,
    enterprise: Object,
    title: String(),
    titles: ["Entrada de Notas", "Pré-Entrada de Notas"],
    permission: Object(),

    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Emissão", value: "date_of_issue" },
      { text: "Data de Entrada", value: "entry_date" },
      { text: "Fornecedor", value: "providerToShow" },
      { text: "Numero Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos R$", value: "value_total_products" },
      { text: "Valor da Nota R$", value: "value_total_note" },
      { text: "BC ICMS R$", value: "base_calc_icms_total" },
      { text: "Valor ICMS R$", value: "value_icms_total" },
      { text: "Outros Valores R$", value: "value_untaxed_tax_total" },
      { text: "Valor base de Substituição R$", value: "base_calc_susbt_total" },
      {
        text: "Valor de ICMS Substituição R$",
        value: "value_icms_substituicao_total",
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  watch: {
    isNote: function (val) {
      this.title = val ? this.titles[1] : this.titles[0];
    },

    enterpriseId: function() {
      this.GetAll()
    },

    search: function(val) {
      if(val.length >= 2) {
        this.GetAutocomplete()
      }
    },

    searchInput: function(val) {
      if(val.length == 0) {
        this.search = ""
        this.GetAll()
      }
    }
  },
  computed: {
    ...mapGetters(["getPreNoteEntry", "getMovimentacaoLinks"]),
  },
  methods: {
    async destroy_object(item) {
      const aliquot = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PRE_NOTEENTRY, aliquot);
      } catch (error) {
        alert(error, "error");
      }
    },
    async change_addDialog() {
      this.addDialog = !this.addDialog;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId,
        status: "PRE-NOTA",
      };
      await this.$store.dispatch(GET_ALL_PRE_NOTEENTRY_BY_ENTERPRISE, value);
    },

    async GetAutocomplete(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId,
        search: this.search,
        status: "PRE-NOTA",
      };
      await this.$store.dispatch(GET_AUTOCOMPLETE_PRE_NOTEENTRY_BY_ENTERPRISE, value);
    },

    setAutocomplete() {
      this.search = this.searchInput
    }
  },

  async created() {
    this.title = this.titles[0];
  },

  components: {
    LoadScreen,
    Container,
    CardTitle,
    Table,
    AlertApi,
    ModalNoteEntry,
  },
};
</script>

<style scoped></style>
