import {
  PREPARE_INF_GENERAL,
  PREPARE_ITENS_NOTE,
  PREPARE_TRANSPORTER,
  PREPARE_DATA_INF_NOTE
} from "../actions/import_xml";

export default {
  state: {
    dataInfGeneral: Object(),
    dataItensNote: Array(),
    dataTransporter: Object(),
    dataInfNote:Object(),
  },
  getters: {
    getDataInfGeneral: (state) => state.dataInfGeneral,
    getDataItensNote: (state) => state.dataItensNote,
    getDataTransporter: (state) => state.dataTransporter,
    getDataInfNote: (state) => state.dataInfNote
  },
  mutations: {
    PREPARE_INF_GENERAL: function (state, payload) {
      state.dataInfGeneral = payload;
    },
    PREPARE_ITENS_NOTE: function (state, payload) {
      state.dataItensNote = payload;
    },
    PREPARE_TRANSPORTER: function (state, payload) {
      state.dataTransporter = payload;
    },
    PREPARE_DATA_INF_NOTE: function (state, payload) {
      state.dataInfNote = payload;
    },
  },
  actions: {
    PREPARE_INF_GENERAL: function (context, payload) {
      context.commit(PREPARE_INF_GENERAL, payload);
    },
    PREPARE_ITENS_NOTE: function (context, payload) {
      context.commit(PREPARE_ITENS_NOTE, payload);
    },
    PREPARE_TRANSPORTER: function (context, payload) {
      context.commit(PREPARE_TRANSPORTER, payload);
    },
    PREPARE_DATA_INF_NOTE: function (context, payload) {
      context.commit(PREPARE_DATA_INF_NOTE, payload);
    },
  },
};
