<template>
  <div>
    <v-data-table
      :items="results"
      :search="search"
      :headers="headers"
      no-data-text="Sem registro!"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      :loading="component.loading"
      loading-text="Carregando os dados!"
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-1"
              v-bind="attrs"
              @click="DialogEdit(item)"
              v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="dialogDelete(item)"
              color="red darken-4"
              v-bind="attrs"
              v-on="on"
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              v-bind="attrs"
              class="mr-1"
              v-on="on"
              @click="viewInfo(item)"
              >mdi-eye</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="green darken-2"
              class="mt-1"
              v-bind="attrs"
              v-on="on"
              @click="DialogConfirm(item)"
              >mdi-checkbox-marked</v-icon
            >
          </template>
          <span>Confirmar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DeleteDialog
      :dialog="dialog"
      :title="title"
      :subtitle="subtitle"
      :alterDialog="alterDialog"
      :confirm="confirmDelete"
    />
    <EditDialog
      v-if="addDialog"
      :dialog="addDialog"
      :object="ObjectToUpdate"
      :changeDialog="alterDialogEdit"
      title="Editar"
      :enterpriseId="enterpriseId"
      :component="this"
      :disabled="false"
    />
    <ViewDialog
      v-if="viewDialog"
      :dialog="viewDialog"
      :object="ObjectToView"
      :changeDialog="alterDialogView"
      title="Informações do"
      :component="this"
      :disabled="true"
    />
    <ConfirmNote v-if="confirmDialog" :component="this" :submit="confirmNote" />
  </div>
</template>

<script>
import DeleteDialog from "../AddDialog/ConfirmAction/";
import EditDialog from "../AddDialog/";
import ViewDialog from "../AddDialog/";
import ConfirmNote from "../AddDialog/ConfirmNote/";
import {
  DESTROY_REVENUES,
  CONFIRM_REVENUES,
} from "../../../store/actions/revenues";
import { REFRESH_REVENUES_ITENS } from "../../../store/actions/revenuesitens";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    headers: Array,
    change_addDialog: Function,
    isLoad: Boolean,
  },
  components: {
    DeleteDialog,
    EditDialog,
    ViewDialog,
    ConfirmNote,
  },

  data: () => ({
    destroyDialog: false,
    addDialog: Boolean(),
    viewDialog: Boolean(),
    totalNF: Number(),
    objectToConfirm: Object(),
    currentItem: {},
    ObjectToUpdate: Object(),
    ObjectToView: Object(),
    enterpriseId: Number(),
    results: Array(),
    total: Number(),
    current: Number(),
    dialog: Boolean(),
    confirmDialog: Boolean(),
    billstoReceive: Object(),
    id: Number(),
    titleConfirm: "Deseja confirmar está nota?",
    subConfirm: "Esta nota será confirmada",
    title: "Deseja deletar está nota?",
    subtitle: "Esta nota será deletada!",
    itemSelectedOnClick: Object(),
  }),
  methods: {
    confirmDelete() {
      try {
        let payload = this.id;
        this.$store.dispatch(DESTROY_REVENUES, payload);
      } catch (error) {
        alert(error);
      } finally {
        this.alterDialog();
      }
    },
    alterDialog() {
      this.dialog = !this.dialog;
    },
    alterDialogEdit() {
      this.addDialog = !this.addDialog;
    },
    alterDialogView() {
      this.viewDialog = !this.viewDialog;
    },
    alterDialogConfirm() {
      this.confirmDialog = !this.confirmDialog;
    },
    DialogConfirm(item) {
      this.totalNF = item.value_total_note;
      this.objectToConfirm = { ...item };
      this.confirmDialog = !this.confirmDialog;
    },
    confirmNote(item, account) {
      let invoices = Object();
      invoices.billstoReceive = [...item];
      invoices.id = this.objectToConfirm.id;
      invoices.account_plans_id = account;
      invoices.billstoReceive.forEach((e) => {
        delete e.id;
      });
      try {
        this.$store.dispatch(CONFIRM_REVENUES, invoices);
        this.alterDialogConfirm();
      } catch (error) {
        alert(error);
      }
    },

    DialogEdit(item) {
      this.enterpriseId = item.enterprise_id;

      this.ObjectToUpdate = { ...item };

      this.refreshRevenusItem(item);
      this.alterDialogEdit();
    },

    dialogDelete(item) {
      this.dialog = !this.dialog;
      this.id = item.id;
    },

    refreshRevenusItem(item) {
      let listItens = [...item.revenus_item];

      listItens.forEach((e) => {
        e.description_item = e.produto.description_item;
        e.value_products = e.value_subtotal_product;
        e.venda_sale = e.value_sale;
        e.description_unit = e.produto.unit.sigla;
        e.base_icms_product = e.value_base_icms_product;

        if (e.cst_id === null) {
          e.cst_code = e.produto.csons.code;
        } else {
          e.cst_code = e.produto.cst.code;
        }
      });
      this.$store.dispatch(REFRESH_REVENUES_ITENS, listItens);
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    viewInfo(item) {
      this.ObjectToView.id = item.id;
      this.ObjectToView.status = item.status;
      this.ObjectToView.type_grade_id = item.type_grade_id;
      this.ObjectToView.finality_nf = item.finality_nf;
      this.ObjectToView.date_of_issue = item.date_of_issue;
      this.ObjectToView.access_key = item.access_key;
      this.ObjectToView.authorization_protocol = item.authorization_protocol;
      this.ObjectToView.series = item.series;
      this.ObjectToView.value_total_products = item.value_total_products;
      this.ObjectToView.value_book = item.value_book;
      this.ObjectToView.value_total_note = item.value_total_note;
      this.ObjectToView.value_discount = item.value_discount;
      this.ObjectToView.specie_note = item.specie_note;
      this.ObjectToView.value_icms_substituicao_total =
        item.value_icms_substituicao_total;
      this.ObjectToView.value_freigth = item.value_freigth;
      this.ObjectToView.base_calc_icms_total = item.base_calc_icms_total;
      this.ObjectToView.base_calc_susbt_total = item.base_calc_susbt_total;
      this.ObjectToView.value_icms_free_total = item.value_icms_free_total;
      this.ObjectToView.value_account_total = item.value_account_total;
      this.ObjectToView.value_outher_tax_total = item.value_outher_tax_total;
      this.ObjectToView.value_icms_total = item.value_icms_total;
      this.ObjectToView.note = item.note;
      this.ObjectToView.value_security = item.value_security;
      this.ObjectToView.value_ipi_total = item.value_ipi_total;
      this.ObjectToView.value_ipi_outher_total = item.value_ipi_outher_total;
      this.ObjectToView.value_ipi_free_total = item.value_ipi_free_total;
      this.ObjectToView.base_calc_ipi_total = item.base_calc_ipi_total;
      this.ObjectToView.vehicle_plate = item.vehicle_plate;
      this.ObjectToView.volume_transported = item.volume_transported;
      this.ObjectToView.gross_weight = item.gross_weight;
      this.ObjectToView.net_weight = item.net_weight;
      this.ObjectToView.volume_type = item.volume_type;
      this.ObjectToView.transporter_id = item.transporter_id;
      this.ObjectToView.client_id = item.client_id;
      this.ObjectToView.payment_terms_id = item.payment_terms_id;
      this.ObjectToView.tax_operation_types_id = item.tax_operation_types_id;
      this.ObjectToView.account_plans_id = item.account_plans_id;
      this.ObjectToView.enterprise_id = item.enterprise_id;
      this.ObjectToView.cliente = item.cliente;
      this.ObjectToView.value_other_incidental_expenses =
        item.value_other_incidental_expenses;
      this.ObjectToView.value_icms_outher_product =
        item.value_icms_outher_product;
      this.refreshRevenusItem(item);
      this.viewDialog = !this.viewDialog;
    },
  },
  created() {
    this.component.GetAll();
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
