import { SET_GENERAL_DATA } from "../actions/import_xml";
import filterResponse from "./assistant_functions/general_data_filter_response";
import axios from "axios";

export default {
  state: {
    generalData: Object(),
  },
  getters: {
    getGeneralData: function (state) {
      return state.generalData;
    },
  },
  mutations: {
    SET_GENERAL_DATA: function (state, payload) {
      state.generalData = payload;
    },
  },
  actions: {
    SET_GENERAL_DATA: function (context, payload) {
      // var providerCNPJ = "01184287000381"; //8
      var enterprise_id = payload.enterprise_id;
       
      try {
        axios
          .post("provider/getprovidercnpj", {
            cnpj:payload.generalData.provider.providerCNPJ ,//providerCNPJ,
            enterprise_id: enterprise_id,
          })
          .then((response) => {
            var finalObject = filterResponse(response.data[0], payload);
            context.commit(SET_GENERAL_DATA, finalObject);
          });
      } catch (error) {
        return error;
      }
    },
  },
};
