<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="700">
      <v-card>
        <v-card-title primary-title>Caixa</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" class="mt-6">
            <v-col>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[
                  () => !!description || 'Campo Obrigatório',
                  () =>
                    (!!description && description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Código"
                v-model="code"
                :readonly="justVisible"
                maxlength="5"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length >= 3) ||
                    'Campo tem que ser maior do que 3',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Saldo Inicial"
                v-model="initial_balance"
                :readonly="justVisible"
                prefix="R$"
                type="number"
                :rules="[() => !!initial_balance || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Data Inicial"
                type="date"
                v-model="initial_date"
                :readonly="justVisible"
                :rules="[() => !!initial_date || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="itensSelect"
                label="*Tipo de Caixa"
                v-model="cashierType"
                :readonly="justVisible"
                :rules="[() => !!cashierType || 'Campo Obrigatorio']"
                outlined
                solo
              ></v-select>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    cancel();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn
                v-if="!justVisible"
                color="success"
                :disabled="!valid"
                class="mr-4"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UiBusinessRules from "../../../UIBusinessRule/Cashier/Methods/AddDialog";
import { Data } from "../../../UIBusinessRule/Cashier/Data/AddDialog";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object
  },

  data() {
    return {
      ...Data
    };
  },

  computed: {
    ...mapGetters(["getAuxCashierToUpdate"]),
  },
  methods: {
    ...UiBusinessRules
  },
  async created() {
    this.autoload(this.getAuxCashierToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.description = this.object.description;
    // this.code = this.object.code;
    // this.initial_date = this.object.initial_date;
    // this.cashierType = this.object.cashierType;
    // this.balance_current = this.object.balance_current;
    // if (this.id >= 0) {
    //   this.initial_balance = this.object.initial_balance.toString();
    // } else {
    //   this.initial_balance = this.object.initial_balance;
    // }
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  }
};
</script>
