<template>
  <v-row justify="center">
    <v-dialog v-model="this.component.addDialog" max-width="500" persistent>
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-card-title>Registrar Tipo de Operação de Caixa</v-card-title>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="type"
                  :readonly="justVisible"
                  outlined
                  :items="types"
                  label="Tipo:"
                  :rules="[() => !!type || 'Campo Obrigatório']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="description"
                  :readonly="justVisible"
                  outlined
                  label="Descrição:"
                  :rules="[() => !!description || 'Campo Obrigatório']"
                ></v-text-field>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    desactive_dialog();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon>
                Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_CASHIER_OPERATION_TYPE,
  UPDATE_CASHIER_OPERATION_TYPE,
  AUX_TO_UPDATE_CASHIER_OPERATION_TYPE,
} from "../../../store/actions/cashier_operation_type";
import { mapGetters } from "vuex";

export default {
  props: { component: Object() },

  data: () => ({
    valid: true,
    id: "",
    type: "",
    types: ['Entrada', 'Saída', 'Sangria', 'Abertura caixa','Fechamento caixa','Suprimento'],
    description: "",
    new_cashier_operation: {},
    justVisible: Boolean(),
  }),
  methods: {
    async create_cashier_operation() {
      const cashier_operation = {
        type: this.type,
        description: this.description,
      };
      await this.$store
        .dispatch(ADD_CASHIER_OPERATION_TYPE, cashier_operation)
        .then(() => {
          this.desactive_dialog();
        });
    },
    submit() {
      this.id == undefined
        ? this.create_cashier_operation()
        : this.update_cashier_operation();
    },
    async update_cashier_operation() {
      const cashier_operation = {
        id: this.id,
        type: this.type,
        description: this.description,
      };
      await this.$store
        .dispatch(UPDATE_CASHIER_OPERATION_TYPE, cashier_operation)
        .then(() => {
          this.desactive_dialog();
        });
    },

    desactive_dialog() {
      this.component.addDialog = !this.component.addDialog;
      this.justVisible = false;
      this.$store.dispatch(AUX_TO_UPDATE_CASHIER_OPERATION_TYPE, Object())
    },
    getComponent() {
      return this._props.component;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    autoload(payload) {
      if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.type = payload.item.type;
        this.description = payload.item.description;
    } else {
        this.type = payload.type;
        this.description = payload.description;
        this.id = payload.id;
    }
    }
  },

  computed: {
    ...mapGetters(["getAuxCashierOperationTypeToUpdate"]),
  },

  async created() {
    this.autoload(this.getAuxCashierOperationTypeToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.type = this.object.type;
    // this.description = this.object.description;
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>

<style>
</style>