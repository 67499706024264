<template>
  <div>
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Sem registro!"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="blue"
          class="mr-2"
          @click="
            () => {
              view_info(item);
            }
          "
          >mdi-information-outline</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination 
      v-model="current" 
      :length="total" 
      color="success"
      :total-visible="10"
    > </v-pagination>
    <ViewInfo
      v-if="dialog_fiscal"
      :component="this"
      :objectToView="objectToView"
    />
  </div>
</template>

<script>
import ViewInfo from "../AddDialog/";

export default {
  props:{
    getters: Object,
    component: Object
  },

  components: {
    ViewInfo,
  },

  data: () => ({
    page: 1,
    dialog_fiscal: Boolean(),
    header: [
      { text: "Data de Entrada da Nota", value: "date_invoice_entry" },
      { text: "Número NF", value: "number_grade" },
      { text: "Série", value: "serie" },
      { text: "Origem", value: "origin" },
      { text: "Ações", value: "actions", align: "center" },
    ],
    objectToView: Object(),
    results: Array(),
    total: Number(),
    current: Number()
  }),

  methods: {
    view_info(item) {
      this.dialog_fiscal = !this.dialog_fiscal;
      this.objectToView = { ...item };
    },

    adjust_date(){
      this.results.forEach(e => {
        e.date_invoice_entry = e.date_invoice_entry.split("-").reverse().join("/")
        e.date_of_issue = e.date_of_issue.split("-").reverse().join("/")
      });
    }
  },

  watch:{
    getters: function(val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.adjust_date()
    },
    current: function(val){
      if(this.component.search) {
        this.component.GetAutocomplete && this.component.GetAltocomplete(val)
      } else {
        this.component.GetAll && this.component.GetAll(val)
      }
    }
  }
};
</script>

