<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Saldo Estoque</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3" v-if="!companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              append-outer-icon="mdi-home-group"
              @click:append-outer="changeSelect()"

              >Adicionar</v-select
            >
          </v-col>
          <v-col class="d-flex" cols="12" sm="3" v-if="companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Grupo de Empresa"
              item-value="id"
              item-text="name"
              loader-height
              v-model="selectCompanyGroup"
              :items="companyGroups"
              append-outer-icon="mdi-home"
              @click:append-outer="changeSelect()"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/stock_balance/Form/";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GET_ALL_SUB_PRODUCT_GROUP } from "../../../store/actions/sub_product_group";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../store/actions/companygroup";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { Form },
  data: () => ({
    dialog: Boolean(),
    valid: false,
    selectValue: Object,
    enterprise: [],
    companyGroups: Array(),
    selectCompanyGroup: Object(),
    companyGroup: Boolean(),
    productGroup: [],
    subproductGroup: [],
    userName: String(),
    report: {
      codProductFinal: null,
      codProductInitial: null,
      product_group_id: null,
      sub_group_product_id: null,
      product_type_id: null,
      currentbalance_equal: null,
      currentbalance_max: null,
      currentbalance_min: null,
      type_report: null,
      search_id: null,
    },
  }),
  methods: {
    async Generate() {
      if(this.companyGroup) {
        this.report.type_report = "COMPANYGROUP"
        this.report.search_id = this.selectCompanyGroup.id
      } else {
        this.report.type_report = "ENTERPRISE"
        this.report.search_id = this.selectValue.id
      }
      let all_report = Object.assign({}, this.report);
      const response = await axios.post("catalog/stock", {
        ...all_report,
      });

      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.selectValue);
      let objetoadress = Object.assign({}, this.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);
      this.createPDF(response.data[0], arrayenterprise, arrayaddress);
    },
    
    createPDF(items, enterprise, address) {
      let data = new Date();
      var doc = new jsPDF("p", "pt");
      var columns = [
        { title: "Código do Produto", dataKey: "product_id" },
        { title: "Descrição", dataKey: "description_item" },
        { title: "Unidade de Medida", dataKey: "unities_description" },
        { title: "Saldo Estoque", dataKey: "current_balance" },
      ];

      
      doc.setFontSize(7);
      doc.text(`Usuário : ${this.userName}`, 430, 40);
      doc.text(`${data.toLocaleString()}`, 430, 50);
      
      doc.setFontSize(15);
      if(this.companyGroup) {
        doc.text("RELATÓRIO DE SALDO ESTOQUE POR GRUPO DE EMPRESA", 80, 70);
        doc.setFontSize(12);
        doc.text(`${this.selectCompanyGroup.name}`, 200, 110);
      } else {
        doc.text("RELATÓRIO DE SALDO ESTOQUE POR EMPRESA", 130, 70);
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 140, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 430, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 200, 115);
        doc.text(`Cidade : ${address[0].city}`, 255, 115);
        doc.text(`Estado : ${address[0].state}`, 400, 115);
        doc.text(`CEP : ${address[0].cep}`, 500, 115);
      }
      doc.line(40, 85, 555, 85);
      doc.line(40, 125, 555, 125);
      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();

      doc.autoTable(columns, items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        startY: 155,
        theme: "grid",
      });
      
      const pages = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      for (let j = 1; j < pages + 1; j++) {
        doc.setLineWidth(1);
        doc.rect(20, 20, 555, 800);
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 550, 35);
      }
      
      doc.output("dataurlnewwindow");
      //doc.save(pdfName + ".pdf");
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      //await dispatchGetAll(this, GET_ALL_PRODUCT, page);
      await this.$store.dispatch(GET_ALL_SUB_PRODUCT_GROUP, value);
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      await this.$store.dispatch(GET_ALL_COMPANY_GROUP, value);
    },

    changeSelect() {
      this.companyGroup = !this.companyGroup
    }
  },

  computed: {
    ...mapGetters([
      "getProduct",
      "getSubProductGroup",
      "getProductGroup",
      "getEnterprises",
      "getCompanygroup",
    ]),
    ...mapGetters({ userLogin: "auth/userLogin" }),
  },

  created() {
    this.GetAllNoPage();
    this.userName = this.userLogin.user.name;
  },

  watch: {
    getCompanygroup(val) {
      this.companyGroups = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.companyGroups[0]);
      this.selectCompanyGroup = aux
    },

    getEnterprises(val) {
      this.enterprise = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = aux;
    },

    getProductGroup(val) {
      this.productGroup = Object.keys(val).map((key) => val[key]);
    },

    getSubProductGroup(val) {
      this.subproductGroup = Object.keys(val).map((key) => val[key]);
    },
  },
};
</script>

<style>
</style>