<template>
  <v-row>
    <v-card-title primary-title>Plano de Contas</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        flat
        height="10px"
        label="Empresa"
        return-object
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <v-btn
      v-if="component.permission.Cadastrar"
      color="success mr-2 mt-3"
      @click="
        () => {
          executeAlterAddDialog();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    component: Object,
  },
  data: () => ({
    enterprises: Array(),
    selectValue: Object()
  }),

  methods: {
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    executeAlterAddDialog() {
      this.$store.dispatch("setShowDialogRegisterAccountPlan",true)
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
    async getAll(page = 1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: false,
      });
    },
  },
  created() {
    this.getAll();
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val.data);
      this.selectValue = this.enterprises[0];
    },

    selectValue: function(val) {
      this.component.enterpriseId = val.id
    }
  },
};
</script>

<style></style>
