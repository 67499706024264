import axios from "axios";
import { SET_ITEMS_NOTE } from "../actions/import_xml";
import { returnArrayToRegister } from "./assistant_functions/item_note_assistant_functions";

export default {
  state: {
    Items_note: Array(),
    Items_tax: Array(),
  },
  getters: {
    getItemNotes: function (state) {
      return state.Items_note;
    },
    getItemTax: function (state) {
      return state.Items_tax
    }
  },
  mutations: {
    SET_ITEMS_NOTE: function (state, payload) {
      // state.Items_note = payload;
      state.Items_note = payload.finalArray;
      state.Items_tax = payload.taxArray;

    },
  },
  actions: {
    SET_ITEMS_NOTE: function (context, payload) {
      var x = 0;
      try {
        if (x == 1) {
          //dados mockados
          //escrito errado
          payload.arrayProducts[0].isResgitered = false;
          payload.arrayProducts[1].isResgitered = true;
          payload.arrayProducts[2].isResgitered = true;
          context.commit(SET_ITEMS_NOTE, payload);
        } else {
          
          let arrayManufacturedCode = Array();
          payload.arrayProducts.map((e) => {
            arrayManufacturedCode.push(e.manufacturer_code);
          });


          axios
            .post("product/getManufactureProductCode", {
              codesManufactures: arrayManufacturedCode,
              enterprise_id: payload.enterprise_id,
            })
            .then((response) => {
              var finalArray = returnArrayToRegister(
                payload,
                arrayManufacturedCode,
                response.data[0],
              );
              
              context.commit(SET_ITEMS_NOTE, { finalArray, taxArray: payload.arrayTax });
              // context.commit(SET_ITEMS_NOTE, finalArray);
              arrayManufacturedCode = Array();
            });
        }
      } catch (error) {
        return error;
      }
    },
  },
};
