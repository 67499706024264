<template>
  <v-container>
    <div>
      <v-form ref="form" :disabled="isDisable" v-model="validationForm">
        <v-row>
          <v-col>
            <v-select label="*Empresa Emitente" item-text="fantasyName" return-object outlined
              append-outer-icon="mdi-plus-circle" @click:append-outer="showDialog('enterprisedialog')"
              :items="enterprises" :rules="[() => !!component.form.enterprise_id || 'Campo obrigatório']" v-model="component.form.enterprise_id">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="*Data de Emissão" placeholder="null" type="Date" v-model="component.form.date_of_issue"
              outlined :rules="[
                () => !!component.form.date_of_issue || 'Campo Obrigatorio',
              ]"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-select label="*Tipo de Nota" v-model="component.form.type_grade_id" :items="getModeItens"
              item-text="description" item-value="id" :rules="[() => !!component.form.type_grade_id || 'Campo obrigatório']" outlined></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-autocomplete outlined label="*Finalidade da Nota" item-text="text" item-value="value"
              :items="finality_note" v-model="component.form.finality_nf" :rules="[() => !!component.form.finality_nf || 'Campo obrigatório']">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" xl="3" lg="3">
            <v-autocomplete outlined item-text="description" item-value="id" label="*Tipo de Operação" return-object
              append-outer-icon="mdi-plus-circle"  :rules="[() => !!component.form.tax_operation_type || 'Campo obrigatório']" @click:append-outer="showDialog('taxoperationtype')"
              :items="taxOperationItens" v-model="component.form.tax_operation_type"
              @blur="openNfceDialog()"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="6" lg="6">
            <v-autocomplete label="*Cliente" v-model="description" :items="clients_items"
              append-outer-icon="mdi-plus-circle" @click:append-outer="showDialog('addclient')" item-text="name"
              return-object outlined :rules="[clientRule]" @blur="checkEmail()" ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="Número da Nota" disabled v-model="component.form.number_grade" outlined></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="Serie" disabled v-model="component.form.series" outlined></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field label="Chave de Acesso da NFE" v-model="component.form.access_key" disabled outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field label="Prot. Autorização de uso" v-model="component.form.authorization_protocol" disabled
              outlined></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field outlined label="Informações Adicionais" v-model="component.form.note"></v-text-field>
          </v-col>
        </v-row>
        <!-- <AddClient v-if="addDialogClient" :component="this" /> -->
        <AddClient :out_of_register="true" v-if="getShowDialogRegisterClient" />
        <EnterpriseDialog />
        <OperationTypeDialog />
        <NfceDialog :component="this" />
        <EmailClientDialog :component="this" v-if="clientEmailDialog" />
      </v-form>
    </div>
  </v-container>
</template>

<script>
// import AddClient from "./DialogClient/";
import AddClient from "../../../Client/AddDialog/index.vue";
import EmailClientDialog from "./ClientValidationDialog/index.vue";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../store/actions/tax_operation_types";
import {
  GET_ALL_NOTE_MODEL,
  GET_SEQUENCE_NFE,
} from "../../../../store/actions/note_model";
import { GET_ALL_CLIENT_TO_SELECT_FIELD } from "../../../../store/actions/client";
// import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";

import EnterpriseDialog from "../../../enterprise/AddDialog";
import OperationTypeDialog from "../../../tax_operation_type/AddDialog";
import NfceDialog from "./NfceDialog/index.vue";
import { GET_ALL_TAX_CUPOM, GET_FILTER_TAX_CUPOM } from "../../../../store/actions/taxCupom";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    client: Object,
    isDisable: Boolean,
  },
  components: {
    AddClient,
    EnterpriseDialog,
    OperationTypeDialog,
    NfceDialog,
    EmailClientDialog,
  },

  data: () => ({
    nfceDialog: Boolean(),
    nfceItems: Array(),
    currentPageNfce: Number(),
    lastPageNfce: Number(),
    searchNfce: String(),

    nfceHeaders: [
      { text: "Id", value: "id" },
      { text: "Status", value: "status" },
      { text: "Data da Venda", value: "data_sale" },
      { text: "Chave de Acesso", value: "access_key" },
      { text: "Número da Nota", value: "number_sale" },
      { text: "Cliente", value: "cliente.people.physicalpeople.name" },
      { text: "Vendedor", value: "seller.people.physicalpeople.name" },
      { text: "PDV", value: "pdv_id" },
      { text: "Valor da Nota", value: "value_total_note" },
    ],
    indexNewRegister: 0,
    addDialogClient: false,
    enterprises: Object(),
    item_typeoperation: Array(),
    description: String(),
    item_client: Array(),
    taxOperationItens: Array(),
    getModeItens: Array(),
    client_id: Object(),
    typeNote: Object(),
    clients_items: Array(),
    finality_note: [
      { text: "NFE-NORMAL", value: "1" },
      { text: "NFE-COMPLEMENTAR", value: "2" },
      { text: "NFE-AJUSTE", value: "3" },
      { text: "DEVOLUCAO PRODUTO", value: "4" },
    ],
    validationForm: false,
    clientEmailDialog: false,
  }),
  methods: {
    showDialog(dialog) {
      if (dialog == "taxoperationtype") {
        this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      } else if (dialog == "enterprisedialog") {
        this.$store.dispatch("setShowDialogRegisterEnterprise", true);
      } else if (dialog == "addclient") {
        this.$store.dispatch("setShowDialogRegisterClient", true);
      }
    },
    openNfceDialog() {
      const OPERATION_NFCE_CUPOM = 5929
      if (this.component.form.tax_operation_type.code == OPERATION_NFCE_CUPOM) {
        this.nfceDialog = true
      }
    },

    async operationOutTypeMovement() {
      this.item_typeoperation = await this.taxOperationItens.filter(
        (item) => item.type === "SAIDA"
      );
    },

    async GetAllOperationType() {
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetAllClient() {
      await this.$store.dispatch(GET_ALL_CLIENT_TO_SELECT_FIELD, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    name_client(value) {
      this.clients_items = value;
      this.clients_items.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
    async GetAllNoteModel() {
      await this.$store.dispatch(GET_ALL_NOTE_MODEL, {
        page: 0,
        limit: null,
        paginate: false,
      });
      this.component.form.number_grade = this.getModeItens.sequence;
      this.component.form.series = this.getModeItens.series;
    },
    // async GetAllEnterprise() {
    //   let page = 1;
    //   await this.$store.dispatch(GET_ALL_ENTERPRISE, {
    //     page: page,
    //     limit: 10,
    //     paginate: false,
    //   });
    // },
    getAllNfce(page) {
      const payload = {
        page: page,
        limit: 10,
        paginate: true,
      }
      this.$store.dispatch(GET_ALL_TAX_CUPOM, payload);
    },
    rowClickSelectTableNfce(item) {
      this.nfceDialog = false
      let date = this.convertDateFormat(item.data_sale);
      this.component.nfereferences.keyAcess = item.access_key
      this.component.nfereferences.ufIssuer = String(item.enterprise.address.my_city.state.id)
      this.component.nfereferences.yearAndMonth = date
      this.component.nfereferences.cnpjIssuer = item.enterprise.cnpj
      this.component.nfereferences.invoiceTemplate = "01"
      this.component.nfereferences.gradeSeries = item.series
      this.component.nfereferences.taxDocumentNumber =item.number_sale
      this.component.form.note =  "Emissão referente a NFCE " + item.access_key  +  " Número " + item.number_sale

    },
    convertDateFormat(inputDate) {
      var dateObject = new Date(inputDate);
      var year = dateObject.getFullYear().toString().slice(-2);
      var month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      var result = year + month;
      return result;
    },
    autocompleteNfce(search) {
      const payload = {
        search: search,
        limit: 10,
        page: 1,
        paginate: true
      }
      this.$store.dispatch(GET_FILTER_TAX_CUPOM, payload)
    },

    clientRule(v) {
      if (!this.description) {
        return 'Cliente é Obrigatório';
      }
      if (!this.description.people.contact[0].primaryEmail) {
        return 'Cliente Não Possuí Email Cadastrado';
      }
      return true;
    },

    checkEmail() {
      if(!this.description.people.contact[0].primaryEmail) {
        this.clientEmailDialog = true
      }
    }
  },
  watch: {
    validationForm: function (val) {
        this.component.validations[0] = val
        this.component.checkValidation()
    },
    getPhysicalClients: function () {
      this.GetAllClient();
    },
    getLegalClients: function () {
      this.GetAllClient();
    },
    getTaxOperationType: function (val) {
      this.taxOperationItens = Object.values(val).filter(
        (item) => item.type === "SAIDA"
      );
      this.component.form.typeOperation = { ...this.taxOperationItens };
    },

    "component.form.enterprise_id": function (val) {
      this.component.form.enterprise_nf = { ...val };
    },
    getNoteModel: function (val) {
      this.getModeItens = Object.values(val).filter(
        (item) => item.tax_document_type === "NF-e"
      );
      this.component.form.noteNf = { ...this.getModeItens };
    },
    getSelectClient: function (val) {
      this.name_client(val);
      if (this.indexNewRegister > 0) {
        this.description = val[val.length - 1];
      }
      this.indexNewRegister++;
    },
    description: function (val) {
      this.component.form.client_id = val.id;
      this.component.form.client = { ...val };
    },
    "component.form.type_grade_id": function (note_model_id) {
      this.$store.dispatch(GET_SEQUENCE_NFE, {
        id: note_model_id,
      });
    },
    getSequenceNfe: function (val) {
      this.component.form.number_grade = val[0].sequence;
      this.component.form.series = val[0].series;
    },
    // getEnterprises(val) {
    //   this.enterprises = Object.values(val.data);
    //   this.selectValue = this.enterprises[0];
    // },
    getListTaxCupom(val) {
      this.nfceItems = val.data
      this.currentPageNfce = val.current_page
      this.lastPageNfce = val.last_page
    },
    currentPageNfce: function (val) {
      this.getAllNfce(val)
    },
    searchNfce: function (val) {
      if (val == "") {
        this.getAllNfce(1)
      } else {
        this.autocompleteNfce(val)
      }
    },
  },
  computed: {
    ...mapGetters([
      "getNoteModel",
      "getTaxOperationType",
      "getSequenceNfe",
      "getEnterprises",
      "getShowDialogRegisterClient",
      "getPhysicalClients",
      "getLegalClients",
      "getSelectClient",
      "getListTaxCupom",
    ]),
  },

  async created() {
    await this.GetAllOperationType();
    await this.GetAllNoteModel();
    await this.GetAllClient();
    // await this.GetAllEnterprise();
    this.getAllNfce(1),
      this.description = { ...this.client };
    
    this.enterprises = Object.values(this.getEnterprises.data);
  },
};
</script>

<style></style>
