import axios from "axios";

const {
    GET_ALL_PURCHASEORDER,
    // UPDATE_PURCHASEORDER,
    ADD_PURCHASEORDER,
    DESTROY_PURCHASEORDER,
    ITEM_TO_UPDATE
} = require("./actions/purchaseOrder");

const { SPLICE_ERRO } = require("./actions/alert_api");

const modulePurchaseOrder = {
    state: () => ({
        purchaseOrder: Object,
        itemToUpdatePurchaseOrder: Object(),
    }),

    mutations: {
        GET_ALL_PURCHASEORDER: (state, payload) => {
            state.purchaseOrder = payload;
        },
        ADD_PURCHASEORDER: (state, payload) => {
            state.purchaseOrder = payload
        },
        DESTROY_PURCHASEORDER: (state, payload) => {
            let auxState = [...state.purchaseOrder.data]
            let index = auxState.indexOf(auxState.find(value => value.id = payload))
            if (index >= 0) {
                auxState.splice(index, 1)
            }
            state.purchaseOrder = [...auxState]
        },
        async ITEM_TO_UPDATE(state, payload){
            state.itemToUpdatePurchaseOrder = payload
        }

    },

    actions: {
        async GET_ALL_PURCHASEORDER({ commit }, payload) {
            await axios.post("purchaseorder/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
                .then((response) => {
                    commit(GET_ALL_PURCHASEORDER, response.data[0]);
                }).catch(() => {
                    commit(SPLICE_ERRO, {
                        alert: 'Erro ao carregar os pedidos!',
                        type: 'E'
                    })
                })
        },
        async ADD_PURCHASEORDER({ commit }, payload) {
            await axios.post("purchaseorder/store", payload)
                .then((response) => {
                    commit(ADD_PURCHASEORDER, response.data)
                })
        },
        async DESTROY_PURCHASEORDER({ commit }, payload) {
            await axios.post("purchaseorder/delete", { id: payload })
                .then((response) => {
                    commit(DESTROY_PURCHASEORDER, payload)
                    commit(SPLICE_ERRO, {
                        alert: response.data.sucess.menssage[0],
                        type: 'S'
                    })
                }).catch((error) => {
                    commit(SPLICE_ERRO, {
                        alert: error.response.data.errors.message[0],
                        type: 'E'
                    })
                })
        },

        async ITEM_TO_UPDATE(context, payload){
            context.commit(ITEM_TO_UPDATE, payload)
        }


    },

    getters: {
        get_purchaseOrder: (state) => ({
            ...state.purchaseOrder
        }),
        getItemToUpdatePurchaseOrder: (state) => state.itemToUpdatePurchaseOrder
    },
};

export default modulePurchaseOrder;