



var Data = {
    search: "",
    getter: "getBank",
    addDialog: false,
    objectToUpdate: {},
    filter: String(),
    title: "Bancos",
    permission: Object(),
    headers: [
        { text: "Codigo", value: "code" },
        { text: "Descrição", value: "description" },
        { text: "Ações", value: "actions" }
    ]
}



export { Data }