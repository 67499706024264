<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="cardTitle" />
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="searchInput" label="Pesquisar" outlined append-icon="mdi-magnify" @keyup.enter="setAutocomplete()">
        </v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :getters="getMoveStock"
        :destroy_object="destroy_object"
        :headers="headers"
        :information="information"
      />
    </v-card>
    <AlertApi />
    <LoadScreen/>
    <ModalMoveStock :component="this" v-if="addDialog" />
  </Container>
</template>

<script>
// v-if="permission.Visualizar"
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/move_stock/CardTitle/CardTitle";
import Table from "../components/move_stock/Table/Table";
import ModalMoveStock from "../components/move_stock/AddDialog/ModalMoveStock";
import {
  GET_ALL_MOVESTOCK_BY_ENTERPRISE,
  DESTROY_ALL_MOVESTOCK,
  GET_AUTOCOMPLETE_MOVESTOCK_BY_ENTERPRISE,
} from "../store/actions/move_stock";
import { GET_ALL_TAX_OPERATION_TYPE } from "../store/actions/tax_operation_types";
import AlertApi from "../components/alert_api/alert_api";
import { mapGetters } from "vuex";
import { GET_ALL_PRODUCT } from "../store/actions/product";
import LoadScreen from "../components/load/index.vue"

export default {
  data: () => ({
    searchInput: String(),
    search: "",
    getter: "getMoveStock",
    taxOperationType: [],
    product: [],
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    permission: Object(),
    selectValue: "",
    title: "Movimentação Estoque",
    headers: [
      { text: "Id", value: "id" },
      { text: "Tipo de Operação Fiscal", value: "tax_type.description",},
      { text: "Data Movimentação", value: "date_movement" },
      { text: "Código Produto", value: "products.id" },
      { text: "Descrição", value: "products.description_item" },
      { text: "Código Fiscal", value: "cfop.code" },
      { text: "Saldo Movimentação", value: "value_movement" },
      { text: "Actions", value: "actions" },
    ],
  }),
  computed: {
    ...mapGetters([
      "getMoveStock",
      "getTaxOperationType",
      //"getEnterprises",
      "getProduct",
      "getMovimentacaoLinks",
    ]),
  },
  methods: {
    setAutocomplete() {
        this.search = this.searchInput
    },
    async destroy_object(item) {
      const stock = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_ALL_MOVESTOCK, stock);
      } catch (error) {
        alert("error", error);
      }
    },
    async information(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },

    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.selectValue.id
      };
      await this.$store.dispatch(GET_ALL_MOVESTOCK_BY_ENTERPRISE, value);
    },
    async GetAutocomplete(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        search: this.search,
        enterprise_id: this.selectValue.id
      };
      await this.$store.dispatch(GET_AUTOCOMPLETE_MOVESTOCK_BY_ENTERPRISE, value);
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, value);
      await this.$store.dispatch(GET_ALL_PRODUCT, value);
    },
  },

  created() {
    this.GetAllNoPage();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getMovimentacaoLinks.getPermissions(name);
  },
  components: {
    Container,
    CardTitle,
    Table,
    ModalMoveStock,
    AlertApi,
    LoadScreen,
  },
  watch: {
    getTaxOperationType(val) {
      this.taxOperationType = Object.keys(val).map((key) => val[key]);
    },
    getProduct(val) {
      this.product = Object.keys(val).map((key) => val[key]);
    },
    selectValue: function() {
      this.GetAll();
    },
    search: function(val) {
      if(val.length >= 2) {
        this.GetAutocomplete()
      }
    },
    searchInput: function(val) {
      if(val.length == 0) {
        this.search = ""
        this.GetAll()
      }
    }
  },
};
</script>

<style scoped></style>