<template>
  <div justify="center">
    <v-dialog v-model="this.component.infoDialog" max-width="1200" persistent>
      <v-card>
        <v-row>
          <v-card-title class="ml-7">Informações Saldo Estoque</v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon class="mr-8 mt-4">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-container>
            <v-divider></v-divider>
            <v-row class="mt-4">
                <v-col cols="12" xl="2" lg="2" md="6" sm="5" xs="6" class="ml-3">
                    <v-text-field label="Codigo do Produto" outlined v-model="this.component.itemToInfo.id" readonly></v-text-field>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="6" sm="5" xs="6">
                    <v-text-field label="Descrição do Produto" outlined v-model="this.component.itemToInfo.products.description_item" readonly></v-text-field>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="6" sm="5" xs="6" class="mr-n1">
                    <v-text-field label="Saldo Estoque" outlined v-model="this.component.itemToInfo.current_balance" readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xl="2" lg="2" md="6" sm="5" xs="6" class="ml-3">
                    <v-text-field label="Ncm - Code" outlined v-model="this.component.itemToInfo.products.ncm.code" readonly></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="5" xs="6" class="mr-n1">
                    <v-text-field label="Ncm - Descrição" outlined v-model="this.component.itemToInfo.products.ncm.description" readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xl="3" lg="3" md="6" sm="5" xs="6" class="ml-3">
                    <v-text-field label="Unidade de Medida - Sigla" outlined v-model="this.component.itemToInfo.products.unit.sigla" readonly></v-text-field>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="6" sm="5" xs="6" class="mr-n1">
                    <v-text-field label="Unidade de Medida - Descrição" outlined v-model="this.component.itemToInfo.products.unit.description" readonly></v-text-field>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    component: Object,
  },

  methods: {
    close() {
      this.component.infoDialog = !this.component.infoDialog;
    },

  },

};
</script>

<style>
</style>