import axios from "axios";

const {
  ADD_CFOP,
  UPDATE_CFOP,
  AUX_CFOP_TO_UPDATE,
  GET_ALL_CFOP,
  DESTROY_CFOP,
  GET_FILTER_CFOP
} = require("./actions/cfop");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCfop = {
  state: () => ({
    cfops: {},
    cfopToAdd: {},
    cfopToUpdate: {}
  }),

  mutations: {
    GET_ALL_CFOP: (state, cfops) => {
      state.cfops = cfops;
    },

    GET_FILTER_CFOP: (state, payload) => {
      state.cfops = payload
    },

    ADD_CFOP: (state, newCFOP) => {
      if (state.cfops.data) {
        
        //state.cfops.data.push(newCFOP)
        state.cfopToAdd = newCFOP
      } else {
        
        //state.cfops.push(newCFOP)
        state.cfopToAdd = newCFOP
      }
    },

    UPDATE_CFOP(state, editCFOP) {
      state.cfops.data.Update(editCFOP)
    },

    DESTROY_CFOP: (state, id) => {
      let auxState = [...state.cfops.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.cfops = [...auxState];
    },

    AUX_CFOP_TO_UPDATE: function (state, payload) {
      state.cfopToUpdate = payload
    }

  },
  actions: {
    async GET_ALL_CFOP({
      commit
    }, payload) {

      try {
        const response =  await axios.post("cfop/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate
        })
        
        commit(GET_ALL_CFOP, response.data[0]);
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
    },

    async GET_FILTER_CFOP({
      commit
    }, payload) {

      try {
        const response = await axios.post("cfop/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
        })
  
        commit(GET_FILTER_CFOP, response.data[0]);
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
    },

    ADD_CFOP({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("cfop/store", payload).then((response) => {
          resolve()
          commit(ADD_CFOP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastro adicionado com sucesso!",
            type: 'S'
          });

        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_CFOP({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("cfop/update", payload).then((response) => {
          resolve()
          commit(UPDATE_CFOP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualização realizada com sucesso!",
            type: 'S'
          });
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_CFOP({
      commit
    }, payload) {
      axios.post("cfop/delete", payload).then((response) => {
        commit(DESTROY_CFOP, response.data);
        commit(SPLICE_ERRO, {
          alert: "Item deletado com sucesso!",
          type: 'S'
        });
      })
    },

    AUX_CFOP_TO_UPDATE: function (contex, payload) {
      contex.commit(AUX_CFOP_TO_UPDATE, payload)
    }
  },


  getters: {
    getCfop: (state) => ({ ...state.cfops }),
    getCfopToUpdate: (state) => ({ ...state.cfopToUpdate }),
    getCfopToAdd: (state) => ({ ...state.cfopToAdd }),

  },
};

export default moduleCfop;