var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(this.component.addDialog),callback:function ($$v) {_vm.$set(this.component, "addDialog", $$v)},expression:"this.component.addDialog"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Movimentação Financeira")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-tabs',[_c('v-tab',{attrs:{"href":"#tab-1"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-card-account-details ")]),_vm._v(" Dados Gerais")],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cash-check")]),_vm._v(" Valores")],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-cash")]),_vm._v(" Contábil")],1),_c('v-tab',{attrs:{"href":"#tab-4"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-outline")]),_vm._v(" Anotações")],1),_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Descrição da Movimentação","readonly":_vm.justVisible,"outlined":"","rules":[
                      () =>
                        !!_vm.object.description_movement || 'Campo Obrigatorio',
                      () =>
                        (!!_vm.object.description_movement &&
                          _vm.object.description_movement.length >= 2) ||
                        'Campo tem que ser maior do que 2',
                    ]},model:{value:(_vm.object.description_movement),callback:function ($$v) {_vm.$set(_vm.object, "description_movement", $$v)},expression:"object.description_movement"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"*Tipo de Movimentação","readonly":_vm.justVisible,"items":_vm.type_m,"rules":[
                      () => !!_vm.object.type_movement || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.type_movement),callback:function ($$v) {_vm.$set(_vm.object, "type_movement", $$v)},expression:"object.type_movement"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"*Tipo da Caixa","readonly":_vm.justVisible,"items":_vm.cashier,"item-text":"description","item-value":"id","rules":[
                      () => !!_vm.object.id_cashier || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.id_cashier),callback:function ($$v) {_vm.$set(_vm.object, "id_cashier", $$v)},expression:"object.id_cashier"}})],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Valor da Movimentação","readonly":_vm.justVisible,"prefix":"R$","type":"number","rules":[
                      () => !!_vm.object.value_movement || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.value_movement),callback:function ($$v) {_vm.$set(_vm.object, "value_movement", $$v)},expression:"object.value_movement"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Saldo de Movimentação","readonly":_vm.justVisible,"prefix":"R$","type":"number","rules":[
                      () => !!_vm.object.balance_movement || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.balance_movement),callback:function ($$v) {_vm.$set(_vm.object, "balance_movement", $$v)},expression:"object.balance_movement"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Numero de Movimento","readonly":_vm.justVisible,"rules":[
                      () => !!_vm.object.number_movement || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.number_movement),callback:function ($$v) {_vm.$set(_vm.object, "number_movement", $$v)},expression:"object.number_movement"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Data de Movimentação","readonly":_vm.justVisible,"type":"date","rules":[
                      () => !!_vm.object.date_movement || 'Campo Obrigatorio',
                    ],"outlined":""},model:{value:(_vm.object.date_movement),callback:function ($$v) {_vm.$set(_vm.object, "date_movement", $$v)},expression:"object.date_movement"}})],1)],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"*Plano de Contas","readonly":_vm.justVisible,"items":_vm.account_plan,"item-value":"id","append-outer-icon":_vm.icons[0],"item-text":"description","outlined":"","rules":[
                      () => !!_vm.object.account_plans_id || 'Campo Obrigatorio',
                    ]},on:{"click:append-outer":_vm.showRegisterFinancialMoviment},model:{value:(_vm.object.account_plans_id),callback:function ($$v) {_vm.$set(_vm.object, "account_plans_id", $$v)},expression:"object.account_plans_id"}})],1)],1),_c('v-tab-item',{attrs:{"value":"tab-4"}},[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":"*Anotações","readonly":_vm.justVisible,"auto-grow":"","rules":[
                      () => !!_vm.object.annotation || 'Campo Obrigatorio',
                    ]},model:{value:(_vm.object.annotation),callback:function ($$v) {_vm.$set(_vm.object, "annotation", $$v)},expression:"object.annotation"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.changeDialog()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                    _vm.submit();
                  }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('AccountPlanDialog',{attrs:{"out_of_register":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }