<template>
  <v-container>
    <v-form ref="form" v-model="component.validContact">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="component.contact.primaryEmail"
            :readonly="component.justVisible"
            hint="exemplo@mail.com"
            :rules="emailRules"
            outlined
            label="E-mail"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="component.contact.secundaryEmail"
            :readonly="component.justVisible"
            :rules="emailRules"
            hint="exemplo@mail.com"
            outlined
            label="E-mail Secundário"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.contact.cellPhone"
            :readonly="component.justVisible"
            label="*Telefone Celular"
            :rules="textRules"
            hint="Exemplo: (12) 3 4567-8901"
            v-mask="'(##) # ####-####'"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.contact.phone"
            :readonly="component.justVisible"
            label="Telefone Residencial"
            hint="Exemplo: (12) 3456-7890"
            v-mask="'(##) ####-####'"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.contact.phoneReference"
            :readonly="component.justVisible"
            label="Telefone Referência"
            hint="Exemplo: (12) 3 4567-8901"
            v-mask="'(##) # ####-####'"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

    </v-form>
  </v-container>
</template>

<script>
import { emailRules } from "./script";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: Boolean(),
    textRules: [(v) => !!v || "Campo obrigatório!"],
    emailRules,
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
