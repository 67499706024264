const GET_ALL_FINANCIAL_MOVEMENTE = 'GET_ALL_FINANCIAL_MOVEMENTE'
const UPDATE_FINANCIAL_MOVEMENTE = 'UPDATE_FINANCIAL_MOVEMENTE'
const DESTROY_FINANCIAL_MOVEMENTE = 'DESTROY_FINANCIAL_MOVEMENTE'
const ADD_FINANCIAL_MOVEMENTE = 'ADD_FINANCIAL_MOVEMENTE'
const GET_ALL_FINANCIAL_MOVEMENTE_BY_ENTERPRISE = 'GET_ALL_FINANCIAL_MOVEMENTE_BY_ENTERPRISE'
const GET_AUTOCOMPLETE_FINANCIAL_MOVEMENTE_BY_ENTERPRISE = 'GET_AUTOCOMPLETE_FINANCIAL_MOVEMENTE_BY_ENTERPRISE'

/**Transfer Cash Register */

const GET_ALL_TRANSFER_CASHIER = 'GET_ALL_TRANSFER_CASHIER'
const ADD_TRANSFER_CASHIER = 'ADD_TRANSFER_CASHIER'
const UPDATE_TRANSFER_CASHIER = 'UPDATE_TRANSFER_CASHIER'
const DESTROY_TRANSFER_CASHIER = 'DESTROY_TRANSFER_CASHIER'



module.exports = {
    GET_ALL_FINANCIAL_MOVEMENTE,
    UPDATE_FINANCIAL_MOVEMENTE,
    DESTROY_FINANCIAL_MOVEMENTE,
    ADD_FINANCIAL_MOVEMENTE,
    GET_ALL_FINANCIAL_MOVEMENTE_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_FINANCIAL_MOVEMENTE_BY_ENTERPRISE,

    /**Transfer Cash Register */

    GET_ALL_TRANSFER_CASHIER,
    ADD_TRANSFER_CASHIER,
    UPDATE_TRANSFER_CASHIER,
    DESTROY_TRANSFER_CASHIER,
}