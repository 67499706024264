
import Axios from 'axios'

const {
    GET_ALL_VENDAS,
    GET_SALE_STATS_REPORT,
} = require('./actions/vendas')
const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleVendas = {
    state: {
        vendas: {},
        salesGetSaleStatsReport: Object(),
    },
    mutations: {
        GET_ALL_VENDAS(state, payload) {
            state.vendas = payload
        },

        GET_SALE_STATS_REPORT (state, payload){
            state.salesGetSaleStatsReport = payload
          },
    },
    actions: {
        GET_ALL_VENDAS(context, payload) {
            Axios.post('sales/index', { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                context.commit(GET_ALL_VENDAS, response.data[0])
            })
        },

        async GET_SALE_STATS_REPORT({ commit }, payload) {
            return new Promise((resolve, reject) => {
              Axios.post("sales/salesStatisticsReport", payload)
              .then((response) => {
                resolve({ response })
                commit(GET_SALE_STATS_REPORT, response.data)
              }).catch((error) => {
                commit(SPLICE_ERRO, {
                  alert: "Não foi possivel gerar Relatório",
                  type: 'E'
                })
              })
            })
          },
    },
    getters: {
        getterVendas(state) {
            return state.vendas
        }
    }
}

export default moduleVendas
