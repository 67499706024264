import axios from "axios";

const {
  ADD_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  GET_ALL_PRODUCT_GROUP,
  DESTROY_PRODUCT_GROUP,
  GET_FILTER_PRODUCT_GROUP,
  AUX_TO_UPDATE_PRODUCT_GROUP,
} = require("./actions/productgroup");

const {
  SPLICE_ERRO
} = require('./actions/alert_api');

const moduleProductGroup = {
  state: () => ({
    objectToUpdate: {},
    productgroups: {},
    newProductGroup: {},
    deletedProductGroupId: {}
  }),

  mutations: {
    AUX_TO_UPDATE_PRODUCT_GROUP: function (state, payload) {
      state.objectToUpdate = payload
    },
    GET_ALL_PRODUCT_GROUP: (state, productGroups) => {
      state.productgroups = productGroups;
    },
    GET_FILTER_PRODUCT_GROUP: (state, payload) => {
      if (payload !== undefined) {
        state.productgroups = payload
      }
    },
    ADD_PRODUCT_GROUP: (state, newProductGroup) => {
      // state.productgroups = newProductGroup;
      state.newProductGroup = newProductGroup;
    },
    UPDATE_PRODUCT_GROUP(state, updateProductGroup) {
      state.productgroups.data.Update(updateProductGroup)
    },

    DESTROY_PRODUCT_GROUP: (state, id) => {
      state.deletedProductGroupId = id
      // let auxState = [...state.productgroups.data];
      // let index = auxState.indexOf(
      //   auxState.find(value => value.id === id),
      // );
      // if (index >= 0) {
      //   auxState.splice(index, 1);
      // }
      // state.productgroups = [...auxState];
    },
  },

  actions: {
    AUX_TO_UPDATE_PRODUCT_GROUP: function (context, payload) {
      context.commit(AUX_TO_UPDATE_PRODUCT_GROUP, payload)
    },
    async GET_ALL_PRODUCT_GROUP({
      commit
    },
      payload
    ) {
      try {
        const response = await axios.post("productGroup/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate
        })
        commit(GET_ALL_PRODUCT_GROUP, response.data[0]);
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
    },

    GET_FILTER_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.post("productGroup/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_PRODUCT_GROUP, response.data[0]);
      })
    },

    ADD_PRODUCT_GROUP({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/productGroup/store", payload).then((response) => {
          resolve()
          commit(ADD_PRODUCT_GROUP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastro com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_PRODUCT_GROUP({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("productGroup/update", payload).then((response) => {
          resolve()
          commit(UPDATE_PRODUCT_GROUP, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    async DESTROY_PRODUCT_GROUP({
      commit
    }, payload) {

      try {
        const response = await axios
        .post("productGroup/delete", payload)
        
        commit(DESTROY_PRODUCT_GROUP, payload.id);
         commit(SPLICE_ERRO, {
           alert: 'Deletado com Sucesso',
           type: 'S'
         })

        const responsePayload = {
          id: payload.id,
          code : response.data.code
        }

        return responsePayload

      } catch (error) {
        throw new Error(error)
      }

    },
  },

  getters: {
    getObjectToUpdate: (state) => ({
      ...state.objectToUpdate
    }),
    getProductGroup: (state) => ({ ...state.productgroups }),
    getNewProduct: (state) => ({ ...state.newProductGroup }),
    getDeletedProductGroupId: (state) => state.deletedProductGroupId
  },
};

export default moduleProductGroup;