const ADD_CSOSN = "ADD_CSOSN";
const UPDATE_CSOSN = "UPDATE_CSOSN";
const GET_ALL_CSOSN = "GET_ALL_CSOSN";
const DESTROY_CSOSN = "DESTROY_CSOSN";
const GET_FILTER_CSOSN = "GET_FILTER_CSOSN";
const AUX_TO_UPDATE_CSOSN = "AUX_TO_UPDATE_CSOSN"

module.exports = {
    AUX_TO_UPDATE_CSOSN,
    ADD_CSOSN,
    UPDATE_CSOSN,
    GET_ALL_CSOSN,
    DESTROY_CSOSN,
    GET_FILTER_CSOSN
}
