import axios from "axios";

const {
  GET_ALL_PROFESSION,
  ADD_PROFESSION,
  UPDATE_PROFESSION,
  DESTROY_PROFESSION,
  GET_FILTER_PROFESSION
} = require("./actions/profession");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleProfession = {
  state: () => ({
    profession: {},
    updatedProfession: Object()
  }),

  mutations: {
    GET_ALL_PROFESSION: (state, payload) => {
      state.profession = payload;
    },

    GET_FILTER_PROFESSION: (state, payload) => {
      state.profession = payload
    },

    ADD_PROFESSION: (state, payload) => {
      state.profession = payload;
    },

    UPDATE_PROFESSION: (state, editProfession) => {
      //state.profession.data.Update(editProfession)
      state.updatedProfession = editProfession
    },

    DESTROY_PROFESSION: (state, id) => {
      let auxState = [...state.profession.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.profession = [...auxState];
    }
  },

  actions: {
    async GET_ALL_PROFESSION({ commit }, payload) {
      const response = await axios.post("profession/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
      
      commit(GET_ALL_PROFESSION, response.data[0]);

      return response.data[0]
    },

    GET_FILTER_PROFESSION({
      commit
    }, payload) {
      axios.get("profession/autocomplete", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
        }
      }).then((response) => {
        commit(GET_FILTER_PROFESSION, response.data[0]);
      })
    },

    ADD_PROFESSION({ commit }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("profession/store", payload).then((response) => {
          resolve()
          commit(ADD_PROFESSION, response.data);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })
      })
    },

    UPDATE_PROFESSION({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("profession/update", payload).then((response) => {
          resolve()
          commit(UPDATE_PROFESSION, payload);
          // commit(UPDATE_PROFESSION, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          });
        }).catch(() => {
          reject()
        })
      })
    },

    async DESTROY_PROFESSION({ commit }, payload) {
      axios.post('profession/delete', payload).then((response) => {
        commit(DESTROY_PROFESSION, payload.id);
        commit(SPLICE_ERRO, {
          alert: "Deletado com sucesso",
        });
      })
    },

  },

  getters: {
    get_profession: (state) => ({
      ...state.profession
    }),
    getUpdatedProfession: (state) => state.updatedProfession
  },
};

export default moduleProfession;