import axios from "axios";

const {
  ADD_BARCODE,
  UPDATE_BARCODE,
  GET_ALL_BARCODE,
  DESTROY_BARCODE,
  GET_FILTER_BARCODE,
  AUX_TO_UPDATE_BARCODE
} = require("./actions/barcode");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleBarcode = {
  state: () => ({
    barcodes: {},
    auxToUpdateBarcode: Object(),
  }),

  data: () => {
    return {
      getter: "getProduct",
    };
  },

  mutations: {
    GET_ALL_BARCODE: (state, barcodes) => {
      state.barcodes = barcodes;
    },
    GET_FILTER_BARCODE: (state, payload) => {
      state.barcodes = payload;
    },
    ADD_BARCODE: (state, newBarcodes) => {
      state.barcodes = newBarcodes;
    },
    UPDATE_BARCODE(state, editBarcode) {
      state.barcodes.data.Update(editBarcode);
    },
    DESTROY_BARCODE: (state, id) => {
      let auxState = [...state.barcodes.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.barcodes = [...auxState];
    },

    AUX_TO_UPDATE_BARCODE: function (state, payload) {
      state.auxToUpdateBarcode = payload
    }
  },

  actions: {
    GET_ALL_BARCODE({ commit }, payload) {
      axios
        .post("/barCode/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_ALL_BARCODE, response.data[0]);
        });
    },

    GET_FILTER_BARCODE({ commit }, payload) {
      axios
        .post("barCode/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_FILTER_BARCODE, response.data[0]);
        });
    },

    ADD_BARCODE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("barCode/store", payload)
          .then((response) => {
            resolve();
            commit(ADD_BARCODE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_BARCODE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("barCode/update", payload)
          .then((response) => {
            resolve();
            commit(UPDATE_BARCODE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualização realizada com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    async DESTROY_BARCODE({ commit }, payload) {
      axios.post("barCode/delete", payload).then((response) => {
        commit(DESTROY_BARCODE, response.data);
        commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
        });
      });
    },
    AUX_TO_UPDATE_BARCODE: function (context, payload) {
      context.commit(AUX_TO_UPDATE_BARCODE, payload)
    }
  },

  getters: {
    getBarcode: (state) => ({ ...state.barcodes }),

    getAuxBarcodeToUpdate: (state) => state.auxToUpdateBarcode
  },
};

export default moduleBarcode;
