<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.dialog" max-width="600">
      <v-card>
        <v-card-title primary-title> Administradoras de Cartão </v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Código"
                v-model="card_administrator.code"
                :readonly="justVisible"
                outlined
                :rules="[
                  () => !!card_administrator.code || 'Campo Obrigatório',
                  () =>
                    (!!card_administrator.code &&
                      card_administrator.code.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Descrição"
                v-model="card_administrator.description"
                :readonly="justVisible"
                :rules="[
                  () => !!card_administrator.description || 'Campo Obrigatorio',
                  () =>
                    (!!card_administrator.description &&
                      card_administrator.description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                label="Habilitar?"
                v-model="card_administrator.status"
                :readonly="justVisible"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field
                label="*Administradoras"
                v-model="card_administrator.managers"
                :readonly="justVisible"
                :rules="[
                  () => !!card_administrator.managers || 'Campo Obrigatorio',
                ]"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Dias de crédito"
                v-model="card_administrator.credit_days"
                :readonly="justVisible"
                type="number"
                :rules="[
                  () => !!card_administrator.credit_days || 'Campo Obrigatorio',
                ]"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Taxa administrativa"
                type="number"
                v-model="card_administrator.administrate_rate"
                :readonly="justVisible"
                :rules="[
                  () =>
                    !!card_administrator.administrate_rate ||
                    'Campo Obrigatorio',
                ]"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Taxa de Antecipação"
                v-model="card_administrator.anteciption_rate"
                :readonly="justVisible"
                type="number"
                :rules="[
                  () =>
                    !!card_administrator.anteciption_rate ||
                    'Campo Obrigatorio',
                ]"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                label="Tipo de uso"
                :items="items_list"
                v-model="card_administrator.use_type"
                :readonly="justVisible"
                outlined
                solo
              >
              </v-select>
            </v-col>
            <v-divider></v-divider>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cancel()">
            <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
          >
          <v-btn color="success" :disabled="!valid" v-if="!justVisible" @click="submit()">
            <v-icon class="mr-1">mdi-content-save</v-icon> Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_CARD_ADM, UPDATE_CARD_ADM, AUX_TO_UPDATE_CARD_ADM } from "../../../store/actions/card_adm";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    objectToUpdate: Object,
  },
  data: () => ({
    valid: Boolean(),
    justVisible: Boolean(),
    card_administrator: {
      code: String(),
      description: String(),
      status: Number(),
      managers: String(),
      credit_days: Number(),
      administrate_rate: Number(),
      anteciption_rate: Number(),
      use_type: String,
    },
    items_list: ["TEF", "POS"],
  }),
  methods: {
    adjustCardAdm(newCardAdm) {
      if (newCardAdm.status) {
        newCardAdm.status = "HABILITADO";
      } else {
        newCardAdm.status = "DESABILITADO";
      }
      newCardAdm.credit_days = parseFloat(newCardAdm.credit_days);
      newCardAdm.administrate_rate = parseFloat(newCardAdm.administrate_rate);
      newCardAdm.anteciption_rate = parseFloat(newCardAdm.anteciption_rate);

      return { ...newCardAdm };
    },

    submit() {
      let newCardAdm = this.adjustCardAdm(this.card_administrator);
      newCardAdm.id === undefined
        ? this.createCardAdm(newCardAdm)
        : this.updateCardAdm(newCardAdm);
    },

    cancel() {
      this.justVisible = false
      this.$store.dispatch(AUX_TO_UPDATE_CARD_ADM, Object())
      this.component.active_add()
    },

    async createCardAdm(item) {
      await this.$store.dispatch(ADD_CARD_ADM, item).then(() => {
        this.$refs.form.reset()
        this.component.active_add();
      });
    },
    async updateCardAdm(item) {
      await this.$store.dispatch(UPDATE_CARD_ADM, item).then(() => {
        this.component.active_add();
        this.$refs.form.reset()
      });
    },

    autoload(payload) {
      if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.card_administrator.code = payload.item.code;
        this.card_administrator.description = payload.item.description;
        this.card_administrator.status = payload.item.status;
        this.card_administrator.managers = payload.item.managers;
        this.card_administrator.credit_days = payload.item.credit_days;
        this.card_administrator.administrate_rate = payload.item.administrate_rate;
        this.card_administrator.anteciption_rate = payload.item.anteciption_rate;
        this.card_administrator.use_type = payload.item.use_type;
      } else{
        this.card_administrator = payload;
      }
    }
  },

  computed: {
    ...mapGetters(["getAuxCardAdmToUpdate"]),
  },

  created() {
    this.autoload(this.getAuxCardAdmToUpdate)
    // if (this.objectToUpdate) {
    //   this.card_administrator = { ...this.objectToUpdate };
    // }
  },
};
</script>

<style>
</style>