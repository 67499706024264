


var Data = {
    attrs: false,
    on: false,
    valid: true,
    id: "",
    description: String(),
    code: Number(),
    search: "",
    justVisible: Boolean(),
}


export { Data }