var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.component.valid),callback:function ($$v) {_vm.$set(_vm.component, "valid", $$v)},expression:"component.valid"}},[_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Cod. Cliente Inicial","type":"number","rules":[
          () => !!_vm.component.report.codClientInitial || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.codClientInitial),callback:function ($$v) {_vm.$set(_vm.component.report, "codClientInitial", $$v)},expression:"component.report.codClientInitial"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Cod. Cliente Final","type":"number","rules":[
          () => !!_vm.component.report.codClientFinal || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.codClientFinal),callback:function ($$v) {_vm.$set(_vm.component.report, "codClientFinal", $$v)},expression:"component.report.codClientFinal"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","xl":"2","lg":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.peopleTypes,"item-text":"text","deletable-chips":"","item.value":"value","outlined":"","label":"Tipo de Pessoa","rules":[
          () => !!_vm.component.report.type_people || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.type_people),callback:function ($$v) {_vm.$set(_vm.component.report, "type_people", $$v)},expression:"component.report.type_people"}})],1),(_vm.showDateInput)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","xl":"2","lg":"2"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Data de Nascimento","type":"date"},model:{value:(_vm.component.report.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.component.report, "dateOfBirth", $$v)},expression:"component.report.dateOfBirth"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"CEP"},model:{value:(_vm.component.report.cep),callback:function ($$v) {_vm.$set(_vm.component.report, "cep", $$v)},expression:"component.report.cep"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.initials,"item-text":"text","deletable-chips":"","return-object":"","outlined":"","label":"Estado"},model:{value:(_vm.component.oldState),callback:function ($$v) {_vm.$set(_vm.component, "oldState", $$v)},expression:"component.oldState"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.component.cities,"deletable-chips":"","item-text":"nome","outlined":"","item-value":"nome","label":"Cidade"},model:{value:(_vm.component.new_city),callback:function ($$v) {_vm.$set(_vm.component, "new_city", $$v)},expression:"component.new_city"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }