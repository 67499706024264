import { CHANGE_LOAD } from "../../../store/actions/load";
import { GET_ALL_CFOP, DESTROY_CFOP } from "../../../store/actions/cfop";
import { GET_FILTER_CFOP } from "../../../store/actions/cfop";





async function destroy_cfop(item) {
    const car_adm = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_CFOP, car_adm);
    } catch (error) {
        alert(error);
    }
}
function open_Dialog(item) {
    this.addDialogCfop = !this.addDialogCfop;
    this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    this.$store.commit(CHANGE_LOAD, true);
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };

    await this.$store.dispatch(GET_ALL_CFOP, value);
}
async function cfop_filter(page = 1) {
        let value = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_CFOP, value);
}

function autocompleteCfop() {
    this.search = this.searchInput
  }



export default {
    destroy_cfop,
    open_Dialog,
    GetAll,
    cfop_filter,
    autocompleteCfop,
}