import axios from "axios"

const {
    GET_ALL_BOOKSUPERVISION_OUT,
    GET_ALL_BOOKSUPERVISION_OUT_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_BOOKSUPERVISION_OUT_BY_ENTERPRISE
} = require("./actions/book_supervision_out");

const moduleBookSupervisionOut = {
    state: () => ({
        booksupervisionouts: Object()
    }),

    mutations:{
        GET_ALL_BOOKSUPERVISION_OUT: (state, payload) => {
            state.booksupervisionouts = payload
        },
        GET_ALL_BOOKSUPERVISION_OUT_BY_ENTERPRISE: (state, payload) => {
            state.booksupervisionouts = payload
        },
        GET_AUTOCOMPLETE_BOOKSUPERVISION_OUT_BY_ENTERPRISE: (state, payload) => {
            state.booksupervisionouts = payload
        },
    },

    actions:{
        async GET_ALL_BOOKSUPERVISION_OUT({
            commit
        }, payload){
            await axios.post("booksupervisionout/index",{
                page: payload.page,
                limit: payload.limit,
                paginate: payload.paginate
            }).then((response) => {
                commit(GET_ALL_BOOKSUPERVISION_OUT, response.data[0])
            })
        },

        async GET_ALL_BOOKSUPERVISION_OUT_BY_ENTERPRISE({
            commit
        }, payload){
            await axios.post("booksupervisionout/getAllByEnterprise",{
                page: payload.page,
                limit: payload.limit,
                paginate: payload.paginate,
                enterprise_id: payload.enterprise_id
            }).then((response) => {
                commit(GET_ALL_BOOKSUPERVISION_OUT_BY_ENTERPRISE, response.data)
            })
        },

        async GET_AUTOCOMPLETE_BOOKSUPERVISION_OUT_BY_ENTERPRISE({
            commit
        }, payload){
            await axios.post("booksupervisionout/autocompleteByEnterprise",{
                page: payload.page,
                limit: payload.limit,
                paginate: payload.paginate,
                search: payload.search,
                enterprise_id: payload.enterprise_id
            }).then((response) => {
                commit(GET_AUTOCOMPLETE_BOOKSUPERVISION_OUT_BY_ENTERPRISE, response.data)
            })
        },
    },

    getters:{
        getBookSupervisionOut: (state) => ({ ...state.booksupervisionouts })
    }
}

export default moduleBookSupervisionOut;