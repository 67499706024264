<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompletePisCofins()"
        ></v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getPisCofins"
        :destroy_pisCofins="destroy_pisCofins"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <ModalPisCofins :component="this" @close="addDialogPisCofins = $event" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/pis_cofins/CardTitle/index";
import Table from "../components/pis_cofins/Table/index";
import ModalPisCofins from "../components/pis_cofins/AddDialog/index";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";
import UiBusinessRules from "../UIBusinessRule/Pis_Confins/View";
import { mapGetters } from "vuex";
// import { GET_FILTER_PIS_COFINS } from "../store/actions/pis_cofins";
// import {
//   GET_ALL_PIS_COFINS,
//   DESTROY_PIS_COFINS,
// } from "../store/actions/pis_cofins";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    getter: "getPisCofins",
    addDialogPisCofins: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: Object(),
    filter: "",
    title: "PIS/COFINS",
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" }
    ],
    permission: Object()
  }),

  methods: {
    ...UiBusinessRules
  },

  async created() {
    this.GetAll();
  },

  components: {
    Container,
    CardTitle,
    Table,
    ModalPisCofins,
    AlertApi,
    loadScreen
  },

  computed: {
    ...mapGetters(["getPisCofins", "getCadastrosLinks"])
  },

  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.piscofins_filter()
      }
    }
  },

  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  }
};
</script>

<style scoped></style>