<template>
  <v-app>
    <Struct v-show="isValid" />
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer v-if="isValid" class="mt-5 success white--text">
      <v-col cols="12" class="text-center">
        Copyright &copy; {{ new Date().getFullYear() }} -
        <strong>Top Way</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Struct from "./components/Struct/index.vue";
export default {
  components: {
    Struct,
  },

  data: () => ({
    isValid: Boolean(),
  }),
  updated() {
    this.isValid = this.$store.getters["auth/getisAuth"];
  },
  mounted() {
    this.isValid = this.$store.getters["auth/getisAuth"];
  },
  computed: {
    ...mapGetters(["auth/getisAuth"]),
  },
};
</script>

<style>
</style>

///* STLYES GLOBAIS asdasd */

/* STLYES GLOBAIS */

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

th.text-start {
  font-size: 15px !important;
  font-family: "Mulish", sans-serif !important;
}