


const headers = [
    { text: "Código", value: "id" },
    { text: "Descrição da Movimentação", value: "description_movement" },
    { text: "Caixa", value: "cashier.description" },
    { text: "Tipo de Movimentação", value: "type_movement" },
    { text: "Anotação", value: "annotation" },
    { text: "Numero de Movimento", value: "number_movement" },
    { text: "Data de Movimentação", value: "date_movement" },
    { text: "Plano de contas", value: "account_plan.description" },
    { text: "Saldo de Movimento", value: "value_movement" },
    { text: "Ações", value: "actions" },
]

export default headers