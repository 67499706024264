<template>
  <v-row>
    <v-dialog
      v-model="this._props.component.dialog_delete"
      transition="fab-transition"
      max-width="400"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title> Deseja excluir este item? </v-card-title>
          <v-card-subtitle
            >Esta ação irá deletar o Tipo de Comissão</v-card-subtitle
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="
                () => {
                  cancel();
                }
              "
              >Cancelar</v-btn
            >
            <v-btn color="success" @click="confirm">Confirmar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DESTROY_COMISSION_TYPE } from "../../../../store/actions/commission_type";
export default {
  props: { component: Object() },
  methods: {
    cancel() {
      this._props.component.dialog_delete = !this._props.component
        .dialog_delete;
    },
    confirm() {
      let id = this.component.item_delete.id;
      this.$store.dispatch(DESTROY_COMISSION_TYPE, id);
      this.cancel();
    },
  },
};
</script>
