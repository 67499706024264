import axios from "axios";
import apifiscal from "../apifiscal";
const {
  SPLICE_ERRO
} = require("../store/actions/alert_api");

export default {
  namespaced: true,
  state: {
    token: null,
    token_fiscal: null,
    isAuth: Boolean(),
    user: null,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_TOKEN_FISCAL(state, token_fiscal) {
      if (token_fiscal) {
        state.token_fiscal = token_fiscal;
        localStorage.setItem("token_fiscal", token_fiscal);
      } else {
        localStorage.removeItem("token_fiscal");
        state.token_fiscal = null;
      }
    },

    SET_ISVALID(state, token) {
      state.isAuth = token;
    },

    SET_USER(state, data) {
    
      state.user = data || {
        user: {}
      };
      if (data) {
        let hasExpired = Object.prototype.hasOwnProperty.call(data, 'status')
        if (hasExpired) {
          localStorage.removeItem('token')
          localStorage.removeItem('authenticated')
        } else {
          localStorage.setItem('authenticated', JSON.stringify(data))
        }
      }

    },
  },

  actions: {
    async login({
      dispatch,
      commit
    }, credentials) {
      try {
        const response = await axios.post("login", credentials);
        return dispatch("attempt", response.data.token);
      } catch (error) {
        let erros = error.response.data.errors;
        //    for (let erro in erros) {
        commit(
          SPLICE_ERRO, {

          alert: erros,
          type: "E",
        }, {
          root: true,
        }
        );

      }
    },
    loginApiFiscal({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        apifiscal
          .post("login", credentials)
          .then((response) => {
            localStorage.setItem("token_fiscal", response.data.token);
            commit("SET_TOKEN_FISCAL", response.data.token);

            resolve("success");
          })
          .catch(({ response, message }) => {
            let erros;
            erros =
              message == "invalid_user"
                ? "Usuario esta Inativo"
                : response.data.errors;
            commit(SPLICE_ERRO, { alert: erros, type: "E" }, { root: true });
            reject("failed");
          });
      });
    },
    async attempt({
      commit,
      state
    }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      try {
        const response = await axios.get("user");
        commit("SET_USER", response.data);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async logOut({
      commit
    }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      localStorage.removeItem('token')
      localStorage.removeItem('authenticated')

      window.location.href = "/"
    },
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    userLogin: (state) => state.user || {},
    getisAuth: (state) => state.isAuth,
    user(state) {
      return state.user?.user
    },
    // user: (state) => state.user || {},
    getUsers: (state) => state.user,
  },
};