async function calculeTributeIpiIn(productInvoiceEntry, cst_ipi_code, aliquot_ipi) {
    try {
      let productTributeIpi = classificationCstIpi(
        productInvoiceEntry,
        cst_ipi_code,
        aliquot_ipi,
      );
  
      return productTributeIpi;
    } catch (error) {
      return error;
    }
  }
  
  async function classificationCstIpi(
    productInvoiceEntry,
    cst_ipi_code,
    aliquot_ipi
  ) {
    if (cst_ipi_code >= 0 || cst_ipi_code <= 55) {
      return _normal_Ipi_Calculation(productInvoiceEntry,aliquot_ipi);
    } else if (cst_ipi_code >= 98 || cst_ipi_code <= 99) {
      return _others_Calculation_Ipi(productInvoiceEntry,aliquot_ipi);
    }
  }
  
  async function _normal_Ipi_Calculation(productInvoiceEntry,aliquot_ipi) {
    try {
      productInvoiceEntry.value_base_ipi_product = productInvoiceEntry.amount * productInvoiceEntry.cust;
      productInvoiceEntry.aliquot_ipi_product = aliquot_ipi;
      productInvoiceEntry.value_ipi_outher_product = 0;
      productInvoiceEntry.value_ipi_free_product = 0;
      productInvoiceEntry.value_ipi_product = productInvoiceEntry.value_base_ipi_product * ( productInvoiceEntry.aliquot_ipi_product / 100);
     
      return productInvoiceEntry;
    } catch (error) {
      return error;
    }
  }
  
  async function _others_Calculation_Ipi(productInvoiceEntry,aliquot_ipi) {
    try {
        productInvoiceEntry.value_base_ipi_product = 0;
        productInvoiceEntry.aliquot_ipi_product = aliquot_ipi;
        productInvoiceEntry.value_ipi_outher_product = productInvoiceEntry.amount * productInvoiceEntry.cust;
        productInvoiceEntry.value_ipi_free_product = 0;
        productInvoiceEntry.value_ipi_product = 0;
  
      return productInvoiceEntry;
    } catch (error) {
      error;
    }
  }
  
  module.exports = {
    calculeTributeIpiIn,
  };
  