function executeAlterDestroyDialog() {
  this.component.destroyDialog = false;
}

function submit() {
  this.executeAlterDestroyDialog();
  this.destroy_object(this.component.currentItem);
}

export default {
  executeAlterDestroyDialog,
  submit,
};
