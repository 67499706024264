import { CHANGE_LOAD } from "./store/actions/load"
import showMessageErros from "./interceptors-v2"

export default (axios, store) => {

    axios.interceptors.request.use(req => {

        store.commit(CHANGE_LOAD, true)
        return req;
    },
        error => {
            store.commit(CHANGE_LOAD, false)
            return Promise.reject(error)
        }
    )

    axios.interceptors.response.use(res => {
        store.commit(CHANGE_LOAD, false)
        return res;
    },
        error => {
            store.commit(CHANGE_LOAD, false)
            showMessageErros(error.response)
            return Promise.reject(error)
        }
    )
}
