
import { ADD_BANK, UPDATE_BANK, AUX_TO_UPDATE_BANK  } from "../../../store/actions/bank";

function getComponent() {
    return this.component;
}
function cancel() {
    this.component.addDialog = false;
    this.justVisible = false
    this.$store.dispatch(AUX_TO_UPDATE_BANK, Object())
}
async function create_bank() {
    const bank = {
        description: this.description,
        code: parseInt(this.code),
    };
    await this.$store.dispatch(ADD_BANK, bank).then(() => {
        this.cancel();
    });
}
function submit() {
    this.id == undefined ? this.create_bank() : this.update_bank();
}
async function update_bank() {
    const bank = {
        id: this.id,
        description: this.description,
        code: parseInt(this.code),
    };
    await this.$store.dispatch(UPDATE_BANK, bank).then(() => {
        this.cancel();
    });
}
function show_bank(bank) {
    this.code = bank.code;
    this.description = bank.description;
}

function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.description = payload.item.description;
        this.code = payload.item.code;
    } else {
        this.description = payload.description;
        this.code = payload.code;
        this.id = payload.id;
    }
}

export default {
    getComponent,
    cancel,
    create_bank,
    submit,
    show_bank,
    update_bank,
    autoload,
}