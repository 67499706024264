import {
  ADD_FORM_OF_PAYMENT,
  UPDATE_FORM_OF_PAYMENT,
  AUX_TO_UPDATE_FORM,
} from "../../../store/actions/formofpayment";

function getComponent() {
    return this.component;
  }
  function close() {
    this.component.addDialog = false;
    this.justVisible = false
    this.$store.dispatch(AUX_TO_UPDATE_FORM, Object())
  }
  async function  create_formofpayment() {
    const formofpayment = {
      code: this.code,
      description: this.description,
    };
    await this.$store
      .dispatch(ADD_FORM_OF_PAYMENT, formofpayment)
      .then(() => {
        this.close();
      });
  }
  function submit() {
    this.id == undefined
      ? this.create_formofpayment()
      : this.update_formofpayment();
  }
  async function  update_formofpayment() {
    const formofpayment = {
      id: this.id,
      code: this.code,
      description: this.description,
    };
    await this.$store
      .dispatch(UPDATE_FORM_OF_PAYMENT, formofpayment)
      .then(() => {
        this.close();
      });
  }

  function autoload(payload) {
    if(payload.justVisible) {
        this.justVisible = payload.justVisible;
        this.description = payload.item.description;
        this.code = payload.item.code;
    } else {
        this.description = payload.description;
        this.code = payload.code;
        this.id = payload.id;
    }
}


  export default {
    getComponent,
    close,
    create_formofpayment,
    submit,
    update_formofpayment,
    autoload,
  }