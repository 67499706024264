import axios from "axios";

const {
  ADD_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
  GET_ALL_PAYMENT_TERM,
  DESTROY_PAYMENT_TERM,
  GET_FILTER_PAYMENT_TERM,
  AUX_TO_UPDATE_PAYMENTTERM
} = require("./actions/paymentterm");

const { SPLICE_ERRO } = require("./actions/alert_api");

const modulePaymentTerm = {
  state: () => ({
    paymentterms: Object(),
    auxToUpdatePaymentterm: Object(),
  }),

  mutations: {
    GET_ALL_PAYMENT_TERM: (state, paymentterms) => {
      state.paymentterms = paymentterms;
    },
    GET_FILTER_PAYMENT_TERM: (state, payload) => {
        state.paymentterms = payload;
    },
    ADD_PAYMENT_TERM: (state, newPaymentterms) => {
      state.paymentterms = {};
      state.paymentterms = newPaymentterms;
      
    },
    UPDATE_PAYMENT_TERM(state, updatePaymentterm) {
      state.paymentterms.data.Update(updatePaymentterm);
    },
    DESTROY_PAYMENT_TERM: (state, id) => {
      let auxState = [...state.paymentterms.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.paymentterms = [...auxState];
    },

    AUX_TO_UPDATE_PAYMENTTERM: function (state, payload) {
      state.auxToUpdatePaymentterm = payload
    }
  },

  actions: {
   async GET_ALL_PAYMENT_TERM({ commit }, payload) {

      try {
        const response = await axios
          .post("paymentTerm/index", {
            page: payload.page,
            limit: payload.limit,
            paginate: payload.paginate,
          })

          commit(GET_ALL_PAYMENT_TERM, response.data[0]);
          return response.data[0]
      } catch (error) {
        throw new Error(error)
      }
    },

    GET_FILTER_PAYMENT_TERM({ commit }, payload) {
      axios
        .post("paymentTerm/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_FILTER_PAYMENT_TERM, response.data[0]);
        });
    },

    ADD_PAYMENT_TERM({ commit }, payload) {

      return new Promise((resolve, reject) => {
        axios
          .post("paymentTerm/store", payload)
          .then((response) => {
            
            resolve();
            // commit(ADD_PAYMENT_TERM, response.data[0]);
            commit(ADD_PAYMENT_TERM, response.data);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_PAYMENT_TERM({ commit }, payload) {

      return new Promise((resolve, reject) => {
        axios
          .post("paymentTerm/update", payload)
          .then((response) => {
            resolve();
            commit(UPDATE_PAYMENT_TERM, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualização realizada com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_PAYMENT_TERM({ commit }, payload) {
      axios.post("paymentTerm/delete", payload).then((response) => {
        commit(DESTROY_PAYMENT_TERM, payload);
        commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
          type: "S",
        });
      });
    },
    AUX_TO_UPDATE_PAYMENTTERM: function (context, payload) {
      context.commit(AUX_TO_UPDATE_PAYMENTTERM, payload)
    }
  },

  getters: {
    getPaymentTerms: (state) => ({ ...state.paymentterms }),

    getAuxPaymenttermToUpdate: (state) => state.auxToUpdatePaymentterm
  },
};

export default modulePaymentTerm;
