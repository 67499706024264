<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteProfession()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :search="search"
        :component="this"
        :getters="get_profession"
        :change_addDialog="change_addDialog"
        :destroy_object="destroy_object"
      />
      <AddProfession v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import AddProfession from "../components/profession/AddDialog/index";
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/profession/CardTitle/";
import Table from "../components/profession/Table/";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";

import {
  DESTROY_PROFESSION,
  GET_ALL_PROFESSION,
  GET_FILTER_PROFESSION,
} from "../store/actions/profession";
import { mapGetters } from "vuex";

export default {
  components: {
    Container,
    CardTitle,
    Table,
    AddProfession,
    AlertApi,
    loadScreen,
  },

  data: () => ({
    addDialog: false,
    search: "",
    searchInput: "",
    getter: Array(),
    objectToUpdate: {},
    filter: "",
    headers: [
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
    permission: Object(),
  }),

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PROFESSION, value).then((response) => {
          this.getter = response
      });
    },

    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },

    async destroy_object(item) {
      const profession = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PROFESSION, profession);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },

    async profession_filter(page = 1) {
      let value = {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_FILTER_PROFESSION, value);
    },
    
    autocompleteProfession() {
      this.search = this.searchInput
    }
  },
watch:{
  getUpdatedProfession: function(val){
    const index = this.getter.data.findIndex((element) => element.id == val.id)
    this.getter.data.splice(index, 1, val)
  },
  searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.profession_filter()
      }
    }
},
  created() {
    this.GetAll();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },

  computed: { ...mapGetters(["get_profession", "getCadastrosLinks","getUpdatedProfession"]) },
};
</script>

<style scoped></style>
