import axios from "axios"

const {
    GET_ALL_BOOKSUPERVISION_IN,
    GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE
} = require("./actions/book_supervision_in");

// const {
//     SPLICE_ERRO
// } = require("./alert_api")

const moduleBookSupervisionIn = {
    state: () => ({
        booksupervisionins: Object()
    }),

    mutations:{
        GET_ALL_BOOKSUPERVISION_IN: (state, payload) => {
            state.booksupervisionins = payload
        },
        GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE: (state, payload) => {
            state.booksupervisionins = payload
        },
        GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE: (state, payload) => {
            state.booksupervisionins = payload
        },
    },

    actions: {
        async GET_ALL_BOOKSUPERVISION_IN({
            commit
        }, payload) {
            await axios.post("booksupervision/index", { 
                page: payload.page, 
                limit: payload.limit, 
                paginate: payload.paginate
            }).then((response) => {
                commit(GET_ALL_BOOKSUPERVISION_IN, response.data[0])
            })
        },
        async GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE({
            commit
        }, payload) {
            await axios.post("booksupervision/getAllByEnterprise", { 
                page: payload.page, 
                limit: payload.limit, 
                paginate: payload.paginate,
                enterprise_id: payload.enterprise_id,
            }).then((response) => {
                commit(GET_ALL_BOOKSUPERVISION_IN_BY_ENTERPRISE, response.data)
            })
        },
        async GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE({
            commit
        }, payload) {
            await axios.post("booksupervision/autocompleteByEnterprise", { 
                page: payload.page, 
                limit: payload.limit, 
                paginate: payload.paginate,
                search: payload.search,
                enterprise_id: payload.enterprise_id,
            }).then((response) => {
                commit(GET_AUTOCOMPLETE_BOOKSUPERVISION_IN_BY_ENTERPRISE, response.data)
            })
        },
    },

    getters: {
        getBookSupervisionIn: (state) => ({ ...state.booksupervisionins })
    }
}

export default moduleBookSupervisionIn;