import axios from "axios";
const {
  ADD_NOTE_MODEL,
  UPDATE_NOTE_MODEL,
  GET_ALL_NOTE_MODEL,
  DESTROY_NOTE_MODEL,
  GET_FILTER_NOTE_MODEL,
  GET_SEQUENCE_NFE,
  AUX_TO_UPDATE_NOTE_MODEL,
  GET_ALL_NOTE_MODEL_BY_ENTERPRISE,
  GET_FILTER_NOTE_MODEL_BY_ENTERPRISE,
} = require("./actions/note_model");

const {
  SPLICE_ERRO,
} = require('./actions/alert_api')

const moduleNoteModel = {
  state: () => ({
    note_models: Object(),
    number_sequence_nfe: Object(),
    auxToUpdateNoteModel: Object(),
  }),

  mutations: {
    GET_ALL_NOTE_MODEL: (state, note_models) => {
      state.note_models = note_models;
    },

    GET_ALL_NOTE_MODEL_BY_ENTERPRISE: (state, note_models) => {
      state.note_models = note_models;
    },

    GET_SEQUENCE_NFE: (state, payload) => {
      state.number_sequence_nfe = payload;
    },
    GET_FILTER_NOTE_MODEL: (state, payload) => {
      state.note_models = payload
    },
    GET_FILTER_NOTE_MODEL_BY_ENTERPRISE: (state, payload) => {
      state.note_models = payload
    },
    ADD_NOTE_MODEL: (state, newNoteModels) => {
      state.note_models = newNoteModels;
    },
    UPDATE_NOTE_MODEL(state, editNoteModel) {
      state.note_models.data.Update(editNoteModel);
    },

    DESTROY_NOTE_MODEL: (state, id) => {
      let auxState = [...state.note_models.data]
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.note_models = [...auxState];
    },

    AUX_TO_UPDATE_NOTE_MODEL: function (state, payload) {
      state.auxToUpdateNoteModel = payload
      
    }
  },

  actions: {
    async GET_ALL_NOTE_MODEL({ commit }, payload) {
      try {
        const response = await axios
          .post("noteModel/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
        
          commit(GET_ALL_NOTE_MODEL, response.data[0]);

          return response.data[0];

      } catch (error) {
        throw new Error(error)
      }
    },

    async GET_ALL_NOTE_MODEL_BY_ENTERPRISE({ commit }, payload) {
      try {
        const response = await axios
          .post("noteModel/getAllByEnterprise", { page: payload.page, limit: payload.limit, paginate: payload.paginate, enterprise_id: payload.enterprise_id })
        
          commit(GET_ALL_NOTE_MODEL_BY_ENTERPRISE, response.data);

          return response.data[0];

      } catch (error) {
        throw new Error(error)
      }
    },

    GET_SEQUENCE_NFE({
      commit
    }, payload) {
      axios.post("noteModel/sequencenfe", payload).then((response) => {
        commit(GET_SEQUENCE_NFE, response.data[0]);
      });
    },

    GET_FILTER_NOTE_MODEL({
      commit
    }, payload) {
      axios.post("noteModel/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_NOTE_MODEL, response.data[0]);
      })
    },

    GET_FILTER_NOTE_MODEL_BY_ENTERPRISE({
      commit
    }, payload) {
      axios.post("noteModel/autocompleteByEnterprise", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
          enterprise_id: payload.enterprise_id
      }).then((response) => {
        commit(GET_FILTER_NOTE_MODEL_BY_ENTERPRISE, response.data);
      })
    },

    async ADD_NOTE_MODEL({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("noteModel/store", payload).then((response) => {
          resolve()
          commit(ADD_NOTE_MODEL, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastro realizado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },

    UPDATE_NOTE_MODEL({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("noteModel/update", payload).then((response) => {
          resolve()
          commit(UPDATE_NOTE_MODEL, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        }).catch(() => {
          reject()
        })


      })
    },

    DESTROY_NOTE_MODEL({
      commit
    }, payload) {
      axios
        .post("noteModel/delete", payload)
        .then((response) => {
          commit(DESTROY_NOTE_MODEL, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        })

    },
    AUX_TO_UPDATE_NOTE_MODEL: function (context, payload) {
      context.commit(AUX_TO_UPDATE_NOTE_MODEL, payload)
    }
  },

  getters: {
    getNoteModel: (state) => ({
      ...state.note_models
    }),
    getSequenceNfe: (state) => ({
      ...state.number_sequence_nfe
    }),

    getAuxNoteModelToUpdate: (state) => state.auxToUpdateNoteModel
  },
};

export default moduleNoteModel;