<template>
  <v-row>
    <v-card-title>Tipo de Comissão</v-card-title>
    <AddComission :component="this" />
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        class="mt-3"
        label="Empresa"
        outlined
        item-value="id"
        item-text="fantasyName"
        dense
        v-model="enterpriseId"
        flat
        :items="enterprises"
        height="10px"
      ></v-select>
    </v-col>
    <v-btn
      v-if="component.permission.Cadastrar"
      class="mr-2 mt-6"
      color="success"
      @click="
        () => {
          open();
        }
      "
      >Adicionar</v-btn
    >
  </v-row>
</template>
<script>
import AddComission from "../AddDialog/";
import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
//import { dispatchGetAll } from "../../Methods/dispatchGetAll";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  components: { AddComission },
  data: () => ({
    dialog: false,
    enterpriseId: Number(),
    enterprises: []
  }),
  methods: {
    open() {
      this.dialog = !this.dialog;
    },
    async getAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },
  created() {
    this.getAllNoPage();
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val)
      this.enterpriseId = this.enterprises[0];
    },
  },
};
</script>
