var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.component.dialog),callback:function ($$v) {_vm.$set(_vm.component, "dialog", $$v)},expression:"component.dialog"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card-title',[_vm._v("Registrar Tipo de Comissão")]),_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"outlined":"","item-value":"value","item-text":"text","items":_vm.item,"label":"Tipo de Comissão:","rules":[
                  () =>
                    !!_vm.commission_type.commissionType || 'Campo Obrigatório',
                ]},model:{value:(_vm.commission_type.commissionType),callback:function ($$v) {_vm.$set(_vm.commission_type, "commissionType", $$v)},expression:"commission_type.commissionType"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Descrição:","rules":[
                  () =>
                    !!_vm.commission_type.description.trim() ||
                    'Campo Obrigatório',
                ]},model:{value:(_vm.commission_type.description),callback:function ($$v) {_vm.$set(_vm.commission_type, "description", $$v)},expression:"commission_type.description"}})],1),_c('v-divider')],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                _vm.desactive_dialog();
              }}},[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),_c('v-btn',{attrs:{"color":"success"},on:{"click":() => {
                _vm.submit();
              }}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Confirmar")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }