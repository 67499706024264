<template>
  <v-row justify="center">
    <v-dialog persistent v-model="getShowDialogRegisterUnit" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Unidade de Medida</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Sigla"
                v-model="sigla"
                :readonly = "justVisible"
                :rules="[() => !!sigla || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly = "justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex"
import UIBusinessRule from "../../../UIBusinessRule/Unit/AddDialog/AddDialog";
export default {
  props: {
    component: Object(),
    OpenDialog:Boolean(),
    
  },
  computed:{
    ...mapGetters(["getShowDialogRegisterUnit", "getItemUnitToUpdate"])
  },
  data() {
    return {
      justVisible: Boolean(),
      CallDialog:Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      sigla:String(),
      code: String(),
      description: String(),
      search: String(),
    };
  },

  methods: {
    ...UIBusinessRule
  },
watch:{
  getItemUnitToUpdate:function(val){
    this.autoLoad(val)
  },
  OpenDialog:function(){
   
  if(this.OpenDialog == false){
    this.CallDialog = !this.CallDialog
    
  }else if(this.OpenDialog == true){
    this.CallDialog = !this.CallDialog
  }  
},
},
  async created() {

  },

  beforeDestroy() {
    
  },
};
</script>
