import axios from "axios";

const {
  GET_ALL_UNIT,
  ADD_UNIT,
  UPDATE_UNIT,
  DESTROY_UNIT,
  GET_FILTER_UNIT,
  ITEM_UNIT_TO_UPDATE
} = require("./actions/unit");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleUnit = {
  state: () => ({
    units: {},
    UnitID: Number(),
    itemUnitToUpdate: Object(),
  }),

  mutations: {
    ITEM_UNIT_TO_UPDATE: (state, payload) => {
      state.itemUnitToUpdate = payload
    },
    GET_ALL_UNIT: (state, units) => {
      state.units = units;
    },
    GET_FILTER_UNIT: (state, payload) => {
      if (payload !== undefined) {
        state.units = payload
      }
    },
    ADD_UNIT: (state, newUnits) => {
      state.UnitID = newUnits.id
      state.units.data.push(newUnits);
    },
    UPDATE_UNIT(state, editUnit) {
      state.units.data.Update(editUnit);
    },

    DESTROY_UNIT: (state, id) => {
      let auxState = [...state.units.data]
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.units = [...auxState];
    },
  },

  actions: {
    ITEM_UNIT_TO_UPDATE: (context, payload) => {
      context.commit(ITEM_UNIT_TO_UPDATE,payload)
    },
    GET_ALL_UNIT({
      commit
    },
      payload
    ) {
      axios.post("unit/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: payload.paginate
      }).then((response) => {
        commit(GET_ALL_UNIT, response.data[0]);
      });
    },

    GET_FILTER_UNIT({
      commit
    }, payload) {
      axios.post("unit/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_UNIT, response.data[0]);
      })
    },

    ADD_UNIT({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {
        axios.post("unit/store", payload).then((response) => {
          resolve()

          commit(ADD_UNIT, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
            type: 'S',
          })
        }).catch(() => {
          reject()
        })
      })

    },

    UPDATE_UNIT({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("unit/update", payload).then((response) => {
          commit(SPLICE_ERRO, {
            alert: "Atualizado com sucesso!",
            type: 'S'
          })
          resolve()
          commit(UPDATE_UNIT, response.data[0]);
          
        }).catch(() => {
          reject()
        })

      })
    },

    DESTROY_UNIT({
      commit
    }, payload) {
      axios
        .post("unit/delete", payload)
        .then((response) => {
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
          commit(DESTROY_UNIT, payload.id);
        })
    },
  },

  getters: {
    getItemUnitToUpdate: (state) => state.itemUnitToUpdate,
    getUnit(state) {
      return state.units
    },
    getID(state) {
      return state.UnitID
    }
  },
};

export default moduleUnit;