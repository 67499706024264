const ADD_ACCOUNT_PLAN = "ADD_ACCOUNT_PLAN";
const UPDATE_ACCOUNT_PLAN = "UPDATE_ACCOUNT_PLAN";
const GET_ALL_ACCOUNT_PLAN = "GET_ALL_ACCOUNT_PLAN";
const DESTROY_ACCOUNT_PLAN = "DESTROY_ACCOUNT_PLAN";
const GET_FILTER_ACCOUNT_PLAN = "GET_FILTER_ACCOUNT_PLAN";
const AUX_TO_UPDATE_ACCOUNT_PLAN = "AUX_TO_UPDATE_ACCOUNT_PLAN"
const NEW_ACCOUNT_PLAN = "NEW_ACCOUNT_PLAN"
const GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE = "GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE"
const GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE = "GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE"

module.exports = {
    NEW_ACCOUNT_PLAN,
    AUX_TO_UPDATE_ACCOUNT_PLAN,
    ADD_ACCOUNT_PLAN,
    UPDATE_ACCOUNT_PLAN,
    GET_ALL_ACCOUNT_PLAN,
    DESTROY_ACCOUNT_PLAN,
    GET_FILTER_ACCOUNT_PLAN,
    GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE,
    GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE,
}