<template>
  <v-row justify="center">
    <v-dialog
      v-model="component.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="component.close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>
            <v-tab href="#tab2" v-if="!register">
              <v-icon class="mr-1">mdi-family-tree</v-icon>
              Pessoal
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-card-account-mail</v-icon> Contato
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-home-account</v-icon>
              Endereço
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-bank</v-icon> Contas Bancárias
            </v-tab>

            <v-tab-item value="tab1">
              <v-switch
                disabled
                v-model="register"
                label="Pessoa Jurídica"
              ></v-switch>
              <v-card flat v-if="!register">
                <TabPhisical :component="this" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab2">
              <v-card flat>
                <TabPessoal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3">
              <v-card flat>
                <TabContact :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4">
              <v-card flat> <TabAddress :component="this" /> </v-card>
            </v-tab-item>
            <v-tab-item value="tab5">
              <v-card flat> <TabBank :component="this" /></v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="() => {}" class="error">Limpar Formulário</v-btn>
            <v-btn
              color="success"
              @click="
                () => {
                  submit();
                }
              "
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import TabPhisical from "../../Tabs/TabPhisical/";
import TabLegal from "../../Tabs/TabLegal/";
import TabPessoal from "../../Tabs/TabPersonal/";
import TabContact from "../../Tabs/TabContact/";
import TabAddress from "../../Tabs/TabAddress/";
import TabBank from "../../Tabs/TabBank/";
import {
  UPDATE_PF_CLIENT,
  UPDATE_PJ_CLIENT,
} from "../../../../store/actions/client";

export default {
  props: {
    component: Object,
  },
  components: {
    TabPhisical,
    TabLegal,
    TabPessoal,
    TabContact,
    TabAddress,
    TabBank,
  },
  data: () => ({
    register: Boolean(),
    valid: Boolean(),
    validPhysical: Boolean(),
    validLegal: Boolean(),
    validTable: Boolean(false),

    transporter: {
      id: undefined,
      type_people: String(),
      people_id: Number(),
      rntr: Number(),
      security: String(),
    },

   physicalClient: {
      id: undefined,
      name: String(),
      lastName: String(),
      cpf: String(),
      gender: String(),
      emitter: String(),
      emitterState: String(),
      idCard: String(),
      dateOfBirth: String(),
      fatherName: String(),
      motherName: String(),
      nacionality: String(),
      naturality: String(),
      monthlyIncome: String(),
      civilStatus: String(),
      spouse: String(),
      icomeSpouse: Number(),
      people_id: Number(),
    },
    client: {
      id: undefined,
      type_people: String(),
      people_id: Number(),
    },
    LegalPeople: {
      id: undefined,
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      people_id: Number(),
    },
    address: {
      id: undefined,
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String,
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id: Number(),
    },
    contact: {
      id: undefined,
      primaryEmail: "",
      secundaryEmail: "",
      phone: "",
      cellPhone: "",
      phoneReference: "",
      people_id: Number(),
    },
    banks: {
      id: undefined,
      bank: String(),
      agency: String(),
      count: Number(),
      manager: String(),
      people_id: Number(),
    },
    enterprise_id:Number(),
    cityObject: Object(),
  }),

  methods: {
    async submit() {
      if (this.register === true) {
        let newPeople = {};
        newPeople.legal_people = { ...this.LegalPeople };
        newPeople.client = { ...this.client };
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.banks = { ...this.banks };
        newPeople.address.city_id = this.cityObject.id;
        newPeople.address.city = this.cityObject.nome;
        newPeople.enterprise_id = this.enterprise_id;
        newPeople.id = this.LegalPeople.id
        newPeople.type_people = "LegalPeople";
        await this.$store.dispatch(UPDATE_PJ_CLIENT, newPeople).then(() => {
          this.component.GetAllLegal()
          this.component.close();
        });
      } else {
        let newPeople = {};
        newPeople.physical_people = { ...this.physicalClient };
        newPeople.client = { ...this.client };
        newPeople.address = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.address.city = this.cityObject.nome;
        newPeople.enterprise_id =  this.enterprise_id;
        newPeople.banks = { ...this.banks };
        await this.$store.dispatch(UPDATE_PF_CLIENT, newPeople).then(() => {
          this.component.GetAllPhysical()
          this.component.close();
        });
      }
    },
  },
};
</script>


// //Enviar
//     async submit() {
//       if (this.register === true) {
//         let newPeople = {};
//         newPeople.legal_people = { ...this.LegalPeople };
//         newPeople.people_id = "";
//         newPeople.type_people = "LegalPeople";
//         newPeople.address = { ...this.address };
//         newPeople.contacts = { ...this.contact };
//         newPeople.banks = { ...this.banks };
//         newPeople.address.city_id = this.cityObject.id;
//         newPeople.address.city = this.cityObject.nome;
//         newPeople.enterprise_id = this.enterprise_id;
//         await this.$store.dispatch(ADD_PJ_CLIENT, newPeople).then(() => {
//           this.close_dialog();
//         });
//       } else {
//         let newPeople = {};
//         newPeople.physical_people = { ...this.physicalClient };
//         newPeople.type_people = "PhysicalPeople";
//         newPeople.people_id = "";
//         newPeople.address = { ...this.address };
//         newPeople.contacts = { ...this.contact };
//         newPeople.banks = { ...this.banks };
//         newPeople.address.city_id = this.cityObject.id;
//         newPeople.address.city = this.cityObject.nome;
//         newPeople.enterprise_id =  this.enterprise_id;

//         await this.$store.dispatch(ADD_PF_CLIENT, newPeople).then(() => {
//           this.close_dialog();
//         });
//       }

