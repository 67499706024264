<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="800">
      <v-card outlined>
      
        <v-card-title primary-title>Registrar Contas a Pagar</v-card-title>
        <v-container>
          <v-divider></v-divider>

          <v-form ref="form">
            <v-col>
              
              <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
                <v-tab
                  v-for="(item, index) in newArrayItems"
                  :key="index"
                  :href="'#tab-' + item.href"
                >
                  {{ item.tabName }}
                </v-tab>

                <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="align-self-center mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      more
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(item, index) in more"
                      :key="index"
                      @click="addItem(item)"
                    >
                      {{ item.tabName }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tabs>

              <v-tabs-items v-model="currentItem">
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenerateData :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <Values :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <Accounting :eager="true" :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <WriteOffs />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                  <v-textarea
                  :readonly="justVisible"
                    outlined
                    label="Anotações"
                    auto-grow
                    v-model="object.annotation"
                  ></v-textarea>
                </v-tab-item>
              </v-tabs-items>


              <!-- <v-tabs class="success">
                <v-tab href="#tab-1">
                  <v-icon class="mr-2"> mdi-card-account-details </v-icon> Dados
                  Gerais</v-tab
                >
                <v-tab href="#tab-2"
                  ><v-icon class="mr-2">mdi-cash-check</v-icon> Valores</v-tab
                >
                <v-tab href="#tab-3">
                  <v-icon class="mr-2">mdi-account-cash</v-icon> Contábil</v-tab
                >
                <v-tab href="#tab-4">
                  <v-icon class="mr-2">mdi-file-outline</v-icon>
                  Baixas</v-tab
                >
                <v-tab href="#tab-5">
                  <v-icon class="mr-2">mdi-file-outline</v-icon>
                  Anotações</v-tab
                >
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenerateData :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <Values :component="this" :eager="true" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <Accounting :component="this" :eager="true" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <WriteOffs />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                  <v-col>
                    <v-textarea
                      outlined
                      label="Anotações"
                      auto-grow
                      v-model="object.annotation"
                    ></v-textarea>
                  </v-col>
                </v-tab-item>
              </v-tabs> -->


            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="changeDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WriteOffs from "../Tabs/WriteOffs/Index";
import GenerateData from "../Tabs/GenerateData/index.vue";
import Values from "../Tabs/Values/Index";
import Accounting from "../Tabs/Accounting/Index";

import {
  ADD_BILLSPAY,
  UPDATE_BILLSPAY,
} from "../../../store/actions/bills_pay";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
  },

  components: {
    WriteOffs,
    GenerateData,
    Values,
    Accounting,
  },
//getSelectedEnterprise
  data() {
    return {
      justVisible: Boolean(),
      newArrayItems: Array(),
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: "Valores",
          href: "2",
        },
        {
          tabName: "Contábil",
          href: "3",
        },
        {
          tabName: "Baixas",
          href: "4",
        },
        {
          tabName: "Anotações",
          href: "5",
        },
      ],
      enterpise: Object(),
      currentItem: "tab-1",
      accounting_plan: Array(),
      valid: Boolean(),
      resultAliquot: null,
      search: "",
      plan: null,
      object: {
        status: String(),
        issuance_date: String(),
        issuance_due: String(),
        type_title: String(),
        number_title: String(),
        value_title: Number(),
        dejection: Number(),
        interest: Number(),
        value_net: 0,
        title_balance: 0,
        provider_id: null,
        account_plans_id: null,
        invoiceentry_id:"",
      },
      aux1: 0,
      aux2: 0,
      aux3: 0,
      validGenerate: false,
      validValues: false,
      validAccounting: false,
    };
  },
  computed: {
    ...mapGetters(['getSelectedEnterprise'])
  },
  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    getComponent() {
      return this.component;
    },

    async create_bills() {
      this.object.enterprise_id = this.getSelectedEnterprise.id
      this.object.provider_id = this.object.peopleComputed.people_id;
      this.object.account_plans_id = this.plan.id;

      const bills = {
        ...this.object,
      };

      await this.$store.dispatch(ADD_BILLSPAY, bills).then(() => {
        this.component.addDialog = false;
      });
    },

    submit() {
      // if (!this.validGenerate) {
      //   alert("Preencha os dados gerais!");
      // } else if (!this.validValues) {
      //   alert("Preencha os valores!");
      // } else if (!this.validAccounting) {
      //   alert("Preencha os dado contábeis!");
      // } else {
      //   }
      this.object.id == undefined ? this.create_bills() : this.update_bills();
    },

    changeDialog() {
      this.component.addDialog = !this.component.addDialog;
    },

    async update_bills() {
      this.object.provider_id = this.object.peopleComputed.people_id;
      this.object.account_plans_id = this.plan.id;
      const bills = {
        ...this.object,
      };
      await this.$store.dispatch(UPDATE_BILLSPAY, bills).then(() => {
        this.component.addDialog = false;
      });
    },

    validate() {
      this.$refs.form.validate();
    },

    mountObject() {

      if (this.getComponent().objectToUpdate.justVisible) {

        this.object = { ...this.getComponent().objectToUpdate.item }

        this.justVisible = this.getComponent().objectToUpdate.justVisible
        this.object.status = this.getComponent().objectToUpdate.item.status
        this.object.issuance_date = this.getComponent().objectToUpdate.item.issuance_date.replace(/[^0-9]/g, '-').split("-").reverse().join("-");
        this.object.issuance_due = this.getComponent().objectToUpdate.item.issuance_due.replace(/[^0-9]/g, '-').split("-").reverse().join("-");

        switch (this.getComponent().objectToUpdate.item.type_title) {
          case "DINHEIRO":
            this.object.type_title = 'Dinheiro'
            break;

          case "BOLETO":
            this.object.type_title = 'Boleto'
            break;

          default:
            this.object.type_title = 'Depósito Bancário'
            break;
        }

        this.plan = this.getComponent().objectToUpdate.item.accont_plan
        this.object.number_title = this.getComponent().objectToUpdate.item.number_title
        this.object.peopleComputed.people_name = this.getComponent().objectToUpdate.item.peopleComputed.fantasyName

      } else {
        if (Object.keys(this.getComponent().objectToUpdate).length) {
          this.object = { ...this.getComponent().objectToUpdate };

          this.object.dejection = this.object.dejection.toString();
          this.object.interest = this.object.interest.toString();
          this.object.issuance_date = this.object.issuance_date
            .split("/")
            .reverse()
            .join("-");
          this.object.issuance_due = this.object.issuance_due
            .split("/")
            .reverse()
            .join("-");

          this.object.peopleComputed.people_name =
            this.object.peopleComputed.name === undefined
              ? this.object.peopleComputed.fantasyName
              : this.object.peopleComputed.name;
          this.object.peopleComputed.type_people =
            this.object.provider.type_people;
          this.object.peopleComputed.people_id = this.object.provider_id;
          this.plan = this.object.accont_plan;
          this.validValues = true;
          this.validAccounting = true;
        } else {
          // this.object.enterprise_id = this.component.enterprise_id;
          this.object.peopleComputed = {};
          this.object.invoiceentry_id = "";
          this.object.status = "Aberto";
        }
      }
    },
  },
  async created() {
    this.setArrayItens()
    this.accounting_plan = Object.keys(this.component.getAccount_Plan).map(
      (key) => this.component.getAccount_Plan[key]
    );

    

    this.mountObject();
  },

  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
    "object.value_title": function (v) {
      this.aux1 = parseFloat(v);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },

    "object.dejection": function (v) {
      this.aux2 = parseFloat(v);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },

    "object.interest": function (v) {
      this.aux3 = parseFloat(v);
      this.object.value_net = this.aux1 - this.aux2 + this.aux3;
      if (isNaN(this.object.value_net)) {
        this.object.value_net = "";
      }
      this.object.title_balance = this.object.value_net;
    },
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>

