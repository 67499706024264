<template>
  <div>
    <apexchart
      type="pie"
      width="380"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    series: [150, 100, 200, 333, 458],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Caixa A", "Caixa B", "Caixa C", "Caixa D", "Caixa E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      title: {
        text: "Fluxo de Caixa",
      },
    },
  }),
};
</script>
