var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(this._props.component.addDialog),callback:function ($$v) {_vm.$set(this._props.component, "addDialog", $$v)},expression:"this._props.component.addDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Movimentação estoque")]),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-autocomplete',{attrs:{"auto-select-first":"","outlined":"","required":"","return-object":"","persistent-hint":"","item-text":"description","item-value":"id","items":this.component.taxOperationType,"label":"Tipo de movimentação","readonly":""},model:{value:(_vm.modalValues.tax_type),callback:function ($$v) {_vm.$set(_vm.modalValues, "tax_type", $$v)},expression:"modalValues.tax_type"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Data da Movimentação","type":"date","rules":[
                () => !!_vm.modalValues.date_movement || 'Campo Obrigatorio',
                () =>
                  (!!_vm.modalValues.date_movement &&
                    _vm.modalValues.date_movement.length >= 1) ||
                  'Campo tem que ser maior do que 1',
              ],"outlined":"","readonly":"","required":""},model:{value:(_vm.modalValues.date_movement),callback:function ($$v) {_vm.$set(_vm.modalValues, "date_movement", $$v)},expression:"modalValues.date_movement"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Nome do Produto","return-object":"","items":this.component.product,"item-text":"description_item","item-value":"id","rules":[
                () => !!_vm.product || 'Campo Obrigatorio',
              ],"outlined":"","readonly":"","required":""},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Saldo de Movimentação","type":"number","rules":[
                () => !!_vm.modalValues.value_movement || 'Campo Obrigatorio',
                () =>
                  (!!_vm.modalValues.value_movement &&
                    _vm.modalValues.value_movement.length >= 1) ||
                  'Campo tem que ser maior do que 1',
              ],"outlined":"","readonly":"","required":""},model:{value:(_vm.modalValues.value_movement),callback:function ($$v) {_vm.$set(_vm.modalValues, "value_movement", $$v)},expression:"modalValues.value_movement"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.runAlterDialog(this, _vm.alterAddDialog);
                }}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_vm._v("Salvar")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }