function close_DestroyDialog() {
    this.component.destroyDialog = false;
  }
  function submit() {
    this.close_DestroyDialog();
    this.destroy_formOfPayment(this.component.currentItem);
  }
  function getTitle() {
    return (
      (this.component.component && this.component.component.title) || "teste"
    );
  }


  export default {
    close_DestroyDialog,
    submit,
    getTitle,
  }