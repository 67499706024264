<template>
  <v-row>
    <v-card-title> Empresas </v-card-title>
    <v-spacer></v-spacer>
    <v-btn
      class="success mr-2 mt-6"
      v-if="component.permission.Cadastrar"
      @click="active_addDialog"
    >
      Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },

  data: () => ({}),
  computed: {
    ...mapGetters(["getShowDialogRegisterEnterprise"]),
  },
  methods: {
    active_addDialog() {
      this.$store.dispatch("setShowDialogRegisterEnterprise", true);
    },
  },
};
</script>
