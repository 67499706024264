<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="700">
      <v-card outlined>
        <v-card-title primary-title>Movimentação Financeira</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-col>
              <v-tabs>
                <v-tab href="#tab-1">
                  <v-icon class="mr-2"> mdi-card-account-details </v-icon> Dados
                  Gerais</v-tab
                >
                <v-tab href="#tab-2">
                  <v-icon class="mr-2">mdi-cash-check</v-icon> Valores</v-tab
                >
                <v-tab href="#tab-3">
                  <v-icon class="mr-2">mdi-account-cash</v-icon> Contábil</v-tab
                >
                <v-tab href="#tab-4">
                  <v-icon class="mr-2">mdi-file-outline</v-icon>
                  Anotações</v-tab
                >
                <v-tab-item value="tab-1">
                  <v-col>
                    <v-text-field
                      label="*Descrição da Movimentação"
                      :readonly="justVisible"
                      v-model="object.description_movement"
                      outlined
                      :rules="[
                        () =>
                          !!object.description_movement || 'Campo Obrigatorio',
                        () =>
                          (!!object.description_movement &&
                            object.description_movement.length >= 2) ||
                          'Campo tem que ser maior do que 2',
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-select
                      label="*Tipo de Movimentação"
                      :readonly="justVisible"
                      :items="type_m"
                      v-model="object.type_movement"
                      :rules="[
                        () => !!object.type_movement || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col>
                    <v-autocomplete
                      label="*Tipo da Caixa"
                      :readonly="justVisible"
                      :items="cashier"
                      item-text="description"
                      item-value="id"
                      v-model="object.id_cashier"
                      :rules="[
                        () => !!object.id_cashier || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <v-text-field
                      label="*Valor da Movimentação"
                      :readonly="justVisible"
                      v-model="object.value_movement"
                      prefix="R$"
                      type="number"
                      :rules="[
                        () => !!object.value_movement || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="*Saldo de Movimentação"
                      :readonly="justVisible"
                      v-model="object.balance_movement"
                      prefix="R$"
                      type="number"
                      :rules="[
                        () => !!object.balance_movement || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="*Numero de Movimento"
                      :readonly="justVisible"
                      v-model="object.number_movement"
                      :rules="[
                        () => !!object.number_movement || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="*Data de Movimentação"
                      :readonly="justVisible"
                      v-model="object.date_movement"
                      type="date"
                      :rules="[
                        () => !!object.date_movement || 'Campo Obrigatorio',
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <v-autocomplete
                      label="*Plano de Contas"
                      :readonly="justVisible"
                      v-model="object.account_plans_id"
                      :items="account_plan"
                      item-value="id"
                      :append-outer-icon="icons[0]"
                      @click:append-outer="showRegisterFinancialMoviment"
                      item-text="description"
                      outlined
                      :rules="[
                        () => !!object.account_plans_id || 'Campo Obrigatorio',
                      ]"
                    ></v-autocomplete>
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <v-textarea
                      outlined
                      label="*Anotações"
                      :readonly="justVisible"
                      auto-grow
                      v-model="object.annotation"
                      :rules="[
                        () => !!object.annotation || 'Campo Obrigatorio',
                      ]"
                    ></v-textarea>
                  </v-col>
                </v-tab-item>
              </v-tabs>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="changeDialog()">
                  <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
                >
                <v-btn
                  v-if="!justVisible"
                  color="success"
                  class="mr-4"
                  :disabled="!valid"
                  @click="
                    () => {
                      submit();
                    }
                  "
                >
                  <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <AccountPlanDialog :out_of_register="true" />
  </v-row>
</template>

<script>
import {
  ADD_FINANCIAL_MOVEMENTE,
  UPDATE_FINANCIAL_MOVEMENTE,
} from "../../../store/actions/financial_movement";
import { GET_ALL_ACCOUNT_PLAN } from "../../../store/actions/account_plan";
import { mdiPlusCircle } from "@mdi/js";
import AccountPlanDialog from "../../account_plans/AddDialog";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },

  components: { AccountPlanDialog },
  data() {
    return {
      justVisible: Boolean(),
      icons: [mdiPlusCircle],
      attrs: false,
      on: false,
      valid: true,
      search: "",
      type_m: ["Entrada", "Saída"],
      formHasErrors: false,
      object: Object(),
      cashier: [],
      account_plan: Array(),
    };
  },
  methods: {
    async getAllAccountPlan() {
      var payload = {
        page: null,
        limit: null,
        paginate: false,
      };

      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, payload);
    },
    showRegisterFinancialMoviment() {
      this.$store.dispatch("setShowDialogRegisterAccountPlan", true);
    },
    getComponent() {
      return this.component;
    },
    changeDialog() {
      this.component.addDialog = !this.component.addDialog;
    },
    loadInformation(payload){
        if(payload.justVisible){
          this.justVisible = payload.justVisible
          this.object = { ...payload.item };
        }else{
          this.object = { ...payload };
        }
    },
    async create_financial() {
      const financial = {
        ...this.object,
      };
      try {
        await this.$store.dispatch(ADD_FINANCIAL_MOVEMENTE, financial).then(() => {
          this.component.GetAll()
        });
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.object.id == undefined
        ? this.create_financial()
        : this.update_financial();
      this.component.addDialog = false;
    },
    async update_financial() {
      const financial = {
        ...this.object,
      };
      try {
        await this.$store.dispatch(UPDATE_FINANCIAL_MOVEMENTE, financial);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    mountList() {
      this.cashier = Object.values(this.component.getCashier);
      // this.account_plan = Object.values(this.component.getAccount_Plan);
    },
  },
  watch: {
    getNewAccountPlan: function (value) {
      this.account_plan.push(value);
      this.object.account_plans_id = value
    },
    getAccount_Plan: function (value) {
      this.account_plan = Object.values(value);
    },
  },
  async created() {
    await this.getAllAccountPlan();
    this.mountList();
    this.loadInformation(this.getComponent().objectToUpdate)
    // this.object = { ... };
    this.object.enterprise_id = this.component.enterpriseId;
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
  computed: {
    ...mapGetters(["getAccount_Plan", "getNewAccountPlan"]),
  },
};
</script>
