<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.downloadialog" max-width="700">
      <v-card>
        <v-card-title primary-title>Contas a Receber</v-card-title>

        <v-container>
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col md="6" class="ml-4">
                <v-row>
                  <p v-if="object">
                    <b>Cliente: </b>
                    {{ object && object.name }}
                  </p>
                </v-row>
                <v-row>
                  <p v-if="object.type_title">
                    <b>Tipo de Titulo: </b> {{ object && object.type_title }}
                  </p>
                </v-row>
                           <v-row>
                  <p v-if="object.type_title">
                    <b>Numero do titulo: </b> {{ object && object.number_title}}
                  </p>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row>
                  <p v-if="object.value_title">
                    <b>Valor Liguido: </b> R$ {{ object && object.value_title }}
                  </p>
                </v-row>
                <v-row>
                  <p v-if="object.title_balance">
                    <b>Saldo Titulo: </b> R$
                    {{ object && object.title_balance }}
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <v-form ref="form" v-model="valid">
            <v-col>
              <v-autocomplete
                label="Caixa"
                v-model="object.cashier"
                :items="cashier_list"
                item-value="id"
                item-text="description"
                outlined
                :rules="[() => !!object.cashier || 'Campo Obrigatorio']"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Data Baixa"
                type="date"
                v-model="object.date_low"
                outlined
                :rules="[() => !!object.date_low || 'Campo Obrigatorio']"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Status"
                v-model="object.status"
                outlined
                disabled
                :rules="[
                  () => !!object.status || 'Campo Obrigatorio',
                  () =>
                    (!!object.status && object.status.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Plano de contas"
                v-model="object.account_plans_id"
                :items="account_plan_list"
                item-value="id"
                item-text="description"
                outlined
                :rules="[
                  () => !!object.account_plans_id || 'Campo Obrigatorio',
                ]"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Titulo Baixa"
                v-model="object.Title_balance"
                outlined
                type="number"
                :rules="[
                  () => !!object.Title_balance || 'Campo Obrigatorio',
                  () =>
                    (!!object.Title_balance &&
                      object.Title_balance.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                  () =>
                    (!!object.Title_balance &&
                      object.Title_balance <= object.title_balance) ||
                    'Valor nao pode ser Acima do valor do titulo valor maximo: R$' +
                      object.title_balance,
                ]"
              ></v-text-field>
              <v-progress-linear
                :value="(object.Title_balance / object.title_balance) * 100"
                :color="
                  object.Title_balance > object.title_balance
                    ? 'red'
                    : 'primary'
                "
              ></v-progress-linear>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    runAlterDialog();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { GET_ALL_CASHIER } from "../../../store/actions/cashier";
import { GET_ALL_ACCOUNT_PLAN } from "../../../store/actions/account_plan";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    download: String,
  },
  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      resultAliquot: null,
      search: "",
      formHasErrors: false,
      account_plan_list: Array(),
      cashier_list: Array(),
      object: Object(),
    };
  },
  computed: {
    ...mapGetters(["getAccount_Plan", "getCashier"]),
  },
  methods: {
    runAlterDialog() {
      this.component.downloadialog = false;
    },
    getComponent() {
      return this.component;
    },
    async createBilss() {
      let  bills = {
        billstoReceive_id: this.object.id,
        id_cashier: this.object.cashier,
        value_movement: parseFloat(this.object.Title_balance).toFixed(2),
        account_plans_id: this.object.account_plans_id,
        balance_movement:parseFloat(this.object.Title_balance).toFixed(2),
        date_movement: this.object.date_low,
        enterprise_id: this.object.enterprise_id,
        number_movement:this.object.number_title,
        annotation: "Baixa referente ao titulo " +  this.object.number_title,
        description_movement:"Baixa do cts a receber",
        type_movement:"Entrada"
      };

      // const bills = {};
      // for (let index in model) {
      //   bills[index] = this.object[index];
      // }

      bills.issuance_paymente = this.object.date_low;
      bills.title_balance = this.object.Title_balance;
      try {
        await this.$store.dispatch(this.download, bills);

        this.component.GetAll();
      } catch (error) {
        console.log(error, error.message);
      }
    },

    submit() {
      this.createBilss();
      this.component.downloadialog = false;
    },

    validate() {
      this.$refs.form.validate();
    },

    mountObjectDownload() {
      if (Object.keys(this.getComponent().itemToDownload).length) {
        this.object = { ...this.getComponent().itemToDownload };
      }
      let data = new Date();
      this.object.cashier = 0;
      this.object.date_low =
        data.getFullYear() +
        "-" +
        (data.getMonth() < 10 ? "0" + data.getMonth() : data.getMonth()) +
        "-" +
        (data.getDate() < 10 ? "0" + data.getDate() : data.getDate());

      if (this.object.client.type_people == "PhysicalPeople") {
        this.object.name = this.object.client.people.physicalpeople.name;
      } else {
        this.object.name = this.object.client.people.legalpeople.fantasyName;
      }
    },

    async GetAll() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };

      await this.$store.dispatch(GET_ALL_CASHIER, value);
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
    },
  },
  watch: {
    getAccount_Plan: function (val) {
      this.account_plan_list = Object.values(val);
    },
    getCashier: function (val) {
      this.cashier_list = Object.values(val);
    },
  },

  async created() {
    this.mountObjectDownload();
    this.GetAll();
  },

  beforeDestroy() {
    this.getComponent().itemToDownload = {};
  },
};
</script>

