const ADD_PJ_TRANSPORTER = "ADD_PJ_TRANSPORTER";
const ADD_PF_TRANSPORTER = "ADD_PF_TRANSPORTER";
const UPDATE_PJ_TRANSPORTER = "UPDATE_PJ_TRANSPORTER";
const UPDATE_PF_TRANSPORTER = "UPDATE_PF_TRANSPORTER";
const GET_ALL_TRANSPORTER = "GET_ALL_TRANSPORTER";
const DESTROY_TRANSPORTER = "DESTROY_TRANSPORTER";
const GET_AUTOCOMPLETE_TRANSPORTER = "GET_AUTOCOMPLETE_TRANSPORTER"
const ITEM_TO_UPDATE = "ITEM_TO_UPDATE"
const GET_ALL_TRANSPORTER_TO_SELECT_FIELD = "GET_ALL_TRANSPORTER_TO_SELECT_FIELD"
const GET_ALL_TRANSPORTER_BY_ENTERPRISE = "GET_ALL_TRANSPORTER_BY_ENTERPRISE"

module.exports = {
  ADD_PJ_TRANSPORTER,
  ADD_PF_TRANSPORTER,
  UPDATE_PJ_TRANSPORTER,
  UPDATE_PF_TRANSPORTER,
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  GET_AUTOCOMPLETE_TRANSPORTER,
  ITEM_TO_UPDATE,
  GET_ALL_TRANSPORTER_TO_SELECT_FIELD,
  GET_ALL_TRANSPORTER_BY_ENTERPRISE
};
