import axios from "axios";
const {
  GET_ALL_NOTEENTRY,
  REVERSAL_NOTEENTRY,
  ADD_NOTE_ENTRY,
  VALIDATION_PRODUCT,
  GET_FILTER,
  GET_ALL_NOTEENTRY_BY_ENTERPRISE,
  GET_FILTER_BY_ENTERPRISE,
} = require("./actions/note_entry");
const { SPLICE_ERRO } = require("./actions/alert_api");
const moduleNoteModel = {
  state: () => ({
    note_entry: Object(),
  }),

  mutations: {
    GET_FILTER: (state, payload) => {
      if (payload !== undefined) {
        state.note_entry = payload;
      }
    },
    GET_FILTER_BY_ENTERPRISE: (state, payload) => {
        state.note_entry = payload;
    },
    GET_ALL_NOTEENTRY: (state, note_entry) => {
      state.note_entry = note_entry;
    },
    GET_ALL_NOTEENTRY_BY_ENTERPRISE: (state, note_entry) => {
      state.note_entry = note_entry;
    },
    ADD_NOTE_ENTRY: (state, newNoteModels) => {
      state.note_entry = newNoteModels;
    },
    REVERSAL_NOTEENTRY: (state, payload) => {
      state.note_entry = payload;
    },
    VALIDATION_PRODUCT(state, payload) {
      state.note_entry = payload;
    },
  },
  actions: {
    GET_FILTER({ commit }, payload) {
      axios
        .post("invoiceentry/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_FILTER, response.data[0]);
        });
    },
    GET_FILTER_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("invoiceentry/autocompleteByEnterprise", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
          status: payload.status,
          enterprise_id: payload.enterprise_id
        })
        .then((response) => {
          commit(GET_FILTER_BY_ENTERPRISE, response.data);
        });
    },
    GET_ALL_NOTEENTRY({ commit }, payload) {
      axios
        .get("invoiceentry/getbystatus", {
          params: {
            page: payload.page,
            limit: payload.limit,
            paginate: payload.paginate,
            status: payload.status,
          },
        })
        .then((response) => {
          commit(GET_ALL_NOTEENTRY, response.data[0]);
        });
    },
    GET_ALL_NOTEENTRY_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("invoiceentry/getAllByEnterprise", {
            page: payload.page,
            limit: payload.limit,
            paginate: payload.paginate,
            status: payload.status,
            enterprise_id: payload.enterprise_id
        })
        .then((response) => {
          commit(GET_ALL_NOTEENTRY_BY_ENTERPRISE, response.data);
        });
    },
    async REVERSAL_NOTEENTRY({ commit }, payload) {
      await axios
        .post("invoiceentry/reverseInvoiceEntry", {
          id: payload.id,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          commit(REVERSAL_NOTEENTRY, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.message,
            type: "S",
          });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r.response.data.errors.message[0],
            type: "E",
          });
        });
    },
    async ADD_NOTE_ENTRY({ commit }, payload) {
      await axios
        .post("invoiceentry/store", payload)
        .then((response) => {
          commit(ADD_NOTE_ENTRY, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Nota criada com sucesso!",
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: erro + " : " + err,
                type: "E",
              })
            );
          }
        });
    },

    VALIDATION_PRODUCT(context, payload) {
      console.log(payload);
      axios.post("", payload).then((response) => {
        context.commit(VALIDATION_PRODUCT, response);
      });
    },
  },
  getters: {
    getNoteEntry: (state) => ({
      ...state.note_entry,
    }),
  },
};

export default moduleNoteModel;
