
import { GET_FILTER_PIS_COFINS } from "../../store/actions/pis_cofins";
import {
    GET_ALL_PIS_COFINS,
    DESTROY_PIS_COFINS,
} from "../../store/actions/pis_cofins";

async function destroy_pisCofins(item) {
    const pisCofins = {
        id: item.id,
    };
    try {
        await this.$store.dispatch(DESTROY_PIS_COFINS, pisCofins);
    } catch (error) {
        alert(error);
    }
}
async function piscofins_filter(page = 1) {
        let value = {
            search: this.search,
            page: page,
            limit: 10,
            paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_PIS_COFINS, value);
}
function open_Dialog(item) {
    this.addDialogPisCofins = !this.addDialogPisCofins;
    this.objectToUpdate = item;
}
async function GetAll(page = 1) {
    let value = {
        page: page,
        limit: 10,
        paginate: true,
    };
    await this.$store.dispatch(GET_ALL_PIS_COFINS, value);
}

function autocompletePisCofins() {
    this.search = this.searchInput
  }

export default {
    destroy_pisCofins,
    piscofins_filter,
    open_Dialog,
    GetAll,
    autocompletePisCofins,
}