<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="600">
      <v-card>
        <v-card-title primary-title> Código de Barras</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Codigo Produto"
                v-model="product.description"
                append-icon="mdi-magnify"
                @blur="selectProductById()"
                @click="clearProduct()"
                @click:append="openAddProduct"
                :readonly="justVisible"
                :rules="[() => !!product.description || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Código de Barras /Ean13"
                v-model="bar_code"
                append-outer-icon="mdi-barcode"
                @click:append-outer="generateEan13"
                :readonly="justVisible"
                :rules="[
                  () => !!bar_code || 'Campo Obrigatorio',
                  () =>
                    (!!bar_code && bar_code.length <= 14) ||
                    'Campo tem que ser menor do que 14',
                ]"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close_dialog();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
          <ProductDataTable :component="this" title="Produtos" />
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UiBusinessRules from "../../../UIBusinessRule/BarCode/AddDialog/AddDialog";
import ProductDataTable from "@/components/barcode/AddDialog/productDialog/index.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },

  components: {
    ProductDataTable,
  },

  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      id: "",
      product: {
        id:'',
        description: '',
      },
      bar_code: "",
      search: "",
      justVisible: Boolean(),
      selectTableShow: Boolean(),
      togleTypePeople: Boolean(),
      listProduct: Array(),
      productById: Array(),
      productHeaders: [
        { text: "ID", value: "id" },
        { text: "Descrição", value: "description_item" },
      ],
        currentPage: 1,
        lastPage: 1,
        search: String()
    };
  },

  computed: {
    ...mapGetters(["getAuxBarcodeToUpdate"]),
  },
  methods: {
    ...UiBusinessRules
  },

  created() {
    this.GetAllProduct(this.currentPage)
    this.autoload(this.getAuxBarcodeToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.product_id = this.object.product_id;
    // this.bar_code = this.object.bar_code;
  },

  watch: {
    currentPage: function () {
      this.GetAllProduct(this.currentPage)
    },
    search: function () {
      this.product_filter();
    }
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },

};
</script>
