import { SET_SHIPPING_COMPANY } from "../actions/import_xml"
import axios from "axios"
export default {
    state: {
        shipping_company: null
    },
    getters: {
        getShippingCompany: function (state) {
            return state.shipping_company;
        }
    },
    mutations: {
        SET_SHIPPING_COMPANY: function (state, payload) {
             
            state.shipping_company = payload
        }
    },
    actions: {
        SET_SHIPPING_COMPANY: function (context, payload) {
            var x = 1;
            //  
            try {
                if (x == 1) {
                    // payload.isRegistered = true
                    context.commit(SET_SHIPPING_COMPANY, payload)
                } else {
                    axios.post('transporter/getcnpjtransporte', payload).then((response) => {
                        context.commit(SET_SHIPPING_COMPANY, response)
                    })
                }
            } catch (error) {
                return error;
            }

        }
    },
}