const headers = {

    isPhisical: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "physicalpeople.name" },
        { text: "CPF", value: "physicalpeople.cpf" },
        { text: "Telefone", value: "contacts[0].phone" },
        { text: "Cidade", value: "address[0].city.name" },
        { text: "Estado", value: "address[0].state" },
        { text: "Ações", value: "actions" },
    ],

    isLegal: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "legalpeople.socialName" },
        { text: "Nome Fantasia", value: "legalpeople.fantasyName" },
        { text: "CNPJ", value: "legalpeople.cnpj" },
        { text: "Telefone", value: "contacts[0].phone" },
        { text: "Cidade", value: "address[0].city.name" },
        { text: "Estado", value: "address[0].state" },
        { text: "Ações", value: "actions" },
    ]

}

    ;
export { headers };