<template>
 <v-container>
  <v-divider></v-divider>
  <v-form ref="form" v-model="component.validBankAccount">
    <v-row>
      <v-col>
        <v-autocomplete
          :items="listBanks"
          item-text="label"
          label="*Banco"
          :rules="textRules"
          v-model="component.banks.bank"
          outlined
          :readonly="component.justView"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="component.banks.count"
          :rules="textRules"
          label="*Conta"
          outlined
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="component.banks.agency"
          :rules="textRules"
          label="*Agência"
          outlined
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="component.banks.manager"
          label="Gerente"
          outlined
          :readonly="component.justView"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
 </v-container>
</template>

<script>
import { listBanks } from "./listBank";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: Boolean(),
    listBanks,
    textRules: [(v) => !!v || "Campo obrigatório!"],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>