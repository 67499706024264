<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this._props.component.addDialog" max-width="600">
      <v-card>
        <v-card-title primary-title>Bancos</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" class="mt-6">
            <v-col>
              <v-text-field
                label="*Codigo"
                v-model="code"
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="3"
                :readonly="justVisible"
                outlined
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length >= 2) ||
                    'O campo deve ter mais de 1 caractere.',
                ]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                outlined
                :rules="[
                  () => !!description || 'Campo Obrigatorio',
                  () =>
                    (!!description && description.length >= 2) ||
                    'O campo deve ter mais de 1 caractere.',
                ]"
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    cancel();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UiBusinessRules from "../../../UIBusinessRule/Bank/methods/AddDialog";
import { Data } from "../../../UIBusinessRule/Bank/Data/AddDialog";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object
  },

  data() {
    return {
      ...Data
    };
  },

  computed: {
    ...mapGetters(["getAuxBankToUpdate"]),
  },
  methods: {
    ...UiBusinessRules
  },
  watch: {
   
  },
  async created() {
    this.autoload(this.getAuxBankToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.description = this.object.description;
    // if (this.id >= 0) {
    //   this.code = this.object.code.toString();
    // } else {
    //   this.code = this.object.code;
    // }

  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  }
};
</script>

