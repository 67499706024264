<template>
  <div>
    <v-dialog v-model="dialog" max-width="450" persistent>
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              () => {
                alterDialog();
              }
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="success"
            @click="
              () => {
                confirm();
              }
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean(),
    title: String(),
    subtitle: String(),
    alterDialog: Function,
    confirm: Function,
  },
};
</script>

<style>
</style>