/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_CASHIER,
  UPDATE_CASHIER,
  GET_ALL_CASHIER,
  DESTROY_CASHIER,
  GET_FILTER_CASHIER,
  AUX_TO_UPDATE_CASHIER
} = require("./actions/cashier");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCashier = {
  state: () => ({
    cashiers: {},
    auxToUpdateCashier: Object(),
  }),

  mutations: {
    GET_ALL_CASHIER: (state, cashiers) => {
      state.cashiers = cashiers;
    },
    GET_FILTER_CASHIER: (state, payload) => {
      if (payload !== undefined) {
        state.cashiers = payload
      }
    },

    ADD_CASHIER: (state, newCashiers) => {
      state.cashiers = newCashiers;
    },
    UPDATE_CASHIER(state, editCashier) {
      state.cashiers.data.Update(editCashier)
    },

    DESTROY_CASHIER: (state, id) => {
      let auxState = [...state.cashiers.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.cashiers = [...auxState];
    },

    AUX_TO_UPDATE_CASHIER: function (state, payload) {
      state.auxToUpdateCashier = payload
    }

  },
  actions: {
    GET_ALL_CASHIER({
      commit
    }, payload) {
      axios.post("cashier/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: payload.paginate
      }).then((response) => {
        commit(GET_ALL_CASHIER, response.data[0]);
      });
    },

    GET_FILTER_CASHIER({
      commit
    }, payload) {

      axios.post("cashier/autocomplete", {
    
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
    
      }).then((response) => {
        commit(GET_FILTER_CASHIER, response.data[0]);
      })
    },

    ADD_CASHIER({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("cashier/store", payload).then((response) => {
          resolve()
          commit(ADD_CASHIER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com Sucesso!"
          })
        }).catch((error) => {
          reject(error)
        })
      })
    },

    UPDATE_CASHIER({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("cashier/update", payload).then((response) => {
          resolve()
          let data = response.data[0]
          data.initial_date = data.initial_date.split("-").reverse().join("/")
          commit(UPDATE_CASHIER, data);
          commit(SPLICE_ERRO, {
            alert: 'Atualizado com sucesso!',

          })

        }).catch(error => {
          reject(error)
        });

      })

    },

    DESTROY_CASHIER({
      commit
    }, payload) {
      axios.post("cashier/delete", { id: payload.id }).then(() => {
        commit(DESTROY_CASHIER, payload);
        commit(SPLICE_ERRO, {
          alert: 'Deletado com sucesso!'
        })
      })
    },
    AUX_TO_UPDATE_CASHIER: function (context, payload) {
      context.commit(AUX_TO_UPDATE_CASHIER, payload)
    }
  },

  getters: {
    getCashier: (state) => ({ ...state.cashiers }),
    
    getAuxCashierToUpdate: (state) => state.auxToUpdateCashier
  },
};

export default moduleCashier;