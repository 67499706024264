import { AUX_TO_UPDATE_FORM } from "../../../store/actions/formofpayment";

function visibleItem(item) {
  this.$store.dispatch(AUX_TO_UPDATE_FORM, {item: item, justVisible: true})
  this.component.addDialog = true;
}



function deleteItem(item) {
    this.destroyDialog = true;
    this.currentItem = item;
  }

  function handleClick(i) {
    this.itemSelectedOnClick = i;
  }



  export default {
    deleteItem,
    handleClick,
    visibleItem
  }

