let emmiterNfe = {
    cnpjEmitter: "",
  };
  
  async function execute(cnpj) {
    if (!cnpj) {
      throw "Favor informar cnpj do emitente";
    }
    emmiterNfe.cnpjEmitter = cnpj;
    return emmiterNfe;
  }
  
  module.exports = {
    execute,
  };
  