const ADD_UNIT = "ADD_UNIT";
const UPDATE_UNIT = "UPDATE_UNIT";
const GET_ALL_UNIT = "GET_ALL_UNIT";
const DESTROY_UNIT = "DESTROY_UNIT";
const GET_FILTER_UNIT = "GET_FILTER_UNIT"
const ITEM_UNIT_TO_UPDATE = "ITEM_UNIT_TO_UPDATE"

module.exports = {
  ADD_UNIT,
  UPDATE_UNIT,
  GET_ALL_UNIT,
  DESTROY_UNIT,
  GET_FILTER_UNIT,
  ITEM_UNIT_TO_UPDATE
};
