<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Fornecedores</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3" v-if="!companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              append-outer-icon="mdi-home-group"
              @click:append-outer="changeSelect()"

              >Adicionar</v-select
            >
          </v-col>
          <v-col class="d-flex" cols="12" sm="3" v-if="companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Grupo de Empresa"
              item-value="id"
              item-text="name"
              loader-height
              v-model="selectCompanyGroup"
              :items="companyGroups"
              append-outer-icon="mdi-home"
              @click:append-outer="changeSelect()"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form"/>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Form from "../../../components/report/provider/Form";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../store/actions/companygroup";
import { mapGetters } from 'vuex';
import axios from "axios";

export default {
  components: { Form },
  data: () => ({
    dialog: Boolean(),
    valid: false,
    selectValue: Object,
    enterprise: [],
    companyGroups: Array(),
    selectCompanyGroup: Object(),
    companyGroup: Boolean(),
    report:{
      type_people: "Default",
      codProviderInitial: null,
      codProviderFinal: null,
      date: null,
      state: null,
      city: null,
      cep: null,
      type_report: null,
      search_id: null,
    },
    provider: null,
    new_city: null
  }),
  methods: {
    async Generate() {
      if(this.companyGroup) {
        this.report.type_report = "COMPANYGROUP"
        this.report.search_id = this.selectCompanyGroup.id
      } else {
        this.report.type_report = "ENTERPRISE"
        this.report.search_id = this.selectValue.id
      }
      let all_report = Object.assign({}, this.report) ;
      if(this.$refs.form.oldState != null){
        all_report.state = this.$refs.form.oldState.text;
      }
      all_report.city = this.new_city;
      
      all_report.paginate = true
      all_report.limit = String()
      all_report.page = String()

      const response = await axios.post("catalog/provider", {
        
          ...all_report
        
      });

      this.dialog = !this.dialog;
      let arrayenterprise = [];
      let arrayaddress = [];
      let objeto = Object.assign({}, this.selectValue); 
      let objetoadress = Object.assign({}, this.selectValue.address);
      arrayenterprise.push(objeto);
      arrayaddress.push(objetoadress);
      this.createPDF(response.data[0], arrayenterprise, arrayaddress);
    },
    
    createPDF(items, enterprise, address) {
      items.forEach(e => {
        if(e.type_people == "LegalPeople"){
          e.name = e.socialName
          e.cpf = e.cnpj
        }
      });
      let data = new Date();
      var doc = new jsPDF("l", "pt");
      var columns = [
        { title: "Nome/RazãoSocial", dataKey: "name" },
        { title: "CPF/CNPJ", dataKey: "cpf" },
        { title: "Contato", dataKey: "primaryEmail" },
        { title: "Cidade", dataKey: "city" },
        { title: "Rua", dataKey: "street" },
        { title: "Cep", dataKey: "cep" },
        { title: "Banco", dataKey: "bank" },
        { title: "Agência", dataKey: "agency" },
        { title: "Conta", dataKey: "count" },
      ];
      doc.setFontSize(7);
      doc.text(`Usuário :` + this.getUsers, 700, 40)
      doc.text(`${data.toLocaleString()}`, 700, 50)
      
      doc.setFontSize(15);
      if(this.companyGroup) {
        doc.text("RELATÓRIO DE FORNECEDOR POR GRUPO DE EMPRESA", 200, 70);
        doc.setFontSize(12);
        doc.text(`${this.selectCompanyGroup.name}`, 270, 110);
      } else {
        doc.text("RELATÓRIO DE FORNECEDOR POR EMPRESA", 220, 70);
      
        doc.setFontSize(7);
        doc.text(`CNPJ : ${ enterprise[0].cnpj }`, 40, 100)
        doc.text(`Razão Social : ${ enterprise[0].name }`, 240, 100)
        doc.text(`Nome Fantasia : ${ enterprise[0].fantasyName }`, 630, 100)
        doc.text(`Rua : ${ address[0].street }`, 40, 115)
        doc.text(`Numero : ${ address[0].number }`, 250, 115)
        doc.text(`Cidade : ${ address[0].city }`, 340, 115)
        doc.text(`Estado : ${ address[0].state }`, 550, 115)
        doc.text(`CEP : ${ address[0].cep }`, 700, 115)
      }
      doc.line(40, 85, 800, 85);
      doc.line(40, 125, 800, 125);

      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();
      doc.autoTable(columns, items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [ 230, 230, 230],
          textColor: [54, 54, 54]
        },
        startY: 145,
        theme: "grid"
      });
      
      const pages = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      for (let j = 1; j < pages + 1 ; j++) {
        doc.setLineWidth(1);
        doc.rect(20, 20, 800, 555);
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 795, 35)
      }
      
      doc.output('dataurlnewwindow');
      //doc.save(pdfName + ".pdf");
    },

    GetAllNoPage(){
      let value = {
        page: null,
        limit: null,
        paginate: false
      }
      this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      this.$store.dispatch(GET_ALL_COMPANY_GROUP, value);
    },

    changeSelect() {
      this.companyGroup = !this.companyGroup
    }
  },

  computed:{ ...mapGetters(["getEnterprises", "getPhisycalProvider", "getLegalProvider","getUsers", "getCompanygroup"]) },

  created(){
    this.GetAllNoPage();
  },

  watch: {
    getEnterprises(val) {
      this.enterprise = Object.keys(val).map(key => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = aux;
    },

    getCompanygroup(val) {
      this.companyGroups = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.companyGroups[0]);
      this.selectCompanyGroup = aux
    },
  },
};
</script>

<style>
</style>