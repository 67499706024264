<template>
  <v-row justify="center">
    <v-dialog v-model="this._props.addDialogNcm" max-width="600" persistent>
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Ncm</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Codigo"
                v-model="code"
                onKeyPress="if(this.value.length==8) return false;"
                type="number"
                :readonly="justVisible"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length == 8) ||
                    'Campo tem que ser igual a 8',
                ]"
                outlined
              ></v-text-field>
              <v-textarea
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closeNcmDialog();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { ADD_NCM, UPDATE_NCM } from "../../../store/actions/ncm";
import UiBusinessRules from "../../../UIBusinessRule/Ncm/AddDialog/AddDialog";
import{ mapGetters } from "vuex";
export default {
  props: {
    addDialogNcm: Boolean,
    component: Object,
  },

  data: () => ({
    attrs: false,
    on: false,
    valid: true,
    id: "",
    code: "",
    description: "",
    search: "",
    justVisible: Boolean(),
  }),

  computed: {
    ...mapGetters(["getAuxNcmToUpdate"]),
  },
  methods: {
    ...UiBusinessRules
  },

  async created() {
    this.autoload(this.getAuxNcmToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.description = this.object.description;
    // if (this.id >= 0) {
    //   this.code = this.object.code.toString();
    // } else {
    //   this.code = this.object.code;
    // }
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
