

var headerTable = [
    { text: "Código", value: "product_id" },
    { text: "Descrição", value: "description_product" },
    { text: "Un", value: "description_unit" },
    { text: "Qaunt.", value: "amount" },
    { text: "Valor de Custo", value: "cust" },
    { text: "Sub Total", value: "value_sub_total_product" },
    { text: "CFOP", value: "cfop_id" },
    { text: "Cst/Csosn", value: "cst_id" },
    { text: "Base ICMS", value: "value_base_icms_product" },
    { text: "Valor Icms", value: "value_icms_product" },
    { text: "Valor IPI", value: "value_ipi_product" },
    { text: "Valor Isento", value: "value_descont" },
    { text: "Outros Valores", value: "value_other_pis_product" },
    { text: "Base de Cálculo ST", value: "value_icms_st_product" },
    { text: "A.IPI", value: "aliquot_ipi_product" },
    { text: "Ações", value: "actions" },
]

module.exports = {
    headerTable
}