/* eslint-disable no-unused-vars */
import axios from "axios";
import { SPLICE_ERRO } from "./actions/alert_api";

const {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  DESTROY_ENTERPRISE,
  SELECTED_ENTERPRISE,
  GET_FILTER_ENTERPRISE,
  AUX_TO_UPDATE_ENTERPRISE,
  VIEW_ENTERPRISE,
} = require("./actions/enterprise");

const moduleEnterprise = {
  state: () => ({
    enterprises: Object(),
    selectedEnterprise: null,
    auxToUpdateEnterprise: Object(),
    justVisible: Boolean(),
  }),

  mutations: {
    GET_FILTER_ENTERPRISE: (state, payload) => {
      if (payload !== undefined) {
        state.enterprises = payload;
      }
    },
    SELECTED_ENTERPRISE: function (store, payload) {
      store.selectedEnterprise = payload;
    },
    GET_ALL_ENTERPRISE: (state, enterprises) => {
      state.enterprises = enterprises;
    },

    ADD_ENTERPRISE: (state, newEnterprises) => {
      state.enterprises = newEnterprises;
    },
    UPDATE_ENTERPRISE(state, payload) {
      let editEnterprise = payload[0];
      state.enterprises[0].data.Update(editEnterprise);
    },
    DESTROY_ENTERPRISE: (state, id) => {
      let auxState = [state.enterprises[0].data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.enterprises = [...auxState];
    },
    AUX_TO_UPDATE_ENTERPRISE: function (state, payload) {
      state.auxToUpdateEnterprise = payload
    },
    VIEW_ENTERPRISE: function (state, payload) {
      state.justVisible = payload
    },
  },
  actions: {
    GET_FILTER_ENTERPRISE({ commit }, payload) {
      axios
        .post("products/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_FILTER_ENTERPRISE, response.data);
        });
    },
    SELECTED_ENTERPRISE: function (context, payload) {
      context.commit(SELECTED_ENTERPRISE, payload);
    },
    GET_ALL_ENTERPRISE({
      commit
    },
      payload
    ) {
      
      axios.post("enterprise/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
        commit(GET_ALL_ENTERPRISE, response.data[0]);
      });
    },

    ADD_ENTERPRISE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/store", payload)
          .then((response) => {
            resolve();
            commit(ADD_ENTERPRISE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_ENTERPRISE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/update", payload)
          .then((response) => {
            resolve();
            commit(UPDATE_ENTERPRISE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/delete", {
          address_id: payload.address_id,
          enterprise_id: payload.id,
        })
        .then((response) => {
          commit(DESTROY_ENTERPRISE, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data[0].msg,
            type: "S",
          });
        });
    },

    AUX_TO_UPDATE_ENTERPRISE: function (context, payload) {
      context.commit(AUX_TO_UPDATE_ENTERPRISE, payload)
    },

    VIEW_ENTERPRISE: function (context, payload) {
      context.commit(VIEW_ENTERPRISE, payload)
    }
  },

  getters: {
    getSelectedEnterprise: (state) => state.selectedEnterprise,
    getEnterprises: (state) => ({
      ...state.enterprises[0],
    }),
    getAuxEnterpriseToUpdate: (state) => state.auxToUpdateEnterprise,

    getViewEnterprise: (state) => state.justVisible,
  },
};

export default moduleEnterprise;
