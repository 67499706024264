<template>
    <v-row justify="center">
        <v-dialog v-model="component.dialog_cancel" max-width="500" persistent>
            <v-card class="d-flex justify-center">
                <v-col>
                    <v-card-title>Deseja cancelar esse Faturamento?</v-card-title>
                    <v-divider class="mb-5" />
                    <v-form ref="form" v-model="form">
                        <v-text-field label="CNPJ Emitente" v-model="getEnterprises.data[0].cnpj" filled disabled dense
                            outlined />
                        <v-text-field label="Chave de acesso" v-model="this.component.objectNfeCancel.access_key" filled
                            disabled dense outlined />
                        <v-text-field label="Protocolo" v-model="this.component.objectNfeCancel.authorization_protocol"
                            filled disabled dense outlined />
                        <v-textarea label="Justificativa" :rules="rules" v-model="justification" dense outlined />
                        <v-textarea label="Modelo de Nota" v-model="modelNf" dense outlined />
                        <v-divider class="mt-1" />
                        <v-card-actions class="d-flex justify-end">
                            <v-btn @click="cancel" class="error">Sair</v-btn>
                            <v-btn @click="confirmationCancel" color="primary"
                                :disabled="isConfirmationButtonDisabled">Confirmar</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-col>
                <AlertForComponents :active="alert" message="Preencha todos os campos" />
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { CANCEL_NFE } from "../../../../store/actions/nfe"
import { REVERSE_REVENUE } from "../../../../store/actions/revenues"
export default {
    props: {
        component: Object,
    },
    data() {
        return {
            justification: String(),
            modelNf: "55",
            justification: String()
        };
    },
    methods: {
        cancel() {
            this._props.component.dialog_cancel = !this._props.component.dialog_cancel;
        },
        async confirmationCancel() {
            const cnpj = this.getEnterprises.data[0].cnpj;
            const invoceNfeCancel = {
                cnpjEmitter: cnpj.replace(/\D/g, ""),
                key: this.component.objectNfeCancel.access_key,
                justification: this.justification,
                protocol: this.component.objectNfeCancel.authorization_protocol,
                modelNf: this.modelNf,
            };
            const revenuesCancel = {
                id: this.component.objectNfeCancel.id,
                enterprise_id : this.getEnterprises.data[0].id
            };
            await this.$store
                .dispatch(CANCEL_NFE, invoceNfeCancel)
                .then(() => {
                    this.$store.dispatch(REVERSE_REVENUE, revenuesCancel);
                    this.cancel()
                })
                .catch((error) => {
                    alert(error)
                });
        },

    },
    computed: {
        ...mapGetters(["getEnterprises"]),
        isConfirmationButtonDisabled() {
            return this.justification.length < 15;
        },
    },
}

</script>