<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="this._props.component.addDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title>Movimentação estoque</v-card-title>
        <v-container>
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-col>
              <v-autocomplete
                auto-select-first
                outlined
                required
                return-object
                persistent-hint
                item-text="description"
                item-value="id"
                v-model="modalValues.tax_type"
                :items="this.component.taxOperationType"
                label="Tipo de movimentação"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Data da Movimentação"
                type="date"
                v-model="modalValues.date_movement"
                :rules="[
                  () => !!modalValues.date_movement || 'Campo Obrigatorio',
                  () =>
                    (!!modalValues.date_movement &&
                      modalValues.date_movement.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
                outlined
                readonly
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Nome do Produto"
                return-object
                v-model="product"
                :items="this.component.product"
                item-text="description_item"
                item-value="id"
                :rules="[
                  () => !!product || 'Campo Obrigatorio',
                ]"
                outlined
                readonly
                required
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Saldo de Movimentação"
                v-model="modalValues.value_movement"
                type="number"
                :rules="[
                  () => !!modalValues.value_movement || 'Campo Obrigatorio',
                  () =>
                    (!!modalValues.value_movement &&
                      modalValues.value_movement.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
                outlined
                readonly
                required
              ></v-text-field>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    runAlterDialog(this, alterAddDialog);
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import {
  runAlterDialog,
  alterAddDialog,
  alterUpdateDialog,
} from "../../Methods/index";
import {
  ADD_ALL_MOVESTOCK,
  UPDATE_ALL_MOVESTOCK,
} from "../../../store/actions/move_stock";

export default {
  props: {
    component: Object,
  },

  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      updated_at: "",
      created_at: "",
      resultmoveStock: null,
      svgPath: mdiAccount,
      search: "",
      formHasErrors: false,
      autoComplete: Array(),
      product: "",
      modalValues: Object({
        id: undefined,
        tax_type: Number() ,
        value_movement: Number(),
        product_id: Number,
        enterprise_id: Number,
        date_movement: Date,
        tax_operation_id: Number,
        cfop_id: Number(),
        invoiceentry_id: Number(1)
      }),
    };
  },
  methods: {
    getComponent() {
      return this._props.component;
    },
    async create_moveStock() {
      this.modalValues.product_id = this.product.id;
      this.modalValues.cfop_id = this.product.cfop_id;
      const moveStock = {
        ...this.modalValues,
      };
      moveStock.provius_balance = 1;
      if(this.modalValues.tax_type.type == "ENTRADA"){
        moveStock.type_movement = 1  
      }else{
        moveStock.type_movement = 2
      }
      //moveStock.type_movement = this.modalValues.tax_type.type;
      moveStock.tax_operation_id = this.modalValues.tax_type.id;
      try {
        await this.$store.dispatch(ADD_ALL_MOVESTOCK, moveStock);
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.modalValues.id == undefined
        ? this.create_moveStock()
        : this.update_moveStock();
      this.component.addDialog = false;
    },
    async update_moveStock() {
      const moveStock = { ...this.modalValues };
      if(this.product.id){
        moveStock.product_id = this.product.id
        moveStock.cfop_id = this.product.cfop_id;
      }
      moveStock.provius_balance = 1;
      moveStock.type_movement = this.modalValues.tax_type.type;
      moveStock.tax_operation_id = this.modalValues.tax_type.id;
      // moveStock.date_movement = this.dataAtualFormatada(
      //   moveStock.date_movement
      // );
      
      try {
        await this.$store.dispatch(UPDATE_ALL_MOVESTOCK, moveStock);
      } catch (error) {
        alert(error);
      }
    },
    show_moveStock(moveStock) {
      (this.moveStock = moveStock.moveStock),
        (this.description = moveStock.description);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    runAlterDialog,
    alterAddDialog,
    alterUpdateDialog,
    dataAtualFormatada(date) {
      var data = new Date(date);
      let dia = data.getDate().toString();
      let diaF = dia.length == 1 ? "0" + dia : dia;
      let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
      let mesF = mes.length == 1 ? "0" + mes : mes;
      let anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    },
    // GetTaxtData() {
    //   let alltaxOperation = this.component.getTaxOperationType;
    //   let autoComplete = [];
    //   alltaxOperation.data.forEach((r) => {
    //     autoComplete.push({ item: r.description, value: r.id });
    //   });
    //   this.autoComplete = [...autoComplete];
    // },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    if (Object.keys(this.object).length > 0) {
      this.modalValues.id = this.object.id;
      this.modalValues.cfop_id = this.object.cfop.id;
      this.modalValues.product_id = this.object.products.id;
      this.product = this.object.products.id;
      this.modalValues.enterprise_id = this.object.enterprise_id;
      this.modalValues.tax_operation_id = this.object.tax_operation_id;
      this.modalValues.tax_type = this.object.tax_type;
      this.modalValues.type_movement = this.object.type_movement;
      this.modalValues.value_movement = this.object.value_movement;
      this.modalValues.date_movement = this.object.date_movement.split('/').reverse().join('-');
      
    }else{
      this.modalValues.enterprise_id = this.component.selectValue.id;
    }
   
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
 
};
</script>
