<v-col class="d-flex" cols="12" sm="3">
   </v-col>
<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <div class="d-flex flex-column">
      <v-select
        class="mr-2"
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >

      <v-btn
        class="mr-2"
        color="success"
        v-if="component.permission.Cadastrar"
        @click="
          () => {
            executeAlterAddDialog();
          }
        "
        >Adicionar</v-btn
      >
      <v-btn
        class="mt-3 mr-2"
        @click="executeAlterAddDialogTranfCaixa()"
        color="success"
        >Transferência de caixa</v-btn
      >
    </div>
  </v-row>
</template>

<script>
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    selectValue: Number(),
    enterprises: [],
  }),

  created() {
    this.GetAllNoPage();
  },

  methods: {
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    executeAlterAddDialog() {
      this.alterAddDialog(this.getComponent());
    },
    executeAlterAddDialogTranfCaixa() {
      this.component.activeDialogCashRegisterTransfer =
        !this.component.activeDialogCashRegisterTransfer;
    },

    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    selectValue: function () {
      this.component.enterpriseId = this.selectValue.id;
      this.$store.dispatch(SELECTED_ENTERPRISE, this.selectValue);
    },
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectValue = this.enterprises[0];
    },
  },
};
</script>

<style>
</style>
