



const MockData = [
    {
        id: "1",
        key: "545462164",
        numberProtocol: "5464646546",
        status: "Ativo",
        descriptionNatureOperation: "Descrição",
        seriesDocumentSupervisior: "546541213",
        numberNfe: "1231243123",
        issuanceDateNfe: "4123123",
        cnpjorcpfDestination: "4123123123",
        nameDestination: "Lucas",
        valueBaseTotalCalculeIcms: "91",
        valueTotalCalculeIcms: "42",
        valueTotalProduct: "55",
        valueTotalNF: "133",
    }
]

module.exports = {
    MockData
}