import {headerTypeProcess,type_people,id,client_id,enterprise_id,physical_people,address,contact,new_city,ListContact,ListAddress,valid} from "./WipeData"


function wipeData(){
    this.headerTypeProcess = {...headerTypeProcess}
    this.type_people = {...type_people}
    this.id = {...id}
    this.client_id = {...client_id}
    this.enterprise_id = {...enterprise_id}
    this.physical_people = {...physical_people}
    this.address = {...address}
    this.contact = {...contact}
    this.new_city = {...new_city}
    this.ListContact = {...ListContact}
    this.ListAddress = {...ListAddress}
    this.valid = {...valid}
}



export default {
    wipeData
}