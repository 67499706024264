const header = [
    { text: "Nome", value: "people.physicalpeople.name" },
    { text: "CPF", value: "people.physicalpeople.cpf" },
    { text: "Identidade", value: "people.physicalpeople.idCard" },
    { text: "Data de Nascimento", value: "people.physicalpeople.dateOfBirth" },
    { text: "Nacionalidade", value: "people.physicalpeople.nacionality" },
    { text: "Naturalidade", value: "people.physicalpeople.naturality" },
    { text: "Estado Civil", value: "people.physicalpeople.civilStatus" },
    { text: "Ações", value: "actions" },
];

export { header };