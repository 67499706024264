<template>
  <v-row justify="center">
    <v-dialog
      v-model="this.component.dialog_cancel"
      max-width="500"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title> Deseja Excluir este item do Vendedor ? </v-card-title>
        <v-card-subtitle class="mt-1"
          >Essa ação irá excluir o Vendedor</v-card-subtitle
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="component.dialog_cancel = !component.dialog_cancel"
            >Cancelar</v-btn
          >
          <v-btn class="success" @click="component.confirmDelete()"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },
};
</script>

