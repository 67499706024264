import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    users: []
  }),
  mutations: {
    SET_DATA (state, payload) {
      const newUser = {
        name: payload.user.name,
        email: payload.user.email
      }
      state.users = [...state.users, newUser]
    }
  },
  actions: {
    async REGISTER ({
      dispatch
    }, payload) {
      const response = await axios.post('register', payload)
      dispatch('attempt', response.data)
    },
    async attempt ({
      commit
    }, data) {
      commit('SET_DATA', data)
    }
  }
}
