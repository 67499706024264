<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="this._props.component.addDialog"
      max-width="600"
    >
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Forma de Pagamento</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-select
                label="*Código Sefaz"
                type="number"
                :items="formPaymentType"
                item-text="description"
                item-value="value"
                v-model="code"
                :rules="[(v) => !!v || 'Campo Obrigatorio']"
                outlined
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[
                  () => !!description || 'Campo Obrigatorio',
                  () =>
                    (!!description && description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    this.close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UiBusinessRules from "../../../UIBusinessRule/FormOfPayment/AddDialog/AddDialog";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },

  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      id: "",
      code: "",
      description: "",
      search: "",
      justVisible: Boolean(),
      formPaymentType: [
        { description: "Dinheiro", value: "01" },
        { description: "Cheque", value: "02" },
        { description: "Cartão de Crédito", value: "03" },
        { description: "Cartão de Débito", value: "04" },
        { description: "Crédito Loja", value: "05" },
        { description: "Vale Alimentação", value: "10" },
        { description: "Vale Refeição", value: "11" },
        { description: "Vale Presente", value: "12" },
        { description: "Vale Combustível", value: "13" },
        { description: "Boleto Bancário", value: "15" },
        { description: "Sem Pagamento", value: "90" },
        { description: "Outros", value: "99" },
        { description: "Depósito Bancário", value: "16" },
        { description: "Pagamento Instantâneo (PIX)", value: "17" },
        { description: "Transferência Bancária, Carteira Digital", value: "18" },
        { description: "Programa de Fidelidade, Cashback, Crédito Virtual", value: "19" },
      ],
    };
  },

  computed: {
    ...mapGetters(["getAuxFormToUpdate"]),
  },
  methods: {
    ...UiBusinessRules,
  },

  async created() {
    this.autoload(this.getAuxFormToUpdate)
    // this.object = { ...this.getComponent().objectToUpdate };
    // this.id = this.object.id;
    // this.code = this.object.code;
    // this.description = this.object.description;
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
