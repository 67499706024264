<template>
  <v-row>
    <v-dialog
      persistent
      v-model="component.activeDialogCashRegisterTransfer"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title>Movimentação Financeira</v-card-title>
        <v-divider class="ml-3 mr-3"></v-divider>
        <v-form ref="form" v-model="enable">
          <v-container>
            <v-select
              outlined
              label="Empresa"
              :items="enterpriseList"
              v-model="object.enterprise_id"
              item-text="fantasyName"
              item-value="id"
            />
            <v-text-field
              v-model="object.date_movement"
              outlined
              label="Data"
              type="date"
              :rules="[(v) => !!v || 'Campo Obrigatório']"
            />
            <div class="d-flex flex-row">
              <v-select
                :items="cashier"
                v-model="object.id_cashier_origin"
                item-value="id"
                item-text="description"
                outlined
                label="Caixa Origem"
                class="mr-2"
                :rules="[(v) => !!v || 'Campo Obrigatório']"
              />
              <v-icon color="black" class="mb-6">mdi-arrow-right</v-icon>
              <v-select
                :items="cashier"
                v-model="object.id_cashier_destination"
                item-value="id"
                item-text="description"
                outlined
                label="Caixa Destino"
                class="ml-2"
                :rules="[(v) => !!v || 'Campo Obrigatório']"
              />
            </div>
            <v-text-field
              outlined
              label="Valor da movimentação"
              type="number"
              prefix="R$"
              v-model="object.value_transfer"
              :rules="[(v) => !!v || 'Campo Obrigatório']"
            />
            <v-text-field
              v-model="object.number_movement"
              outlined
              label="Número do Documento"
              type="number"
              :rules="[
                (v) => !!v || 'Preencher campo',
                (v) =>
                  (v && v.length >= 10) ||
                  'Número de documento deve ter no mínimo 10 Caracteres',
              ]"
            />
            <v-select
              :items="accountPlansItems"
              item-text="description"
              item-value="id"
              v-model="object.account_plans_id"
              outlined
              label="Plano de Contas"
              :rules="[(v) => !!v || 'Campo Obrigatório']"
            />
            <v-textarea
              v-model="object.description_transfer"
              outlined
              label="Informação da Movimentação"
            ></v-textarea>
            <v-divider class="ml-3 mr-3"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="closeDialog()">Cancelar</v-btn>
              <v-btn :disabled="!enable" color="success" @click="submit()"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { ADD_TRANSFER_CASHIER } from "../../../store/actions/financial_movement";
import { GET_ALL_ACCOUNT_PLAN } from "../../../store/actions/account_plan";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
export default {
  props: {
    enterpriseID: Number(),
    component: Object(),
  },
  data() {
    return {
      accountPlansItems: Array(),
      enterpriseList: Array(),
      object: {
        date_movement: undefined,
        number_movement: undefined,
        type_transfer: "Transferencia",
        description_transfer: undefined,
        value_transfer: undefined,
        id_cashier_origin: Object(),
        id_cashier_destination: Object(),
        enterprise_id: undefined,
        account_plans_id: undefined,
      },

      id: null,
      enable: false,
      selectValueOrigin: Object(),
      selectValueDestiny: Object(),
      cashier: [],
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getAllEnterprises() {
      this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    getAccountPlans() {
      this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    submit() {
      this.id == null ? this.create() : this.update();
    },
    async create() {
      this.$store.dispatch(ADD_TRANSFER_CASHIER, this.object).then(() => {
        this.component.activeDialogCashRegisterTransfer =
          !this.component.activeDialogCashRegisterTransfer;
        this.reset();
        this.resetValidation();
      });
    },
    update() {},
    closeDialog() {
      this.component.activeDialogCashRegisterTransfer =
        !this.component.activeDialogCashRegisterTransfer;
      this.object = Object();
    },
  },

  computed: {
    ...mapGetters(["getCashier", "getAccount_Plan", "getEnterprises"]),
  },
  watch: {
    getEnterprises: function () {
      this.enterpriseList.push(this.getEnterprises[0]);
    },
    getAccount_Plan: function () {
      this.accountPlansItems.push(this.getAccount_Plan[0]);
    },
    getCashier: function (val) {
      this.cashier = Object.values(val);
      this.valueOrigin = this.cashier[0];
      this.valueDestiny = this.cashier[0];
    },
  },
  created() {
    this.getAllEnterprises();
    this.getAccountPlans();
  },
};
</script>

<style>
</style>