/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_COMISSION_TYPE,
  GET_ALL_COMISSION_TYPE,
  DESTROY_COMISSION_TYPE,
  UPDATE_COMISSION_TYPE,
  GET_FILTER_COMISSION_TYPE
} = require("./actions/commission_type");
const { SPLICE_ERRO } = require("./actions/alert_api")

const moduleComissionType = {

  state: () => ({
    commission_types: Object(),
  }),

  mutations: {
    GET_ALL_COMISSION_TYPE: (state, commission_types) => {
      if (Array.isArray(commission_types.data)) {
        commission_types.data.forEach(e => {
          switch (e.commissionType) {
            case 1:
              e.commissionType = "Items";
              break;
            case 2:
              e.commissionType = "Vendas";
              break;
            case 3:
              e.commissionType = "Produto";
              break;
            default:
              break;
          }
        });
      }    
      state.commission_types = commission_types;
    },
    GET_FILTER_COMISSION_TYPE: (state, payload) => {
      if (payload != undefined) {
        payload.data.forEach(e => {
          if (e.commissionType == 1) {
            e.commissionType = "Items";
          } else if (e.commissionType == 2) {
            e.commissionType = "Vendas";
          } else if (e.commissionType == 3) {
            e.commissionType = "Produto";
          }
        })
      }
      state.commission_types = payload
    },
    ADD_COMISSION_TYPE: (state, payload) => {
      state.commission_types = payload[0];
    },
    DESTROY_COMISSION_TYPE: (state, payload) => {
      let oldState = { ...state.commission_types }
      let data = []
      oldState.data.forEach((e) => {
        if (e.id !== payload) data.push(e)
      })
      state.commission_types.data = data;
    },
    UPDATE_COMISSION_TYPE: (state, payload) => {
      if (payload.commissionType == 1) {
        payload.commissionType = "Items";
      } else if (payload.commissionType == 2) {
        payload.commissionType = "Vendas";
      } else if (payload.commissionType == 3) {
        payload.commissionType = "Produto";
      }
      state.commission_types.data.Update(payload)
    }


  },
  actions: {
    async GET_ALL_COMISSION_TYPE({
      commit
    }, payload) {
      
      try {
        const response = await axios.post("commissionType/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })
        commit(GET_ALL_COMISSION_TYPE, response.data[0]);
        return response.data[0]
      } catch (error) {
        throw new Error(error)
      }

    },

    GET_FILTER_COMISSION_TYPE({
      commit
    }, payload) {
      axios.post("commissionType/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_COMISSION_TYPE, response.data[0]);
      })
    },

    ADD_COMISSION_TYPE({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("commissionType/store", payload).then(({ data }) => {
          resolve()
          commit(ADD_COMISSION_TYPE, data)
          commit(SPLICE_ERRO, {
            alert: "Cadastrado com sucesso!",
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })
    },
    DESTROY_COMISSION_TYPE({ commit }, payload) {

      axios.post("commissionType/delete", { id: payload })
        .then((response) => {
          commit(DESTROY_COMISSION_TYPE, payload)
          commit(SPLICE_ERRO, {
            alert: response.data.menssage[0],
            type: 'S'
          })
        })
    },
    UPDATE_COMISSION_TYPE({ commit }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("commissionType/update", payload)
          .then(({ data }) => {
            resolve()
            commit(UPDATE_COMISSION_TYPE, data[0])
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: 'S'
            })
          }).catch(() => {
            reject()
          })

      })

    }


  },

  getters: {
    getCommission_types: (state) => ({
      ...state.commission_types
    }),
  },
};

export default moduleComissionType;