import { GET_ALL_ENTERPRISE } from "../../store/actions/enterprise";
async function GetAllNoPage() {
  let value = {
    page: null,
    limit: null,
    paginate: false,
  };
  await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
}

function getComponent() {
  return this.component;
}

function executeAlterAddDialog() {
  this.$store.dispatch("setShowDialogRegisterAliquot", true);
}

function alterAddDialog(component) {
  this.component.action = "Registrar";
  component.addDialogAliquot = !component.addDialogAliquot;
}

export default {
  GetAllNoPage,
  getComponent,
  executeAlterAddDialog,
  alterAddDialog,
};
