<template>
  <v-list-group no-action :value="false" color="white">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-account-supervisor</v-icon
          >Fiscal</v-list-item-title
        >
      </v-list-item-content>
    </template>
    <div v-for="link in fiscal" :key="link.text">
      <v-list-item
        color="white"
        router
        :to="link.route"
        class="navDrop"
        v-if="link.active"
      >
        <v-list-item-icon>
          <v-icon class="white--text">{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="link.class">{{
            link.text
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list-group>
</template>

<script>
import { fiscal } from "./script";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    fiscal,
  }),
  methods: {
    activePermissions() {
      fiscal.forEach((permissionScript) => {
        this.getFiscalLinks.forEach((getPermissionLink) => {
          if (permissionScript.text == getPermissionLink.text) {
            permissionScript.active = true;
          }
        });
      });
    },
  },
  computed: {
    ...mapGetters(["getFiscalLinks"]),
  },
  created() {
    this.activePermissions();
  },
};
</script>

<style></style>
