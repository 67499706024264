import axios from "axios";
const {
  ADD_TAX_OPERATION_TYPE,
  UPDATE_TAX_OPERATION_TYPE,
  GET_ALL_TAX_OPERATION_TYPE,
  DESTROY_TAX_OPERATION_TYPE,
  GET_FILTER_TAX_OPERATION_TYPE,
  AUX_TO_UPDATE_TAX_OPERATION_TYPE
} = require("./actions/tax_operation_types");
const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleTaxOperationType = {
  state: () => ({
    taxoperationtypes: Object(),
    itemToUpdateTaxOperationTypes: null,
    newTaxOperationToAdd: Object(),
    deletedItemId: Number(),
  }),

  mutations: {
    GET_ALL_TAX_OPERATION_TYPE: (state, taxoperationtypes) => {
      if (taxoperationtypes.current_page) {
        taxoperationtypes.data.forEach((e) => {
          if (e.type === "1") {
            e.type = "ENTRADA"
          } else {
            e.type = "SAIDA"
          }
        })
      } else {
        taxoperationtypes.forEach((e) => {
          if (e.type === "1") {
            e.type = "ENTRADA"
          } else {
            e.type = "SAIDA"
          }
        })
      }
      state.taxoperationtypes = taxoperationtypes;
    },

    GET_FILTER_TAX_OPERATION_TYPE: (state, taxoperationtypes) => {
      if (taxoperationtypes.current_page) {
        taxoperationtypes.data.forEach((e) => {
          if (e.type === "1") {
            e.type = "ENTRADA"
          } else {
            e.type = "SAIDA"
          }
        })
      } else {
        taxoperationtypes.forEach((e) => {
          if (e.type === "1") {
            e.type = "ENTRADA"
          } else {
            e.type = "SAIDA"
          }
        })
      }
      state.taxoperationtypes = taxoperationtypes;
    },
    
    ADD_TAX_OPERATION_TYPE: (state, newTaxoperationtypes) => {
      // state.taxoperationtypes = newTaxoperationtypes;
      state.newTaxOperationToAdd = newTaxoperationtypes;
    },
    UPDATE_TAX_OPERATION_TYPE(state, editTaxoperationtypes) {
      if (editTaxoperationtypes.type === "1") {
        editTaxoperationtypes.type = "ENTRADA"
      } else {
        editTaxoperationtypes.type = "SAIDA"
      }

      state.taxoperationtypes.data.Update(editTaxoperationtypes);
    },
    DESTROY_TAX_OPERATION_TYPE: (state, id) => {

        state.deletedItemId = id

      // let auxState = [...state.taxoperationtypes.data];
      // let index = auxState.indexOf(auxState.find((value) => value.id === id));
      // if (index >= 0) {
      //   auxState.splice(index, 1);
      //   state.taxoperationtypes = [...auxState];
      // }
    },

    AUX_TO_UPDATE_TAX_OPERATION_TYPE: function (state, payload) {
      state.itemToUpdateTaxOperationTypes = payload;
    }
  },

  actions: {
   async GET_ALL_TAX_OPERATION_TYPE({ commit }, payload) {
      try {
        const response = await axios
          .post("taxOperationType/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate })

          commit(GET_ALL_TAX_OPERATION_TYPE, response.data[0]);

          return response.data[0];

      } catch (error) {
        throw new Error(error)
      }

        
      // .then((response) => {

      //   commit(GET_ALL_TAX_OPERATION_TYPE, response.data[0]);
      // });
    },

    GET_FILTER_TAX_OPERATION_TYPE({
      commit
    }, payload) {
      axios.post("taxOperationType/autocomplete", {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
      }).then((response) => {
        commit(GET_FILTER_TAX_OPERATION_TYPE, response.data[0]);
      })
    },

    ADD_TAX_OPERATION_TYPE({ commit }, payload) {
      return new Promise((resolve, reject) => {

        axios
          .post("taxOperationType/store", payload)
          .then((response) => {
            resolve()
            commit(ADD_TAX_OPERATION_TYPE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
            });
          }).catch(() => {
            reject()
          })


      })
    },

    UPDATE_TAX_OPERATION_TYPE({ commit }, payload) {
      return new Promise((resolve, reject) => {

        axios
          .post("taxOperationType/update", payload)
          .then((response) => {
            resolve()
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
            });
            commit(UPDATE_TAX_OPERATION_TYPE, response.data[0]);
          }).catch(() => {
            reject()
          })

      })
    },

    async DESTROY_TAX_OPERATION_TYPE({ commit }, payload) {
      try {
        const response = await axios
          .post("taxOperationType/delete", {
            id: payload,
          });
        commit(SPLICE_ERRO, {
          alert: response.data.menssage[0],
        });

        commit(DESTROY_TAX_OPERATION_TYPE, payload);
        
        const returnPayload = {
          id: payload,
          code: response.data.code
        }
        
        return returnPayload

      } catch (error) {
        return new Error('Error',error)
      }

    },

    AUX_TO_UPDATE_TAX_OPERATION_TYPE: function (context, payload) {
      context.commit(AUX_TO_UPDATE_TAX_OPERATION_TYPE, payload)
    }

  },

  getters: {
    getTaxOperationType: (state) => ({...state.taxoperationtypes}),
    getDeletedItemid: (state) => state.deletedItemId,
    getItemToUpdateTaxOperationType: (state) => state.itemToUpdateTaxOperationTypes,
    getNewTaxOperationToAdd: (state) => state.newTaxOperationToAdd
  },
};

export default moduleTaxOperationType;
